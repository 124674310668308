import React, { useEffect, useState, useRef } from "react";
import Utils from "../widgets/edit/WebBuilder/Utils";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { filter_class } from '../widgets/edit/ControlFilter';
import { chartSlice } from "../../../app/_redux/modlSlice";
import KiciKeyword from '../widgets/kici/partsComponent/KiciKeyword';
import { Row } from "react-bootstrap";
import Loading from "./Loading";

// =====================================================
// [S] 2차원 배열 생성 함수
// =====================================================
Array.prototype.division = function(number) {
    var arr = this;
    var length = arr.length;
    var count = Math.floor(length / number) + (Math.floor(length % number) > 0? 1: 0);
    var temp = [];

    for(var i=0; i<count; i++){
        temp.push(arr.splice(0,number));
    }

    return temp;
}

const KiciHistoryFilter = ({ className, el }) => {

    let filter_put;

    // =====================================================
    // [S] state
    // =====================================================
    const [filter_nav, set_filter_nav] = useState(1);
    const [keyword_arr, set_keyword_arr] = useState([]);
    const swiper_ref = useRef(null);
    const [filter_in, set_filter_in] = useState(['전체']);


    // =====================================================
    // [S] redux action
    // =====================================================
    const { changeChartList } = chartSlice.actions;


    // =====================================================
    // [E] 키워드 렌더링
    // =====================================================
    useEffect(()=>{
        let flag_effect = true;

        let payload = {
            queries: [
                {
                    key: "keyword_list",
                    query: `db.collection('CrawlerTemplate').find({
                        "metadata.source" : "${el.data.rowdata.source}"
                    }).toArray()`
                },
            ]
        }
        console.log(el.data.rowdata.source);

        Utils.getQueryResult(payload).then((result) => {

            console.log(result.keyword_list.length);
            if(result.keyword_list.length == 0){
                flag_effect = false;
                return
            }
            if(flag_effect){
                filter_put = new filter_class(result);
                const filter_result = filter_put.keyword_method();

                set_keyword_arr(filter_result);
            }
        });
        return ()=>{
            flag_effect = false;
        }
    },[]);


    // =====================================================
    // [S] swipe index
    // =====================================================
    const swiper_func = {
        onSlideChange: (e) => nav_func(e.activeIndex)
    }


    // =====================================================
    // [S] swipe move
    // =====================================================
    const nav_func = (e) => {
        set_filter_nav(Number(e) + 1);
    }


    // =====================================================
    // [S] 키워드 체크
    // =====================================================
    const select_keyword = (param) => {
        const keyword_absence = filter_in.includes(param);

        if(keyword_absence){

            const after_arr = filter_in.filter((data) => data !== param);
            set_filter_in((filter_in)=>{ return after_arr });

        } else {

            set_filter_in([...filter_in, param]);

        }
    }

    return(
        <>
            <div className={`card card-custom ${className} kici_custom_card`}>
                <div className="card-header border-0 kici_custom_header">
                    <h3 className="card-title font-weight-bolder text-dark">
                        전체 내역 필터
                    </h3>
                </div>
                <div className="card-body pt-4 kici_custom_card_body">
                    <div className="keyword_wrap">
                        <p className="keyword_text">키워드</p>
                        <Swiper {...swiper_func} ref={swiper_ref}>
                        {
                            keyword_arr.length === 0? 
                            <Loading color={"#3a427a"}/> 
                            :
                            keyword_arr.map((data, index)=>(
                                <SwiperSlide key={index}>
                                    <div className={index === keyword_arr.length ? "swiper_slide_wrap" : "last swiper_slide_wrap"}>
                                        {
                                            data.map((data, index)=>(
                                                <KiciKeyword data={data} key={index}/>
                                            ))
                                        }
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                        </Swiper>
                        <div className="filter_btn_wrap">
                            <div className="swipe_bar">
                                <div className="inner_bar"
                                    style={{ width : (Number(filter_nav) / Number(keyword_arr.length)) * 100 + "%"}}
                                ></div>
                            </div>
                            <div className="swipe_number">
                                <div className="chapter_wrap">
                                    <span>
                                        &lt;
                                    </span>
                                    <span>{filter_nav}</span>
                                    <span>/</span>
                                    <span>
                                        {keyword_arr && keyword_arr.length}
                                    </span>
                                    <span>
                                        &gt;
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default React.memo(KiciHistoryFilter);