
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageAdminMember_Delete(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    사용자 추가
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>






                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>아이디*</Form.Label>
                        <Form.Control placeholder="아이디를 입력해주세요" />
                    </Form.Group>

                    <Form.Group id="formUserId">
                        <Form.Label>이름*</Form.Label>
                        <Form.Control placeholder="이름을 입력해주세요" />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} id="formPassword">
                            <Form.Label>비밀번호*</Form.Label>
                            <Form.Control placeholder="비밀번호를 입력해주세요" />
                        </Form.Group>

                        <Form.Group as={Col} id="formPasswordConfirm">
                            <Form.Label>비밀번호 확인*</Form.Label>
                            <Form.Control placeholder="동일한 비밀번호를 입력해주세요" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} id="formPhone">
                            <Form.Label>전화 번호</Form.Label>
                            <Form.Control placeholder="전화번호를 입력해주세요" />
                        </Form.Group>

                        <Form.Group as={Col} id="formEmail">
                            <Form.Label>이메일</Form.Label>
                            <Form.Control placeholder="이메일을 입력해주세요" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group id="formMemo">
                        <Form.Label>메모</Form.Label>
                        <Form.Control placeholder="memo" />
                    </Form.Group>


                    <Form.Group id="formGridState">
                        <Form.Label>권한 그룹 선택</Form.Label>
                        <Form.Control as="select">
                            <option>권한 그룹 선택</option>
                            <option>...</option>
                        </Form.Control>
                    </Form.Group>


                    <Button variant="primary" type="submit">
                        추가
                    </Button>



                </Form>




            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}