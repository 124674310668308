
import React, { useEffect, useMemo, useState } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { date } from "yup";

import { ComponentLoader } from "../../pages/ComponentLoader";

export function ToPage({ location }) {


    //DB
    let host = '' //mongodb://wise:Dhkdlwm1@169.56.88.185:27017/IotData
    let id = ''
    let pw = ''
    let db_name = ''
    let db_type = 'mongodb'

    //API
    let result = ''

    const [el, setEl] = useState({});
    const [setting, setSetting] = useState({});


    //================================================================================
    //CREATE INIT TABLE
    //site /siteGroup / group / user / menu / page / component / notice   초기 파일 생성


    useEffect(() => {
        let id = location.pathname.replace("/page/", "")

        console.log(location)
        console.log(id)

        InsertText(id)
        InsertText("** NOT SUPPORT YET **")



    }, []);



    function InsertText(text) {
        result = '<div>[' + new Date().toLocaleString() + '] ' + text + '</div>' + result
        let target = document.getElementById('result');
        target.innerHTML = result
    }



    //===================================================================================================
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================

    return (
        <>

            <div className="d-flex flex-column flex-root">

                <div id='result'></div>

            </div>

        </>
    );
}
