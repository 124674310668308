/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { actionTypes } from "../../../../redux/actionType";

import { UnitImage } from "../edit/WebBuilder/Element/UnitImage"



export function ImageGallery({ el, states, className }) {

    let id = "component" + el.i

    const [height, setHeight] = useState(0);
    const [images, setImages] = useState('');


    const dispatch = useDispatch()
    const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual);


    let imageId1 = "first57381946"
    let imageId2 = "second3409875"



    //==============================================================
    // [E] Event (예약어를 제외한 나머지 언급된 setting key )
    //==============================================================


    useEffect(() => {

        if (el.data.rowdata !== '' || el.data.rowdata === undefined) {
            const h = document.getElementById("imageArea").getBoundingClientRect().height
            let objArr = []
            let imgArr = []

            if (el.data.rowdata[imageId1] !== undefined) {
                el.data.rowdata[imageId1].forEach((e) => {
                    imgArr.push({
                        original: e.toString(),
                        originalHeight: h
                    })
                })
                objArr[imageId1] = imgArr
                imgArr = []
            }

            if (el.data.rowdata[imageId2] !== undefined) {
                el.data.rowdata[imageId2].forEach((e) => {
                    imgArr.push({
                        original: e.toString(),
                        originalHeight: h
                    })
                })
                objArr[imageId2] = imgArr
                imgArr = []
            }

            setImages(objArr)
        }
    }, [])



    /**
     * 현재 컴포넌트의 height가 업데이트 되면 실행
     * UnitImage 상위 div의 높이를 저장
     */
    useEffect(() => {

        setHeight(document.getElementById("imageArea").getBoundingClientRect().height)
    }, [el.h])


    /**
     * 업로드한 이미지 경로를 db에 저장
     */
    const selectSetting = (data, id) => {

        if (data.length > 0) {
            let elCopy = JSON.parse(JSON.stringify(el))
            if (elCopy.data.rowdata === '') {
                elCopy.data.rowdata = {}
            }

            if (elCopy.data.rowdata[id] === '' || elCopy.data.rowdata[id] === undefined) {
                elCopy.data.rowdata[id] = {}
            }
            elCopy.data.rowdata[id] = data

            const imgArr = []
            let objArr = []
            data.forEach((e) => {
                imgArr.push({
                    original: e.toString(),
                    originalHeight: height
                })

            })

            objArr[id] = imgArr
            setImages({ ...images, [id]: objArr[id] })

            dispatch({ type: actionTypes.UpdateComponentData, payload: { pageIdx: currentPage.slice("page".length), component: elCopy } })
        }
    };


    return (
        <>

            {/* begin::Tiles Widget 1 */}
            <div className={`card card-custom ${className}`}>
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <div className="card-title">
                        <div className="card-label">
                            <div className="font-weight-bolder">ImageGallery</div>
                            <div className="font-size-sm text-muted mt-2"></div>
                        </div>
                    </div>

                </div>
                {/* end::Header */}

                {/* <ImageGalleryTest items={images == '' ? sampleImage : images} showPlayButton={false} showBullets={!isOne} lazyLoad={true} /> */}
                <div id="imageArea" style={{ overflow: "hidden", width: "50%", display: "inline-block" }}>
                    <UnitImage id={imageId1} images={images[imageId1] ? images[imageId1] : ""} parentCallback={selectSetting} />
                </div>
                <div style={{ overflow: "hidden", width: "50%", display: "inline-block" }}>
                    <UnitImage id={imageId2} images={images[imageId2] ? images[imageId2] : ""} parentCallback={selectSetting} />
                </div>
                {/* end::Body */}
            </div>
            {/* end::Tiles Widget 1 */}

        </>
    );
}


