/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Button, Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/esm/locale"

export function UnitDatePicker({ el, id, layout, data, onEvent }) {

  const [selectDate, setSelectDate] = useState(new Date());

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control type="text" size="md" onClick={onClick} ref={ref} value={value} readOnly />
  ))


  useEffect(() => {

    if (layout == undefined) return

    if (layout.date == undefined) {
      setSelectDate(new Date())
      onEvent("saveState", id, new Date());
    } else {

      setSelectDate(layout.date)
      onEvent("saveState", id, layout.date);
    }

  }, [layout]);



  const handleChange = date => {

    setSelectDate(date)

    onEvent("saveState", id, date);

  };

  //https://reactdatepicker.com/
  return (
    <>
      <DatePicker
        dateFormat="yyyy.MM.dd"
        selected={selectDate}
        onChange={handleChange}
        locale={ko}
        customInput={<CustomInput />}

      />
    </>
  );
}

