
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function CrawlingList_Edit(props) {
    const { parentId, parentCallback, data, ...rest } = props

    const [template, setTemplate] = useState("");


    useEffect(() => {

        if (rest.show == true) {

            data.data.map((list) => {

                console.log(list)

                setTemplate(JSON.stringify(list,null,2))

            })

        }

    }, [rest.show]);




    const changeTemplate = (e) => {
        setTemplate(e.target.value)
    }



    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Template 수정
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                    <Form.Group id="formMemo">
                        <Form.Label>template*</Form.Label>
                        <Form.Control as="textarea" rows={30} placeholder="template 입력해주세요" onChange={changeTemplate} value={template || ''} />
                    </Form.Group>



                    <Button size='sm' onClick={() => {


                        let objTpl = JSON.parse(template)

                        

                        
                        if (objTpl._id == undefined) {
                            alert("_id")
                            return
                        }


                        if (objTpl.title == undefined) {
                            alert("title")
                            return
                        }

                        if (objTpl.rule == undefined) {
                            alert("rule")
                            return
                        }

                        if (objTpl.selector == undefined) {
                            alert("selector")
                            return
                        }

                        if (objTpl.items == undefined) {
                            alert("items")
                            return
                        }


                        console.log(objTpl._id)
                        console.log(objTpl.title)
                        console.log(objTpl.rule)
                        console.log(objTpl.selector)
                        console.log(objTpl.items)

                        let templateIdx =  objTpl._id

                        parentCallback(templateIdx, objTpl)
                        rest.onHide()
                    }}> 수정</Button>


                </Form>




            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={() => {
                    rest.onHide()
                }}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}