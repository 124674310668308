
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

export function ControlPageSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    ControlPageSetting {parentId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Option Setting !
                <br /><br />
                {/* <div>
                    <Button style={btnStyle} size='lg' onClick={() => {
                        parentCallback("Setting A")
                        rest.onHide()
                    }}> Setting A</Button>


                    <Button style={btnStyle} size='lg' onClick={() => {
                        parentCallback("Setting B")
                        rest.onHide()
                    }}> Setting B</Button>
                </div> */}

                <div>

                    className: "layout"<br />
                    useCSSTransforms: false<br />
                    cols: Json ( lg: 96, md: 80, sm: 48, xs: 32, xxs: 16 ) <br />
                    rowHeight: 5<br />
                    margin:[100,100] <br />
                    containerPadding:[100,100] <br />
                    items: 2<br />
                    compactType : horizontal / vertical / null  <br />
                    preventCollision: true <br />

                </div>



            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}