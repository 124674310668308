import React, { useEffect, useMemo, useState, useCallback } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { TempKeywordAlertWork } from "../../func-component/TempKeywordAlertWork";

export function Test4({ el, onUpdateAutoHeight, states, className }) {
  //let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  useEffect(() => {
    console.log(">> useEffect");
  }, []);

  if (states == undefined) return <></>;

  return (
    <>
      <div style={{ backgroundColor: "white", paddingTop: "30px" }}>
        <div className="card-body pt-0">
          <TempKeywordAlertWork
            el={el}
            className={className}
            states={states}
            onUpdateAutoHeight={onUpdateAutoHeight}
          />
        </div>
      </div>
    </>
  );
}
