import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as modl from "../app/_redux/modlActions";
import {modlSlice, dateSlice, chartSlice, keywordSlice, chartSubSlice } from "../app/_redux/modlSlice";

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistModlConfig = {
  key: 'wise-modl',
  // localStorage에 저장합니다.
  storage,
  // auth modl localstorage에 저장합니다.
  // whitelist: ['currentWorkspace'],
};

export const rootReducer = combineReducers({
  auth: auth.reducer, // default : persist
  modl: persistReducer(persistModlConfig, modlSlice.reducer),
  date: dateSlice.reducer,
  chart: chartSlice.reducer,
  keyword: keywordSlice.reducer,
  subChart: chartSubSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga(), modl.saga()]);
}
