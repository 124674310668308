
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';
import { DraggableArea } from 'react-draggable-tags';


export function ManageAdminMember_Add(props) {
    const { parentId, parentCallback, checkEmailCallback,isCheckEmail, action, selectIndex, menu, adminMemberList,workspacesAll, ...rest } = props


    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const [workspaceName, setWorkspaceName] = useState(""); // 현장명

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [phone, setPhone] = useState("");

    const [teamName, setTeamName] = useState("");
    const [comment, setComment] = useState("");

    const [workspaceIdx, setWorkspaceIdx] = useState("");

    const [existCheckEmail, setExistCheckEmail] = useState([]);
    const [existCheckEmailText, setExistCheckEmailText] = useState("new");

    const [tags, setTags] = useState([]);

    
    useEffect(() => {

        if (rest.show == true) {

            // console.log("menu > ")
            // console.log(menu)
            // console.log("member > ")
            // console.log(member)
            // console.log("action > ")
            // console.log(action)
            // console.log("selectIndex > ")
            // console.log(selectIndex)

            console.log("isCheckEmail >")
            console.log(isCheckEmail)
            
            setExistCheckEmail(isCheckEmail)

            if (action == "edit") {



                console.log("edit >")
                console.log(adminMemberList[selectIndex])

                // console.log(adminMemberList[selectIndex].userInfo[0].email)

                setEmail(adminMemberList[selectIndex]?.email)
                setName(adminMemberList[selectIndex]?.name)
                setPassword(adminMemberList[selectIndex]?.password)
                setPasswordConfirm(adminMemberList[selectIndex]?.password)
                setPhone(adminMemberList[selectIndex]?.phone)
                setTeamName(adminMemberList[selectIndex]?.teamName)
                setComment(adminMemberList[selectIndex]?.comment)
               
                let _tag = []
                for (let _ws of adminMemberList[selectIndex]?.userWorkspaceInfo) {
                    for (let workspace of workspacesAll) {
                        if (_ws.workspaceIdx == workspace._id) {
                            if (_ws.role == "admin") {
                                console.log("setTag >>>>>>")
                                _tag.push({ id: workspace._id, content: workspace.title })


                            }
                        }
                    }
                }

                setTags(_tag);

                // setWorkspaceIdx(adminMemberList[selectIndex].workspaceIdx)
          
            } else if (action == "add") {

                console.log("add >")
               
                setEmail("")
                setName("")
                setPassword("")
                setPasswordConfirm("")
                setPhone("")
                setComment("")
                setTags([])
                setWorkspaceIdx("")
                setWorkspaceName("")

                setExistCheckEmail([])

            }


            // console.log("allowMenuList > ")
            // console.log(allowMenuList)
        }

    }, [rest.show]);


    
    useEffect(() => {
        setExistCheckEmail(isCheckEmail)
    }, [isCheckEmail]);




    useEffect(() => {
        console.log("existCheckEmail>>")
        console.log(existCheckEmail)

        if (existCheckEmail != null) {
            if (existCheckEmail.length == 0) {
                setExistCheckEmailText("")

            } else {
                
                if(action == "add") {setExistCheckEmailText("# 존재하는 아이디 입니다")}

            }
        }
    }, [existCheckEmail]);




    const btnStyle = {
        margin: "2px",
    }



    
    const changeEmail = (e) => {

        let payload = {
            "email": e.target.value
        }

        checkEmailCallback(payload)

        setEmail(e.target.value)

    }

    const changeName = (e) => {
        setName(e.target.value)
    }

    const changeWorkspaceName = (e) => {
        setWorkspaceName(e.target.value)
    }


    const changePassword = (e) => {
        setPassword(e.target.value)
    }

    const changePasswordConfirm = (e) => {
        // console.log(">> changeTitle")
        // console.log(e.target.value)
        setPasswordConfirm(e.target.value)
    }

    const changePhone = (e) => {
        // console.log(">> changeTitle")
        // console.log(e.target.value)
        setPhone(e.target.value)
    }

    const changeTeamName = (e) => {
        // console.log(">> changeTitle")
        // console.log(e.target.value)
        setTeamName(e.target.value)
    }


    const changeComment = (e) => {
        // console.log(">> changeTitle")
        // console.log(e.target.value)
        setComment(e.target.value)
    }







    const setCheckState = (e) => 
    {

        console.log("^^ e.target.value")
        console.log(e.target.value)


        let selected = workspacesAll.filter(t => e.target.value == t._id);
        console.log(selected[0]?.title)

        setWorkspaceIdx(e.target.value)
        setWorkspaceName(selected[0]?.title)
  
    }



    const handleClickAdd = () => {

        let check = tags.filter(t => workspaceIdx == t.id);
        if (check.length == 0) {
            // setTags([{id: Math.random() , content: tag}])
            let joined = tags.concat({ id: workspaceIdx, content: workspaceName })
            setTags(joined);
        }
        else {
            alert("이미 추가됨")
        }

    }

    const handleClickDelete = (tag) => {
        console.log("handleClickDelete:" + tag.content)

        let _tags = tags.filter(t => tag.id !== t.id);
        setTags(_tags)
    }


    const handleChangeTag = (tags) => {
        console.log("handleChangeTag:" + tags)
        setTags(tags)
    }



    
    let SelectorUI = (workspacesAll != null)? workspacesAll.map((_group, index) =>
        <option key={Math.random()} value={_group._id || ''}>{_group.title}</option>
    ):null


    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {(action == "add") ? <>사용자 추가</> : <>사용자 수정</>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>아이디*</Form.Label>
                        {
                            (action == "add") ? <Form.Control placeholder="아이디를 입력해주세요" onChange={changeEmail} value={email || ''} />
                                : <Form.Control placeholder="아이디를 입력해주세요" onChange={changeEmail} value={email || ''} readOnly />
                        }
                        <span style={{color:"red"}}>{existCheckEmailText}</span>
                    </Form.Group>
                    

                    <Form.Row style={{ marginBottom: "10px" }}>
                        <Col>
                            <Form.Label>현장명*</Form.Label>
                            <Form.Control as="select" onChange={setCheckState} value={workspaceIdx}>
                                <option key={Math.random()} value="">없음</option>
                                {SelectorUI}
                            </Form.Control>

                            {/* <Form.Control placeholder="현장명을 입력해주세요"  onChange={changeWorkspaceName} value={workspaceName || ''}/> */}

                        </Col>

                        <Col>
                            <Button style={{bottom:"0px",position: "absolute"}} onClick={() => handleClickAdd()} variant="primary">
                                추가
                            </Button>
                        </Col>
                    </Form.Row>

                    <DraggableArea
                            tags={tags}
                            render={({ tag, index }) => (
                                <Button size='sm'  variant="outline-dark" style={{ marginLeft: "2px" }}>
                                    {tag.content}
                                    <span onClick={() => handleClickDelete(tag)}> x </span>
                                </Button>
                            )}
                            onChange={tags => handleChangeTag(tags)}
                        />




                    <Form.Group id="formUserId" style={{ marginTop: "20px" }}>
                        <Form.Label>이름*</Form.Label>
                        <Form.Control placeholder="이름을 입력해주세요"  onChange={changeName} value={name || ''} />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} id="formPassword">
                            <Form.Label>비밀번호*</Form.Label>
                            <Form.Control placeholder="비밀번호를 입력해주세요"  onChange={changePassword} value={password || ''} />
                        </Form.Group>

                        <Form.Group as={Col} id="formPasswordConfirm">
                            <Form.Label>비밀번호 확인*</Form.Label>
                            <Form.Control placeholder="동일한 비밀번호를 입력해주세요"  onChange={changePasswordConfirm} value={passwordConfirm || ''} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} id="formPhone">
                            <Form.Label>전화 번호</Form.Label>
                            <Form.Control placeholder="전화번호를 입력해주세요" onChange={changePhone} value={phone || ''}/>
                        </Form.Group>

                        <Form.Group as={Col} id="formEmail">
                            <Form.Label>소속</Form.Label>
                            <Form.Control placeholder="소속을 입력해주세요" onChange={changeTeamName} value={teamName || ''}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group id="formMemo">
                        <Form.Label>비고</Form.Label>
                        <Form.Control placeholder="비고" onChange={changeComment} value={comment || ''}/>
                    </Form.Group>





                    {(action == "add") ?
                        <Button style={btnStyle} size='sm' onClick={() => {

                            // if (groupIdx == "") {
                            //     alert("그룹을 선택해주세요")
                            //     return
                            //   }
                            if (email.length == 0) {
                                alert("아이디를 입력해주세요")
                                return
                            }

                            if (existCheckEmail.length != 0) {
                                alert("존재하는 아이디 입니다")
                                return
                            }

                            if(tags.length == 0){
                                alert("현장이 없습니다.")
                                return
                            }



                            let payload = {
                                "action": "add",
                                "workspaceIdx": menu[0].workspaceIdx,
                                "email": email,
                                "name": name,
                                "password": password,
                                "phone": phone,
                                "teamName": teamName,
                                "comment": comment,
                                "tags": tags,
                                // "groupIdx": groupIdx

                            }
                            parentCallback(payload)
                            rest.onHide()
                        }}> 추가</Button>
                        :
                        <Button style={btnStyle} size='sm' onClick={() => {

                            // if (groupIdx == "") {
                            //     alert("그룹을 선택해주세요")
                            //     return
                            //   }


                            let payload = {
                                "action": "edit",
                                "_id": adminMemberList[selectIndex]._id,
                                "workspaceIdx": adminMemberList[selectIndex].workspaceIdx,
                                "email": email,
                                "name": name,
                                "password": password,
                                "phone": phone,
                                "teamName": teamName,
                                "comment": comment,
                                "tags": tags,
                                // "groupIdx": groupIdx
                                
                            }

                          
                            parentCallback(payload)
                            rest.onHide()
                        }}> 저장</Button>
                    }



                </Form>




            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}