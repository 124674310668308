/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';
import { Button, Table, Pagination, Form, Col, Row } from 'react-bootstrap';
import * as _ from "lodash"

import * as XLSX from 'xlsx';

const moment = require('moment');

export function UnitTable({ el, id, layout, data, onEvent }) {


  const [action, setAction] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [checkedList, setCheckedList] = useState([]);
  const [isCheckedAllButton, setIsCheckedAllButton] = useState(false);


  let [columns, setColumns] = React.useState();
  let [footers, setFooters] = React.useState();
  let [style, setStyle] = React.useState();
  let [settings, setSettings] = React.useState();

  // For Data
  let [columnsLayout, setColumnsLayout] = React.useState('');
  let [dataLayout, setDataLayout] = React.useState('');
  let [footersLayout, setFootersLayout] = React.useState('');


  let [dataPaging, setDataPaging] = React.useState();
  let [flatColumns, setFlatColumns] = React.useState();
  let [flatFooters, setFlatFooters] = React.useState();
  let [maxDepth, setMaxDepth] = React.useState(0);

  // let [settings, setSettings] = React.useState(_settings);


  // SETTING PAGING
  let [totalItemCount, setTotalItemCount] = useState(0); // 전체 페이지
  let [currentPage, setCurrentPage] = useState(1); // 현재 페이지


  // let itemCountOfPage = 6 // 한 페이지의 아이템수
  // let pageCountOfGroup = 10 // 페이지에 보여지는 카운트 수 < 1 2 3 4 5 > 

  let [itemCountOfPage, setItemCountOfPage] = React.useState(0);
  let [pageCountOfGroup, setPageCountOfGroup] = React.useState(0);

  let totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
  let currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
  let lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
  let pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
  let lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스
  let pageCountOfCurrentGroup = (currentGroup == lastGroup) ? pageCountOfLastGroup : pageCountOfGroup // 현제 페이지의 페이지수




  // =====================================================
  // [S] send to parent
  // =====================================================


  const deleteItem = () => {
    // console.log("deleteItem >> " + JSON.stringify(checkedList))
    let _checkList = JSON.parse(JSON.stringify(checkedList))
    onEvent("delete", id, _checkList);

    setCheckedList([])

  };


  const addItem = () => {
    // console.log("addItem >> ")
    onEvent("add", id, null);

  };


  const editItem = (key) => {
    // console.log('editItem click: ' + key)
    onEvent("edit", id, key);

  };

  const runItem = (key) => {
    // console.log('editItem click: ' + key)
    onEvent("run", id, key);

  };





  // =====================================================
  // [S] other function
  // =====================================================

  const downloadExcel = () => {
    console.log("downloadExcel > dataPaging > ")
    // console.log(JSON.stringify(dataPaging))
    console.log(JSON.stringify(dataPaging))

    /*
    // ===============================
    // table to excel
    // ===============================
    var wb = XLSX.utils.table_to_book(document.getElementById('mytable'), { sheet: "시트명", raw: true });
    XLSX.writeFile(wb, (`file${Math.random()}.xlsx`));
    */


    // ===============================
    // json to excel
    // ===============================
    var dataWS = XLSX.utils.json_to_sheet(flattenObjectArray(data.dataList))
    var wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, dataWS, 'sheetName')
    XLSX.writeFile(wb, (`수집 데이터.xlsx`))

  };




  // =====================================================
  // [S] Utils
  // =====================================================

  const flattenObject = (obj) => {
    const flattened = {}
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        Object.assign(flattened, flattenObject(value))
      } else {
        flattened[key] = value
      }
    })
    return flattened
  }


  const flattenObjectArray = (arr) => {
    let retVal = []
    arr.map((_obj) => {
      retVal.push(flattenObject(_obj))
    })
    return retVal
  }





  // =========================================================
  // check
  // =========================================================

  const changeCheckAll = (e) => {

    // console.log("checkAll")
    let isChecked = e.target.checked

    if (isChecked) {
      let _checkList = []
      for (let mGroup of dataPaging) {
        _checkList.push(mGroup._id)
      }

      // console.log(_checkList)
      setCheckedList(_checkList)
    } else {
      setCheckedList([])
    }

  }


  const getCheckState = (_id) => {

    // console.log(">getCheckState")
    // console.log(checkedList.includes(_id))

    return checkedList.includes(_id)
  }


  const setCheckState = (_id, e) => {
    // console.log(">setCheckState")
    // console.log(_id)

    let isChecked = e.target.checked

    // console.log(isChecked)

    if (isChecked) {
      let joined = checkedList.concat(_id)

      // console.log(joined)
      setCheckedList(joined)
    } else {
      // console.log(checkedList.filter(item => item !== _id))
      setCheckedList(checkedList.filter(item => item !== _id))
    }

  }





  let adjustFormat = (content, format) => {

    for (let _format of format) {

      if (_format.type == 'match') {

        const regexStr = _format.regex;
        let matches = regexStr.matchAll(new RegExp("{{([^{]+)}}", "gi"));

        let tempContent = regexStr
        for (let match of matches) {
          let convContents = content.matchAll(new RegExp(match[1], _format.option)); // match[1] > group ()

          let joinContent = []
          for (let convContent of convContents) {

            for (let i = 1; i < convContent.length; i++) {
              joinContent.push(convContent[i])
            }

          }
          tempContent = tempContent.replace(match[0], joinContent.join(_format.join))
        }

        content = tempContent

      } else if (_format.type == 'replace') {

        // replace 는 {{}} 탬플릿 하나만 가능 .. 두개처리 해야 할때는 replaceText는 array 여야 한다 .
        let regexStr = _format.regex
        let replaceText = _format.to

        let matches = regexStr.matchAll(new RegExp("{{([^{]+)}}", "gi"));

        let tempContent = regexStr
        for (let match of matches) {
          let convContents = content.replace(new RegExp(match[1], _format.option), replaceText); // match[1] > ([0-9]+)(-) $1
          tempContent = tempContent.replace(match[0], convContents.trim())
        }

        content = tempContent


      } else if (_format.type == 'search') {
        console.log("not support yet")
      } else if (_format.type == 'split') {
        console.log("not support yet")
      } else if (_format.type == 'digit') {
        console.log("not support yet")
      } else if (_format.type == 'substring') {

        let start = _format.start
        let end = _format.end
        if (_format.start == "length") start = content.length
        if (_format.end == "length") end = content.length

        content = content.substring(start, end) + _format.last;

      } else if (_format.type == 'function') {
        console.log("not support yet")
      }
      else if (_format.type == 'date') {

        // console.log("date ____ content")
        // console.log(content)
        // console.log(_format.locale)

        if (_format.locale == "ko") {
          try {
            var _date = new Date(content);
            content = moment(_date).format('YYYY-MM-DD HH:mm:ss');
            // console.log(content)
          } catch (e) {
            console.log("check format.type = date")
          }
        }

        

      }
      
    }

    return content

  }


  // useEffect(() => {
  //   console.log("===>> totalItemCount")
  //   console.log(totalItemCount)
  //   // totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
  //   // currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
  //   // lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
  //   // pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
  //   // lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스
  //   // pageCountOfCurrentGroup = (currentGroup == lastGroup) ? pageCountOfLastGroup : pageCountOfGroup // 현제 페이지의 페이지수
  //   // console.log("currentPage:"+currentPage)
  //   // console.log("lastPage:"+lastPage)
  //   if (currentPage > lastPage && lastPage != 0) {
  //     selectPage(lastPage)
  //   }
  // }, [totalItemCount]);


  const depthDecorator = (array, _depth = 0) => {

    let _maxDepth = 0
    let _flatColumns = []
    const _depthDecorator = (_array, depth) => {
      if (_maxDepth <= depth) _maxDepth = depth
      return _array.map((child) => {
        _flatColumns.push(child)

        let depthRowspan = 0
        if (child.hasOwnProperty("rowspan")) {
          depthRowspan = child.rowspan - 1
        }

        Object.assign(child, {
          depth,
          children: _depthDecorator(child.children || [], depth + 1 + depthRowspan)
        })

      });
    }

    _depthDecorator(array, _depth)

    setMaxDepth(_maxDepth)

    return _flatColumns
  }


  useEffect(() => {

    if (layout == undefined) return

    setColumns(layout.columns)
    setFooters(layout.footers)
    setStyle(layout.style)
    setSettings(layout.settings)

  }, [layout])


  useEffect(() => {

    if (style == undefined) return

    console.log("_Style")

  }, [style])


  useEffect(() => {

    if (settings == undefined) return
    if (totalItemCount == undefined) return

    setItemCountOfPage(settings.itemCountOfPage) // 한 페이지의 아이템수
    setPageCountOfGroup(settings.pageCountOfGroup) // 페이지에 보여지는 카운트 수 < 1 2 3 4 5 > 

    totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
    currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
    lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
    pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
    lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스
    pageCountOfCurrentGroup = (currentGroup == lastGroup) ? pageCountOfLastGroup : pageCountOfGroup // 현제 페이지의 페이지수

  }, [settings, totalItemCount])


  useEffect(() => {
    if (columns == undefined) return
    let _columns = JSON.parse(JSON.stringify(columns))
    setFlatColumns(depthDecorator(_columns))
  }, [columns])


  useEffect(() => {
    if (footers == undefined) return
    let _footers = JSON.parse(JSON.stringify(footers))
    setFlatFooters(depthDecorator(_footers))
  }, [footers])







  // =========================================================
  // Header
  // =========================================================

  useEffect(() => {

    if (flatColumns == undefined) return
    if (dataPaging == undefined) return

    let coLayout = (depth) => {
      let depthData = flatColumns.filter((value) => (value.depth == depth))
      let _layout = depthData.map((cell) => {
        // console.log(">> cell::" + cell.title)

        //colspan
        let cspan = 1
        if (cell.children.length != 0) {

          let idx = flatColumns.findIndex((item) => item === cell)

          let lastIdx = 99
          for (let i = idx + 1; i < flatColumns.length; i++) {
            if (flatColumns[i].depth == depth) {
              lastIdx = i
              break
            }
          }

          let child = flatColumns.filter((value, index) => {
            return (value.depth > depth && index > idx && index < lastIdx && value.children.length == 0)
          })

          // console.log("idx:"+idx)
          // console.log("lastIdx:"+lastIdx)
          // console.log("depth:"+depth)
          // console.log(child)

          cspan = child.length
        }


        //rowspan
        let rspan = 1
        if (cell.children.length == 0) {
          rspan = maxDepth - cell.depth
        }

        //정의된 값이 있다면 따른다
        if (cell.hasOwnProperty("rowspan")) rspan = cell.rowspan
        if (cell.hasOwnProperty("colspan")) cspan = cell.colspan

        // console.log("cspan:" + cspan + " rspan:" + rspan)

        // '=' 삭제하고 {} 안에 있는 테그 정보를 실제 식으로 변경하는 구문
        // 1. ={grade} >> [0].grade
        let resultContent = null
        if (cell.title.charAt(0) === '=') {

          // 모든 태그 내용이 숫자일때만 사칙연산을 따른다. 
          let _type = "number"
          let _exp = cell.title.substr(1);

          let _expResult = _exp.replace(/{(.+?)}/gi, function (x) {

            let retVal = x.replace(/{|}/gi, "")

            if (retVal.charAt(0) === '[') {

            } else {
              retVal = "[0]." + retVal
              _type = "string"
            }

            retVal = _.get(dataPaging, retVal, "")

            if (typeof retVal == "string") {
              _type = "string"
            }

            return retVal
          });

          if (_type == "number") {
            try {
              _expResult = eval(_expResult) // eval zzimzzim 다음에 대체...
            } catch (e) {
              _expResult = "(can not calculate)"
            }

          } else {
            _expResult = _expResult
          }


          // FORMAT format
          if (cell.hasOwnProperty("format")) {
            resultContent = adjustFormat(_expResult, cell.format)
          } else {
            resultContent = _expResult
          }


          // return <th colSpan={cspan} rowSpan={rspan}>{_.get(data, cell.title,"(check dataKey)")}</th>

        } else {


          // FORMAT format
          if (cell.hasOwnProperty("format")) {
            resultContent = adjustFormat(cell.title, cell.format)
          } else {
            resultContent = cell.title
          }
          // return <th colSpan={cspan} rowSpan={rspan}>{cell.title}(col:{cspan},row:{rspan})</th>
        }

        const styleMerge = { ...style.th, ...cell.style }
        return <th style={styleMerge} key={Math.random()} colSpan={cspan} rowSpan={rspan}>{resultContent}</th>

      })

      return _layout

    }


    let _columnsLayout =
      <thead style={style.thead}>
        {
          Array.from(Array(maxDepth).keys()).map((depth) => {
            return (
              <tr key={Math.random()}>
                {coLayout(depth)}
              </tr>
            )
          })
        }
      </thead>

    setColumnsLayout(_columnsLayout)


  }, [dataPaging, checkedList]) //flatColumns,dataPaging









  // =========================================================
  // FOOTER
  // =========================================================

  useEffect(() => {


    // console.log("_footersLayout >> 2")
    // console.log(flatFooters)
    // console.log(dataPaging)


    if (flatFooters == undefined) return
    // if (flatColumns == undefined) return
    if (dataPaging == undefined) return


    let foLayout = (depth) => {
      let depthData = flatFooters.filter((value) => (value.depth == depth))
      let _layout = depthData.map((cell) => {
        // console.log(">> cell::" + cell.title)

        //colspan
        let cspan = 1
        if (cell.children.length != 0) {

          let idx = flatFooters.findIndex((item) => item === cell)

          let lastIdx = 99
          for (let i = idx + 1; i < flatFooters.length; i++) {
            if (flatFooters[i].depth == depth) {
              lastIdx = i
              break
            }
          }

          let child = flatFooters.filter((value, index) => {
            return (value.depth > depth && index > idx && index < lastIdx && value.children.length == 0)
          })

          // console.log("idx:"+idx)
          // console.log("lastIdx:"+lastIdx)
          // console.log("depth:"+depth)
          // console.log(child)

          cspan = child.length
        }


        //rowspan
        let rspan = 1
        if (cell.children.length == 0) {
          rspan = maxDepth - cell.depth
        }

        //정의된 값이 있다면 따른다
        if (cell.hasOwnProperty("rowspan")) rspan = cell.rowspan
        if (cell.hasOwnProperty("colspan")) cspan = cell.colspan

        // console.log("cspan:" + cspan + " rspan:" + rspan)

        // '=' 삭제하고 {} 안에 있는 테그 정보를 실제 식으로 변경하는 구문
        // 1. ={grade} >> [0].grade
        let resultContent = null
        if (cell.title.charAt(0) === '=') {

          // 모든 태그 내용이 숫자일때만 사칙연산을 따른다. 
          let _type = "number"
          let _exp = cell.title.substr(1);

          let _expResult = _exp.replace(/{(.+?)}/gi, function (x) {

            let retVal = x.replace(/{|}/gi, "")

            if (retVal.charAt(0) === '[') {

            } else {
              retVal = "[0]." + retVal
              _type = "string"
            }

            retVal = _.get(dataPaging, retVal, "")

            if (typeof retVal == "string") {
              _type = "string"
            }

            return retVal
          });

          if (_type == "number") {
            try {
              _expResult = eval(_expResult) // eval zzimzzim 다음에 대체...
            } catch (e) {
              _expResult = "(can not calculate)"
            }

          } else {
            _expResult = _expResult
          }


          // FORMAT format
          if (cell.hasOwnProperty("format")) {
            resultContent = adjustFormat(_expResult, cell.format)
          } else {
            resultContent = _expResult
          }


          // return <th colSpan={cspan} rowSpan={rspan}>{_.get(data, cell.title,"(check dataKey)")}</th>

        } else {


          // FORMAT format
          if (cell.hasOwnProperty("format")) {
            resultContent = adjustFormat(cell.title, cell.format)
          } else {
            resultContent = cell.title
          }
          // return <th colSpan={cspan} rowSpan={rspan}>{cell.title}(col:{cspan},row:{rspan})</th>
        }

        const styleMerge = { ...style.th, ...cell.style }
        return <th style={styleMerge} key={Math.random()} colSpan={cspan} rowSpan={rspan}>{resultContent}</th>

      })

      return _layout

    }


    let _footersLayout =
      <thead style={style.tfoot}>
        {
          Array.from(Array(maxDepth).keys()).map((depth) => {
            return (
              <tr key={Math.random()}>
                {foLayout(depth)}
              </tr>
            )
          })
        }
      </thead>

    setFootersLayout(_footersLayout)

  }, [dataPaging, checkedList]) //flatColumns,dataPaging







  // =========================================================
  // Data
  // =========================================================

  useEffect(() => {

    if (data == undefined) return
    if (flatColumns == undefined) return

    let tdLayout = (d, index, arr) => {

      let _layout = flatColumns.map((cell) => {


        if (cell.hasOwnProperty("dataKey")) {

          // 1. ={grade} >> [n].grade
          let resultContent = null
          if (cell.dataKey.charAt(0) === '=') {

            // 모든 태그 내용이 숫자일때만 사칙연산을 따른다. 
            let _type = "number"
            let _exp = cell.dataKey.substr(1);
            let _expResult = _exp.replace(/{(.+?)}/gi, function (x) {

              let retVal = x.replace(/{|}/gi, "")

              //예약 테그 autoIncrease / autoDecrease 
              if (retVal == "autoIncrease") {
                retVal = index + ((currentPage - 1) * itemCountOfPage) + 1
              } else if (retVal == "autoDecrease") {
                retVal = totalItemCount - (index + ((currentPage - 1) * itemCountOfPage))
              } else {

                if (retVal.charAt(0) === '[') {
                  // [n] 만 되는데 2n+1 이런거 ... 안됨 
                  // TODO : 시간있을때 좀 만지자 ..
                  retVal = retVal.replace(/\[(.+?)\]/gi, function (x1) {
                    let x2 = eval(x1.replace('n', index))
                    return x2
                  })

                  retVal = _.get(arr, retVal, "undefined")
                  if (typeof retVal == "string") {
                    _type = "string"
                  }
                } else {
                  retVal = _.get(d, retVal, "undefined")
                  _type = "string"
                }
              }

              return retVal
            });

            if (_type == "number") {
              try {
                _expResult = eval(_expResult) // eval zzimzzim 다음에 대체...
              } catch (e) {
                _expResult = "(can not calculate)"
              }
            } else {
              _expResult = _expResult
            }

            // FORMAT dataFormat
            if (cell.hasOwnProperty("dataFormat")) {
              resultContent = adjustFormat(_expResult, cell.dataFormat)
            } else {
              resultContent = _expResult
            }

          } else {

            // FORMAT dataFormat
            if (cell.hasOwnProperty("dataFormat")) {
              resultContent = adjustFormat(cell.dataKey, cell.dataFormat)
            } else {
              resultContent = cell.dataKey
            }

          }


          if(resultContent == 'undefined'){
            resultContent=''
          }


          if (cell.type == 'check') {
            return <td style={style.td} key={Math.random()} ><Form.Check onChange={(e) => setCheckState(d._id, e)} checked={getCheckState(d._id)} /></td>
          } else if (cell.type == 'edit') {
            return <td style={style.td} key={Math.random()} onClick={() => { editItem(resultContent) }}><Button variant="outline-dark" style={btnStyle} size='sm'>수정</Button></td>
          } else if (cell.type == 'run') {
            return <td style={style.td} key={Math.random()} onClick={() => { runItem(resultContent) }}><Button variant="outline-dark" style={btnStyle} size='sm'>실행</Button></td>
          } else if (cell.type == 'button') {
            return <td style={style.td} key={Math.random()} ><Button style={btnStyle} size='sm' >{resultContent}</Button></td>
          } else if (cell.type == 'link') {
            return <td style={style.td} key={Math.random()} ><a target="_blank" href={resultContent}>{resultContent}</a></td>
          } else if (cell.type == 'displayStatus') {

            let status = "-"
            if (resultContent == "run") {
              status = "실행"
            } else if (resultContent == "ready") {
              status = "예약"
            } else if (resultContent == "crawling") {
              status = "수집중"
              return <td style={style.td} key={Math.random()} >{status}
                <div class="spinner_crawler">
                  <div class="rect1"></div>
                  <div class="rect2"></div>
                  <div class="rect3"></div>
                  <div class="rect4"></div>
                  <div class="rect5"></div>
                </div>
              </td>
            } else if (resultContent == "complete") {
              status = "수집완료"
            }

            return <td style={style.td} key={Math.random()} >{status}
            </td>

          } else {
            return <td style={style.td} key={Math.random()} >{resultContent}</td>
          }



        }




      })

      return _layout

    }


    let _dataLayout =
      <tbody style={style.tbody}>
        {
          dataPaging.map((d, index, arr) => {
            return (
              <tr key={Math.random()} >
                {tdLayout(d, index, arr)}
              </tr>
            )
          })
        }
      </tbody>

    setDataLayout(_dataLayout)

    // updatePaging()

  }, [dataPaging, checkedList]);





  useEffect(() => {

    if (columns == undefined) return
    if (data == undefined) return

    if (settings.tableType == "dataTable") {

      // ===========================
      // tableType : dataTable
      // ===========================
      let _data = data.dataList.filter(function (element, index, array) {
        return index >= (itemCountOfPage * (currentPage - 1)) && index < (itemCountOfPage * currentPage)
      });

      setDataPaging(_data)

    } else if (settings.tableType == "table") {

      // ===========================
      // tableType : table
      // ===========================


      setDataPaging(data.dataList)

    }


    if (settings.tableType == "dataTable") {
      setTotalItemCount(data.dataList.length)

    } else if (settings.tableType == "table") {
      setTotalItemCount(data.totalCount) //set total count.

    }

  }, [currentPage, data, checkedList]);



  // =====================================================
  // [S] page
  // =====================================================



  const selectPage = (idx) => {

    // console.log(idx)
    if (settings.tableType == "dataTable") {

      // ===========================
      // tableType : dataTable
      // ===========================
      setCheckedList([])

      setCurrentPage(idx)

    } else if (settings.tableType == "table") {

      // ===========================
      // tableType : table
      // ===========================
      setCheckedList([])

      onEvent("currentPage", id, idx)
      setCurrentPage(idx)
    }

  };



  // const updatePaging = () => {
  //   if (settings.tableType == "dataTable") {
  //     setTotalItemCount(data.dataList.length)
  //   } else if (settings.tableType == "table") {
  //     setTotalItemCount(data.totalCount) //set total count.
  //   }
  // };



  let items = [];
  if (currentGroup != 1) {
    items.push(
      <Pagination.Prev key={Math.random()} onClick={() => { selectPage(currentPage - pageCountOfGroup) }}></Pagination.Prev>
    );
  }

  for (let number = 1; number <= pageCountOfCurrentGroup; number++) {

    let idx = (currentGroup - 1) * pageCountOfGroup + number
    items.push(
      <Pagination.Item key={Math.random()} active={idx === currentPage} onClick={() => { selectPage(idx) }}>
        {idx}
      </Pagination.Item>,
    );
  }

  if (currentGroup != lastGroup) {
    items.push(
      <Pagination.Next key={Math.random()} onClick={() => { selectPage((currentPage + pageCountOfGroup > lastPage) ? lastPage : currentPage + pageCountOfGroup) }}></Pagination.Next>
    );
  }




  const btnStyle = {
    margin: "2px",
    float: "left"
  }

  const btnStyle2 = {
    margin: "10px 2px ",
    float: "right"
  }



  if (dataPaging == undefined) return <></>
  if (settings == undefined) return <></>
  // if (columns == undefined) return <></>
  // if (data == undefined) return <></>
  // if (dataPaging == undefined) return (<></>)


  // console.log("settings")
  // console.log(settings.isDelete)

  return (
    <div style={{ backgroundColor: "white" }}>

      <Form.Group>

        {
          (settings.isExcel == true) ? <Button variant="outline-dark" style={btnStyle} size='sm' onClick={() => {
            downloadExcel("downloadExcel A")
          }}> download EXCEL</Button> : <></>
        }

        {
          (settings.isAllCheck == true) ? <Form.Check onChange={changeCheckAll} checked={(checkedList.length == dataPaging.length) ? true : false} label="check all" /> : <></>
        }

        {
          (settings.isDelete == true) ? <Button style={btnStyle2} size='sm' onClick={() => {
            deleteItem("deleteItem A")
          }}> 삭제</Button> : <></>
        }

        {
          (settings.isAdd == true) ? <Button style={btnStyle2} size='sm' onClick={() => {
            addItem("addItem B")
          }}> 추가</Button> : <></>
        }

        <Col sm="12" >

        </Col>

      </Form.Group>

      <Table id="mytable" style={style.table} striped bordered hover>

        {columnsLayout}

        {dataLayout}

        {footersLayout}

      </Table>

      {
        (settings.isPaging == true) ? <Pagination>{items}</Pagination> : <></>
      }


    </div>

  );
}


