
import React, { useEffect, useMemo, useState } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { date } from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/actionType";
import store from "../../../redux/store";


export function ToWorkspace({ location }) {

    const LAYOUT_CONFIG_KEY = "LayoutConfig";

    const user = useSelector((state) => state.auth.user, shallowEqual);
    const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);
    const workspace = useSelector((state) => state.modl[currentWorkspace], shallowEqual)
    //================================================================================
    //CREATE INIT TABLE
    //site /siteGroup / group / user / menu / page / component / notice   초기 파일 생성

    const dispatch = useDispatch();

    useEffect(() => {
        let id = location.pathname.replace("/workspace/", "")

        console.log(location)
        console.log(id)

        InsertText(id)

        dispatch({ type: actionTypes.LoginVisitor })

        let payload = {
            "workspaceIdx": id,
            "userIdx": null,
        }

        dispatch({ type: actionTypes.LoginVisitorWorkspace, payload: payload })

    }, []);




    useEffect(() => {
        if (user == undefined || currentWorkspace == undefined) {
        }else{

            console.log(">>> currentWorkspace <<<")
            console.log(currentWorkspace)
            console.log(workspace)
            
            localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(workspace['layout']));
           
            setTimeout(() => { window.location.href = '/' }, 700)

        }


    }, [user, currentWorkspace]);






    //===================================================================================================
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================

    function getWorkspace(instance, data) {

        return new Promise((resolve, reject) => {

            const dataStr = JSON.stringify(data)

            const req = {
                query: `query getWorkspace($data: String) {
                getWorkspace(data: $data)
              }`,
                variables: {
                    data: dataStr
                }
            }


            instance.post('graphql/', req)
                .then(resp => {
                    console.log(">> resp.data.data.getWorkspace")
                    console.log(resp.data.data.getWorkspace)

                    resolve(resp.data.data.getWorkspace)
                })
                .catch(error => {
                    InsertText('ERR getWorkspace >' + error)
                });
        });
    }



    function getLog() {

        console.log(">>> getLog <<<")
        console.log(JSON.stringify(store.getState()))

    }

    function InsertText(text) {
        // result = '<div>[' + new Date().toLocaleString() + '] ' + text + '</div>' + result
        // let target = document.getElementById('result');
        // target.innerHTML = result
    }



    //===================================================================================================
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================

    return (
        <>

            {/* 
            <h3>MODL Component</h3>
            {JSON.stringify(el)}
             */}



            {/* <div onClick={() => { getLog() }}>getLog</div>
            <div className="d-flex flex-column flex-root">

                <div id='result'></div>

            </div> */}

        </>
    );
}
