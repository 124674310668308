import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../_helpers";



export function LoginModal(props) {

    const { parentId, ...rest } = props

    const { naver } = window
    // const NAVER_CALLBACK_URL = "http://192.168.29.128:3000/naver"
    // const NAVER_CALLBACK_URL = "http://localhost:3000/naver"
    const NAVER_CALLBACK_URL = "http://localhost:3000/naver"
    const NAVER_CLIENT_ID = "OrldxBP6CYxLFJvpduUP"

    const naverRef = useRef()

    const initializeNaverLogin = () => {

        const naverLogin = new naver.LoginWithNaverId({
            clientId: NAVER_CLIENT_ID,
            callbackUrl: NAVER_CALLBACK_URL,
            isPopup: false,
            loginButton: {
                color: "green",
                type: 3,
                height: 55
            }
        })

        naverLogin.init()
    }

    useEffect(() => {

        if (props.show) {

            initializeNaverLogin()
        }
    })

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <a onClick={() => rest.onHide()}><img className="po-modal-close" src={toAbsoluteUrl("/media/pageone/button_modal_close.png")} /></a>
                <div className="po-modal-title d-flex">
                    로그인 하기
                </div>

                <div className="po-modal-title-divider" />

                <div className="d-flex flex-column align-items-center">

                    <div className="po-modal-login-body">
                        <img className="po-modal-wise-icon" src={toAbsoluteUrl("/media/pageone/icon_pageone.png")} />
                    </div>

                    <div className="po-modal-login-text d-flex flex-column align-items-center">
                        <span>SNS 로그인으로 가입 / 로그인하고</span>
                        <span className="login-text-color">무료로 데이터 수집하세요</span>
                    </div>

                    <div className="po-modal-login-button">
                        <a onClick={() => naverRef.current.children[0].click()}>
                            <img alt="naver login" src={toAbsoluteUrl("/media/pageone/button_naver_login.png")} />
                        </a>
                    </div>

                    <div ref={naverRef} id="naverIdLogin" style={{ display: "none" }} />

                </div>
            </Modal.Body>

        </Modal>
    )
}