/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useCallback } from "react";
import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toAbsoluteUrl } from "../../../_helpers";
import { WelcomeSetting } from "./WelcomeSetting";

import axios from 'axios';

export function Welcome({ el, states, className }) {

  let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  const [data, setData] = React.useState("");

  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <WelcomeSetting
          data={data}
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });


  //==============================================================
  // Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    },
    expand: function () {
      fullScreenHandle.enter()

      return true
    },
    embed: function () {

      // src 외부 접근 가능 URL 을 적어주세요
      let copyStr =
        `<iframe width="1280" height="720" 
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

          console.log(copyStr)
          alert("check console.")

      return true
    },
    test: function (a, b) {
      return a + b
    }
  };


  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);






  // =====================================================
  // [S] Call Back
  // =====================================================

  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        {/* begin::Tiles Widget 1 */}
        <div key={Math.random()} className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`} style={{ backgroundColor: "#9780D8", width: "100%", height: "100%" }}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder text-muted">Welcome MODL</div>
                <div className="font-size-sm text-muted mt-2"></div>
              </div>
            </div>

          </div>
          {/* end::Header */}

          <div>

            <img src={toAbsoluteUrl("/media/bg/welcome.png")} style={{ width: "100%", height: "100%" }}></img>

          </div>

          {/* end::Body */}
        </div>
        {/* end::Tiles Widget 1 */}
      </FullScreen>
    </>
  );
}


