import React, {useState, useMemo} from "react";
import objectPath from "object-path";
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../../../_helpers";
import {useHtmlClassService} from "../../../_core/WiseLayout";
import {HeaderMenu} from "./HeaderMenu";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function HeaderMenuWrapper() {

    const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual);
    const workspace = useSelector((state) => state.modl[currentWorkspace], shallowEqual)
    // let logo = workspaces.workspaceInfo
    
    // console.log("HeaderMenuWrapper >> currentWorkspace")
    // console.log(currentWorkspace)
    // console.log(workspaces)
    // console.log(modl[currentWorkspace])
    
    let logo = ''
    logo = workspace.logo

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);




    const getHeaderLogo = () => {
        // let result = "logo-light.png";
        // if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
        //     result = "logo-dark.png";
        // }
        // return toAbsoluteUrl(`/media/logos/${result}`);
 
        let result = logo 
        return result
    };

    return <>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            {layoutProps.disabledAsideSelfDisplay && (
                <>

                {/* 
                aside.self.display == false 
                일때 헤더에 나타내는 로고 정의
                */}

                    {/*begin::Header Logo*/}
                    <div className="header-logo">
                        <Link to="/">
                            <img alt="logo" style={{ height: localStorage.projectName === "pageone" ? "24px" : "30px", paddingTop: localStorage.projectName === "pageone" ? "5px" : "" }} src={getHeaderLogo()}/>
                        </Link>
                    </div>
                    {/*end::Header Logo*/}
                </>
            )}
            {/*begin::Header Menu*/}
            <HeaderMenu layoutProps={layoutProps} />
            {/*end::Header Menu*/}
        </div>
        {/*Header Menu Wrapper*/}
    </>
}
