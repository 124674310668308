import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { useHtmlClassService } from "../../_core/WiseLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { CurrentDate } from "../extras/CurrentDate";
import { LoginModal } from "../../../../../_partials/widgets/pageone/LoginModal";
import { shallowEqual, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";



export function Topbar() {

  const currentMenu = useSelector((state) => state.modl.currentMenu, shallowEqual);
  const menuSelector = useSelector((state) => state.modl[currentMenu], shallowEqual);

  let mypageId = ""
  let contactId = ""

  if (menuSelector && localStorage.projectName === "pageone") {
    mypageId = menuSelector[0].items.filter(item => item.title === "MyPage")[0].id
    contactId = menuSelector[0].items.filter(item => item.title === "Contact")[0].id
  }
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <LoginModal
          show={loginModalShow}
          onHide={() => setLoginModalShow(false)}
        />
        ,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================

  const [loginModalShow, setLoginModalShow] = useState(false)

  const user = useSelector((state) => state.auth.user, shallowEqual);
  var userName = user?.name
  var userEmail = user?.email
  var userGrade = user?.grade

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar align-items-center">
      {
        localStorage.projectName === "pageone" ?

          userName === "visitor" ?

            <div className="d-flex align-items-center">
              <Link to={`/menu/${contactId}`} className="d-flex align-items-center" style={{ marginRight: "40px" }}>
                <img alt="mail" src={toAbsoluteUrl("/media/pageone/icon_mail.png")} />
                <span style={{ fontSize: "12px", fontWeight: "400", color: "#16215b", marginLeft: "8px" }}>contact</span>
              </Link>
              <div onClick={() => setLoginModalShow(true)} style={{ cursor: "pointer" }}>
                <img alt="login" src={toAbsoluteUrl("/media/pageone/button_login.png")} style={{ width: "102px", height: "30px" }} />
              </div>
            </div>

            :

            <div className="d-flex align-itens-center">
              <Link to={`/menu/${contactId}`} className="d-flex align-items-center" style={{ marginRight: "26px" }}>
                <img alt="mail" src={toAbsoluteUrl("/media/pageone/icon_mail.png")} />
                <span style={{ fontSize: "12px", fontWeight: "400", color: "#16215b", marginLeft: "8px" }}>contact</span>
              </Link>

              <div className="d-flex align-items-center" style={{ marginRight: "26px" }}>
                <span style={{ width: "1px", height: "18px", backgroundColor: "#16215b" }} />
              </div>

              <Link to={`/menu/${mypageId}`} className="d-flex align-items-center" style={{ marginRight: "53px", cursor: "pointer" }}>
                <img alt="page" src={toAbsoluteUrl("/media/pageone/icon_page.png")} />
                <span style={{ fontSize: "12px", fontWeight: "400", color: "#16215b", marginLeft: "8px" }}>my page</span>
              </Link>

              <Link
                to="/logout"
                className="d-flex align-items-center"
              >
                <img alt="logout" src={toAbsoluteUrl("/media/pageone/button_logout.png")} style={{ width: "102px", height: "30px" }} />

              </Link>
            </div>




          :
          <></>

      }




      {/* <CurrentDate /> */}





      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />}

      {
        layoutProps.viewQuickPanelDisplay && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
          >
            <div
              className="topbar-item"
              data-toggle="tooltip"
              title="Quick panel"
              data-placement="right"
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1"
                id="kt_setting_panel_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
              </div>
            </div>
          </OverlayTrigger>
        )
      }

      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {localStorage.projectName !== "pageone" ? layoutProps.viewUserDisplay && <QuickUserToggler /> : ""}
    </div >
  );
}
