
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageWorkspace_Delete(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    태그그룹 관리Del
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>






            </Modal.Body>
            <Modal.Footer>
                <Button style={btnStyle} size='lg' onClick={() => {
                    parentCallback("Setting ssssA")
                    rest.onHide()
                }}> no</Button>
                <Button style={btnStyle} size='lg' onClick={() => {
                    parentCallback("Setting ssssA")
                    rest.onHide()
                }}> yes</Button>
            </Modal.Footer>
        </Modal>
    );
}