import React, { useEffect, useMemo, useState, useCallback } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export function Test2({ el, onUpdateAutoHeight, states, className }) {

  let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  useEffect(() => {

    console.log(">> useEffect")


  }, []);




  if (states == undefined) return (<></>)

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <FullScreen handle={fullScreenHandle}>
        <div
          className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`}
        >
          <div className="card-body d-flex flex-column align-items-start justify-content-start">
            <div className="p-1 flex-grow-1">
              <h3>
      Test2...
              </h3>
            </div>

          </div>

        </div>

      </FullScreen>
    </div>
  );
}
