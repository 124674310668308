/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';

import BaseTable, { Column } from 'react-base-table'
import 'react-base-table/styles.css'

import { toAbsoluteUrl } from "../../../_helpers";

import { InputFormSetting } from "./InputFormSetting";
const axios = require("axios");


export function InputForm({ el, states, className }) {

  let id = "component" + el.i
  
  const [content, setContent] = React.useState("");


  useEffect(() => {
    console.log("DDDDD")

    let url = "http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/Corona19Status/1/20/"

    axios({
      method: 'get',
      url: url,
      responseType: 'application/xml'
    })
      .then(function (response) {
        console.log(response.data);
        setContent(response.data)

      });

  }, []);


  

  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <InputFormSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });
  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================






  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };

  const backgroundImageUrl = toAbsoluteUrl("/media/bg/bg-1.jpg");
  const imageUrl = toAbsoluteUrl("/media/bg/InputForm.png");

  return (
    <>

      <div className={`card card-custom`}>
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder text-muted">InputForm MODL</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>

        <div style={{overflow:"auto"}}>
          <div>{content}</div>
        </div>

      </div>


    </>
  );
}


