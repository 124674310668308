/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import { Button, Table, Pagination, Form, Col, Row } from "react-bootstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ManageKeyword_Setting } from "./ManageKeyword_Setting";
import { TempKeywordAlertAddWork } from "./TempKeywordAlertAddWork";
import { PushKeywordWork } from "./PushKeywordWork";
import { KeywordTagWork } from "./KeywordTagWork";

import { useResizeDetector } from "react-resize-detector";
import { useScreenClass, Visible } from "react-grid-system";

import { actionTypes } from "../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Header } from "../widgets/hyorim/Header";
import Utils from "../widgets/edit/WebBuilder/Utils";

import * as requestFromServer from "../../../app/_redux/modlCrud";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
import { Sweetalert_class } from "./SweetAlert";
import RenderManageHooks from "../widgets/kici/customHooks/RenderManageHooks";

const moment = require("moment");

export function TempKeywordAlertWork({
  el,
  onUpdateAutoHeight,
  states,
  className,
}) {


  let id = "component" + el.i;

  const fullScreenHandle = useFullScreenHandle();

  const dispatch = useDispatch();

  // =====================================================
  // [S] param
  // =====================================================

  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [pushModalShow, setPushModalShow] = useState(false);
  const [keywordModal, setKeywordModal] = useState(false);
  const [action, setAction] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [select_data, set_select_data] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  // const [site_list, set_site_list] = useState([]);
  const [changed, set_changed] = useState(null);
  const [keyword_arr, set_keyword_arr] = useState([]);
  const [push_data, set_push_data] = useState(null);
  const [sort_data, set_sort_data] = useState("keyword_regis");

  const modl = useSelector((state) => state.modl, shallowEqual);
  const currentWorkspace = useSelector(
    (state) => state.modl.currentWorkspace,
    shallowEqual
  );
  const currentMenu = useSelector(
    (state) => state.modl.currentMenu,
    shallowEqual
  );
  const memberList = useSelector(
    (state) => state.modl.memberList,
    shallowEqual
  );
  const groupAll = useSelector((state) => state.modl.groupAll, shallowEqual);
  const isCheckEmail = useSelector(
    (state) => state.modl.isCheckEmail,
    shallowEqual
  );

  // SETTING PAGING
  let [totalItemCount, setTotalItemCount] = useState(0); // 현재 페이지
  let [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [total_item, set_total_item] = useState(0); // 전체 아이템 개수
  let itemCountOfPage = 5; // 한 페이지의 아이템수
  let pageCountOfGroup = 10; // 페이지에 보여지는 카운트 수 < 1 2 3 4 5 >

  const [totalPageCount, set_totalPageCount] = useState(0);
  const [limit, set_limit] = useState(5); // 한 페이지의 리스트 수
  const [page, set_page] = useState(1); // 현재 페이지
  const [offset, set_offset] = useState((page - 1) * limit);
  const [num_pages, set_num_pages] = useState(total_item / limit); // 페이지네이션 버튼 개수
  //const off_set = (page - 1) * limit;

  //let totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
  let currentGroup = Math.ceil(currentPage / pageCountOfGroup); // 현재 그룹 인덱스
  let lastGroup = Math.ceil(totalPageCount / pageCountOfGroup); // 마지막 그룹 인덱스
  let pageCountOfLastGroup =
    totalPageCount % pageCountOfGroup == 0
      ? pageCountOfGroup
      : totalPageCount % pageCountOfGroup; // 마지막 그룹의 페이지 수
  let lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup; // 마지막 페이지 인덱스
  let pageCountOfCurrentGroup =
    currentGroup == lastGroup ? pageCountOfLastGroup : pageCountOfGroup; // 현제 페이지의 페이지수

  let class_put;

  const formGroupStyle = {
    overflow: "hidden",
    marginBottom: "0",
    position: "relative",
    height: "78.375px",
  };

  const formCheckStyle = {
    float: "left",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const btnStyle = {
    margin: "20px 4.5px",
    float: "right",
  };

  const delBtnStyle = {
    margin: "20px 0 20px 4.5px",
    float: "right",
  };

  // const btnStyle = {
  //   margin: "8px 2px",
  //   float: "right"
  // }

  const thStyle = {
    padding: "15px 22px 15px 22px",
    //borderWidth: "1px",
    border: "0 !important",
    // borderStyle: "solid",
    borderColor: "#ebebeb",
    // backgroundColor: "#fafafb",
    backgroundColor: "#ecf0f9",
    textAlign: "center",
  };

  const kicithStyle = {
    padding: "15px 22px 15px 22px",
    // borderWidth: "1px",
    border: "0 !important",
    // borderStyle: "solid",
    borderColor: "#ebebeb",
    backgroundColor: "#cbd2e1",
    textAlign: "center",
  };

  const tdStyle = {
    padding: "10px 22px 10px 22px",
    borderWidth: "1px",
    borderColor: "#ebebeb",
    textAlign: "center",
    height: "62.5px",
    verticalAlign: "middle",
  };

  const screenClass = useScreenClass();

  // =====================================================
  // [S] Resize Event
  // =====================================================
  const onResize = useCallback((width, height) => {
    if (el.isAutoHeight == true) {
      onUpdateAutoHeight(el.i, height);
    }
  }, []);

  const { ref, width, height } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    // refreshMode: 'debounce',
    // refreshRate: 300,
    onResize,
  });

  // =====================================================
  // [E] Resize Event
  // =====================================================

  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  useEffect(() => {
    let parent = document.getElementById("contentModal");
    if (parent != null) {
      ReactDOM.render(
        <>
          <ManageKeyword_Setting
            show={modalShow}
            onHide={() => setModalShow(false)}
            parentCallback={settingCallback}
          />
          <TempKeywordAlertAddWork
            show={addModalShow}
            action={action}
            data={select_data}
            workSpace={currentWorkspace}
            onHide={() => setAddModalShow(false)}
            parentRender={updatePaging}
            item={total_item}
            menu={modl[currentMenu]}
          />
          <PushKeywordWork
            show={pushModalShow}
            data={push_data}
            workSpace={currentWorkspace}
            onHide={() => setPushModalShow(false)}
            parent_send={parent_send}
          />
          <KeywordTagWork
            show={keywordModal}
            onHide={() => setKeywordModal(false)}
            keyword={keyword_arr}
          />
        </>,
        document.getElementById("contentModal")
      );
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================

  const parent_send = (param) => {
    set_changed(param);
  };

  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function() {
      setModalShow(true);
      return true;
    },
  };

  useEffect(() => {
    if (states[id] == undefined) return;
    let method = Object.keys(states[id])[0];
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]();
    } else {
      console.log("method: not exist \nInsert key in functionObj");
    }
  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  useEffect(() => {
    let payloadGroup = {
      workspaceIdx: currentWorkspace.replace("workspace", ""),
    };
    dispatch({ type: actionTypes.GetGroupAll, payload: payloadGroup });

    // let payload = {
    //   "workspaceIdx": currentWorkspace.replace("workspace", ""),
    //   "itemCountOfPage": 1,
    //   "currentPage": 1

    // }

    // dispatch({ type: actionTypes.GetWorkspaceMember, payload:payload })

    updatePaging();
  }, []);

  //==============================================================
  // [E] 컴포넌트 로딩시 사이트 데이터 가져오기
  //==============================================================

  useEffect(() => {

    let payload = {
      queries:
        sort_data === "keyword_regis"
          ? [
              
              {
                key: "count",
                query: `db.collection('KeywordGroup').aggregate([
                  { $match: { "workspaceIdx" : "${currentWorkspace.replace(
                    "workspace",
                    ""
                )}" } },
                { $count: "totalCount" }
                ]).toArray()`,
              },
            ]
          : [
              {
                key: "count",
                query: `db.collection('KeywordGroup').aggregate([
                  { $match: { "workspaceIdx" : "${currentWorkspace.replace(
                    "workspace",
                    ""
                )}" } },
                { $count: "totalCount" }
                ]).toArray()`,
              }
            ]
    };

    Utils.getQueryResult(payload).then((result) => {
      const { count } = { ...result };

      if (count) {
        set_total_item(count[0].totalCount);
      }
    });
  }, [changed, sort_data, currentPage]);

  const { site_list } = RenderManageHooks(changed, sort_data, currentPage, currentWorkspace);

  useEffect(() => {
    set_totalPageCount(Math.ceil(total_item / itemCountOfPage)); // 전체 페이지수
    currentGroup = Math.ceil(currentPage / pageCountOfGroup); // 현재 그룹 인덱스
    lastGroup = Math.ceil(totalPageCount / pageCountOfGroup); // 마지막 그룹 인덱스
    pageCountOfLastGroup =
      totalPageCount % pageCountOfGroup == 0
        ? pageCountOfGroup
        : totalPageCount % pageCountOfGroup; // 마지막 그룹의 페이지 수
    lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup; // 마지막 페이지 인덱스

    if (currentPage > lastPage && lastPage != 0) {
      selectPage(lastPage);
    }
  }, [totalItemCount, total_item, currentPage]);

  useEffect(() => {
    set_offset((page - 1) * limit);
  }, [page]);

  useEffect(() => {
    set_num_pages(total_item / limit);
  }, [total_item, limit]);

  // =====================================================
  // [S] modal
  // =====================================================

  const deleteItem = async () => {
    class_put = new Sweetalert_class(
      "warning",
      "정말로 삭제하시겠습니까?",
      "선택하신 그룹을 삭제하시겠습니까?"
    );
    const swal_result = await class_put.confirm_alert();

    if (swal_result) {
      let payload = {
        queries: [
          {
            key: "deleteGroup",
            query: `db.collection('KeywordGroup').deleteMany({ title: { $in : ${JSON.stringify(
              checkedList
            )} } })`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        console.log(result);
        if (result.deleteGroup.acknowledged === true) {
          class_put = new Sweetalert_class(
            "success",
            "삭제 완료!",
            "삭제 완료되었습니다."
          );
          class_put.crate_alert();
        }
      });

      Swal.fire({
        icon: "success",
        title: "삭제하였습니다!",
        text: "성공적으로 삭제하였습니다.",
      });

      updatePaging();
    }
  };

  const addItem = () => {
    setAction("add");
    setSelectIndex(0);
    setAddModalShow(true);
  };

  const editItem = (param) => {
    setAction("edit");
    set_select_data(param);
    setAddModalShow(true);
  };

  /** 모달이 출력될때 데이터를 보내므로 클릭시 데이터 갱신*/
  const push_modal_open = (param) => {
    setPushModalShow(true);
    set_push_data(param);
  };

  const settingCallback = (data) => {
    console.log("callback: " + data);
  };

  const view_keyword_modal = (param) => {
    set_keyword_arr(param);
    setKeywordModal(true);
  };


  // =====================================================
  // [E] modal
  // =====================================================
  const getCheckState = (id) => {
    return checkedList.includes(id);
  };

  const setCheckState = (id, e) => {
    let isChecked = e.target.checked;

    if (isChecked) {
      let joined = checkedList.concat(id);
      setCheckedList(joined);
    } else {
      setCheckedList(checkedList.filter((item) => item !== id));
    }
  };

  const change_sort = (evt) => {
    const { value } = evt;

    set_sort_data(value);
  };

  const averlap_focus_out = async (param, evt) => {
    class_put = new Sweetalert_class(
      "info",
      "저장하시겠습니까?",
      "키워드 중복설정을 저장하시겠습니까?"
    );
    const swal_result = await class_put.confirm_alert();

    if (swal_result) {
      const { value } = evt;

      const payload = {
        queries: [
          {
            key: "update",
            query: `db.collection('KeywordGroup').update({ _id: new require('mongodb').ObjectID('${param}') },
            { $set: { 'threshold': ${Number(value)} }})`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        if (result.update.acknowledged === true) {
          class_put = new Sweetalert_class(
            "success",
            "수정되었습니다.",
            "키워드 중복 설정을 수정하였습니다."
          );
          class_put.crate_alert();
          set_changed(new Date());
        }
      });
    }
  };

  const delete_condition = async (param, keywords) => {
    class_put = new Sweetalert_class(
      "warning",
      "삭제하시겠습니까?",
      "조건을 삭제하시겠습니까?"
    );
    const swal_result = await class_put.confirm_alert();

    if (swal_result) {
      keywords.map(async (data) => {
        const payload = {
          queries: [
            {
              key: "update",
              query: `db.collection('Group').update({ _id: new require('mongodb').ObjectID('${param}'), 'keywords.keyword': '${data.keyword}' },
              { $unset: { 'keywords.$.more': '', 'keywords.$.type': '' }})`,
            },
          ],
        };

        await Utils.getQueryResult(payload).then((result) => {
          if (result.update.acknowledged === true) {
            class_put = new Sweetalert_class(
              "success",
              "삭제되었습니다.",
              "키워드 중복 설정을 삭제하였습니다."
            );
            class_put.crate_alert();
            set_changed(new Date());
          }
        });
      });
    }
  };

  // =====================================================
  // [S] page
  // =====================================================

  let items = [];

  const updatePaging = () => {
    let payload = {
      workspaceIdx: currentWorkspace.replace("workspace", ""),
      document: "Group",
    };

    requestFromServer
      .getDocumentCount(payload)
      .then((response) => {
        setTotalItemCount(JSON.parse(response).count);
        set_changed(new Date());
        return response;
      })
      .catch((error) => {
        error.clientMessage = "Can't delete modl";
      });
  };

  const selectPage = (idx) => {
    setCurrentPage(idx);
    set_page(idx);
  };

  if (currentGroup != 1) {
    items.push(
      <Pagination.Prev
        key={Math.random()}
        onClick={() => {
          selectPage(currentPage - pageCountOfGroup);
        }}
      ></Pagination.Prev>
    );
  }

  for (let number = 1; number <= Math.ceil(Number(num_pages)); number++) {
    let idx = (currentGroup - 1) * pageCountOfGroup + number;
    items.push(
      <Pagination.Item
        key={Math.random()}
        active={idx === currentPage}
        onClick={() => {
          selectPage(idx);
        }}
      >
        {idx}
      </Pagination.Item>
    );
  }

  if (currentGroup != lastGroup) {
    items.push(
      <Pagination.Next
        key={Math.random()}
        onClick={() => {
          selectPage(
            currentPage + pageCountOfGroup > lastPage
              ? lastPage
              : currentPage + pageCountOfGroup
          );
        }}
      ></Pagination.Next>
    );
  }

  // pageCountOfLastGroup

  // =====================================================
  // [E] table content
  // =====================================================

  if (!site_list) return <>데이터 없음</>;
  let site_ui = site_list.slice(offset, offset + limit).map((data, index) => {
    let checked = data.filter.some((item) => item.more.length !== 0);
    return (
      <tr key={Math.random()}>
        <td style={tdStyle}>
          <Form.Check
            onChange={(e) => setCheckState(data.title, e)}
            checked={getCheckState(data.title)}
          />
        </td>
        <td style={tdStyle}>{total_item - 5 * (page - 1) - index}</td>
        <td style={tdStyle}>{data.title ? data.title : "-"}</td>
        <td
          style={tdStyle}
          onClick={() => {
            view_keyword_modal(data.filter);
          }}
        >
          {!data.filter.length === 0
            ? "데이터 없음"
            : data.filter.length + "건"}
        </td>
        <td style={tdStyle}>
          {
            <input
              type="text"
              className="overlap_set"
              defaultValue={data.threshold === 0 ? "-" : data.threshold}
              onBlur={(e) => {
                averlap_focus_out(data._id, e.target);
              }}
            />
          }
        </td>
        <td style={tdStyle}>
          <Form.Check
            checked={checked ? true : false}
            inline
            label="Y"
            name={"group" + index}
            type="radio"
            id={"group" + index}
          />
          <Form.Check
            checked={checked ? false : true}
            inline
            label="N"
            name={"group" + index}
            type="radio"
            id={"group" + index}
          />
        </td>
        <td
          style={tdStyle}
          className="terms_class"
          onClick={() => {
            push_modal_open(data);
          }}
        >
          {!checked ? "조건 추가" : "조건 수정"}
        </td>
        <td
          style={tdStyle}
          onClick={() => {
            editItem(data);
          }}
        >
          <button className="table_in_btn">수정하기</button>
        </td>
        <td style={tdStyle}>
          {data.updateDate ? data.updateDate.substr(0, 10) : "-"}
        </td>
      </tr>
    );
  });

  return (
    // <div style={{ paddingBottom: "20px" }}>
    <div ref={ref}>
      {/* div 대신 <></> 를 쓰면 item size 만큼 배경을 채워진다. */}
      <FullScreen handle={fullScreenHandle}>
        <div
          style={{ backgroundColor: "transparent", boxShadow: "none" }}
          className={`card card-custom ${className}`}
        >
          <Header el={el}></Header>
          <div
            className="card-body shadow-xs d-flex flex-column p-0"
            style={{
              backgroundColor: "white",
              borderRadius: "0.45rem",
              overflow: "auto",
            }}
          >
            <div className="flex-grow-1" style={{ padding: "0 20px 20px" }}>
              <Form.Group style={formGroupStyle}>
                <div className="sort_wrap">
                  <Form.Label>정렬</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      change_sort(e.target);
                    }}
                    label="전체 선택"
                    className="sort_select"
                    value={sort_data}
                  >
                    <option value="keyword_regis">키워드 등록 순</option>
                    <option value="site_name">사이트명 순</option>
                  </Form.Control>
                </div>

                <Button
                  style={delBtnStyle}
                  onClick={() => {
                    deleteItem();
                  }}
                >
                  {" "}
                  삭제
                </Button>

                <Button
                  style={btnStyle}
                  onClick={() => {
                    addItem();
                  }}
                >
                  {" "}
                  추가
                </Button>
              </Form.Group>
              <div style={{ overflow: "auto" }}>
                <Table
                  striped
                  hover
                  style={{ minWidth: "1200px", marginBottom: "0" }}
                >
                  <thead>
                    <tr>
                      <th style={thStyle}>선택</th>
                      <th style={thStyle}>번호</th>
                      <th style={thStyle}>그룹명</th>
                      <th style={thStyle}>키워드</th>
                      <th style={kicithStyle}>키워드 중복설정</th>
                      <th style={kicithStyle}>조건 유무</th>
                      <th style={kicithStyle}>조건 추가 및 수정</th>
                      <th style={thStyle}>수정</th>
                      <th style={thStyle}>업데이트 날짜</th>
                    </tr>
                  </thead>
                  <tbody className="keyword_alert_tbody">
                    {Object.keys(site_ui).length !== 0 ? (
                      site_ui
                    ) : (
                      <tr>
                        <td colSpan="11" className="not_data">
                          현재 데이터가 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div style={{ float: "right" }}>
                <Pagination>{items}</Pagination>
              </div>
            </div>
          </div>
        </div>
      </FullScreen>
      {/* </div> */}
    </div>
  );
}
