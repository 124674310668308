import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_wise/_helpers";

import store, { persistor } from "../../../../redux/store"
import clsx from "clsx";
/*
  Explain docs:
  https://www.zerocho.com/category/React/post/57b60e7fcfbef617003bf456
*/

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("이메일 형식이 맞지 않습니다")
      .min(3, "3자리이상 입력해주세요")
      .max(50, "50자리를 넘을수 없습니다")
      .required("빈칸을 입력해주세요"),
    password: Yup.string()
      .min(3, "3자리이상 입력해주세요")
      .max(50, "50자리를 넘을수 없습니다")
      .required("빈칸을 입력해주세요"),
  });

  // 언어 지원
  // intl.formatMessage({
  //   id: "AUTH.GENERAL.SUBMIT_BUTTON",
  // })

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(resp => {
            // console.log("useFormik ======== resp")
            // console.log(resp)

            if (resp.data.data.login == null) {
              disableLoading();
              setSubmitting(false);
              setStatus("아이디나 비밀번호를 다시 입력해주세요.");

              // intl.formatMessage({
              //   id: "아이디나 비밀번호를 다시 입력해주세요.",
              // })
            } else {
              disableLoading();
              console.log("useFormik ======== resp 1")
              props.login(resp.data.data.login);
              console.log("useFormik ======== resp 2")
            }

          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus("로그인 에러");
          });
      }, 1000);
    },
  });



  return (

    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5"
        style={{
          backgroundImage: `url("https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/images/kici_logo.PNG")`,
          width: '100%',
          height: '50px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >

      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="mb-15">
          <div className="text-center"
            style={{
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            본 시스템은 와이즈솔루션이 개발하고
            납품하는 시스템입니다.
          </div>
        </div>

        <div className="input-group mb-5">
          <div className="input-group-prepend">
            <span className="input-group-text ">
              <img
                alt="Logo"
                className="max-h-70px"
                src={toAbsoluteUrl("/media/logos/id_image.png")}
              />
            </span>
          </div>
          <input
            placeholder="아이디를 입력해주세요."
            type="email"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
        </div>

        {formik.touched.email && formik.errors.email ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.email}</div>
          </div>
        ) : null}

        <div className="input-group mb-5">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <img
                alt="Logo"
                className="max-h-70px"
                src={toAbsoluteUrl("/media/logos/pw_image.png")}
              />
            </span>
          </div>
          <input
            placeholder="비밀번호를 입력해주세요."
            type="password"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
        </div>

        {formik.touched.password && formik.errors.password ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.password}</div>
          </div>
        ) : null}

        {formik.status ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.status}</div>
          </div>
        ) : null}

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-10">


          {/* Forgot Password */}
          {/* <Link
          to="/auth/forgot-password"
          className="text-dark-50 text-hover-primary my-3 mr-2"
          id="kt_login_forgot"
        >
          <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
        </Link> */}



          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary btn-lg rounded-pill btn-block font-weight-bold`}
            style={{ backgroundColor: '#017bc5' }}
          >
            <span>로그인</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <div style={{ width: '100%', height: '1px', margin: '30px 0 20px', backgroundColor: '#ebecf0' }}></div>
          <div style={{ textAlign: 'center', width: '100%', fontWeight: 500 }}>접근권한은 <span style={{ color: '#017bc5' }}>tech@wisesolution.co.kr </span> 으로 요청해주세요.</div>
        </div>
      </form>
      {/*end::Form*/}
    </div>



  );
}



export default injectIntl(connect(null, auth.actions)(Login));
