import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../_helpers";



export function TargetModal(props) {

    const { parentId, ...rest } = props



    useEffect(() => {

        if (props.show) {

        }
    })

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <a onClick={() => rest.onHide()}>
                    <img className="po-modal-close" src={toAbsoluteUrl("/media/pageone/button_modal_close.png")} />
                </a>
                <div className="po-modal-title d-flex justify-content-between align-items-end">
                    <span>리뷰수집 대상 사이트</span>
                    <span className="target-text-color">* 페이지 하단에서 사이트 추가 요청이 가능합니다.</span>
                </div>

                <div className="po-modal-title-divider" />

                <div className="po-modal-target-body d-flex">
                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/modal_coupang.png" />
                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/modal_ssg.png" />
                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/modal_lotte.png" />
                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/modal_homeplus.png" />
                </div>
            </Modal.Body>
        </Modal>
    )
}