import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/WiseLayout";
import { toAbsoluteUrl } from "../../../../../_helpers";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { KTUtil } from "../../../_assets/js/components/util";


export function Brand() {

  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual);
  const workspace = useSelector((state) => state.modl[currentWorkspace], shallowEqual)

  // let logo = workspaces.workspaceInfo

  // console.log("brand >> currentWorkspace")
  // console.log(currentWorkspace)
  // console.log(workspace)
  // console.log(modl[currentWorkspace])


  let logo = ''
  logo = workspace.logo


  // console.log(logo)

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: logo, //uiService.getLogo(),
      headerStickyLogo: logo // uiService.getStickyLogo()
    };
  }, [uiService]);



  const clickLogo = () => {

    const element = document.getElementById("kt_aside_menu").children[0].children
    console.log("+ clickLogo")
    // window.location.href="/"
    // window.location.reload(false);

    for (var i = 0, len = element.length; i < len; i++) {
      var el_ = element[i];
      var submenu_ = KTUtil.child(el_, '.menu-submenu');
      if (submenu_) {
        KTUtil.slideUp(submenu_, 200, function () {
          KTUtil.removeClass(el_, 'menu-item-open');
        });
        
        // 서브메뉴가 있는 경우 서브메뉴도 초기화
        if (el_.querySelector('.menu-subnav').children.length > 1) {
          var subnav_ = el_.querySelector('.menu-subnav').querySelector('.menu-item.menu-item-submenu')
          subnav_.classList.remove('menu-item-open')
        }
        el_.classList.remove('menu-item-open');
      }
    }
  }



  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      // onClick={() => clickLogo()}
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo" style={{ width: "100%", height: "100%" }} onClick={clickLogo}>
          <img id="modl-workspace-logo" alt="logo" src={layoutProps.headerLogo} style={{ width : "auto", height : "100%"}}/>
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
