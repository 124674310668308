/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toAbsoluteUrl } from "../../../_helpers";

/* PO_welcome.js 에만 적용되는 css: _pageone.scss */
import "../../../templates/type1/_assets/sass/components/_pageone.scss"

import axios from 'axios';

import { useResizeDetector } from 'react-resize-detector';
import { useScreenClass, Visible } from 'react-grid-system';
import GoogleMap from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';

import Utils from "../edit/WebBuilder/Utils";

const moment = require('moment');

export function Contact({ el, onUpdateAutoHeight, states, className }) {

    let id = "component" + el.i

    const fullScreenHandle = useFullScreenHandle();

    const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
    const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
    const userId = useSelector((state) => state.auth.user._id, shallowEqual)


    // =====================================================
    // [S] Resize Event
    // =====================================================
    const onResize = useCallback((width, height) => {
        if (el.isAutoHeight == true) {
            onUpdateAutoHeight(el.i, height)
        }
    }, []);

    const { ref, width, height } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        // refreshMode: 'debounce',
        // refreshRate: 300,
        onResize
    });

    // =====================================================
    // [E] Resize Event
    // =====================================================


    // =====================================================
    // [S] modal - setModalShow(true)
    // =====================================================


    useEffect(() => {
        let parent = document.getElementById("contentModal")
        if (parent != null) {
            ReactDOM.render(
                <>
                </>
                ,
                document.getElementById("contentModal")
            )
        } else {
        }
    });




    return (
        <div ref={ref}>
            <FullScreen handle={fullScreenHandle}>
                {/* begin::Tiles Widget 1 */}
                <div style={{ minWidth: "1080px", height: "100%" }}>

                    {/* Sub Header */}
                    <div className="subheader-background" />

                    <div className="subheader d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <img src={toAbsoluteUrl("/media/pageone/icon_mail.png")} className="subheader-icon-mail" />
                            <span>Contact Us</span>
                        </div>
                    </div>
                    {/* Sub Header */}

                    {/* Body */}
                    <div className="contact-body d-flex justify-content-center">
                        <div className="contact-map" >
                            <GoogleMap
                                bootstrapURLKeys={{ key: "AIzaSyD483dpTHHeIlI3dB-LqO0nmKeTM_pkUr8" }}
                                defaultZoom={15}
                                defaultCenter={{ lat: 37.3985, lng: 126.9632 }}
                                draggable={false}
                            >
                                <FaMapMarkerAlt lat={37.3985} lng={126.9632} />
                            </GoogleMap>
                        </div>

                        <div className="contact-address">
                            <div className="contact-address-title">address</div>

                            <div className="contact-divider" />

                            <div className="contact-address-detail">
                                <div><b>(14055)</b></div>
                                <div className="address-location">
                                    <div>경기도 안양시 동안구</div>
                                    <div>시민대로327번길 11-41 (관양동) 611호</div>
                                </div>

                                <div className="address-way-to-come">
                                    <div>평촌역 3번 출구에서 약 400m 직진 &gt;</div>
                                    <div>오른쪽으로 50m 직진 &gt; 안양창업지원센터</div>
                                </div>

                                <div className="contact-method">

                                    <div className="contact-mail d-flex align-items-center">
                                        <img src={toAbsoluteUrl("/media/pageone/icon_mail.png")} />
                                        <span>biz@wisesolution.co.kr</span>
                                    </div>

                                    <div className="contact-call d-flex align-items-center">
                                        <img src={toAbsoluteUrl("/media/pageone/icon_phone.png")} />
                                        <span>영업담당자 직접 문의 |</span> &nbsp; 010-7178-3960
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Body */}


                </div>

                {/* end::Tiles Widget 1 */}
            </FullScreen>
        </div>
    );
}


