
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageKeyword_Add(props) {
    const { parentId, parentCallback, checkEmailCallback,isCheckEmail, action, selectIndex, menu, memberList,groupAll, siteList, ...rest } = props

    const [email, setEmail] = useState("");
    const [site_name, set_site_name] = useState("");
    const [name, setName] = useState("");
    const [site_url, set_site_url] = useState("");
    const [site_keyword, set_site_keyword] = useState([]);

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [phone, setPhone] = useState("");
    const [memo, setMemo] = useState("");
    const [existCheckEmail, setExistCheckEmail] = useState([]);
    const [existCheckEmailText, setExistCheckEmailText] = useState("new");

  
    useEffect(() => {

        if (rest.show == true) {

            // console.log("menu > ")
            // console.log(menu)
            // console.log("memberList > ")
            // console.log(memberList)
            // console.log("action > ")
            // console.log(action)
            // console.log("selectIndex > ")
            // console.log(selectIndex)

            // console.log("isCheckEmail >")
            // console.log(isCheckEmail)
            
            setExistCheckEmail(isCheckEmail)

            if (action == "edit") {

                set_site_url(siteList[selectIndex].selector);
                set_site_name(siteList[selectIndex].title);
                set_site_keyword(siteList[selectIndex].filter[0].rule);
          
            } else if (action == "add") {
               
                set_site_url("");
                set_site_name("");
                set_site_keyword([]);

                setExistCheckEmail([]);

            }


            // console.log("allowMenuList > ")
            // console.log(allowMenuList)
        }

    }, [rest.show]);


    
    useEffect(() => {
        setExistCheckEmail(isCheckEmail)
    }, [isCheckEmail]);




    useEffect(() => {
        // console.log("existCheckEmail>>")
        // console.log(existCheckEmail)

        if (existCheckEmail != null) {
            if (existCheckEmail.length == 0) {
                setExistCheckEmailText("")

            } else {
                if(action == "add") {setExistCheckEmailText("[존재하는 아이디 입니다. 초대하시겠습니까?]")}

            }
        }
    }, [existCheckEmail]);




    const btnStyle = {
        margin: "2px",
    }


    
    const change_site_name = (e) => {

        const { value } = e.target;
        // let payload = {
        //     "email": e.target.value
        // }
        //checkEmailCallback(payload)
        //setEmail(e.target.value)
        set_site_name(value);
    }

    const change_site_url = (e) => {
        
        const { value } = e.target;
        
        setName(value);
    }

    const change_keyword = (e, param) => {

        const { value } = e.target;
        
        const modify_arr = site_keyword.map((data, index)=> index === param ? (
            data = value
        ) : data )

        set_site_keyword(modify_arr);
    }



    // const getCheckState = (id) => {
    //     // console.log("^^ getCheckState")
    //     let isCheck = false

    //     for (let mList of allowMenuList) {
    //         if (mList.idx == id) {
    //             isCheck = true
    //         }
    //     }

    //     return isCheck
    // }

    let SelectorUI = (groupAll != null)? groupAll.map((_group, index) =>
        <option key={Math.random()} value={_group._id || ''}>{_group.title}</option>
    ):null

    if (selectIndex == -1) return <></>


    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {(action == "add") ? <>키워드 추가</> : <>키워드 수정</>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>사이트명* {existCheckEmailText}</Form.Label>
                        {
                            (action == "add") ? <Form.Control placeholder="사이트명을 입력해주세요" onChange={change_site_name} value={site_name || ''} />
                                : <Form.Control placeholder="사이트명을 입력해주세요" onChange={change_site_name} value={site_name || ''} readOnly />
                        }
                        
                    </Form.Group>

                    {(action == "edit" || (action == "add" &&(existCheckEmail == null || existCheckEmail.length == 0))) ? (<>
                    <Form.Group id="formUserId">
                        <Form.Label>URL*</Form.Label>
                        <Form.Control placeholder="URL를 입력해주세요"  onChange={change_site_url} value={site_url || ''} readOnly />
                    </Form.Group>
                    <Form.Row>
                    {
                        site_keyword.map((data, index) => (
                            
                                <Form.Group as={Col} id="formPassword" md={6}>
                                    <Form.Label>키워드{Number(index) + 1}*</Form.Label>
                                    <Form.Control placeholder="첫번째 키워드를 입력해주세요"  onChange={(e)=>{ change_keyword(e, index) }} value={data} />
                                </Form.Group>
                        ))
                    }
                    </Form.Row>
                    </>   
                    )
                        : <></>
                    }


                    {(action === "edit") &&
                        <Button style={btnStyle} size='sm' onClick={() => {

                            let payload = {
                                "action": "edit",
                                "title": site_name,
                                "url": site_url,
                                "keyword": site_keyword,                                
                                "_id": siteList[selectIndex]._id
                            }

                          
                            parentCallback(payload)
                            rest.onHide()
                        }}> 저장</Button>
                    }



                </Form>




            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}