/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, Component, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { Button, Form, Col, Row, Modal, ListGroup } from 'react-bootstrap';

import axios from "axios";

// import { WebBuilderCore } from "./WebBuilderCore";
import { WebBuilderSetting } from "./WebBuilderSetting";
import SlidingPane from "react-sliding-side-panel";
import 'react-sliding-side-panel/lib/index.css';

import { actionTypes } from "../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import store from "../../../../redux/store";

import Builder from "./WebBuilder/Builder";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrag } from "react-dnd";

import "./WebBuilder/styles.css";

import shortid from "shortid";
import { LAYOUT, WRAPPER, ROW, COLUMN, ELEMENT, SIDEBAR_LAYOUT, SIDEBAR_ROW, SIDEBAR_COLUMN, SIDEBAR_ELEMENT } from "./WebBuilder/constants";

export function WebBuilder({ el, states, className, ...props }) {
  let id = "component" + el.i


  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)
  const components = useSelector((state) => state.modl[currentPage].component, shallowEqual)
  const [initLayout, setInitLayout] = React.useState();
  
  

  

  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  

  const loadContent = () => {

    let content = document.getElementById("contentModal")
    if (content != null) {

      // return ReactDOM.render(
      return ReactDOM.createPortal(

        <div>
          <SlidingPane
            panelContainerClassName="sliding-pane-container-input-builder"
            size={25}
            type={'right'}
            isOpen={modalShow}
            backdropClicked={() => setModalShow(false)}
            noBackdrop={true}

          >

            <WebBuilderSetting
              key={Math.random()}
              parentCallback={selectSetting}
              onHide={() => setModalShow(false)}
              states={states}
              id={id}
            />

          </SlidingPane>

        </div>
        , content)

    }

  }

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    edit: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  
  // ========================================================================
  // 데이터 INIT...
  // ========================================================================

  useEffect(() => {
    console.log("initLayout ==== currentComponent ")
  
    components.map((comp, index) => {

      if (comp.i == el.i) {

        if(comp.data?.layout == undefined){

          let defaultLayout = [{
            "type": "layout",
            "id": shortid.generate(),
            "children": [{
              "type": "row",
              "id": shortid.generate(),
              "children": [{
                "type": "column",
                "id": shortid.generate(),
                "children": []
              }]
            }]
          }]

          let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: defaultLayout })
          dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

          

        }else{
          setInitLayout(comp.data?.layout)
        }


        

      }
    })
  }, [components]);





  // =====================================================
  // [E] modal
  // =====================================================



  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  // let content = document.getElementById("content")
  // const handlePress = (e) => {
  //   console.log("____ handlePress:")
  //   e.stopPropagation();
  //   e.nativeEvent.stopImmediatePropagation();
  //   // props.callbackParent({ isDraggable: false });
  // }


  const clickTest = () => {

    // console.log("store ======")
    // console.log(store.getState().modl)
    
    // console.log("For TEST ======")

    // let layout = [{"type":"layout","id":"iu1E-p9_j22","children":[{"type":"row","id":"X8sLqPR0ZF","children":[{"type":"column","id":"yw6T-LaCTp","children":[{"type":"element","id":"0JIkp6aATW","elementType":"input","test1":"hardcoder 2","style":{"padding":"0px","width":"0px"},"data":{"test":"data_0"}}]}]}]}]

    // let elCopy = JSON.parse(JSON.stringify(el))

    // console.log(elCopy)

    // elCopy.data.layout = layout

    // let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), component: elCopy }) 

    // dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

    // console.log("For TEST ======")
    
  }




  return (
    <>

      {/* side menu */}
      {loadContent()}

      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">WebBuilder</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>

        <div className="card-body d-flex flex-column px-0">

          <div className="flex-grow-1 card-spacer-x">

            {/* <Button size="sm" onClick={clickTest}> load sub layouts </Button> */}

            <Builder el={el} initLayout={initLayout}/>

          </div>

        </div>

      </div>


    </>

  );
}

