
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageWorkspace_Setting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    A
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




settings...



                <Form>


                    <Button style={btnStyle} size='lg' onClick={() => {
                        parentCallback("Setting A")
                        rest.onHide()
                    }}> 추가</Button>




                </Form>



            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}