import React, { useState, useEffect } from "react";
import { useCallback, Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_wise/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_wise/templates/type1/layout";
import { DragDropContext } from 'react-beautiful-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrag } from "react-dnd";

export default function App({ store, persistor, basename }) {


  // using useCallback is optional
  const onBeforeCapture = () => {
  }
  const onBeforeDragStart = () => {
  }
  const onDragStart = () => {
  }
  const onDragUpdate = () => {
  }
  const onDragEnd = () => {
  }

  return (

    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <DragDropContext onBeforeCapture={onBeforeCapture} onBeforeDragStart={onBeforeDragStart} onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            <React.Suspense fallback={<LayoutSplashScreen />}>
              <BrowserRouter basename={basename}>
                <MaterialThemeProvider>
                  <I18nProvider>

                    <Routes />

                  </I18nProvider>
                </MaterialThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </DragDropContext>
      </DndProvider>
    </Provider>



  );
}
