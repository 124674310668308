import React, { useEffect, useMemo } from "react";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { insertMenu } from "../../../../../../app/_redux/modlCrud";



const barLineChartOptions = (data, type) => {

    const resultData = makeBarLineFormat(data);



    return {
        grid: resultData.grid,
        legend: {
            left: resultData.legendLeft,
            top : resultData.legendTop,
            // right: '20%',          
            backgroundColor: type !== 'KICI_Dashboard' ? "#f8f9fd" : "#ffffff", //#fffff
            data: resultData.legend,
            itemWidth: 15,
            itemHeight: 14,
            show: resultData.legend_show
        },
        title: resultData.title,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            formatter: function (data) {                
                let toolTipdata = [];
                data.map((item) => toolTipdata.push({ name: item.seriesName, value: item.data, color: resultData.tooltipForm.find((e) => e.name === item.seriesName).color }));
                // let baseLineData = resultData.tooltipForm.find((item) => item.type === 'baseLine');
                let baseLineData = resultData.tooltipForm.filter((item) => item.type === 'baseLine');
                if(Object.keys(baseLineData).length !== 0){
                    baseLineData.map((v) => {
                        toolTipdata.push({ name: v.name, value: v.value, color: v.color })
                    })
                }                
                return toolTipdata.map((item) => {
                    return `<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${item.color};\"></span> ${item.name} : ${!isNaN(item.value) ? item.value : "-"} <br>`
                }).join(' ');
            }
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: resultData.x_data,
            name: resultData.x_label
        },
        yAxis: resultData.y_label,
        series: resultData.y_data
    };
}

const pieChartOptions = data => {

    const resultData = makePieFormat(data);

    return {
        title: resultData.title,
        tooltip: {
            trigger: 'item',
            formatter: function (v) {
                if (resultData.unit !== undefined) {
                    return `${v.marker}` + ' ' + `${v.data.name} <span style='font-weight : bold'> ${v.data.value}</span> ${resultData.unit}`;
                } else {
                    return `${v.marker}` + ' ' + `${v.data.name} <span style='font-weight : bold'> ${v.data.value}</span>`;
                }
            }
        },
        legend: {
            bottom: '7%',            
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
                fontSize: 12
            }

        },
        color: resultData.color,
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: resultData.size,
                center: ['50%', '45%'],
                label: {
                    position: 'inner',
                    formatter: '{d}%',
                    textStyle: {
                        align: 'center',
                        baseline: 'middle',
                        fontFamily: 'SpoqaHanSansNeo',
                        fontSize: 12,
                        color: resultData.labelColor
                    }
                },
                data: resultData.data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
}

const H_barCartOptions = data => {

    const resultData = make_H_BarLineFormat(data);

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        title: resultData.title,
        legend: {
            bottom: '40%',
            // left: '35%'
            left: 'center'
        },
        grid: {
            containLabel: true,
            top: "0",
            left: "-10",
            right : "-10%"
        },
        xAxis: {
            type: 'value',
            show: false
        },
        yAxis: {
            type: 'category',
            data: [''],
            show: false
        },
        series: resultData.data
    };
}

const makeBarLineFormat = params => {


    // Y 축값들 추출해서 Y축 가장큰값 설정
    let compareYdata = params.y_data.map((data) => {
        if (typeof (data.value) === 'object') {
            const arrMaxData = Math.max(...data.value.filter(v => v !== "null"));
            return arrMaxData;
        } else {
            return Number(data.value)
        }
    });
    const yMax = Math.max(...compareYdata);

    // y축 한개 더있을시 해당 yMax 값 추출
    const compareYdata1 = params.y_data.map((data) => {
        if (data.yAxisIndex !== undefined) {
            let arrV = data.value.map(v => parseFloat(v));
            const arrMaxData = Math.max(...arrV);
            return arrMaxData;
        }
    }).filter((v) => v !== undefined);


    let yMax1 = "";
    if (Object.keys(compareYdata1).length !== 0) {
        yMax1 = Math.max(...compareYdata1);
    }
    
    const resultData = {    
                
        grid: params.layoutChange !== undefined ?
            {
                containLabel: true,
                bottom: 0,
                left: params.layoutChange.left,
                right: params.layoutChange.right,
                top : params.layoutChange.top
            } :
            {
                containLabel: true,
                bottom: '7%'
            },
        legendLeft: params.layoutChange !== undefined ? '20px' : '10%',
        legendTop: params.layoutChange !== undefined ? '8' : 0,
        title: params.title !== undefined ? {
            text: params.title,            
            left: 'left',
            textStyle: {
                fontSize: 10
            },
            top: '5%'
    // text 아래로 적용 하는것 아래
    //         top: 'bottom',
    // textStyle: {
    //     fontSize: 15
    // },
    // left : 'center'
        } : {},
        x_label: params.x_label,
        y_label: params.y_label.map((data, index) => {
            if (index === 0) {
                // y축 한개일시
                return { type: 'value', name: data, max: yMax }
            } else if (index === 1) {
                // y축 두개일시
                return { type: 'value', name: data, max: yMax1 }
            }
        }),
        legend_show: params.legendShow,
        x_data: params.x_data,
        y_data: params.y_data.map((data) => {

            let seriesData = {};

            if (data.type === 'bar') {
                seriesData.type = 'bar';
                seriesData.name = data.name;
                seriesData.data = data.value;
                seriesData.barMaxWidth = '8%';
                seriesData.itemStyle = { color: data.color };
            } else if (data.type === 'line') {
                seriesData.type = 'line';
                seriesData.name = data.name;
                seriesData.data = data.value;
                seriesData.smooth = true; //data.smooth
                seriesData.symbol = 'emptyCircle'; //'circle'
                seriesData.lineStyle = { color: data.color };
                seriesData.symbolSize = 7; //5
                seriesData.itemStyle = {
                    color: data.color
                };
                // 네모 bar형태의 markArea                           
                seriesData.markArea = data.boxArea === true ? {
                    itemStyle: {
                        color: '#02c7b0',
                        borderColor: '#c6c6c6',
                        opacity: 0.2,
                        borderWidth: 1
                    },
                    //emphasis는 area 효과! 이게 없으면  계속 label 이 위로올라감... test시에는 안그런데 modl에서만 오류...
                    emphasis: {
                        label: {
                            position: 'inside'
                        }
                    },


                    data: params.x_data.map((v, i, a) => {
                        if (data.value[i] !== "null" && data.value[i + 1] !== undefined) {
                            return [
                                {
                                    // name: '월간공정률' + parseFloat(Number(data.value[i + 1] - data.value[i]).toFixed(2)) + '%',
                                    label: {
                                        position: 'inside',
                                        color: '#1e1e1e'
                                    },
                                    xAxis: v
                                },
                                {
                                    xAxis: a[i + 1],
                                    yAxis: data.value[i + 1]
                                }
                            ]
                        }
                    }).filter((v) => v !== undefined)
                } : {};

                // y 축 오른쪽 추가
                if (data.yAxisIndex !== undefined) {
                    seriesData.yAxisIndex = 1
                }
            } else if (data.type === 'baseLine') {
                let showYn = true;
                if (data.showYn !== undefined) {
                    showYn = false;
                }
                seriesData.type = 'line';
                seriesData.name = data.name;
                seriesData.label = { show: true };
                seriesData.markLine = {
                    symbol: 'none',
                    label: {
                        position: 'end'
                    },
                    data:
                        [
                            {
                                yAxis: data.value,
                                label: {
                                    normal: { show: showYn },
                                },
                                lineStyle: {
                                    normal: {
                                        type: data.lineType,
                                        color: data.color,
                                        width: 2
                                    }
                                }
                            }
                        ]
                };
                seriesData.markArea = {
                    data: [
                        [
                            {
                                yAxis: 0,
                                itemStyle: {
                                    color: data.markAreaColor,
                                    opacity: '0.07'
                                }
                            },
                            {
                                yAxis: data.value,
                                itemStyle: {
                                    color: data.markAreaColor,
                                    opacity: '0.07'
                                }
                            }
                        ]
                    ]
                }
            };

            return seriesData;
        }),
        legend: params.y_data.map((data) => {

            if (data.type === 'bar') {
                return { name: data.name };
            } else if (data.type === 'line') {
                return {
                    name: data.name,
                    itemStyle: { opacity: 0 },
                    lineStyle: { color: data.color }
                }
            } else if (data.type === 'baseLine') {
                return {
                    name: data.name,
                    itemStyle: { opacity: 0 },
                    lineStyle: { color: data.color, type: 'dotted' }
                }
            }
        }),
        tooltipForm: params.y_data.map((data) => {
            return {
                type: data.type,
                value: data.value,
                name: data.name,
                color: data.color
            }
        })
    }
    
    return resultData;

}

const makePieFormat = params => {

    const resultData = {
        data: params.data.map((item) => { return { value: item.value, name: item.name } }),
        color: params.data.map((item) => item.color),
        size: params.size
    }


    resultData.title = params.title;
    resultData.labelColor = params.labelColor;


    if (params.unit !== undefined) {
        resultData.unit = params.unit;
    }

    return resultData;
}

const make_H_BarLineFormat = params => {
    const resultData = {
        data: params.data.map((item, index) => {
            const cdata = {
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                barMaxWidth: 30
            };

            if (index === 0) {
                return {
                    ...cdata,
                    name: item.name + '%',
                    data: [item.value],
                    color: '#027bc6'
                }
            }
            if (index === 1) {
                return {
                    ...cdata,
                    name: item.name + '% ', // 퍼센트 뒤에 스페이스 해줘야함 안그러면 위의  name 과 동일하게 인식됨
                    data: [item.value],
                    color: '#02c7b0'
                }
            }
        }),
        title: {
            text: params.title,
            left: 'center',
            textStyle: {
                fontSize: 14
            },
        }
    }

    return resultData;
}



const ChartCommon = (props) => {
    
    const { type, chartData = {}, style = {} } = props;
    let option = '';

    if (Object.keys(chartData).length !== 0) {
        if (chartData.chartType === 'pie') {
            option = pieChartOptions(chartData);
        } else if (chartData.chartType === 'barLine') {
            option = barLineChartOptions(chartData, type);
        } else if (chartData.chartType === 'h_bar') {
            option = H_barCartOptions(chartData)
        }
    }




    let echartsInstance = null

    const onChartReadyCallback = (e) => {
        echartsInstance = e
    }

    const onChartLegendselectchanged = (params) => {

        // 1개만 남았을때 체크
        let checkValue = Object.values(params.selected)
        let trueValue = checkValue.filter(item => item === true)

        if (trueValue.length == 0) {
            selectGraph(params)
        }

        // echartsInstance.dispatchAction({
        //     type: 'legendSelect',
        //     name: "기준",
        // })

    }

    const selectGraph = (params) => {
        echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: params.name,
        })
    }

    const unselectGrap = (params) => {
        for (const legend in params.selected) {
            if (legend !== params.name) {
                echartsInstance.dispatchAction({
                    type: 'legendUnSelect',
                    name: legend,
                })
            }
        }
    }

    const onEvents = {
        'legendselectchanged': onChartLegendselectchanged
    }


    let isNull = false

    if (Object.keys(chartData).length !== 0 && chartData.chartType !== 'pie' && chartData.chartType !== 'h_bar') {


        Array.from(chartData.y_data).forEach(d => {
            if (d.type !== "baseLine") {
                isNull = (Array.from(d.value).length < 1) ? true : false
            }
        })

        if(chartData.x_data.length == 0 || chartData.y_data.length == 0) isNull = true

       
    }


    if (isNull) {

        return (
            <>
                {/* <div>선택한 날짜에 해당하는 데이터가 없습니다.</div> */}
                <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", background: "#fafafb", borderRadius: "4px", width: "100%" }}>
                    <img src="https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/images/NoData.png" alt="no data" />
                </div>
            </>
        )
    } else {
        return (
            <>
                {/* {Object.keys(chartData).length === 0 ? '' : <ReactECharts onChartReady={(e) => onChartReadyCallback(e)} option={option} style={style} onEvents={onEvents}></ReactECharts>}                 */}
                {Object.keys(chartData).length === 0 ? '' : <ReactECharts option={option} style={type === "dashboard" ? {height: "100%", width: "100%"} : {style}}></ReactECharts>}
            </>
        )
    }
};



export default ChartCommon;
