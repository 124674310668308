import axios from "axios";


class Utils {


  // DATA LOAD
  async getQueryResult(payload) {

    var instance = axios.create();
    const dataStr = JSON.stringify(payload)

    const req = {
      query: `query getQueryResult($data: String) {
      getQueryResult(data: $data)
    }`,
      variables: {
        data: dataStr
      }
    }

    return new Promise((resolve, reject) => {
      instance.post('graphql/', req)
        .then(resp => {
          resolve(JSON.parse(resp.data.data.getQueryResult))
        })
        .catch(error => {
          reject(error)
        });
    });
  }


}

export default new Utils()
