import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal, Row } from 'react-bootstrap';
import Switch from 'react-switch';


/**
 * 사용자 관리 메뉴 권한 추가 및 수정 컴포넌트
 * 
 * @param props 부모 컴포넌트에서 전달한 props 값
 * @returns 해당 컴포넌트
 */
export function ManageGroup_Add(props) {


    /**
     * 부모 컴포넌트에서 받아온 props
     * 
     * 메뉴에 권한을 부여하는 데이터를 담은 상태 값
     */
    const { listId, parentId, menuList, parentCallback, action, selectIndex, menu, groupList, ...rest } = props
    const [allowMenuList, setAllowMenuList] = useState([]);


    /**
     * 컴포넌트 마운트시 action에 따라 
     * 
     * 메뉴 권한 설정 Effect
     */
    useEffect(() => {

        if (rest.show == true) {
            if (action == "edit") {

                /**
                 * 수정 시 기존 메뉴 권한을 가져옵니다.
                 */

                setAllowMenuList(menuList)

            } else if (action == "add") {

                /**
                 * 멤버 추가 시 메뉴 권한을 기본 값으로 설정합니다.  
                 */

                const menu_child_count = menu.map(_menu =>
                    _menu.items.filter((item, i) => item.childCount !== 0)
                );

                const child_count_id = menu_child_count.map(_menu => _menu.map(data => data.id)).filter(data => data.length !== 0);

                const child_count_flat = child_count_id.flat();

                const result_arr = child_count_flat.map(data => {
                    return { idx: data, role: 'edit' }
                });

                setAllowMenuList(result_arr);
            }
        }
    }, [rest.show]);


    /**
     * Button style
     */
    const btnStyle = {
        margin: "2px",
    }


    /**
     * switch 체크 시 체크 리스트 id를 받아
     * 
     * 체크 표시 및 체크 해제
     *  
     * @param id 메뉴 리스트 id
     * @returns 체크 boolean (true / false)
     */
    const getCheckState = (id) => {

        let isCheck = false

        for (let mList of allowMenuList) {
            if (mList.idx == id) {
                isCheck = true
            }
        }

        return isCheck
    }



    /**
     * switch checked true시 AllowMenuList에 추가
     * @param id 해당 리스트 id
     * @param isChecked 해당 리스트 chekced 상태 여부
     */
    const setCheckState = async (id, isChecked) => {

        if (isChecked == true) {

            let joined = allowMenuList.concat({
                "idx": id,
                "role": "edit"
            })

            setAllowMenuList(joined)

        } else {
            for (let mList of allowMenuList) {
                await setAllowMenuList(allowMenuList.filter(item => item.idx !== id))
            }
        }
    }


    // menu >> private, workspace
    let SelectorUI = menu.map((_menu, index) =>

        // item list
        _menu.items.map((item, i) => {
            return (
                <>
                    {
                        /**
                         * 리스트별 배치를 다르게 구성합니다.
                         */
                        item.childCount !== 0 ?
                            <Row style={{ width: "90%" }} key={Math.random()}>
                                <Form.Label className="group_category" column sm="12">
                                    {item.title}
                                </Form.Label>
                            </Row> :
                            <Row className="group_row" key={Math.random()}>
                                <Form.Label column sm="8" style={{ paddingLeft: "27px" }}>
                                    {item.title}
                                </Form.Label>
                                <Col sm="4" className="group_switch">
                                    <Switch
                                        checked={getCheckState(item.id)}
                                        onChange={checked => { setCheckState(item.id, checked) }}

                                        height={20}
                                        width={40}
                                        uncheckedIcon={
                                            <></>
                                        }
                                        checkedIcon={
                                            <></>
                                        }
                                        disabled={item.childCount !== 0 ? true : false}
                                    />
                                </Col>
                            </Row>
                    }
                </>
            )
        }
        )
    )



    if (selectIndex == -1) return <></>

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(action == "add") ? <>사용자 권한 그룹 추가</> : <>사용자 권한그룹 수정</>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} id="formPlaintextEmail" className="justify-content-between">

                        {SelectorUI}

                    </Form.Group>
                    {(action == "add") ?
                        <Button style={btnStyle} size='sm' onClick={() => {
                            let payload = {
                                "userIdx": listId,
                                "action": "add",
                                "workspaceIdx": menu[0].workspaceIdx,
                                "title": 'kici 일반 사용자',
                                "allowMenu": allowMenuList
                            }
                            parentCallback(payload)
                            rest.onHide()
                        }}>설정하기</Button>
                        :
                        <Button style={btnStyle} size='sm' onClick={() => {
                            let payload = {
                                "action": "edit",
                                "userIdx": listId,
                                "workspaceIdx": menu[0].workspaceIdx,
                                "allowMenu": allowMenuList
                            }

                            parentCallback(payload)
                            rest.onHide()
                        }}>수정하기</Button>
                    }

                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}