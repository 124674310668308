/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Form, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { shallowEqual, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

import ImageGallery from 'react-image-gallery';
import { useScreenClass } from "react-grid-system";


// export function UnitImage({ id, images, isOne }) {
export function UnitImage(props) {
    const { id, images, limit = 1, noOverlay, enableUpload = false, parentCallback, parentImageName } = props

    const imageRef = useRef()

    const screenClass = useScreenClass()

    // 권한 .. 
    // const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual);
    // const workspaces = useSelector((state) => state.modl.workspaces, shallowEqual);

    // let currentRole = 'guest'
    // workspaces.map(ws => {
    //     if ('workspace' + ws.workspaceIdx === currentWorkspace) {
    //         currentRole = ws.role
    //     }
    // })

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    })

    const bucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_BUCKET },
        region: process.env.REACT_APP_REGION
    })

    const isOne = images.length > 1 ? false : true

    const onSubmit = async (files) => {

        let checkFile = Array.from(files).every(f => {
            return (f.type.match("image/*"))
        })

        if (!checkFile) {
            alert("이미지 파일 형식을 확인해주세요")
        } else if (Array.from(files).length > limit) {
            alert(`${limit}개 까지 업로드 가능합니다`)
        } else {

            try {
                let dataArr = []

                const putObjectPromise = []

                Array.from(files).forEach(f => {


                    const originFileName = f.name.split('.').reverse()[1];
                    const fileName = originFileName + "|" + uuidv4().split("-")[4] + '.' + f.name.split('.').reverse()[0];

                    const params = {
                        ACL: 'public-read',
                        Body: f,
                        Bucket: process.env.REACT_APP_BUCKET,
                        Key: "images/" + fileName
                    }

                    dataArr.push("https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/images/" + fileName);

                    putObjectPromise.push(bucket.putObject(params).promise())

                    Promise.all(putObjectPromise).then(async () => {
                        await parentCallback(dataArr, id)
                    })
                })

            } catch (ex) {
                console.log(ex);
            }

        }
        document.getElementById("image" + id).value = ""
    }


    /**
     * 전달받은 images가 없을 경우 보여지는 이미지
     * (originalHeight가 세팅되지 않아 컴포넌트의 높이와 맞지 않을 수 있음)
     */
    const sampleImage = [
        {
            // 주석이미지: 흰색이미지
            // original: 'https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/images/4f702f0ff142.png',
            original: 'https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/images/no_image.png',
            originalHeight: 150
        }
    ];

    /**
     * 이미지 클릭 시 이미지 첨부 화면 띄우기
     * currentRole이 admin인 경우에만 업로드 가능
     */

    const onImageClick = () => {
        document.getElementById('image' + id).click()
    }


    const onImageOver = () => {
        if (imageRef.current.state.isFullscreen) {
            document.getElementById('uploadButton' + id).style.display = "none"
        } else {
            document.getElementById('uploadButton' + id).style.display = "block"
        }
    }

    const onImageLeave = () => {
        document.getElementById('uploadButton' + id).style.display = "none"
    }


    const ImageSlider = (index) => {
        parentImageName(index);
    }

    const overLayDiv = () => {

        if (!noOverlay && (!['xs'].includes(screenClass) || enableUpload)) {


            return <div style={{ position: "absolute", height: "100%", width: "100%", zIndex: "1", backgroundColor: "transparent" }}

                onMouseOver={() => {
                    onImageOver()
                }}
                onMouseLeave={() => {
                    onImageLeave()
                }}
            >
                <Button id={'uploadButton' + id} style={{ display: "none", margin: "5px" }} onClick={onImageClick}>이미지 업로드</Button>
            </div>
        }
    };


    return (
        <>

            <div style={{ backgroundColor: "#fafafb" }}>
                <ImageGallery
                    ref={imageRef}
                    items={images === '' ? sampleImage : images}
                    showBullets={!isOne}
                    showPlayButton={false}
                    showThumbnails={false}

                    showFullscreenButton={images === '' ? false : true}

                    // onClick={() => {
                    //     imageRef.current.state.isFullscreen ? imageRef.current.exitFullScreen() : onImageClick()
                    // }}

                    renderCustomControls={overLayDiv}

                    onSlide={(i) => {
                        if (parentImageName) {
                            ImageSlider(i)
                        }
                    }}
                />
                <Form>
                    <Form.Control id={'image' + id} type="file" multiple style={{ display: "none" }} accept="image/*" onChange={e => onSubmit(e.target.files)} />
                </Form>
            </div>
        </>
    );
}


