export default function setupAxios(axios, store) {
  // =============================================================================
  // 로컬 주소
  // =============================================================================
  // axios.defaults.clientURL = "http://localhost"; // 로컬
  // axios.defaults.serverURL = "http://localhost:4000"; // 로컬

  // axios.defaults.clientURL = 'http://192.168.29.153';  // 로컬
  // axios.defaults.serverURL = 'http://192.168.29.153:4000';  // 로컬

  // =============================================================================
  // 효림 실서버 주소
  // =============================================================================
  // axios.defaults.clientURL = 'http://43.200.24.230';
  // axios.defaults.serverURL = 'http://43.200.24.230:4000';

  // =============================================================================
  // 효림 테스트 주소
  // =============================================================================
  // axios.defaults.clientURL = "http://52.79.174.21";
  // axios.defaults.serverURL = "http://52.79.174.21:4000";

  // =============================================================================
  // WISE PAGEONE 주소 (KICI)
  // =============================================================================
  // axios.defaults.clientURL = 'http://13.209.56.84';
  // axios.defaults.serverURL = 'http://13.209.56.84:4000';


  

  // =============================================================================
  // WISE PAGEONE 주소 (KICI - http://infocatcher.co.kr/)
  // =============================================================================
  // axios.defaults.clientURL = 'http://infocatcher.co.kr';
  // axios.defaults.serverURL = 'http://infocatcher.co.kr:4000';

  axios.defaults.clientURL = 'https://infocatcher.co.kr';
  axios.defaults.serverURL = 'https://infocatcher.co.kr:4001';

  // axios.defaults.clientURL = 'http://101.79.10.1';
  // axios.defaults.serverURL = 'http://101.79.10.1:4000';

  // =============================================================================
  // PAGEONE 주소
  // =============================================================================
  // axios.defaults.clientURL = 'http://175.45.193.51';
  // axios.defaults.serverURL = 'http://175.45.193.51:4000';

  axios.defaults.baseURL = axios.defaults.serverURL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.timeout = 5 * 60 * 1000;
  // axios.defaults.withCredentials = true;

  const {
    auth: { authToken },
  } = store.getState();
  if (authToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
  }

  // var instance = axios.create();
  // 이것때문에 인스턴스에서 authToken 인터셉터는 안되는듯....
  // https://genie-youn.github.io/journal/axios%EC%99%80_global_interceptor.html

  // axios.interceptors.request.use(
  //   config => {
  //     const {
  //       auth: { authToken }
  //     } = store.getState();

  //     if (authToken) {
  //       config.headers.Authorization = `Bearer ${authToken}`;
  //     }

  //     return config;
  //   },
  //   err => Promise.reject(err)
  // );
}
