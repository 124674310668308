import React from "react";
import { useDrag } from "react-dnd";

import { DataLayout } from "./LayoutStructure"

const SideBarData = ({ data }) => {

  // console.log(">> SideBarData")
  // console.log(data)

  const returnUI = () => {

    if(data == undefined) return (<></>)

    if (data != undefined) {

      if (data.data.type == "row") {

        let layout = new DataLayout();
        let ui = layout[data.data.type](data)
        return (ui)

      } else if (data.data.type == "column") {

        let layout = new DataLayout();
        let ui = layout[data.data.type](data)
        return (ui)

      } else {

        let layout = new DataLayout();
        let ui = layout[data.data.elementType](data)
        return (ui)
      }


    } else {
      let ui = <div>ELEMENT 를 선택해주세요</div>
      return ui
    }


  }


  return (
    <div >
      {/* SideBarData{JSON.stringify(data)} */}
      {returnUI()}
    </div>
  );
};
export default SideBarData;
