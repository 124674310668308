import * as requestFromServer from "./modlCrud";
import { modlSlice, callTypes } from "./modlSlice";
import { fork, put, call, take, takeLatest, takeLeading, takeEvery } from "redux-saga/effects";
import { actionTypes } from "../../redux/actionType";
import store from "../../redux/store";
import { Redirect,push } from "react-router-dom";

const { actions } = modlSlice;


// export const getModl = queryParams => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.list }));
//   return requestFromServer
//     .findModl(queryParams)
//     .then(response => {
//       const { totalCount, entities } = response.data;
//       dispatch(actions.modlGeted({ totalCount, entities }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't find modl";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const getModl2 = id => dispatch => {
//   if (!id) {
//     return dispatch(actions.modlGeted({ modlForEdit: undefined }));
//   }

//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .getModlById(id)
//     .then(response => {
//       const modl = response.data;
//       dispatch(actions.modlGeted({ modlForEdit: modl }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't find modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };


// export const testButton1 = id => dispatch => {
//   console.log("++++ modlActions testButton1 ++++")
//   // dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .testButton1(id)
//     .then(response => {

//       console.log('response ::')
//       console.log(response)
//       // update state and call update to listener
//       dispatch(actions.testButton1({ response }));

//     })
//     .catch(error => {

//       console.log(error)
//       error.clientMessage = "Can't delete modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };


// export const testButton2 = id => dispatch => {
//   console.log("++++ modlActions testButton2 ++++")
//   // dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .testButton2(id)
//     .then(response => {

//       console.log('response2 ::')
//       console.log(response)
//       // update state and call update to listener
//       dispatch(actions.testButton2({ response }));

//     })
//     .catch(error => {

//       console.log(error)
//       error.clientMessage = "Can't delete modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };



// export const createModl = modlForCreation => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createModl(modlForCreation)
//     .then(response => {
//       const { modl } = response.data;
//       dispatch(actions.modlCreated({ modl }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't create modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const updateModl = modl => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateModl(modl)
//     .then(() => {
//       dispatch(actions.modlUpdated({ modl }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const updateModlStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForModl(ids, status)
//     .then(() => {
//       dispatch(actions.modlStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update modl status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };






// 아래 getUser 를 saga 형식으로 변환 함
// export const getUser = id => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .getMenu(id)
//     .then(response => {
//       dispatch(actions.getMenu({ response }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };




// //이걸쓰는 건가 .. 
// export const getPage = id => dispatch => {
//   return requestFromServer
//     .getPage(id)
//     .then(response => {
//       console.log(id)
//       console.log(response)
//       // update state and call update to listener
//       dispatch(actions.getPage({ response }));

//     })
//     .catch(error => {

//       console.log(error)
//       error.clientMessage = "Can't delete modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };



// export const getMenu = id => dispatch => {
//   console.log("++++ modlActions getMenu ++++")
//   // dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .getMenu(id)
//     .then(response => {
//       console.log('> modlAction > getMenu ::')
//       dispatch(actions.getMenu({ response }));
//     })
//     .catch(error => {
//       console.log(error)
//       error.clientMessage = "Can't delete modl";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };









//workspace 리스트 불러오기 + 기본 메뉴 로드 ..
function* initWorkspace(action) {
  try {

    // console.log('> modlAction > getLnkWorkspace')
    // console.log(action.payload.userIdx)

    //첫번째 workspace 로드.
    const workspaces = yield call(requestFromServer.getLnkWorkspace, action.payload);
    yield put(actions.getLnkWorkspace({ response: workspaces }));
    // console.log(workspaces)
    const workspacesObj = JSON.parse(workspaces)

    //첫번째 workspace 로드.
    const workspace = yield call(requestFromServer.getWorkspace, workspacesObj[0]);
    yield put(actions.getWorkspace({ response: workspace }));
    // console.log(workspace)

    const menu = yield call(requestFromServer.getMenu, workspacesObj[0]);
    yield put(actions.getMenu({ response: menu }));
    // console.log(menu)

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}



// 이거 안씀
// function* getUser(action) {
//   try {
//     console.log(action.payload.userIdx)
//     console.log('>> getUser')
//     console.log(store.getState())

//     yield put(actions.startCall({ callType: callTypes.action }));


//     const result = yield call(requestFromServer.getUser, action.payload);
//     yield put(actions.getWorkspace({ response: result }));

//   } catch (e) {

//     yield put({ type: "USER_FETCH_FAILED", message: e.message });
//   }
// }


// 이거 안씀
// function* getComponent(action) {
//   try {
//     console.log('>> GetComponent')
//     const result = yield call(requestFromServer.getComponent, action.payload);
//     // console.log(result)
//     yield put(actions.getComponent({ response: result }))
//     onsole.log(store.getState())

//   } catch (e) {

//     yield put({ type: "getComponent", message: e.message });
//   }
// }


// 이거 안씀
// function* getMenu(action) {
//   try {
//     console.log('>> getMenu')
//     // const result = yield call(requestFromServer.getMenu, action.payload);
//     // yield put(actions.getMenu({ response: result }))
//     // console.log(store.getState())
//   } catch (e) {

//     yield put({ type: "getMenu", message: e.message });
//   }
// }



function* loginVisitorWorkspace(action) {
  try {
    console.log('>> LoginVisitorWorkspace')

    const workspace = yield call(requestFromServer.getWorkspace, action.payload);
    yield put(actions.getWorkspace({ response: workspace }));

    const site = yield call(requestFromServer.getSite, { siteIdx: JSON.parse(workspace).siteIdx });
    yield put(actions.getSite({ response: site }));

    const menu = yield call(requestFromServer.getMenu, action.payload);
    yield put(actions.getMenu({ response: menu }));

  } catch (e) {

    yield put({ type: "LoginVisitorWorkspace", message: e.message });
  }
}


function* isExistUser(action) {
  try {
    // console.log('>> isExistUser')
    const result = yield call(requestFromServer.isExistUser, action.payload);
    // console.log(result)

    yield put(actions.isExistUser({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "isExistUser", message: e.message });
  }
}

function* getTagGroupAll(action) {
  try {
    // console.log('>> getTagGroupAll.')
    const result = yield call(requestFromServer.getTagGroupAll, action.payload);
    yield put(actions.getTagGroupAll({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getTagGroupAll", message: e.message });
  }
}

function* getWorkspacesAll(action) {
  try {
    // console.log('>> getWorkspacesAll')
    const result = yield call(requestFromServer.getWorkspacesAll, action.payload);
    yield put(actions.getWorkspacesAll({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getWorkspacesAll", message: e.message });
  }
}

function* getGroupAll(action) {
  try {
    // console.log('>> getGroupAll')
    const result = yield call(requestFromServer.getGroupAll, action.payload);
    yield put(actions.getGroupAll({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getGroupAll", message: e.message });
  }
}

function* getPage(action) {
  try {
    // console.log('>> getPage')
    const result = yield call(requestFromServer.getPage, action.payload);
    yield put(actions.getPage({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getPage", message: e.message });
  }
}


function* getSite(action) {
  try {

    // console.log('>> getSite')
    // console.log(store.getState())

    //첫번째 site 로드.
    const result = yield call(requestFromServer.getSite, action.payload);
    yield put(actions.getSite({ response: result }));
    // console.log(result)


  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}



function* getLnkWorkspace(action) {
  try {

    // console.log('> modlAction > getLnkWorkspace')
    // console.log(action.payload.userIdx)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getLnkWorkspace, action.payload);
    yield put(actions.getLnkWorkspace({ response: result }));
    // console.log(workspace)
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* getWorkspace(action) {
  try {

    // console.log('>> getWorkspace')
    // console.log(store.getState())

    //첫번째 workspace 로드.
    const workspace = yield call(requestFromServer.getWorkspace, action.payload);
    yield put(actions.getWorkspace({ response: workspace }));
    // console.log(workspace)

    const menu = yield call(requestFromServer.getMenu, action.payload);
    yield put(actions.getMenu({ response: menu }));
    // console.log(menu)

    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* getTplComponent(action) {
  try {
    // console.log('>> GetTplComponent')
    const result = yield call(requestFromServer.getTplComponent, action.payload);
    // console.log(result)
    yield put(actions.getTplComponent({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getTplComponent", message: e.message });
  }
}


// 컴포넌트 데이터 수정 하기 ... 
function* updateComponentData(action) {
  try {

    // console.log('modlAction >> updateComponentData >>')
    // console.log(store.getState())

    const result = yield call(requestFromServer.updateComponentData, action.payload);
    // console.log('modlAction >> updateComponentData >> result')
    // console.log(result)

    yield put(actions.updateComponentData({ response: result }))
    // console.log(store.getState())
  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}


function* updateComponent(action) {
  try {

    // console.log('modlAction >> updateComponents >>')
    // console.log(store.getState())

    const result = yield call(requestFromServer.updateComponent, action.payload);
    // console.log(result)

    yield put(actions.updateComponent({ response: result }))

    // console.log(store.getState())
  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* updateComponents(action) {
  try {

    // console.log('modlAction >> updateComponents >>')
    // console.log(store.getState())

    const result = yield call(requestFromServer.updateComponents, action.payload);
    yield put(actions.updateComponents({ response: result }))

    // console.log(store.getState())


  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* updateTplComponent(action) {
  try {

    console.log('modlAction >> updateTplComponent >>')
    console.log(action.payload)

    const result1 = yield call(requestFromServer.updateTplComponent, action.payload);
    // console.log("updateTplComponent --------------")

    const result2 = yield call(requestFromServer.getTplComponent, action.payload);
    yield put(actions.getTplComponent({ response: result2 }))


  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* updateMenu(action) {
  try {

    // console.log('modlAction >> updateMenu >>')
    // console.log(action.payload)

    const result = yield call(requestFromServer.updateMenu, action.payload);
    // console.log("updateMenu --------------")
    // console.log(result)

    yield put(actions.updateMenu({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* updatePage(action) {
  try {

    // console.log('modlAction >> updatePage >>')
    // console.log(action.payload)

    const result = yield call(requestFromServer.updatePage, action.payload);
    // console.log("updatePage --------------")
    // console.log(result)

    yield put(actions.updatePage({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* reloadPage(action) {
  try {

    console.log('modlAction >> reloadPage >>')
    yield put(actions.reloadPage({ response: action.payload }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* insertMenu(action) {
  try {

    // console.log('modlAction >> insertMenu >>')
    // console.log(action.payload.menuIdx)
    // console.log(action.payload.menuName)
    // console.log(action.payload.menuRole)

    const result = yield call(requestFromServer.insertMenu, action.payload);
    // console.log("menu--------------")
    // console.log(result)
    yield put(actions.insertMenu({ response: result }))

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* inviteUser(action) {
  try {

    // console.log('modlAction >> inviteUser >>')
    // console.log(action.payload) //{ workspaceIdx: selector.modl.currentWorkspace, email: addName, role: addRole }


    const result = yield call(requestFromServer.inviteUser, action.payload);
    // console.log("inviteUser --------------")
    // console.log(result)

    yield put(actions.inviteUser({ response: result }))

    alert('complete')

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* logout(action) {
  try {

    yield put(actions.logout())

    // alert('complete')

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}

function* deleteCurrentWorkspaceInfo(action) {
  try {

    yield put(actions.deleteCurrentWorkspaceInfo())

    // alert('complete')

  } catch (e) {

    yield put({ type: "Failure", message: e.message });
  }
}


// =======================================================


function* getDocumentCount(action) {

  try {
    // console.log(action.payload.workspaceIdx)
    // console.log(action.payload.document)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getDocumentCount, action.payload);
    yield put(actions.getDocumentCount({ response: result }));


    console.log('> modlAction > getDocumentCount')
    console.log(result)

    return result

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


// export const getDocumentCount2 = id => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .getDocumentCount(id)
//     .then(response => {
//       return response
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete modl";

//     });
// };



// 효림 관리자 관리(admin)
//===========================================================================================
// START
//===========================================================================================
function* getWorkspaceAdminMember(action) {
  try {

    // console.log('> modlAction > getWorkspaceAdminMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getWorkspaceAdminMember, action.payload);
    yield put(actions.getWorkspaceAdminMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* insertWorkspaceAdminMember(action) {
  try {

    // console.log('> modlAction > insertWorkspaceAdminMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertWorkspaceAdminMember, action.payload);
    yield put(actions.insertWorkspaceAdminMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateWorkspaceAdminMember(action) {
  try {

    // console.log('> modlAction > updateWorkspaceAdminMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateWorkspaceAdminMember, action.payload);
    yield put(actions.updateWorkspaceAdminMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* deleteWorkspaceAdminMember(action) {
  try {

    // console.log('> modlAction > deleteWorkspaceAdminMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteWorkspaceAdminMember, action.payload);
    yield put(actions.deleteWorkspaceAdminMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


// 사용자 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
function* getWorkspaceMember(action) {
  try {

    // console.log('> modlAction > getWorkspaceMember')
    // console.log(action.payload.workspaceIdx)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getWorkspaceMember, action.payload);
    yield put(actions.getWorkspaceMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* insertWorkspaceMember(action) {
  try {

    // console.log('> modlAction > insertWorkspaceMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertWorkspaceMember, action.payload);
    // console.log('> modlAction > insertWorkspaceMember > result')
    // console.log(result)

    yield put(actions.insertWorkspaceMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateWorkspaceMember(action) {
  try {

    // console.log('> modlAction > updateWorkspaceMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateWorkspaceMember, action.payload);
    yield put(actions.updateWorkspaceMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* deleteWorkspaceMember(action) {
  try {

    // console.log('> modlAction > deleteWorkspaceMember')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteWorkspaceMember, action.payload);
    yield put(actions.deleteWorkspaceMember({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


// 사용자 권한 그룹 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
function* getWorkspaceGroup(action) {
  try {

    // console.log('> modlAction > getWorkspaceGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getWorkspaceGroup, action.payload);
    yield put(actions.getWorkspaceGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* insertWorkspaceGroup(action) {
  try {

    // console.log('> modlAction > insertWorkspaceGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertWorkspaceGroup, action.payload);
    yield put(actions.insertWorkspaceGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateWorkspaceGroup(action) {
  try {

    // console.log('> modlAction > updateWorkspaceGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateWorkspaceGroup, action.payload);
    yield put(actions.updateWorkspaceGroup({ response: result }));


  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* deleteWorkspaceGroup(action) {
  try {

    // console.log('> modlAction > deleteWorkspaceGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteWorkspaceGroup, action.payload);
    yield put(actions.deleteWorkspaceGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


// 태그 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
function* getWorkspaceTag(action) {
  try {

    // console.log('> modlAction > getWorkspaceTag')
    // console.log(action.payload.workspaceIdx)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getWorkspaceTag, action.payload);
    yield put(actions.getWorkspaceTag({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* insertWorkspaceTag(action) {
  try {

    // console.log('> modlAction > insertWorkspaceTag')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertWorkspaceTag, action.payload);
    yield put(actions.insertWorkspaceTag({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateWorkspaceTag(action) {
  try {

    // console.log('> modlAction > updateWorkspaceTag')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateWorkspaceTag, action.payload);
    yield put(actions.updateWorkspaceTag({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* deleteWorkspaceTag(action) {
  try {

    // console.log('> modlAction > deleteWorkspaceTag')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteWorkspaceTag, action.payload);
    yield put(actions.deleteWorkspaceTag({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


// 태그 그룹 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
function* getWorkspaceTagGroup(action) {
  try {

    // console.log('> modlAction > getWorkspaceTagGroup')
    // console.log(action.payload.workspaceIdx)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.getWorkspaceTagGroup, action.payload);
    yield put(actions.getWorkspaceTagGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* insertWorkspaceTagGroup(action) {
  try {

    // console.log('> modlAction > insertWorkspaceTagGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertWorkspaceTagGroup, action.payload);
    yield put(actions.insertWorkspaceTagGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateWorkspaceTagGroup(action) {
  try {

    // console.log('> modlAction > updateWorkspaceTagGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateWorkspaceTagGroup, action.payload);
    yield put(actions.updateWorkspaceTagGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* deleteWorkspaceTagGroup(action) {
  try {

    // console.log('> modlAction > deleteWorkspaceTagGroup')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteWorkspaceTagGroup, action.payload);
    yield put(actions.deleteWorkspaceTagGroup({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


// 전체 워크스페이스 관리(admin)
//===========================================================================================
// START
//===========================================================================================
function* getWorkspaceList(action) {
  try {
    // console.log('>> getWorkspaceList')

    const result = yield call(requestFromServer.getWorkspaceList, action.payload);
    yield put(actions.getWorkspaceList({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getWorkspaceList", message: e.message });
  }
}

function* insertWorkspace(action) {
  try {

    // console.log('> modlAction > insertWorkspace')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertWorkspace, action.payload);
    yield put(actions.insertWorkspace({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateWorkspace(action) {
  try {

    // console.log('> modlAction > updateWorkspace')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateWorkspace, action.payload);
    yield put(actions.updateWorkspace({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* deleteWorkspace(action) {
  try {

    // console.log('> modlAction > deleteWorkspace')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteWorkspace, action.payload);
    yield put(actions.deleteWorkspace({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

//===========================================================================================









// DataTest
//===========================================================================================
// START
//===========================================================================================
function* getDataTest(action) {
  try {
    // console.log('>> getDataTest')

    const result = yield call(requestFromServer.getDataTest, action.payload);
    yield put(actions.getDataTest({ response: result }))
    // console.log(store.getState())

  } catch (e) {

    yield put({ type: "getDataTest", message: e.message });
  }
}

function* insertDataTest(action) {
  try {

    // console.log('> modlAction > insertDataTest')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertDataTest, action.payload);
    yield put(actions.insertDataTest({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateDataTest(action) {
  try {

    // console.log('> modlAction > updateDataTest')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.updateDataTest, action.payload);
    yield put(actions.updateDataTest({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}



function* deleteDataTest(action) {
  try {

    // console.log('> modlAction > deleteDataTest')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.deleteDataTest, action.payload);
    yield put(actions.deleteDataTest({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* insertExcelData(action) {
  try {

    // console.log('> modlAction > insertExcelData')
    // console.log(action.payload.data)

    //첫번째 workspace 로드.
    const result = yield call(requestFromServer.insertExcelData, action.payload.data);
    yield put(actions.insertExcelData({ response: result }));

  } catch (e) {

    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* updateSelectElement(action) {
  try {

    // console.log('> modlAction > updateSelectElement')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    // const result = yield call(requestFromServer.updateSelectElement, action.payload);

    yield put(actions.updateSelectElement({ response: action.payload }));

  } catch (e) {

    yield put({ type: "Error", message: e.message });
  }
}



function* updateWebBuilder(action) {
  try {

    // console.log('> modlAction > updateSelectElement')
    // console.log(action.payload)

    //첫번째 workspace 로드.
    // const result = yield call(requestFromServer.updateSelectElement, action.payload);

    yield put(actions.updateWebBuilder({ response: action.payload }));

  } catch (e) {

    yield put({ type: "Error", message: e.message });
  }
}




export function* saga() {

  // yield takeEvery(actionTypes.UserLoaded, getUser);
  // yield takeEvery(actionTypes.GetUser, getUser);
  // yield takeEvery(actionTypes.GetComponent, getComponent);
  // yield takeEvery(actionTypes.GetMenu, getMenu);


  yield takeEvery(actionTypes.LoginVisitorWorkspace, loginVisitorWorkspace);

  yield takeEvery(actionTypes.IsExistUser, isExistUser);

  yield takeEvery(actionTypes.GetTagGroupAll, getTagGroupAll);
  yield takeEvery(actionTypes.GetGroupAll, getGroupAll);
  yield takeEvery(actionTypes.GetPage, getPage);

  yield takeEvery(actionTypes.GetWorkspacesAll, getWorkspacesAll);

  yield takeEvery(actionTypes.InitWorkspace, initWorkspace);
  yield takeEvery(actionTypes.GetLnkWorkspace, getLnkWorkspace);
  yield takeEvery(actionTypes.GetSite, getSite);
  yield takeEvery(actionTypes.GetWorkspace, getWorkspace);
  yield takeEvery(actionTypes.GetTplComponent, getTplComponent);

  yield takeEvery(actionTypes.UpdateComponentData, updateComponentData);
  yield takeEvery(actionTypes.UpdateComponent, updateComponent);
  yield takeEvery(actionTypes.UpdateComponents, updateComponents);
  yield takeEvery(actionTypes.UpdateTplComponent, updateTplComponent);
  yield takeEvery(actionTypes.UpdateMenu, updateMenu);
  yield takeEvery(actionTypes.UpdatePage, updatePage);

  yield takeEvery(actionTypes.InsertMenu, insertMenu);
  yield takeEvery(actionTypes.InviteUser, inviteUser);

  yield takeEvery(actionTypes.ReloadPage, reloadPage);

  yield takeEvery(actionTypes.Logout, logout);

  yield takeEvery(actionTypes.DeleteCurrentWorkspaceInfo, deleteCurrentWorkspaceInfo);

  yield takeEvery(actionTypes.GetDocumentCount, getDocumentCount);

  yield takeEvery(actionTypes.GetWorkspaceAdminMember, getWorkspaceAdminMember);
  yield takeEvery(actionTypes.InsertWorkspaceAdminMember, insertWorkspaceAdminMember);
  yield takeEvery(actionTypes.UpdateWorkspaceAdminMember, updateWorkspaceAdminMember);
  yield takeEvery(actionTypes.DeleteWorkspaceAdminMember, deleteWorkspaceAdminMember);

  yield takeEvery(actionTypes.GetWorkspaceMember, getWorkspaceMember);
  yield takeEvery(actionTypes.InsertWorkspaceMember, insertWorkspaceMember);
  yield takeEvery(actionTypes.UpdateWorkspaceMember, updateWorkspaceMember);
  yield takeEvery(actionTypes.DeleteWorkspaceMember, deleteWorkspaceMember);

  yield takeEvery(actionTypes.GetWorkspaceGroup, getWorkspaceGroup);
  yield takeEvery(actionTypes.InsertWorkspaceGroup, insertWorkspaceGroup);
  yield takeEvery(actionTypes.UpdateWorkspaceGroup, updateWorkspaceGroup);
  yield takeEvery(actionTypes.DeleteWorkspaceGroup, deleteWorkspaceGroup);

  yield takeEvery(actionTypes.GetWorkspaceTag, getWorkspaceTag);
  yield takeEvery(actionTypes.InsertWorkspaceTag, insertWorkspaceTag);
  yield takeEvery(actionTypes.UpdateWorkspaceTag, updateWorkspaceTag);
  yield takeEvery(actionTypes.DeleteWorkspaceTag, deleteWorkspaceTag);

  yield takeEvery(actionTypes.GetWorkspaceTagGroup, getWorkspaceTagGroup);
  yield takeEvery(actionTypes.InsertWorkspaceTagGroup, insertWorkspaceTagGroup);
  yield takeEvery(actionTypes.UpdateWorkspaceTagGroup, updateWorkspaceTagGroup);
  yield takeEvery(actionTypes.DeleteWorkspaceTagGroup, deleteWorkspaceTagGroup);

  yield takeEvery(actionTypes.GetWorkspaceList, getWorkspaceList);
  yield takeEvery(actionTypes.DeleteWorkspace, deleteWorkspace);
  yield takeEvery(actionTypes.InsertWorkspace, insertWorkspace);
  yield takeEvery(actionTypes.UpdateWorkspace, updateWorkspace);

  yield takeEvery(actionTypes.GetDataTest, getDataTest);
  yield takeEvery(actionTypes.DeleteDataTest, deleteDataTest);
  yield takeEvery(actionTypes.InsertDataTest, insertDataTest);
  yield takeEvery(actionTypes.UpdateDataTest, updateDataTest);

  yield takeEvery(actionTypes.InsertExcelData, insertExcelData);


  //WEB BUILDER
  yield takeEvery(actionTypes.UpdateSelectElement, updateSelectElement);
  yield takeEvery(actionTypes.UpdateWebBuilder, updateWebBuilder);

  //WEB BUILDER
  yield takeEvery(actionTypes.UpdateSelectElement, updateSelectElement);

}
