import { createSlice } from "@reduxjs/toolkit";

const moment = require("moment");

const now = new Date();
const now2 = new Date();

const initialModlState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  modlForEdit: undefined,
  lastError: null,
};

// =====================================================
// KICI 날짜 선택 state
// =====================================================
const initialDateState = {
  prevDate: new Date(now.setDate(now.getDate() - 9)),
  nextDate: new Date(now2.setDate(now2.getDate())),
  prevMoment: moment(new Date())
    .subtract(9, "day")
    .format("YYYY.MM.DD"),
  nextMoment: moment(new Date())
    .subtract(-1, "day")
    .format("YYYY.MM.DD"),
  assemblyDate: new Date(now2.setDate(now2.getDate())),
  assemblyDateMoment: moment(new Date())
    .subtract(-1, "day")
    .format("YYYY.MM.DD")
};

// =====================================================
// KICI 차트 state
// =====================================================
const chartState = {
  chartData: null,
  chartList: null,
  recentCrawling: null,
  keywordName: null,
  keywordCount: null,
  filterData: null,
};

// =====================================================
// KICI 커뮤니티 및 뉴스 차트 state
// =====================================================
const crawlingState = {
  chartData: null,
};

// =====================================================
// 오늘 데이터 state
// =====================================================
const today_data = {
  today_collect: 0,
  today_crawling: 0,
};

// =====================================================
// 키워드 필터 state
// =====================================================
const keywordState = {
  keyword_data: ["전체"],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const modlSlice = createSlice({
  name: "modl",
  initialState: initialModlState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      // console.log("startCall TEST")
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getModlById
    modlFetched: (state, action) => {
      state.actionsLoading = false;
      state.modlForEdit = action.payload.modlForEdit;
      state.error = null;
    },

    // findModl
    modlFetched2: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createModl
    modlCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.modl);
    },

    // updateModl
    modlUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.modl.id) {
          return action.payload.modl;
        }
        return entity;
      });
    },

    // testButton1 // SUCCESS
    testButton1: (state, action) => {
      const entities = action.payload.response;
      state.error = null;
      state.actionsLoading = false;
      // state.entities = entities
    },

    testButton2: (state, action) => {
      const entities = JSON.parse(action.payload.response);
      state.error = null;
      state.actionsLoading = false;
      // state.entities = entities;
      // state.test = entities;

      // console.log('state')
      // console.log(state)
      // console.log(entities)
      //  state.entities = state.entities.filter(el => el.i !== action.payload.id);
    },

    // getComponent 이거 안씀
    // getComponent: (state, action) => {
    //   const entities = action.payload.response
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.component = JSON.parse(entities)
    // },

    // getTplComponent
    getTplComponent: (state, action) => {
      const entities = action.payload.response;
      state.error = null;
      state.actionsLoading = false;
      state.tplComponent = JSON.parse(entities);
    },

    // getSite
    getSite: (state, action) => {
      const entities = action.payload.response;
      state.error = null;
      state.actionsLoading = false;
      // console.log(entities)

      const siteId = "site" + JSON.parse(entities)._id;

      state.currentSite = siteId;
      state[siteId] = JSON.parse(entities);
    },

    // getLnkWorkspace
    getLnkWorkspace: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getLnkWorkspace")
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      state.workspaces = JSON.parse(entities);
    },

    // getWorkspace
    getWorkspace: (state, action) => {
      const entities = action.payload.response;
      state.error = null;
      state.actionsLoading = false;

      const workspaceId = "workspace" + JSON.parse(entities)._id;

      state.currentWorkspace = workspaceId;
      state[workspaceId] = JSON.parse(entities);
    },

    // getMenu
    getMenu: (state, action) => {
      const entities = action.payload.response;
      state.error = null;
      state.actionsLoading = false;

      // console.log('>>>>>> getMenu')
      // console.log(entities)

      const menuId = "menu" + JSON.parse(entities)[0]._id;
      state.currentMenu = menuId;
      state[menuId] = JSON.parse(entities);
      // console.log(state[menuId])
    },

    // getTagGroupAll
    getTagGroupAll: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getTagGroupAll")
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      state.tagGroupAll = JSON.parse(entities);

      // console.log("V===================================")
    },

    getWorkspacesAll: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspacesAll")
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      state.workspacesAll = JSON.parse(entities);

      // console.log("V===================================")
    },

    // getGroupAll
    getGroupAll: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getGroupAll")
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      state.groupAll = JSON.parse(entities);

      // console.log("V===================================")
    },

    // getPage
    isExistUser: (state, action) => {
      state.error = null;
      state.actionsLoading = false;

      const entities = action.payload.response;
      // console.log("> slice > isExistUser")
      // console.log(entities)

      state.isCheckEmail = JSON.parse(entities);
    },

    // getPage
    getPage: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getPage")
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      const pageId = "page" + JSON.parse(entities)._id;
      state.currentPage = pageId;
      state[pageId] = JSON.parse(entities);
      // console.log("V===================================")
    },

    // updateComponentData // 컴포넌트 하나의 데이터 변경 저장 ..
    updateComponentData: (state, action) => {
      const entities = action.payload.response;
      // console.log('slice updateComponentData > action.payload.response')
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      const pageId = "page" + JSON.parse(entities).pageIdx;
      state.currentPage = pageId;

      state[pageId].component.map((comp, index) => {
        if (comp.i == JSON.parse(entities).component.i) {
          state[pageId].component[index] = JSON.parse(entities).component;
        }
      });
    },

    // updateComponent // 컴포넌트 하나의 변경 저장 ..
    updateComponent: (state, action) => {
      const entities = action.payload.response;
      // console.log('slice > action.payload.response')
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      const pageId = "page" + JSON.parse(entities).pageIdx;
      state.currentPage = pageId;

      state[pageId].component.map((comp, index) => {
        if (comp.i == JSON.parse(entities).component.i) {
          state[pageId].component[index] = JSON.parse(entities).component;
        }
      });

      // // TODO : 기능 개선
      // // state[pageId] 의 변경으로 인해
      // // GridPage.js 의 useEffect[pageData] 가 재실행되어 리로드가 일어난다
      // state[pageId].component = JSON.parse(entities).component
    },

    // updateComponents // 페이지 안의 컴포넌트 이동/생성/삭제 시 저장 ..
    updateComponents: (state, action) => {
      const entities = action.payload.response;
      // console.log('slice > action.payload.response')
      // console.log(entities)

      state.error = null;
      state.actionsLoading = false;
      const pageId = "page" + JSON.parse(entities).pageIdx;
      state.currentPage = pageId;

      // TODO : 기능 개선
      // state[pageId] 의 변경으로 인해
      // GridPage.js 의 useEffect[pageData] 가 재실행되어 리로드가 일어난다
      state[pageId].component = JSON.parse(entities).component;
    },

    // updateTplComponent
    updateTplComponent: (state, action) => {
      // console.log('slice > updateTplComponent > action.payload.response')
      // console.log(action.payload.response)
      // console.log(state.tplComponent)

      const entities = action.payload.response;

      // state.error = null;
      // state.actionsLoading = false;
      // const pageId = 'page'+JSON.parse(entities)._id
      // state.currentPage = pageId
      // state[pageId] = JSON.parse(entities)
    },

    // updateMenu
    updateMenu: (state, action) => {
      // console.log('slice > updateMenu ')
      // console.log(action.payload.response)

      const entities = action.payload.response;

      state.error = null;
      state.actionsLoading = false;

      // console.log('>>>>>> getMenu')
      // console.log(entities)

      const menuId = "menu" + JSON.parse(entities)[0]._id;
      state.currentMenu = menuId;
      state[menuId] = JSON.parse(entities);
    },

    // updatePage
    updatePage: (state, action) => {
      // console.log('slice > updatePage ')
      // console.log(action.payload.response)

      const entities = action.payload.response;

      state.error = null;
      state.actionsLoading = false;
      const pageId = "page" + JSON.parse(entities)._id;
      state.currentPage = pageId;
      state[pageId] = JSON.parse(entities);
      state.updatePageFlag = Math.random();
    },

    // reloadPage
    reloadPage: (state, action) => {
      // console.log('slice > reloadPage > ')
      // console.log(action.payload.response)

      // const entities = action.payload.response

      // state.error = null;
      // state.actionsLoading = false;
      // const pageId = 'page'+JSON.parse(entities)._id
      // state.currentPage = pageId
      // state[pageId] = JSON.parse(entities)
      state.updatePageFlag = Math.random();
    },

    // insertMenu
    insertMenu: (state, action) => {
      // console.log('slice insertMenu')
      // console.log(state)
      // console.log(action.payload)

      state.error = null;
      state.actionsLoading = false;

      const entities = action.payload.response;

      // console.log(entities)

      const menuId = "menu" + JSON.parse(entities)[0]._id;
      state.currentMenu = menuId;
      state[menuId] = JSON.parse(entities);
    },

    // inviteUser
    inviteUser: (state, action) => {
      // console.log('slice insertMenu')
      // console.log(state)
      // console.log(action.payload)

      state.error = null;
      state.actionsLoading = false;

      const entities = action.payload.response;

      // console.log('slice > inviteUser')
      // console.log(entities)

      // const menuId = 'menu'+JSON.parse(entities)[0]._id
      // state.currentMenu = menuId
      // state[user] = JSON.parse(entities)
    },

    // modlUpdateState
    modlStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    // modlUpdateState
    logout: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      state.currentMenu = undefined;
      state.currentWorkspace = undefined;
      state.workspaces = undefined;
      state.groupList = undefined;

      // state = state.map(key => {
      //   key = undefined
      // });

      for (var key in state) {
        // console.log(key)

        state[key] = null;
        delete state[key];
      }
    },

    // deleteCurrentWorkspaceInfo
    deleteCurrentWorkspaceInfo: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      state.currentMenu = undefined;
      state.currentWorkspace = undefined;
      state.workspaces = undefined;
    },

    // ==================================================================
    // ==================================================================
    // ==================================================================

    getDocumentCount: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getDocumentCount")
      // console.log(entities)
      state.totalCount = JSON.parse(entities).count;
    },

    // 효림 관리자 관리(admin)
    //===========================================================================================
    // START
    //===========================================================================================
    getWorkspaceAdminMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspaceAdminMember")
      // console.log(entities)

      state.adminMemberList = JSON.parse(entities);
    },

    insertWorkspaceAdminMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > insertWorkspaceAdminMember")
      // console.log(entities)

      state.adminMemberList = JSON.parse(entities);
    },

    updateWorkspaceAdminMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateWorkspaceAdminMember")
      // console.log(entities)

      state.adminMemberList = JSON.parse(entities);
    },

    deleteWorkspaceAdminMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > deleteWorkspaceMember")
      // console.log(entities)

      state.adminMemberList = JSON.parse(entities);
    },

    // 사용자 관리(workspace)
    //===========================================================================================
    // START
    //===========================================================================================
    getWorkspaceMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspaceMember")
      // console.log(entities)

      state.memberList = JSON.parse(entities);
    },

    insertWorkspaceMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > insertWorkspaceMember")
      // console.log(JSON.parse(entities))

      state.memberList = JSON.parse(entities);
    },

    updateWorkspaceMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateWorkspaceMember")
      // console.log(state.memberList)
      // console.log(entities)
      let oMember = JSON.parse(entities);
      state.memberList.map((mMember, index) => {
        if (mMember._id == oMember._id) {
          // console.log(JSON.stringify(mMember))
          // console.log(JSON.stringify(oMember))

          mMember.userInfo[0].email = oMember.email;
          mMember.userInfo[0].name = oMember.name;
          mMember.userInfo[0].password = oMember.password;
          mMember.userInfo[0].phone = oMember.phone;
          mMember.userInfo[0].memo = oMember.memo;
          mMember.groupIdx = oMember.groupIdx;

          state.groupAll.map((mGroup, index) => {
            if (mGroup._id == oMember.groupIdx) {
              mMember.groupInfo[0].title = mGroup.title;
            }
          });

          // mGroup.updateDate = oGroup.updateDate
        }
      });
    },

    deleteWorkspaceMember: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > deleteWorkspaceMember")
      // console.log(entities)

      state.memberList = JSON.parse(entities);
    },

    // 사용자 권한 그룹 관리(workspace)
    //===========================================================================================
    // START
    //===========================================================================================
    getWorkspaceGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspaceGroup")
      // console.log(entities)

      state.groupList = JSON.parse(entities);
    },

    insertWorkspaceGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > insertWorkspaceGroup")
      // console.log(entities)

      state.groupList = JSON.parse(entities);
    },

    updateWorkspaceGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateWorkspaceGroup")
      // console.log(entities)
      let oGroup = JSON.parse(entities);
      state.groupList.map((mGroup, index) => {
        if (mGroup._id == oGroup._id) {
          mGroup.title = oGroup.title;
          mGroup.allowMenu = oGroup.allowMenu;
          mGroup.updateDate = oGroup.updateDate;
        }
      });
    },

    deleteWorkspaceGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > deleteWorkspaceGroup")
      // console.log(entities)

      state.groupList = JSON.parse(entities);
    },

    // 태그 관리(workspace)
    //===========================================================================================
    // START
    //===========================================================================================
    getWorkspaceTag: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspaceTag")
      // console.log(entities)

      state.tagList = JSON.parse(entities);
    },

    insertWorkspaceTag: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > insertWorkspaceTag")
      // console.log(entities)

      state.tagList = JSON.parse(entities);
    },

    updateWorkspaceTag: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateWorkspaceTag")
      // console.log(entities)
      let oGroup = JSON.parse(entities);

      state.tagList.map((mGroup, index) => {
        if (mGroup._id == oGroup._id) {
          mGroup.title = oGroup.title;
          mGroup.content = oGroup.content;
          mGroup.comment = oGroup.comment;
          mGroup.tagGroupIdx = oGroup.tagGroupIdx;

          state.tagGroupAll.map((tGroup, index) => {
            if (tGroup._id == oGroup.tagGroupIdx) {
              mGroup.tagGroupInfo[0].title = tGroup.title;
            }
          });
        }
      });
    },

    deleteWorkspaceTag: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > deleteWorkspaceTag")
      // console.log(entities)

      state.tagList = JSON.parse(entities);
    },

    // 태그 그룹 관리(workspace)
    //===========================================================================================
    // START
    //===========================================================================================
    getWorkspaceTagGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspaceTagGroup")
      // console.log(entities)

      state.tagGroupList = JSON.parse(entities);
    },

    insertWorkspaceTagGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > insertWorkspaceTagGroup")
      // console.log(entities)

      state.tagGroupList = JSON.parse(entities);
    },

    updateWorkspaceTagGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateWorkspaceTagGroup")
      // console.log(entities)

      // state.tagGroupList = JSON.parse(entities)
      let oGroup = JSON.parse(entities);
      state.tagGroupList.map((mGroup, index) => {
        if (mGroup._id == oGroup._id) {
          mGroup.title = oGroup.title;
          mGroup.comment = oGroup.comment;
        }
      });
    },

    deleteWorkspaceTagGroup: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > deleteWorkspaceTagGroup")
      // console.log(entities)

      state.tagGroupList = JSON.parse(entities);
    },

    // 전체 워크스페이스 관리(admin)
    //===========================================================================================
    // START
    //===========================================================================================
    getWorkspaceList: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > getWorkspaceList")
      // console.log(entities)

      state.workspacesList = JSON.parse(entities);
    },

    insertWorkspace: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > insertWorkspace")
      // console.log(entities)

      state.workspacesList = JSON.parse(entities);
    },

    updateWorkspace: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateWorkspace")
      // console.log(entities)

      let oGroup = JSON.parse(entities);
      state.workspacesList.map((mGroup, index) => {
        if (mGroup._id == oGroup._id) {
          mGroup.title = oGroup.title;
          mGroup.content = oGroup.content;
          mGroup.image = oGroup.image;
        }
      });
    },

    deleteWorkspace: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > deleteWorkspace")
      // console.log(entities)

      state.workspacesList = JSON.parse(entities);
    },

    // WEB BUILDER
    //===========================================================================================
    // START
    //===========================================================================================

    updateSelectElement: (state, action) => {
      const entities = action.payload.response;
      // console.log("> slice > updateSelectElement")
      // console.log(entities)

      state.selectElement = JSON.parse(entities);
    },

    updateWebBuilder: (state, action) => {
      const entities = action.payload.response;
      // console.log('updateWebBuilder > slice > action.payload.response')
      // console.log(entities)
      // console.log('pageId1')
      const pageId = "page" + JSON.parse(entities).pageIdx;
      const compId = JSON.parse(entities).componentIdx;
      const layout = JSON.parse(entities).layout;
      // console.log('>> pageId')
      // console.log(pageId)
      // console.log('>> compId')
      // console.log(compId)
      // console.log('>> layout')
      // console.log(layout)

      state[pageId].component.map((comp, index) => {
        if (comp.i == compId) {
          comp.data.layout = layout;
        }
      });
    },

    // DataTest
    //===========================================================================================
    // START
    //===========================================================================================
    getDataTest: (state, action) => {
      const entities = action.payload.response;
      console.log("> slice > getDataTest");
      console.log(entities);

      state.workspacesList = JSON.parse(entities);
    },

    insertDataTest: (state, action) => {
      const entities = action.payload.response;
      console.log("> slice > insertDataTest");
      console.log(entities);

      state.workspacesList = JSON.parse(entities);
    },

    updateDataTest: (state, action) => {
      const entities = action.payload.response;
      console.log("> slice > updateDataTest");
      console.log(entities);

      let oGroup = JSON.parse(entities);
      state.workspacesList.map((mGroup, index) => {
        if (mGroup._id == oGroup._id) {
          mGroup.title = oGroup.title;
          mGroup.content = oGroup.content;
          mGroup.image = oGroup.image;
        }
      });
    },

    deleteDataTest: (state, action) => {
      const entities = action.payload.response;
      console.log("> slice > deleteDataTest");
      console.log(entities);

      state.workspacesList = JSON.parse(entities);
    },
  },
});

//===========================================================================================
// KICI 대시보드 달력 REDUX
//===========================================================================================
export const dateSlice = createSlice({
  name: "date",
  initialState: initialDateState,
  reducers: {
    changePrevSlice: (state, action) => {
      state.prevDate = action.payload;
    },

    changeNextSlice: (state, action) => {
      state.nextDate = action.payload;
    },

    changePrevMomentSlice: (state, action) => {
      state.prevMoment = action.payload;
    },

    changeNextMomentSlice: (state, action) => {
      state.nextMoment = action.payload;
    },

    changeAssembleSlice: (state, action) => {
      state.assemblyDate = action.payload;
    },

    changeAssembleMomentSlice: (state, action) => {
      state.assemblyDateMoment = action.payload;
    }
  },
});

//===========================================================================================
// KICI 대시보드 차트 REDUX
//===========================================================================================
export const chartSlice = createSlice({
  name: "chart",
  initialState: chartState,
  reducers: {
    changeChartData: (state, action) => {
      state.chartData = action.payload;
    },
    changerecentCrawling: (state, action) => {
      state.recentCrawling = action.payload;
    },
    changeChartList: (state, action) => {
      state.chartList = action.payload;
    },
    changeKeywordName: (state, action) => {
      state.keywordName = action.payload;
    },
    changeKeywordCount: (state, action) => {
      state.keywordCount = action.payload;
    },
    changeprevFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

//===========================================================================================
// KICI 커뮤니티 및 뉴스 차트 REDUX
//===========================================================================================
export const chartSubSlice = createSlice({
  name: "chart",
  initialState: crawlingState,
  reducers: {
    changeSubChartData: (state, action) => {
      state.chartData = action.payload;
    },
  },
});

//===========================================================================================
// KICI 필터-키워드 REDUX
//===========================================================================================
export const keywordSlice = createSlice({
  name: "keyword",
  initialState: keywordState,
  reducers: {
    changeKeywordData: (state, action) => {
      state.keyword_data = action.payload;
    },
  },
});

// export const todaySlice = createSlice({
//   name: "today_data",
//   initialState: today_data,
//   reducers: {
//     changeCollectData: (state, action) => {
//       state.today_collect = action.payload;
//     }
//   }
// })
