import React, {useEffect, useState} from 'react';
import Utils from '../../edit/WebBuilder/Utils';
import RenderingHooks from './RenderingHooks';


/**
 * 키워드 및 알람 설정, 그룹 렌더링 Hooks
 * @param {string} change stringtype - date
 * @param {string} sort string type - 정렬 방식
 * @param {number} page number type - 현재 페이지 
 * @param {string} workspace string type - 유저워크스페이스
 * @returns 그룹 리스트들을 배열 타입으로 반환
 */

const RenderManageHooks = (change, sort, page, workspace) => {

    

    const [site_list, set_site_list] = useState([]);

    useEffect(()=>{
        get_site_function();
    }, [change, sort, page]);

    const get_site_function = async() => {

        let payload = {
            queries:
                sort === "keyword_regis"
                ? [
                    {
                      key: "group_list",
                      query: `db.collection('KeywordGroup').aggregate([
                        { $sort: { updateDate: -1 } },
                        { $match: {"workspaceIdx" : "${workspace.replace("workspace","")}" } }
                        ]).toArray()`,
                    }
                  ]
                : [
                    {
                      key: "group_list",
                      query: `db.collection('KeywordGroup').aggregate([
                        { $sort: { site: -1 } },
                        { $match: { "workspaceIdx" : "${workspace.replace("workspace", "")}" } }
                        ]).toArray()`,
                    }
                ],
        };

        const get_site_data =  await Utils.getQueryResult(payload);
        
        const { group_list } = { ...get_site_data }
        
        if(Object.keys(group_list).length > 0) {
            set_site_list(group_list);
        }
    }

    return { site_list }
}

export default RenderManageHooks;