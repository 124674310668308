import React from 'react';
import Loading from './Loading';
import '../widgets/kici/style/mobile.scss';

const KiciUpdateToday = ({recentCrawling}) => {

    return(
        <div className="side_update_date">
            <div>
                <h3>최신 업데이트 일자</h3>
            </div>
            <div className="update_data_wrap">
                <span>D</span>
                <span className='left_date'>{ recentCrawling ? recentCrawling.substr(0,8) : <Loading color={'white'} size={48} /> }</span>
                <span>T</span>
                <span className='right_time'>{ recentCrawling ? recentCrawling.slice(-8) : <Loading color={'#95b9ff'} size={48} /> }</span>
            </div>
        </div>
    )
}

export default KiciUpdateToday;