
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageTag_Delete(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    태그 관리
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




            <Form>

<Form.Group id="formUserId">
    <Form.Label>태그명*</Form.Label>
    <Form.Control placeholder="아이디를 입력해주세요" />
</Form.Group>

<Form.Group id="formGridState">
    <Form.Label>태그 그룹 선택</Form.Label>
    <Form.Control as="select">
        <option>태그 그룹 선택</option>
        <option>...</option>
    </Form.Control>
</Form.Group>


<Form.Group id="formUserId">
    <Form.Label>데이터 정의</Form.Label>
    <Form.Control placeholder="데이터 정의를 입력해주세요" />
</Form.Group>

<Form.Group id="formUserId">
    <Form.Label>비고</Form.Label>
    <Form.Control placeholder="비고를 입력해주세요" />
</Form.Group>



<Button variant="primary" type="submit">
    추가
</Button>



</Form>



            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}