import React from "react";
import { useDrag } from "react-dnd";

import { StyleLayout } from "./LayoutStructure"

const SideBarStyle = ({ data }) => {

  // console.log(">> SideBarStyle")
  // console.log(data)

  const returnUI = () => {

    if (data == undefined) return (<></>)
    
    if (data != undefined) {

      if (data.data.type == "row") {

        let layout = new StyleLayout();
        let ui = layout[data.data.type](data)
        return (ui)

      } else if (data.data.type == "column") {

        let layout = new StyleLayout();
        let ui = layout[data.data.type](data)
        return (ui)

      } else {

        let layout = new StyleLayout();
        let ui = layout[data.data.elementType](data)
        return (ui)
      }

    } else {
      let ui = <div>ELEMENT 를 선택해주세요</div>
      return ui
    }

  }


  return (
    <div >
      {/* SideBarStyle{JSON.stringify(data)} */}
      {returnUI()}
    </div>
  );
};
export default SideBarStyle;
