import React, { useEffect, useState, useCallback } from "react";
import { Button } from 'react-bootstrap';

import DropZone from "./DropZone";
import TrashDropZone from "./TrashDropZone";
import SideBarElement from "./SideBarElement";
import Row from "./Row";

import {
  handleMoveElement,
  
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarElementIntoParent,
  handleMoveSidebarCulumnIntoParent,
  handleMoveSidebarRowIntoParent,
  handleMoveSidebarLayoutIntoParent,
  handleRemoveItemFromLayout
} from "./helpers";

import { LAYOUT, ROW, COLUMN, ELEMENT, SIDEBAR_LAYOUT, SIDEBAR_ROW, SIDEBAR_COLUMN, SIDEBAR_ELEMENT } from "./constants";

import shortid from "shortid";
import { element } from "prop-types";

import { actionTypes } from "../../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import store from "../../../../../redux/store";


const Builder = ({ el, initLayout }, props) => { //el initLayout

  // console.log(">> props.initLayout")

  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)


  const components = useSelector((state) => state.modl[currentPage].component, shallowEqual)
  // currentComponent.data.layout[0]?.children
  
  const [totalLayout, setTotalLayout] = useState(); // GLOABL
  const [layout, setLayout] = useState(initLayout); // GLOABL
  const [elements, setElements] = useState(null); // GLOBAL



  
  // ========================================================================
  // 데이터 INIT...
  // ========================================================================

  

  useEffect(() => {

    console.log("~~~~~~~~~ initLayout")
    // console.log(layout)

    setLayout(initLayout)
 
  }, [initLayout]);


  useEffect(() => {

  
    components.map((comp, index) => {

      if (comp.i == el.i) {

        console.log('Builder > initLayout > DONE')

        if(comp.data?.layout == undefined){

          setTotalLayout(initLayout)

        }else{
          setTotalLayout(comp.data?.layout)
        }
        
        // setInitLayout(comp.data?.layout[0]?.children)
        // setCurrentComponent(comp)

      }
    })
  }, [components]);

  
  

  useEffect(() => {

    // console.log("~~~~~~~~~>>> ")
    // console.log(initLayout)
    // console.log(layout)


    if (layout != null) {
      let _element = {}
      for (let _l of layout) {
        for (let _r of _l.children) {
          for (let _c of _r.children) {
            for (let _e of _c.children) {
              _element[_e.id] = _e

            }
          }
        }
      }

      // console.log(">> init _element")
      // console.log(_element)

      setElements(_element)
    }
  }, [layout]);






  const clickTest = () => {

    if (layout != null) {

      // console.log("store======")
      // console.log(store.getState())

      console.log("layout======")
      console.log(JSON.stringify(layout))

      // console.log("totalLayout ======")
      // console.log(JSON.stringify(totalLayout))

      // console.log("elements======")
      // console.log(JSON.stringify(elements))

    }
  }




  const handleDropToTrashBin = useCallback(
    (dropZone, item) => {

      console.log("handleDropToTrashBin > ")
      console.log(item)

      console.log("4. Remove handleRemoveItemFromLayout")
      let _totalLayout = handleRemoveItemFromLayout(totalLayout, item)
      console.log("==> _totalLayout")
      // console.log(JSON.stringify(_totalLayout,null,2)) 

      let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
      dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })



      // METHOD 1 //
      // setLayout(handleRemoveItemFromLayout(layout, item));

      // METHOD 2 //
      // let elCopy = JSON.parse(JSON.stringify(el))
      // console.log(elCopy)
      // elCopy.data.layout[0].children = handleRemoveItemFromLayout(layout, item)
      // let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), component: elCopy }) 
      // dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

    },
    [layout]
  );






  const handleDrop = useCallback(
    (dropZone, item) => {

      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
      
      const newItem = { ...item };

      // if (item.type === ROW) {
      //   newItem.children = item.children;
      // }

      // if (item.type === COLUMN) {
      //   newItem.children = item.children;
      // }


      
      if (item.type === SIDEBAR_ELEMENT) {

        console.log(".... SIDEBAR_ELEMENT")
        // console.log(item)
        // 1. Move sidebar item into builder
        const newElement = {
          ...item,
          id: shortid.generate()
        };
        const newItem = {
          ...item,
          id: newElement.id,
          type: ELEMENT,

        };
        setElements({
          ...elements,
          [newElement.id]: newElement
        });


        let _totalLayout = handleMoveSidebarElementIntoParent(totalLayout, newItem, dropZone)
        console.log("==> _totalLayout")
        console.log(JSON.stringify(_totalLayout, null, 2)) 


        let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
        dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

    
        return;
      }



      //SIDEBAR_COLUMN
      if (item.type === SIDEBAR_COLUMN) {
        console.log(".... SIDEBAR_COLUMN")
        // console.log(item)
        // console.log(splitDropZonePath.length)

        if (splitDropZonePath.length <= 2) { // 0-1

          const newElement = {
            ...item,
            id: shortid.generate()
          };
          const newItem = {
            ...item,
            id: newElement.id,
            type: COLUMN,
          };

          let _elements = {}
          for (let _c of item.children) {
            _elements[_c.id] = _c
          }

          // console.log(_elements)

          setElements({
            ...elements,
            ..._elements
          });

          // console.log(dropZone.path) // 0-1



          let _totalLayout = handleMoveSidebarCulumnIntoParent(totalLayout, newItem, dropZone)
          // console.log("==> _totalLayout")
          // console.log(JSON.stringify(_totalLayout, null, 2)) 
          let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
          dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })


        } else {
          console.log("현재 컴포넌트는 column 이상 영역에 추가 할수있습니다.")

        }
        return;
      }


      //SIDEBAR_ROW
      if (item.type === SIDEBAR_ROW) {
        console.log(".... SIDEBAR_ROW")
        // console.log(item)
        // console.log(splitDropZonePath.length)

        if (splitDropZonePath.length <= 1) { // 0


          const newElement = {
            ...item,
            id: shortid.generate()
          };
          const newItem = {
            ...item,
            id: newElement.id,
            type: ROW,
          };


          let _elements = {}

          for (let _c of item.children) {
            for (let _e of _c.children) {
              _elements[_e.id] = _e

            }
          }

          // console.log(_elements)

          setElements({
            ...elements,
            ..._elements
          });


          let _totalLayout = handleMoveSidebarRowIntoParent(totalLayout, newItem, dropZone)
          // console.log("==> _totalLayout")
          // console.log(JSON.stringify(_totalLayout, null, 2)) 
          let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
          dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

        } else {
          console.log("현재 컴포넌트는 row 이상 영역에 추가 할수있습니다.")

        }

        return;
      }



      //SIDEBAR_LAYOUT
      if (item.type === SIDEBAR_LAYOUT) {
        console.log(".... SIDEBAR_LAYOUT")
        // console.log(item)
        // console.log(splitDropZonePath.length)

        if (splitDropZonePath.length <= 1) { // 0

          const newItem = {
            ...item.children
          };

          // console.log("newItem")
          // console.log(newItem)

          let _elements = {}
          for (let _r of item.children) {
            for (let _c of _r.children) {
              for (let _e of _c.children) {
                _elements[_e.id] = _e

              }
            }
          }

          // console.log(_elements)

          setElements({
            ...elements,
            ..._elements
          });

          console.log(dropZone.path) // 0

          let _totalLayout = handleMoveSidebarLayoutIntoParent(totalLayout, newItem, dropZone)
          // console.log("==> _totalLayout")
          // console.log(JSON.stringify(_totalLayout, null, 2)) 
          let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
          dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })


        } else {
          console.log("현재 컴포넌트는 row 이상 영역에 추가 할수있습니다.")

        }

        return;
      }

    






      console.log("======================")
      console.log("# item") // 움직일려는 아이템
      console.log(item)
      console.log("# dropZone") // data : 목표 위치
      console.log(dropZone)
      console.log("# totalLayout") // data : 목표 위치
      console.log(totalLayout)
      console.log("======================")
      


      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");


      // console.log("item.path:" + item.path)
      // console.log("dropZone.path:" + dropZone.path)
      

      if (splitItemPath.length === splitDropZonePath.length) {

        console.log("1. Move + handleMoveElement")

        // // FOR TEST
        let _totalLayout = handleMoveWithinParent(totalLayout, item, dropZone) //layout 은 필요 없을듯 ...
        // console.log("==> _totalLayout")
        // console.log(JSON.stringify(_totalLayout,null,2)) 

        let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
        dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

        return

      }
      else{

        console.log("3. Move + Create handleMoveToDifferentParent")
        let _totalLayout = handleMoveToDifferentParent(totalLayout, newItem, dropZone)
        // console.log("==> _totalLayout")
        // console.log(JSON.stringify(_totalLayout,null,2)) 

        let payload = JSON.stringify({ pageIdx: currentPage.slice("page".length), componentIdx: el.i, layout: _totalLayout })
        dispatch({ type: actionTypes.UpdateWebBuilder, payload: payload })

        return

      }

    },
    [layout, elements]
  );





  const renderRow = (row, currentPath) => {

    return (
      <Row
        key={row.id}
        el={el} 
        data={row}
        handleDrop={handleDrop}
        elements={elements}
        path={currentPath}
      />
    );
  };











  if (elements == null || layout == undefined)  return (<></>)


  
  // dont use index for key when mapping over items
  // causes this issue - https://github.com/react-dnd/react-dnd/issues/342
  return (
    <React.Fragment key={Math.random()}>
      <Button size="sm" onClick={clickTest}> GET </Button>
      {/* <Button style={{ margin: "1px" }} onClick={save}> SAVE </Button> */}

      <div className="webBuilder">
        <div className="builderContainer">
          <div className="builder">

            {/* {layout[0].id} (Layout) */}
            {layout[0].children.map((row, index) => {
              const currentPath = `${index}`;

              return (
                <>
                  <DropZone
                    data={{
                      path: currentPath,
                      childrenCount: layout[0].children.length,
                      children: layout[0].children,
                      id: layout[0].id,
                      type: LAYOUT
                    }}
                    onDrop={handleDrop}
                    path={currentPath}
                  />
                  {renderRow(row, currentPath)}
                </>
              );
            })}
            <DropZone
              data={{
                path: `${layout[0].children.length}`,
                childrenCount: layout[0].children.length,
                id: layout[0].id,
                children: layout[0].children,
                type: LAYOUT
              }}
              onDrop={handleDrop}
              isLast
            />
          </div> 





          <TrashDropZone
            data={{
              layout
            }}
            onDrop={handleDropToTrashBin}
          />



        </div>
      </div>

    </React.Fragment>
  );
};
export default Builder;
