import React from "react";


import {Helmet} from "react-helmet";
import { useSelector } from "react-redux";
// import useScript from '../../useScript';
// import webviewNetar from "../../webviewNetar";

// const path = require('path')

//TODO 
//1.REMOTE 에서 preload.js 를 받을수있는 부분 구현 해야함 ( https://developpaper.com/electronic-webview-load-remote-preload-method/ )
//2.LOCAL or REOMTE 접속 경로에 따라 자동으로 webview > src 와 script > src 경로 설정하게 하기


export const Netar = (props) => {
  console.log("Netar:")


  return (<>

    <input id="button_addDiv_host" type="button" value="addDiv[host]" /> & 
    <input id="button_addDiv_iFrame" type="button" value="addDiv[iFrame]" />
   
    {/* <input type="button" value="addDiv[host]" onclick="addDiv_Host()"/> */}D:\Projects\Netar\public

    <br />Find local path. not [https://www.wisesolution.co.kr/webviewNetar.js]

    {/* <webview id="foo" src={'https://brunch.co.kr/'} preload={`file://${__dirname}/preloadNetar.js`} partition="persist:link"/> */}

    {/* <webview id="foo" src={'https://www.daum.net/'} preload={`file://C:/Projects/Modl/src/preloadNetar.js`} partition="persist:link"/> */}

    {/* <webview id="netarWebview" src={'https://nid.naver.com/nidlogin.login?mode=form&url=https%3A%2F%2Fwww.naver.com'} preload={'file://D:/Projects/Project/Modl/src/preloadNetar.js'}  /> */}

    <webview id="netarWebview" src={'https://nid.naver.com/nidlogin.login?mode=form&url=https%3A%2F%2Fwww.naver.com'} preload={`file://D:/Projects/Netar_electron/public/preloadNetar.js`} />

    <Helmet>
      {/* //추후에 로드 되서 public 폴데에 있다 */}
      {/* <script src="https://www.wisesolution.co.kr/webviewNetar.js" type="text/javascript" />  */}
      {/* <script src="webviewNetar.js" type="text/javascript" /> */}

      {/* 1. 로컬에 빼도 된다 */}
      <script src="webviewNetar.js" type="text/javascript" />
      {/* 2. 서버에 빼도 된다 */}
      {/* <script src="http://169.56.88.179/build/webviewNetar.js" type="text/javascript" /> */}
    
    </Helmet>


  </>);
};



