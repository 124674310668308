/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';
import { Button, Table, Pagination, Form, Col, Row } from 'react-bootstrap';

import { ManageGroup_Setting } from "./ManageGroup_Setting";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { ManageGroup_Add } from "./ManageGroup_Add";
import { ManageGroup_Delete } from "./ManageGroup_Delete";

import { actionTypes } from "../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as requestFromServer from "../../../../src/app/_redux/modlCrud";
import { Header } from "../widgets/hyorim/Header"
import { useScreenClass } from "react-grid-system";

const moment = require('moment');

export function ManageGroup({ el, states, className }) {

  let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  const dispatch = useDispatch();

  const screenClass = useScreenClass();

  // =====================================================
  // [S] param
  // =====================================================
  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [delModalShow, setDelModalShow] = useState(false);
  const [action, setAction] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [checkedList, setCheckedList] = useState([]);

  const modl = useSelector((state) => state.modl, shallowEqual)
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
  const currentMenu = useSelector((state) => state.modl.currentMenu, shallowEqual)
  const groupList = useSelector((state) => state.modl.groupList, shallowEqual)


  // SETTING PAGING
  let [totalItemCount, setTotalItemCount] = useState(0); // 현재 페이지
  let [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  let itemCountOfPage = 5 // 한 페이지의 아이템수
  let pageCountOfGroup = 10 // 페이지에 보여지는 카운트 수 < 1 2 3 4 5 > 

  let totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
  let currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
  let lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
  let pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
  let lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스
  let pageCountOfCurrentGroup = (currentGroup == lastGroup) ? pageCountOfLastGroup : pageCountOfGroup // 현제 페이지의 페이지수


  const formGroupStyle = {
    overflow: "hidden",
    marginBottom: "0",
    position: "relative"
  }

  const formCheckStyle = {
    float: "left",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  }

  const btnStyle = {
    margin: "20px 4.5px",
    float: "right"

  }

  const delBtnStyle = {
    margin: "20px 0 20px 4.5px",
    float: "right"
  }

  const tableStyle = ['xs'].includes(screenClass) ? {
    minWidth: "1600px",
    marginBottom: "0"
  } : {
    minWidth: "900px",
    marginBottom: "0"
  }

  const thStyle = {
    padding: "15px 22px 15px 22px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    backgroundColor: "#fafafb",
    textAlign: "center"
  }

  const tdStyle = {
    padding: "30px 22px 30px 22px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    textAlign: "center"
  }


  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  useEffect(() => {

    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <>
          <ManageGroup_Setting
            show={modalShow}
            onHide={() => setModalShow(false)}
            parentCallback={settingCallback}
          />
          <ManageGroup_Add
            show={addModalShow}
            action={action}
            selectIndex={selectIndex}
            menu={modl[currentMenu]}
            groupList={groupList}
            onHide={() => setAddModalShow(false)}
            parentCallback={addItemCallback}
          />
          <ManageGroup_Delete
            show={delModalShow}
            onHide={() => setDelModalShow(false)}
            parentCallback={delItemCallback}
          />
        </>,

        document.getElementById("contentModal"))
    } else {
    }

  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================






  useEffect(() => {

    // let payload = {
    //   "action": "fetch",
    //   "workspaceIdx": currentWorkspace.replace("workspace", ""),
    //   "itemCountOfPage": itemCountOfPage,
    //   "currentPage": currentPage
    // }

    // dispatch({ type: actionTypes.GetWorkspaceGroup, payload: payload })




    updatePaging()

  }, []);


  useEffect(() => {
    // console.log("===>> totalItemCount")
    // console.log(totalItemCount)

    totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
    currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
    lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
    pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
    lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스

    // console.log("currentPage:"+currentPage)
    // console.log("lastPage:"+lastPage)

    if (currentPage > lastPage && lastPage != 0) {
      selectPage(lastPage)
    }

  }, [totalItemCount]);


  useEffect(() => {

    let payload = {
      "action": "fetch",
      "workspaceIdx": currentWorkspace.replace("workspace", ""),
      "itemCountOfPage": itemCountOfPage,
      "currentPage": currentPage
    }

    dispatch({ type: actionTypes.GetWorkspaceGroup, payload: payload })

  }, [currentPage]);




  // =====================================================
  // [S] modal
  // =====================================================

  const deleteItem = () => {
    console.log("deleteItem >> ")
    console.log(checkedList)

    let payload = {
      "action": "delete",
      "workspaceIdx": currentWorkspace.replace("workspace", ""),
      "_ids": checkedList,

      "itemCountOfPage": itemCountOfPage,
      "currentPage": currentPage
    }

    dispatch({ type: actionTypes.DeleteWorkspaceGroup, payload: payload })
    updatePaging()
    // setDelModalShow(true)

  };


  const addItem = () => {
    setAction("add")
    setSelectIndex(0)
    setAddModalShow(true)
    console.log('addItem click: ')

  };


  const editItem = (index) => {
    setAction("edit")
    setSelectIndex(index)
    setAddModalShow(true)
    console.log('editItem click: ' + index)

  };


  const settingCallback = (data) => {
    console.log('callback: ' + data)

  };

  const addItemCallback = (payload) => {

    // console.log('addItemCallback: ' + payload)
    payload["itemCountOfPage"] = itemCountOfPage
    payload["currentPage"] = currentPage

    if (payload.action == "add") {
      dispatch({ type: actionTypes.InsertWorkspaceGroup, payload: payload })
    } else if (payload.action == "edit") {
      dispatch({ type: actionTypes.UpdateWorkspaceGroup, payload: payload })
    }

    updatePaging()

  };

  const delItemCallback = (data) => {
    console.log('delItemCallback: ' + data)
  };


  // =====================================================
  // [E] modal
  // =====================================================





  const ConvMenuName = (allowMenu) => {

    let List = []

    try {
      for (let menu of allowMenu) {
        for (let compare of modl[currentMenu]) {
          for (let item of compare.items) {
            if (item.id == menu.idx) {
              List.push(item.title)
            }
          }
        }
      }

    } catch (e) {

    }
    return List.join(" / ")
  }


  const changeCheckAll = (e) => {
    let isChecked = e.target.checked
    if (isChecked) {
      let _checkList = []
      for (let mGroup of groupList) {
        _checkList.push(mGroup._id)
      }
      setCheckedList(_checkList)
    } else {
      setCheckedList([])
    }
  }


  const getCheckState = (id) => {
    return checkedList.includes(id)
  }


  const setCheckState = (id, e) => {

    let isChecked = e.target.checked

    if (isChecked) {
      let joined = checkedList.concat(id)
      setCheckedList(joined)
    } else {
      setCheckedList(checkedList.filter(item => item !== id))
    }

  }



  // =====================================================
  // [S] page
  // =====================================================



  let items = [];

  const updatePaging = () => {

    let payload = {
      "workspaceIdx": currentWorkspace.replace("workspace", ""),
      "document": "Group"
    }


    requestFromServer
      .getDocumentCount(payload)
      .then(response => {

        console.log("result=====")
        console.log(response)

        setTotalItemCount(JSON.parse(response).count)


        return response
      })
      .catch(error => {
        error.clientMessage = "Can't delete modl";

      });



    // dispatch({ type: actionTypes.GetDocumentCount, payload: payload })

    // console.log(totalItemCount)

  };



  const selectPage = (idx) => {

    setCurrentPage(idx)

  };




  if (currentGroup != 1) {
    items.push(
      <Pagination.Prev key={Math.random()} onClick={() => { selectPage(currentPage - pageCountOfGroup) }}></Pagination.Prev>
    );
  }

  for (let number = 1; number <= pageCountOfCurrentGroup; number++) {

    let idx = (currentGroup - 1) * pageCountOfGroup + number
    items.push(
      <Pagination.Item key={Math.random()} active={idx === currentPage} onClick={() => { selectPage(idx) }}>
        {idx}
      </Pagination.Item>,
    );
  }

  if (currentGroup != lastGroup) {
    items.push(
      <Pagination.Next key={Math.random()} onClick={() => { selectPage((currentPage + pageCountOfGroup > lastPage) ? lastPage : currentPage + pageCountOfGroup) }}></Pagination.Next>
    );
  }

  // pageCountOfLastGroup

  // =====================================================
  // [E] table content
  // =====================================================


  if (groupList == undefined) return (<></>)

  // let MemberGroupUI = <></>
  let MemberGroupUI = groupList.map((value, index) =>
    <tr key={Math.random()}>
      <td style={tdStyle}><Form.Check onChange={(e) => setCheckState(value._id, e)} checked={getCheckState(value._id)} /></td>
      <td style={tdStyle}>{totalItemCount - (itemCountOfPage * (currentPage - 1)) - index}</td>
      <td style={tdStyle}>{value.title}</td>
      <td style={tdStyle}>{ConvMenuName(value.allowMenu)}</td>
      <td style={tdStyle}>{value.userInfo.length}</td>
      <td style={tdStyle}>{moment(value.registDate).format('YYYY-MM-DD HH:mm:ss')}</td>
      <td style={tdStyle} onClick={() => {
        editItem(index)
      }}>[관리]</td>
    </tr>
  )


  return (




    <div style={{ paddingBottom: "20px" }}>
      {/* div 대신 <></> 를 쓰면 item size 만큼 배경을 채워진다. */}

      <FullScreen handle={fullScreenHandle}>

        <div style={{ backgroundColor: "transparent", boxShadow: "none" }} className={`card card-custom ${className}`}>


          <Header el={el}></Header>
          <div className="card-body shadow-xs d-flex flex-column p-0" style={{ backgroundColor: "white", borderRadius: "0.45rem" }} >
            <div className="flex-grow-1" style={{ padding: "0 20px 20px" }} >





              <Form.Group style={formGroupStyle}>
                <Form.Check style={formCheckStyle} onChange={changeCheckAll} label="전체 선택" />
                <Button style={delBtnStyle} onClick={() => { deleteItem() }}> 삭제</Button>
                <Button style={btnStyle} onClick={() => { addItem() }}> 추가</Button>
              </Form.Group>

              <div style={{ overflow: "auto" }}>
                <Table hover style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>선택</th>
                      <th style={thStyle}>번호</th>
                      <th style={thStyle}>권한 그룹명</th>
                      <th style={thStyle}>권한 자세히 보기</th>
                      <th style={thStyle}>해당되는 계정 수</th>
                      <th style={thStyle}>날짜</th>
                      <th style={thStyle}>수정</th>
                    </tr>
                  </thead>
                  <tbody>

                    {MemberGroupUI}

                  </tbody>
                </Table>
              </div>

              <div style={{ float: "right" }}><Pagination>{items}</Pagination></div>



            </div>
          </div>

        </div>

      </FullScreen>


    </div>























  );
}


