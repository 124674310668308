
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageTag_Add(props) {
    const { parentId, parentCallback, action, selectIndex, menu, tagList, tagGroupList, ...rest } = props

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [comment, setComment] = useState("");
    const [tagGroupIdx, setTagGroupIdx] = useState("");



    useEffect(() => {

        if (rest.show == true) {


            console.log("tagList > ")
            console.log(tagList)

            console.log("tagGroupList > ")
            console.log(tagGroupList)

            console.log("selectIndex > ")
            console.log(selectIndex)


            if (action == "edit") {

                console.log("edit >")

                console.log(tagList[selectIndex].title)
                setTitle(tagList[selectIndex].title)
                setContent(tagList[selectIndex].content)
                setComment(tagList[selectIndex].comment)

                setTagGroupIdx(tagList[selectIndex].tagGroupIdx)

            } else if (action == "add") {

                console.log("add >")

                setTitle("")
                setContent("")
                setComment("")

                setTagGroupIdx("")


            }



        }

    }, [rest.show]);


    const btnStyle = {
        margin: "2px",
    }



    const changeTitle = (e) => {
        setTitle(e.target.value)
    }

    const changeContent = (e) => {
        setContent(e.target.value)
    }

    const changeComment = (e) => {
        setComment(e.target.value)
    }



    const setCheckState = (e) => {

        console.log("^^ e.target.value")
        console.log(e.target.value)

        setTagGroupIdx(e.target.value)

    }


    let SelectorUI = (tagGroupList != null) ? tagGroupList.map((_group, index) =>
        <option key={Math.random()} value={_group._id || ''}>{_group.title}</option>
    ) : null

    if (selectIndex == -1) return <></>


    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(action == "add") ? <>태그 추가</> : <>태그 수정</>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>태그명*</Form.Label>
                        <Form.Control placeholder="태그명을 입력해주세요" onChange={changeTitle} value={title || ''} />

                    </Form.Group>

                    <Form.Group id="formGridState">
                        <Form.Label>태그 그룹 선택</Form.Label>
                        <Form.Control as="select" onChange={setCheckState} value={tagGroupIdx}>
                            <option key={Math.random()} value="">없음</option>

                            {SelectorUI}
                        </Form.Control>
                    </Form.Group>


                    <Form.Group id="formUserId">
                        <Form.Label>데이터 정의</Form.Label>
                        <Form.Control placeholder="데이터 정의를 입력해주세요" onChange={changeContent} value={content || ''} />
                    </Form.Group>

                    <Form.Group id="formUserId">
                        <Form.Label>비고</Form.Label>
                        <Form.Control placeholder="비고를 입력해주세요" onChange={changeComment} value={comment || ''} />
                    </Form.Group>



                    {(action == "add") ?
                        <Button style={btnStyle} size='sm' onClick={() => {

                            if (tagGroupIdx == "") {
                                alert("그룹을 선택해주세요")
                                return
                            }


                            let payload = {
                                "action": "add",
                                "workspaceIdx": menu[0].workspaceIdx,
                                "title": title,
                                "content": content,
                                "comment": comment,
                                "tagGroupIdx": tagGroupIdx

                            }
                            parentCallback(payload)
                            rest.onHide()
                        }}> 추가</Button>
                        :
                        <Button style={btnStyle} size='sm' onClick={() => {

                            if (tagGroupIdx == "") {
                                alert("그룹을 선택해주세요")
                                return
                            }


                            let payload = {
                                "action": "edit",
                                "_id": tagList[selectIndex]._id,
                                "workspaceIdx": tagList[selectIndex].workspaceIdx,
                                "title": title,
                                "content": content,
                                "comment": comment,
                                "tagGroupIdx": tagGroupIdx

                            }


                            parentCallback(payload)
                            rest.onHide()
                        }}> 저장</Button>
                    }





                </Form>



            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}