/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';

import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../../../dropdowns";
import { useHtmlClassService } from "../../../../../templates/type1/layout";


import { actionTypes } from "../../../../../../redux/actionType";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';



export function UnitGraph({ el, states, className }) {
  let id = "element"


  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      // colorsGrayGray500: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray500"
      // ),
      // colorsGrayGray200: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray200"
      // ),
      // colorsGrayGray300: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray300"
      // ),
      // colorsThemeBaseDanger: objectPath.get(
      //   uiService.config,
      //   "js.colors.theme.base.danger"
      // ),
      // fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);



  let chartId = 'chart' + id



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  const [modalShow, setModalShow] = React.useState(false);



  // =====================================================
  // [E] modal
  // =====================================================




  useEffect(() => {
    const element = document.getElementById(chartId);
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);


  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>

        {/* end::Header */}

        <div
              id={chartId}

            ></div>
      </div>
      {/* end::Tiles Widget 1 */}


    </>
  );
}


function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [{
      name: 'Series 1',
      data: [80, 50, 30, 40, 100, 20],
    }],


    chart: {
      height: "100%",
      type: 'radar',
    },
    // title: {
    //   text: 'Basic Radar Chart'
    // },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June']
    }




  };
  return options;
}
