/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, Component, useState } from "react";
import ReactDOM from 'react-dom';
import { Tabs, Tab } from 'react-bootstrap';


import axios from "axios";


import { ComponentWrapperSetting } from "./ComponentWrapperSetting";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";



var parseString = require('xml2js').parseString;

export function ComponentWrapper({ el, states, className }) {
  let id = "component" + el.i

  const [content, setContent] = React.useState('');




  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <SlidingPane
          className="sliding-pane-contents"
          overlayClassName="sliding-pane-overlay"
          from="right"
          isOpen={modalShow}
          onRequestClose={() => {
            setModalShow(false);
          }}
        >


          <ComponentWrapperSetting
            parentCallback={selectSetting}
            content={content}
            states={states}
            id={id}
          />

          {/* <SelectSettings
            parentCallback={selectSetting}
            content={content}
          /> */}

        </SlidingPane>
        ,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================






  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  // 서울시 코로나19 확진자 현황
  // http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/Corona19Status/1/5/


  // 서울특별시 코로나19 백신 예방접종 현황
  // http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/tvCorona19VaccinestatNew/1/5/


  // 서울시 문화행사정보 장소명 검색
  // http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/SearchConcertPlaceService/1/50


  // 서울시 고용지표 통계
  // http://data.seoul.go.kr/dataList/59/S/2/datasetView.do


  const url = 'http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/tvCorona19VaccinestatNew/1/5/'



  useEffect(() => {

    axios({
      method: 'get',
      url: url,
      responseType: 'application/xml'
    })
      .then(function (response) {

        parseString(response.data, function (err, result) {
          console.log(result);
          setContent(JSON.stringify(result))
        });

      });
  }, []);


  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">ComponentWrapper</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>

        <div className="card-body d-flex flex-column px-0">

          <div className="flex-grow-1 card-spacer-x">

            ComponentWrapper<br /><br />

            <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
              <Tab eventKey="home" title="Home">
                AAAA
              </Tab>
              <Tab eventKey="profile" title="Profile">
                BBBB
              </Tab>
              <Tab eventKey="contact" title="Contact" disabled>
                CCCC
              </Tab>
            </Tabs>


            {/* {content} */}

          </div>

        </div>

      </div>




    </>

  );
}


