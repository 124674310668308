
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageWorkspace_Add(props) {
    const { parentId, parentCallback, action, selectIndex, menu, workspacesList, ...rest } = props


    let [title, setTitle] = useState("");
    let [content, setContent] = useState("");
    let [image, setImage] = useState("/media/logos/stars.png");



    useEffect(() => {

        if (rest.show == true) {

            console.log("menu > ")
            console.log(menu)

            console.log("workspace > ")
            console.log(workspacesList)

            console.log("action > ")
            console.log(action)
            console.log(selectIndex)


            if (action == "edit") {
                console.log(workspacesList[selectIndex].title)
                setTitle(workspacesList[selectIndex].title)
                setContent(workspacesList[selectIndex].content)
                setImage(workspacesList[selectIndex].image)

            } else if (action == "add") {
                setTitle("")
                setContent("")
                setImage("")
            }

            // console.log("allowMenuList > ")
            // console.log(allowMenuList)
        }

    }, [rest.show]);

    const btnStyle = {
        margin: "2px",
    }




    const changeTitle = (e) => {

        setTitle(e.target.value)
    }


    const changeContent = (e) => {

        setContent(e.target.value)
    }

    const changeImage = (e) => {
        console.log(">> changeImage")
        console.log(e.target.value)

        // TODO : 
        // 업로드 후 서버 주소를 불러와서 setImage 에 넣어준다.

        // image_serverPath
        // setImage(image_serverPath)

        // 일단 이렇게..
        setImage(e.target.value) // error : not allowed to load local resource

    }


    if (selectIndex == -1) return <></>



    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(action == "add") ? <>워크스페이스 관리 추가</> : <>워크스페이스 관리 수정</>}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>워크스페이스 명*</Form.Label>

                        {(action == "add") ?
                            <Form.Control placeholder="제목을 입력해주세요" onChange={changeTitle} /> :
                            <Form.Control placeholder="제목을 입력해주세요" onChange={changeTitle} value={title}  />}
                    </Form.Group>

                    <Form.Group id="formUserId2">
                        <Form.Label>내용</Form.Label>
                        {(action == "add") ?
                            <Form.Control placeholder="내용을 입력해주세요" onChange={changeContent} /> :
                            <Form.Control placeholder="내용을 입력해주세요" onChange={changeContent} value={content||""} />}
                    </Form.Group>


   

                    <Form.Group id="formUserId4">
                        <Form.Row style={{ marginBottom: "10px" }}>
                            <Col>
                               
                                <img style={{width:"100px",height:"60px"}} src={image} />

                            </Col>

                            <Col>

                                <input style={{ bottom: "0px", position: "absolute" }} type="file" onChange={changeImage}/>
                                {/* <Button style={{ bottom: "0px", position: "absolute" }} onClick={() => changeImage} variant="primary">
                                    업로드
                                </Button> */}
                            </Col>
                        </Form.Row>
                    </Form.Group>

                    {(action == "add") ?
                        <Button style={btnStyle} size='sm' onClick={() => {
                            let payload = {
                                "action": "add",
                                // "workspaceIdx": menu[0].workspaceIdx,
                                "title": title,
                                "content": content,
                                "image": image

                            }
                            parentCallback(payload)
                            rest.onHide()
                        }}> 추가</Button>
                        :
                        <Button style={btnStyle} size='sm' onClick={() => {
                            let payload = {
                                "action": "edit",
                                "_id": workspacesList[selectIndex]._id,
                                // "workspaceIdx": workspacesList[selectIndex].workspaceIdx,
                                "title": title,
                                "content": content,
                                "image": image

                            }

                            parentCallback(payload)
                            rest.onHide()
                        }}> 저장</Button>
                    }


                </Form>



            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}