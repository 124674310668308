// =============================================================================
// [E] 키워드 추출 class
// =============================================================================
import React from 'react';

/** 2차원 배열 생성 메소드 */
Array.prototype.division = function(number) {
    var arr = this;
    var length = arr.length;
    var count = Math.floor(length / number) + (Math.floor(length % number) > 0? 1: 0);
    var temp = [];

    for(var i=0; i<count; i++){
        temp.push(arr.splice(0,number));
    }

    return temp;
}


//** 키워드 정규식 함수 */
const reg_function = (param) => {
    let filter_data;

    filter_data = param.replaceAll("⚡", "").split("data");
    filter_data = filter_data.map(data => data.trim());
    filter_data = filter_data[1].replaceAll('"', '');
    filter_data = filter_data.replaceAll(':', '');
    filter_data = filter_data.replaceAll('}', '');
    filter_data = filter_data.replaceAll(']', '');
    filter_data = filter_data.split(',');

    return filter_data;
}

export class filter_class{
    
    constructor(prev_data) {
        this.data = prev_data;
    }

    //** keyword_list object 추출 메소드 */
    keyword_method(){
        
        const user_device = window.innerWidth;

        const { keyword_list } = this.data;

        const history_keyword = keyword_list[0];

        const reg_result = reg_function(history_keyword.filter[0].rule);

        reg_result.unshift('전체');
        
        const result_arr = user_device <= 500 ? reg_result.division(3) : reg_result.division(5);

        return result_arr;
    }

    //** filter object 추출 메소드 */
    filter_method(){
        const { filter } = this.data;

        const reg_result = reg_function(filter);
        
        const filter_data = reg_result.map(data => data.trim());

        const keyword_view = filter_data.map((data, index)=>
            <option key={index} value={data}>{data}</option>
        )

        return keyword_view;
    }
}