
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';
// import { shallowEqual, useDispatch, useSelector } from "react-redux";


export function CrawlingList_Add(props) {

    // const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);

    const { parentId, parentCallback, ...rest } = props

    const [template, setTemplate] = useState("");
    
    useEffect(() => {
        if (rest.show == true) {
        }

    }, [rest.show]);


    const changeTemplate = (e) => {
        setTemplate(e.target.value)
    }

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Template 추가
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                <Form.Group id="formMemo">
                        <Form.Label>template*</Form.Label>
                        <Form.Control  as="textarea" rows={30} placeholder="template 입력해주세요" onChange={changeTemplate} value={template || ''} />
                    </Form.Group>



                    <Button size='sm' onClick={() => {

                        if (template.length < 20) {
                            alert("template 입력해주세요")
                            return
                        }



                        try {

                            let objTpl = JSON.parse(template)

                            if(objTpl.title == undefined){
                                alert("title")
                                return
                            }

                            if(objTpl.rule == undefined){
                                alert("rule")
                                return
                            }

                            if(objTpl.selector == undefined){
                                alert("selector")
                                return
                            }

                            if(objTpl.items == undefined){
                                alert("items")
                                return
                            }



                            console.log(objTpl.title)
                            console.log(objTpl.rule)
                            console.log(objTpl.selector)
                            console.log(objTpl.items)

                            delete objTpl._id

                            parentCallback(objTpl)
                            rest.onHide()
                        } catch (e) {

                            alert("parsing error")

                        }
                     
                     
                    }}> 추가</Button>


                </Form>




            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    rest.onHide()
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}