/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';


import { actionTypes } from "../../../../redux/actionType";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Table, Button, Form, InputGroup, Col, Row, Overlay, Tooltip, OverlayTrigger, ButtonToolbar } from "react-bootstrap";
import { ControlUserSetting } from "./ControlUserSetting";



export function ControlUser({ el, states, className }) {
  let id = "component" + el.i

  const [addRole, setAddRole] = useState('guest');
  const [addName, setAddName] = useState('');

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const modl = useSelector((state) => state.modl, shallowEqual);
  const workspaces = (modl == undefined || modl.workspaces == undefined) ? [] : modl.workspaces
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <ControlUserSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });


  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================







  let currentRole = 'guest'
  workspaces.map(ws => {
    if ('workspace' + ws.workspaceIdx == currentWorkspace) {
      currentRole = ws.role
    } else {
      return (<>없음</>)
    }
  })


  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  const addUser = () => {

    if (currentRole != 'admin') {
      alert('admin 만 유저 초대 가능')
      return
    }

    if (addName.length < 2) {
      console.log('too short!')
      return
    }

    console.log(addRole);
    console.log(addName);

    dispatch({ type: actionTypes.InviteUser, payload: { workspaceIdx: currentWorkspace.replace('workspace', ''), email: addName, role: addRole, workspaceUserEmail: user.email, workspaceUserName: user.name } })

  }

  const handleChangeAddRole = (e) => {
    setAddRole(e.target.value)
  }

  const handleChangeAddName = (e) => {
    setAddName(e.target.value)
  }


  const roleStyle = {
    width: "200px",
    left: 0

  };




  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>





        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">


              <div className="font-weight-bolder">INVITE USER</div>


              {/* 
              <ButtonToolbar>
                <OverlayTrigger
                  placement='left'
                  overlay={
                    <Tooltip id={`tooltip-right`} >


                    </Tooltip>
                  }
                >
                  <div className="font-weight-bolder">CONTROL USER</div>

                </OverlayTrigger>
              </ButtonToolbar> */}



              <div className="font-size-sm text-muted mt-2">내 workspace 에 다른 친구를 초대해주세요</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">

            {(currentRole != 'admin') ? "not admin" : (

              <Form>
                <Form.Row>
                  <Form.Group as={Col} id="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={addName} placeholder="Enter email" onChange={handleChangeAddName} />
                  </Form.Group>

                  <Form.Group as={Col} id="formGridState">
                    <Form.Label>Role</Form.Label>
                    <Form.Control as="select" value={addRole} onChange={handleChangeAddRole}>
                      <option value="guest">Guest</option>
                      <option value="member">Member</option>
                      <option value="admin">Admin</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} id="formGridState">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Group>
                      <Button onClick={addUser} variant="primary">초대하기</Button>
                    </Form.Group>

                  </Form.Group>
                </Form.Row>
              </Form>

            )}


            {/* https://jess2.tistory.com/36 */}
            {/* 
            {
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {selector["workspaces"].map((ws, index) =>
                    <tr key={index}>
                      <td>{index}</td>
                      <td>
                        유저명유저명유저명유저명유저 {(selector["modl"]["currentWorkspace"] == ("workspace" + ws.workspaceIdx)) ? "(현재)" : ""}
                        <div className="font-size-sm text-muted mt-2">내 workspace 에 다른 친구를 초대해주세요</div>

                      </td>

                      <td>
                        <Form.Control as="select">
                          <option>Guest</option>
                          <option>Member</option>
                          <option>Admin</option>
                        </Form.Control>

                      </td>
                      <td>
                        <Button variant="outline-primary" onClick={changeWorkspace.bind(this, index)}>저장</Button>&nbsp;
                        <Button variant="outline-danger" onClick={changeWorkspace.bind(this, index)}>삭제</Button>
                      </td>
                    </tr>

                  )
                  }

                </tbody>
              </Table>

            } */}

          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

    </>
  );
}

