// =====================================================
// [E] SweetAlert class
// =====================================================

import Swal from "sweetalert2";


export class Sweetalert_class {
    constructor(icon, title, text) {
        this.icon = icon;
        this.title = title;
        this.text = text;
    }

    // Api 실패시 지정된 alert
    designated_alert(){
        const alert_variable = Swal.fire({
            icon: `error`,
            title: `네트워크 오류!`,
            html: `관리자에게 문의해주세요.`
        });

        return alert_variable;
    }

    // custom alert
    crate_alert(){
        const alert_variable = Swal.fire({
            icon: `${this.icon}`,
            title: `${this.title}`,
            html: `${this.text}`
        });

        return alert_variable;
    }

    // custom confirm alert
    confirm_alert(){
        const confirm = Swal.fire({
            title: `${this.title}`,
            html: `${this.text}`,
            icon: `${this.icon}`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '확인',
            cancelButtonText: '취소'
        }).then((result) => {
            if (result.isConfirmed) {
              return true;
            }
        });

        return confirm;
    }
}