/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';


import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../_helpers";

import { actionTypes } from "../../../../redux/actionType";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Form, InputGroup, Col, Row, Overlay, Tooltip, OverlayTrigger, ButtonToolbar } from "react-bootstrap";
import { ControlMenuSetting } from "./ControlMenuSetting";


import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sortly, { ContextProvider, useDrag, useDrop as sortlyDrop, ItemData, DragObject, add, remove, findDescendants, ID, useIsClosestDragging, ItemRendererProps, findParent } from 'react-sortly';
import update from 'immutability-helper';
import { makeStyles, useTheme } from '@material-ui/styles';



export function ControlMenu({ el, states, className }) {
  let id = "component" + el.i

  const [addRole, setAddRole] = useState('workspace');
  const [addName, setAddName] = useState('');
  const [delMenuValue, setDelMenuValue] = useState('menu1');

  const dispatch = useDispatch();
  // const user = useSelector((state) => state.auth.user, shallowEqual);










  const [menuPrivate, setMenuPrivate] = useState([]);
  const [menuWorkspace, setMenuWorkspace] = useState([]);
  const [menuPublic, setMenuPublic] = useState([]);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const modl = useSelector((state) => state.modl, shallowEqual)

  const currentMenu = useSelector((state) => state.modl.currentMenu, shallowEqual);
  const menuSelector = useSelector(state => state.modl[currentMenu], shallowEqual);



  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual);
  const workspaces = useSelector((state) => state.modl.workspaces, shallowEqual);

  // const location = useLocation();

  useEffect(() => {

    console.log(":: CONVERT DEPTH LIST TO NESTED MENU")

    for (let i in menuSelector) {
      if (menuSelector[i].type == 'private') {
        //setMenuPrivate(menuSelector[i].menu)

        let convertedArr = []
        menuSelector[i].items.map((item, index) => {

          let itemCopy = JSON.parse(JSON.stringify(item))

          // console.log(itemCopy.title)
          // console.log(itemCopy.depth)

          if (itemCopy.depth == 0) {
            convertedArr.push(itemCopy)
          } else if (itemCopy.depth == 1) {

            let lastElement1 = convertedArr[convertedArr.length - 1]
            if (lastElement1.menu == undefined) {
              lastElement1.menu = []
            }
            lastElement1.menu.push(itemCopy)

          } else if (itemCopy.depth == 2) {

            let lastElement1 = convertedArr[convertedArr.length - 1]
            let lastElement2 = lastElement1.menu[lastElement1.menu.length - 1]
            if (lastElement2.menu == undefined) {
              lastElement2.menu = []
            }
            lastElement2.menu.push(itemCopy)

          }
        })

        setMenuPrivate(convertedArr)

      } else if (menuSelector[i].type == 'workspace') {
        // setMenuWorkspace(menuSelector[i].menu)

        let convertedArr = []
        menuSelector[i].items.map((item, index) => {

          let itemCopy = JSON.parse(JSON.stringify(item))

          if (itemCopy.depth == 0) {
            convertedArr.push(itemCopy)
          } else if (itemCopy.depth == 1) {

            let lastElement1 = convertedArr[convertedArr.length - 1]
            if (lastElement1.menu == undefined) {
              lastElement1.menu = []
            }
            lastElement1.menu.push(itemCopy)

          } else if (itemCopy.depth == 2) {

            let lastElement1 = convertedArr[convertedArr.length - 1]
            let lastElement2 = lastElement1.menu[lastElement1.menu.length - 1]
            if (lastElement2.menu == undefined) {
              lastElement2.menu = []
            }
            lastElement2.menu.push(itemCopy)

          }
        })

        setMenuWorkspace(convertedArr)


      }
    }
  }, [menuSelector]);



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <ControlMenuSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================






  if (currentMenu == null || menuSelector == null || currentWorkspace == null || workspaces == null) return (<></>)

  let currentRole = 'guest'
  workspaces.map(ws => {
    if ('workspace' + ws.workspaceIdx == currentWorkspace) {
      currentRole = ws.role
    } else {
      return (<>없음</>)
    }
  })


  // const getMenuItemActive = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  //     ? ` ${!hasSubmenu &&
  //     "menu-item-active"} menu-item-open menu-item-not-hightlighted`
  //     : "";
  // };


  let menuSetStyle = {
    fontSize: "1.2rem",
    color: "mediumpurple" //mediumpurple
  }






  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };

  const delMenu = () => {
    // console.log(delMenuValue);
  }

  const handleChangeDelMenu = (e) => {
    setDelMenuValue(e.target.value)
  }

  const addMenu = () => {
    if (currentRole != 'admin' && currentRole != 'superadmin') {
      alert('admin 만 메뉴 생성/이동/삭제 가능')
      return
    }

    if (addName.length < 2) {
      console.log('too short!')
      return
    }


    // console.log(addRole);
    // console.log(addName);
    // console.log(states);

    dispatch({ type: actionTypes.InsertMenu, payload: { menu: modl[currentMenu], menuName: addName, menuRole: addRole } })

  }

  const handleChangeAddRole = (e) => {
    setAddRole(e.target.value)
  }

  const handleChangeAddName = (e) => {
    setAddName(e.target.value)
  }



  // ==============================================================================================
  // [S] NEW LAYOUT
  // ==============================================================================================



  const useStyles = makeStyles({

    root: (props) => ({
      color: props.muted ? 'red' : 'inherit',
      zIndex: props.muted ? 1 : 0,
      // height:  props.data.collapsed && props.data.type === 'file' ? 0 : 'auto',
      // height: props.data.collapsed && ((clickedId==0)?true:(clickedId != props.data.id)) && props.data.depth != 0 ? 0 : 'auto',
      height: props.data.depth == 0 || props.data.visible ? 'auto' : 0,
      overflow: 'hidden',
    }),
  });





  // ==============================================================================================
  // ItemRenderer
  // ==============================================================================================
  const ItemRenderer = (props) => {

   

    const { data: { id, type, title, path, icon, collapsed, visible, childCount, depth }, onToggleCollapse, onChangeMenuTitle, onChangeMenuIcon, onRemoveMenu } = props;
    const [{ isDragging }, drag] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const [, drop] = sortlyDrop();



    // console.log(visible)

    const clickMenu = () => {

      // console.log("ItemRenderer > handleClick")
      // if (type === 'file') {
      //   return;
      // }
      onToggleCollapse(id);

    };



    const changeMenuIcon = (e) => {

      // console.log("ItemRenderer > changeMenuIcon")
      // console.log(id)
      // console.log(e.target.value)

      onChangeMenuIcon(id, e.target.value)

    };


    const changeMenuTitle = (e) => {

      // console.log("ItemRenderer > changeMenuTitle")
      // console.log(id)
      // console.log(e.target.value)


      onChangeMenuTitle(id, e.target.value)

    };

    const removeMenu = (e) => {

      // console.log("ItemRenderer > removeMenu")
      // console.log(id)

      onRemoveMenu(id)

    };

    // console.log(props.data.title + " >> " + props.data.collapsed + " >> " + props.data.visible)
    // console.log(props.data.title + " >> " + props.data.childCount)

    // STATE ..
    const classes = useStyles({
      ...props,
      depth,
      muted: useIsClosestDragging() || isDragging,
    });

    return (

      <>
        <li
          id={id}
          key={id}
          ref={(ref) => drop(ref)}
          className={`menu-item menu-item-submenu ${classes.root}`}
          aria-haspopup="true"
          onClick={clickMenu}
          style={{ marginLeft: depth * 30 }}
        // data-menu-toggle={(value1.menu !== undefined) ? "hover" : ""}
        >

          <div
            ref={drag}
            className='menu-link menu-toggle'
          >


            <span className="svg-icon menu-icon">
              {(icon !== undefined && icon !== "") ? (
                <SVG src={toAbsoluteUrl(icon)} />
              ) : (
                <span></span>
              )}
            </span>



            {/* <span className="menu-text" style={{ height: "100%" }}>{title} */}
            
            <input type="text" style={{ "borderWidth": "0px", "border": "none" }} placeholder="" defaultValue={title} onBlur={changeMenuTitle} />
            {visible?.toString()}

            <select onChange={changeMenuIcon} value={icon}>
              <option value="">NO IMG</option>
              <option value="/media/svg/icons/General/Bullet.svg">Bullet</option>
              <option value="/media/svg/icons/Home/Book.svg">Book</option>
              <option value="/media/svg/icons/Food/Beer.svg">Beer</option>
              <option value="/media/svg/icons/Food/Cake.svg">Cake</option>
              <option value="/media/svg/icons/General/Clip.svg">Clip</option>
              <option value="/media/svg/icons/General/Folder.svg">Folder</option>

            </select>
            {(visible==true)?(<span onClick={removeMenu} >[삭제]</span>):(<span onClick={removeMenu} >[삭제(HIDDEN)]</span>)}

            {/* </span> */}


            {(childCount !== 0) ? <i className="menu-arrow" /> : <></>}
          </div>

        </li>
      </>

    )
  };




  // ==============================================================================================
  // MenuSet
  // ==============================================================================================

  const MenuSet = (props) => {

    // console.log(props)

    const [{ isOver, dragItem }, drop] = useDrop({
      accept: "menu",
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        dragItem: monitor.getItem(),
      }),
    });


    React.useEffect(() => {
      if (dragItem) {
        handleMove();
      }
    }, [dragItem, isOver, handleMove]);


    const handleMove = React.useCallback(() => {

      // console.log("handleMove")

      if (!dragItem) {
        return;
      }

      if (isOver) {
        props.onEnter(dragItem);
      }
    }, [dragItem, isOver, props.onEnter]);



    const handleChangeMenuTitle = (id, title) => {

      // console.log("> handleChangeMenuTitle")
      // console.log(id)
      // console.log(title)

      let items = props.items

      const index = items.findIndex((item) => item.id === id);

      const updateFn = {
        [index]: { title: { $set: title } },
      };

      props.onUpdate(updateFn);

    };


    const handleChangeMenuIcon = (id, icon) => {

      // console.log("> handleChangeMenuIcon")
      // console.log(id)
      // console.log(icon)

      let items = props.items

      const index = items.findIndex((item) => item.id === id);

      const updateFn = {
        [index]: { icon: { $set: icon } },
      };

      props.onUpdate(updateFn);



    };


    const handleRemoveMenu = (id) => {

      // console.log("> handleRemoveMenu")
      // console.log(id)

      let items = props.items

      const index = items.findIndex((item) => item.id === id);
      // console.log("index: " + index)
      const updateFn = index;

      props.onRemove(updateFn);



    };



    const handleToggleCollapse = (id) => {

      // console.log("handleToggleCollapse")

      // let items = props.items

      // const index = items.findIndex((item) => item.id === id);
      // const item = items[index];

      // const { collapsed } = item;
      // const descendants = findDescendants(items, index)


      //=============================================================================================
      // console.log(collapsed)
      // console.log(descendants)
      // console.log(items)

      // const updateFn = {
      //   [index]: { title: { $set: "true" } },
      // };


      // // 부분열기
      // const updateFn = {
      //   [index]: { collapsed: { $set: !collapsed }, visible: { $set: true } },
      // };

      // // 1. 전체 열기
      // items.forEach((item,index) => {
      //   // const descendantIndex = items.indexOf(descendant);
      //   updateFn[index] = { collapsed: { $set: true }, visible: { $set: true } };
      // });

      // // 2. collapsed 유지 
      // console.log("ROOT collapsed : " + !collapsed)
      // descendants.forEach((descendant, index) => {
      //   const descendantIndex = items.indexOf(descendant);
      //   let depthCursor = descendant.depth

      //   // console.log("==============")
      //   // console.log(":: " + descendant.title + " - Depth: " + depthCursor)

      //   // 상위체크
      //   let isVisible = collapsed
      //   for (let i = index; i >= 0; i--) {
      //     if (depthCursor > descendants[i].depth) {
      //       // console.log("check >> " + descendants[i].title + " >> " + descendants[i].collapsed)
      //       if (descendants[i].collapsed) { isVisible = false }
      //       depthCursor = depthCursor - 1
      //     }
      //   }

      //   // console.log(":: " + descendant.title + " - isVisible: " + isVisible)
      //   updateFn[descendantIndex] = { visible: { $set: isVisible } };
      // });

      //=============================================================================================
      // props.onUpdate(updateFn);







    };


    return (

      // ${layoutProps.ulClasses}

      <ul id={`items${props.id}`} ref={drop} className={`menu-nav`}>
        <li className="menu-section " key={Math.random()}><h5 style={menuSetStyle} className="menu-text">{props.type}</h5> </li>

        <Sortly
          type="menu"
          maxDepth={2}

          items={props.items}
          onChange={props.onChange}  >
          {(props) => <ItemRenderer {...props} onToggleCollapse={handleToggleCollapse} onChangeMenuTitle={handleChangeMenuTitle} onChangeMenuIcon={handleChangeMenuIcon} onRemoveMenu={handleRemoveMenu} />}
        </Sortly>

      </ul>
    );
  };



  // ==============================================================================================
  // MultipleTree
  // ==============================================================================================
  const MultipleTree = () => {
    // console.log(">> MultipleTree >  ")

    const [categories, setCategories] = React.useState(menuSelector);


    // 하위 폴더 카운트
    React.useEffect(() => {
      let updateArr = {}
      categories.map((category, index) => {
        const updateFn = {};
        let items = category.items
        items.forEach((descendant, i) => {
          const descendants = findDescendants(items, i)
          updateFn[i] = { childCount: { $set: descendants.length }, collapsed: { $set: true }, visible: { $set: true } };
        });
        updateArr[index] = { items: updateFn }
      })

      setCategories(update(categories, updateArr));

    }, [categories]);



    const handleRemove = (index) => (updateFn) => {

      // console.log("MultipleTree > handleRemove")
      // console.log(index)
      // console.log(updateFn)
      // console.log(updateFn.index)



      setCategories(update(categories, {
        [index]: {
          items: { $set: remove(categories[index].items, updateFn) },
        },

      }));



      // console.log(categories[index])
    };




    const handleUpdate = (index) => (updateFn) => {

      // console.log("handle Update")

      setCategories(update(categories, {
        [index]: { items: updateFn },
      }));

      // console.log(categories[index])
    };




    const handleChange = (index) => (newItems) => {
      setCategories(update(categories, {
        [index]: { items: { $set: newItems } },
      }));
    };


    const handleEnter = (targetCategoryIndex) => (dragItem) => {

      // console.log("handle Enter")

      const sourceCategoryIndex = categories.findIndex((category) => (
        category.items.some((item) => item.id === dragItem.id)
      ));
      const sourceCategory = categories[sourceCategoryIndex];
      const targetCategory = categories[targetCategoryIndex];

      if (targetCategory.items.some((item) => item.id === dragItem.id)) {
        return;
      }

      const sourceItemIndex = sourceCategory.items.findIndex((item) => item.id === dragItem.id);
      const sourceItem = sourceCategory.items[sourceItemIndex];
      const sourceDescendants = findDescendants(sourceCategory.items, sourceItemIndex);
      const items = [sourceItem, ...sourceDescendants].map((item) => ({ ...item, categoryId: targetCategoryIndex /*targetCategory.id*/ }));

      setCategories(update(categories, {
        [sourceCategoryIndex]: {
          items: { $set: remove(sourceCategory.items, sourceItemIndex) },
        },
        [targetCategoryIndex]: {
          items: { $set: add(targetCategory.items, items) }
        },
      }));
    };





    const saveMenu = () => {


      if (currentRole != 'admin' && currentRole != 'superadmin') {
        alert('admin 만 메뉴 생성/이동/삭제 가능')
        return
      }


      console.log("==== categories SAVE");

      console.log(categories);
      // console.log(states);

      dispatch({ type: actionTypes.UpdateMenu, payload: { menu: categories } })

    }


    

    if (categories == undefined) return (<></>)

    return (
      <>
        {
        categories.map(({ id, title, visible, type, items }, index) => (
          <MenuSet
            id={id}

            key={Math.random()}
            type={type}
            title={title}
            visible={visible}
            items={items}
            onChange={handleChange(index)}
            onEnter={handleEnter(index)}
            onUpdate={handleUpdate(index)}
            onRemove={handleRemove(index)}



          />
        ))}

        <br />
        <Button onClick={saveMenu} variant="primary">적용하기</Button>
      </>
    );
  };



  // ==============================================================================================
  // END
  // ==============================================================================================
  // return (
  //   <>
  //     <DndProvider backend={HTML5Backend}>
  //       <ContextProvider>
  //         <MultipleTree />
  //       </ContextProvider>
  //     </DndProvider>
  //   </>
  // )

  // ==============================================================================================
  // [E] NEW LAYOUT
  // ==============================================================================================













  return (
    <>



      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">ADD MENU</div>
              <div className="font-size-sm text-muted mt-2">메뉴 컨트롤 테스트</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">


            {(currentRole != 'admin' && currentRole != 'superadmin') ? "not permission" : (

              <Form>
                <Form.Row>
                  <Form.Group as={Col} id="formGridEmail">
                    <Form.Label>메뉴명</Form.Label>
                    <Form.Control type="email" placeholder="menu name" onChange={handleChangeAddName} />
                  </Form.Group>

                  <Form.Group as={Col} id="menuRole">
                    <Form.Label>권한</Form.Label>
                    <Form.Control as="select" value={addRole} onChange={handleChangeAddRole} >


                      {/* Public은 추후에 생각 해보자 원래는 guest 들이 볼수있는 메뉴 였다 ... */}
                      {/* <option value="public">Public</option> */}
                      <option value="workspace">Workspace</option>
                      <option value="private">Private</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} id="formGridState">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Group>
                      <Button onClick={addMenu} variant="primary">추가하기</Button>
                    </Form.Group>

                  </Form.Group>
                </Form.Row>

              </Form>

            )}


            <DndProvider backend={HTML5Backend}>
              <ContextProvider>
                <MultipleTree />
              </ContextProvider>
            </DndProvider>

          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}



    </>




  );
}

