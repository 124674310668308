/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef } from "react";
import ChartCommon from "../edit/WebBuilder/Element/ChartCommon";
import { useSelector } from "react-redux";
import data_icon from './style/img/dataicon.png';
import back_data from './style/img/back_data_img_new.png';
import KiciTodayData from "../../func-component/KiciTodayData";
import * as _ from "lodash";
import {
  Col,
  Container,
  Row,
  Form
} from "react-bootstrap";
import KiciDateInput from "../../func-component/KiciDateInput";
import RenderingHooks from "./customHooks/RenderingHooks";
import './style/kici.scss';
import './style/mobile.scss';
import ChartKeyword from "../edit/WebBuilder/Element/ChartKeyword";
import KiciUpdateToday from "../../func-component/KiciUpdateToday";
import Loading from "../../func-component/Loading";


export function KICI_Dashboard_Admin({ className }) {

  const { chartData, chartList, recentCrawling } = useSelector((state)=>state.chart);

  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================

  const [firstRun, setFirstRun] = useState(true);
  const [values, setValues] = useState({});
  const [data, setData] = React.useState("");
  const [layout, setLayout] = React.useState();

  
  // =====================================================
  // [S] Handle Event (handleEvent)
  // =====================================================

  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  let field1 = "7J1kudaO9"
  let field2 = "ZRd_IEk7j"


  // =====================================================
  // [S] FOR FCM REGIST
  // =====================================================
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control type="text" onClick={onClick} ref={ref} value={value} readOnly style={{ width: "120px" }} />
  ));


  var handleEventObj = {
    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다 
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      setValues(previousState => ({ ...previousState, [id]: value }));
      return true
    },
    test: function (method, id, ...value) {
      return true
    }
  };


  const handleEvent = (method, id, ...value) => {
    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }
  }


  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드 
  // =====================================================
  const { today_collect_date } = RenderingHooks();


  // =====================================================
  // [S] 반응형을 위한 변수 할당
  // =====================================================
  const lanking_component = {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`
  };

  return (
    <>
      <Container fluid className={"p-0"}>
        <div className="col-xs-12 col-md-12 mb-5 dashboard_title">
          <div className={`card card-custom ${className}`}>
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                대시보드(Admin)
              </h3>
              <div className="d-flex align-items-center flex-wrap input_box" style={{ padding : "10px 0" }}>
                <KiciDateInput />
              </div>
            </div>
          </div>
        </div>
        <Row key={Math.random()} >
          <Col className="mb-5 col-xxl-8" xs={12} sm={12} md={8} lg={7} xl={8}>
            <div className={`card card-custom ${className} kici_custom_card`} >
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  데이터 수집량
                </h3>
              </div>
              <div className="card-body pt-2 kici_custom_card_body chart_admin">
                { chartData && <ChartCommon chartData={chartData} type={"dashboard"}/> }
              </div>
            </div>
          </Col>
          <Col className="mb-5 col-xxl-3" xs={12} sm={12} md={4} lg={5} xl={4} style={lanking_component}>
            <div className="kici_side_wrap">
              <KiciUpdateToday recentCrawling={recentCrawling} />
              <KiciTodayData today_collect_date={today_collect_date} />
              <div className="today_collect_data">
                <div>
                  <h3>오늘 수집한 데이터</h3>
                </div>
                <div className="update_collect_wrap">
                  <span>
                    <img src={data_icon} alt="눈 모양 아이콘"/>
                  </span>
                  <span className="update_collect_count">{today_collect_date ? today_collect_date : <Loading color={'#3a427a'} size={48} />}</span>
                </div>
                <img className="bg_image" src={back_data} alt="백그라운드 배경"/>
              </div>
            </div>
          </Col>
        </Row>
        <Row key={Math.random()}  >
          <Col className="mb-5 col-xxl-5" xs={12} md={6} lg={6} xl={6}>
            <div className={`card card-custom ${className} kici_custom_card`}>
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  전체내역
                </h3>
              </div>
              <div className="card-body pt-4 kici_custom_card_body">
                <div className="kici_timeline_wrap">
                  {chartList ? chartList : <Loading color={"#3a427a"} size={48} />}
                </div>
              </div>
            </div>
          </Col>
          <Col className="mb-5 col-xxl-6" xs={12} md={6} lg={6} xl={6}>
            <div className={`card card-custom ${className} kici_custom_card keyword_grid_wrap`}>
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  키워드 집계
                </h3>
              </div>
              <div className="card-body pt-4 keyword_chart_wrap kici_custom_card_body">
                <ChartKeyword />
              </div>
            </div>
          </Col>
        </Row>
      </Container >
    </>
  );
}
