import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Layout, useSubheader } from "../../_wise/templates/type1/layout";
import RGL, { WidthProvider, Responsive } from "react-grid-layout";
import _, { uniqueId } from "lodash";
import { v4 as uuidv4 } from 'uuid';

import axios from "axios";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/modlActions";
import SVG from "react-inlinesvg";

import * as redux from "../../redux";

import store from "../../redux/store";


import { ComponentLoader } from "./ComponentLoader";
import { ComponentSelector } from "./ComponentSelector";
import { actionTypes } from "../../redux/actionType";

import Icon from 'react-icons-wrapper'
import { isThisHour } from "date-fns";
import { isThisISOWeek } from "date-fns";

import { Button } from 'react-bootstrap';
import { FixedSizeGrid } from "react-window";
import { prev } from "cheerio/lib/api/traversing";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import { lightBlue } from "@material-ui/core/colors";
import "./page.scss";


import { ResizeComponent } from "../../_wise/_partials/widgets/edit/ResizeComponent"
import { Banner } from "../../_wise/_partials";
import { el } from "date-fns/locale";

const ResponsiveReactGridLayout = WidthProvider(Responsive);



// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});






export function GridPage(props) {

    const [modalShow, setModalShow] = React.useState(false);

    const [state, setState] = useState([getItems(5), getItems(2, 5)]);

    // ==========================================================================================================
    // 컴포넌트에서 활용 해야 될 데이터들 ... 집합

    const dispatch = useDispatch();
    const layoutRef = useRef(null);




    let pageData = useSelector((state) => state.modl["page" + props.id], shallowEqual)

    let updatePageFlag = useSelector((state) => state.modl.updatePageFlag, shallowEqual)

    // let updatePageFlag = useSelector((state) => state.updatePageFlag)

    let isLoaded = false

    // console.log('GridPage >')
    // console.log(updatePageFlag)

    // console.log(">>> store.getState <<<")
    // console.log(store.getState())
    // ==========================================================================================================

    useEffect(() => {

        const SELECTED_WORKSPACE_IDX_KEY = "SelectedWorkspaceIdx";
        localStorage.setItem(SELECTED_WORKSPACE_IDX_KEY, "");

        // console.log("props.id : " + props.id)

        let payload = {
            "pageIdx": props.id,
        }

        //saga style
        dispatch({ type: actionTypes.GetPage, payload: payload })

        //default style
        // dispatch(actions.getPage(payload)).then((res) => {
        //     console.log('== getPage Finish')
        // });
    }, []);




    let modal = ''
    useEffect(() => {
        // TODO: props.id 로 조회된 페이지의 스펙에 따라
        // GRID시스템을 이용하여 원하는 컴포넌트를 적용한다.
        if (pageData != undefined) {
            // console.log('== getPage useEffect ')

            isLoaded = true

            // import("./GridHook.jsx").then(fn => { fn.default(AddRemoveLayout, layoutRef, props.id, pageData) });
        }
    }, [(pageData && !isLoaded), updatePageFlag]);



    if (pageData == undefined) {
        return (<></>)
    } else {


    }



    const selectComponent = (data) => {

        // console.log("selectComponent > layoutRef")
        // console.log(layoutRef)

        layoutRef.current.onAddItem(data)
    };


    const onLayoutChange = layout => {
        // console.log(">>v onLayoutChange")
        // console.log(layout)
        // this.setState({ layout: layout });
    };

    const stringifyLayout = () => {
        return this.state.layout.map(function (l) {
            const name = l.id === "__dropping-elem__" ? "drop" : l.id;
            return (
                <div className="layoutItem" key={l.id}>
                    <b>{name}</b>
                    {`: [${l.x}, ${l.y}, ${l.w}, ${l.h}]`}
                </div>
            );
        });
    }





    const loadContent = (Layout, layoutRef, pageIdx, pageData) => {

        let content = document.getElementById("content")
        if (content != null) {

            let content = document.getElementById("content")

            // return ReactDOM.render(
            return ReactDOM.createPortal(
                <Layout id="Layout-Id" ref={layoutRef} onLayoutChange={onLayoutChange} pageIdx={pageIdx} pageData={pageData} />
                ,
                content)

        }

    }

 


    return (<>

        {/* ID : {props.id} */}

        {/* {console.log(COMP)} */}
        {/* <Button size="sm" variant="dark" onClick={() => setModalShow(true)}>+ 컴포넌트 추가</Button> */}


        {/* here remove */}
        {/* here remove */}
        {/* <a href="#" className="float" onClick={() => setModalShow(true)}>
            <i className="fa fa-plus my-float"></i>
        </a> */}


        {/* <button onClick={testButton1}>testButton1</button>
        <button onClick={testButton2}>testButton2- change WS</button> */}
        {/* <div className="layoutJSON">
            Displayed as <code>[x, y, w, h]</code>:
            <div className="columns">{stringifyLayout}</div>
        </div> */}
        {/* <div id="content"></div> */}
        {/* {loadContent(AddRemoveLayout, layoutRef, props.id, pageData)} */}


        <AddRemoveLayout id="Layout-Id" ref={layoutRef} onLayoutChange={onLayoutChange} pageIdx={props.id} pageData={pageData} />


        {/* 컴포넌트 MODAL */}
        <div id="contentModal"> </div>


        {/* MODAL */}
        {/* here remove */}
        {/* here remove */}
        {/* <ComponentSelector
            show={modalShow}
            onHide={() => setModalShow(false)}
            parentCallback={selectComponent}
        /> */}




    </>);

};






/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
export default class AddRemoveLayout extends React.PureComponent {


    constructor(props) {
        super(props);

        // console.log('>> class AddRemoveLayout start')
        // console.log(props)

        if (props.pageData == null) {
            // var COMP = require('../datas/Page.json');
        } else {

            // defaultProps = props.pageData.option
            // console.log(defaultProps)

            this.onUpdateAutoHeight = this.onUpdateAutoHeight.bind(this);

            // let test = Object.assign({}, props.pageData.component.map(function (val, index, list) {
            //     return val
            // }))

            // console.log('>> class AddRemoveLayout start2')
            // console.log( props.pageData.component)
            // console.log(test)
            //console.log(props);
            let initLayout = {}
            initLayout["lg"] = props.pageData.component

            this.state = {
                items: initLayout,
                setting: {}
            };

            this.dispatch = store.dispatch

        }

    }


    createElement(el, index) {

        // if(el.i != "8ba3815b382f432b9ce7499fbaa2035f") return (<>SKIP</>)
        // console.log("createElement:" + el.i)
        // console.log(this.state.items)
        console.log("=======================")
        console.log("createElement:" + el.i)
        console.log(this.state.setting)
        return (

            <div id={el.i} key={el.i} data-grid={el} >

                {(el.hasOwnProperty('data') && el.data.hasOwnProperty('source')) ? <ComponentLoader el={el} onUpdateAutoHeight={this.onUpdateAutoHeight} states={this.state.setting} className="className1" /> : "NO SOURCE"}

                {(el.data.hasOwnProperty('settings')) ?

                    <div style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}>

                        {el.data.settings.map((setting) => {

                            // 예약어 (주로 el 형식에 영향을 주는 데이터들 이나 컴포넌트 공통으로 쓰는 기능  )
                            // fix , remove
                            if (setting.key == 'fix') {
                                return (
                                    <>
                                        {/* here remove */}
                                        {/* here remove */}
                                        {/* <span key={Math.random()}
                                            data-toggle="tooltip" data-html="true" title={setting.tooltip}
                                            onClick={this.onFixItem.bind(this, el.i)}
                                        > {(el.isDraggable) ? <Icon name={setting.icon[0]} size={setting.iconSize} color={'lightgray'} /> : <Icon name={setting.icon[1]} size={setting.iconSize} color={'lightblue'} />}
                                        </span> */}
                                    </>
                                )
                            }
                            else if (setting.key == 'remove') {
                                return (
                                    <>
                                        {/* here remove */}
                                        {/* here remove */}
                                        {/* <span key={Math.random()}
                                            data-toggle="tooltip" data-placement="top" title={setting.tooltip}
                                            onClick={this.onRemoveItem.bind(this, el.i)}
                                        ><Icon name={setting.icon[0]} size={setting.iconSize} color={'lightgray'} /> </span> */}
                                    </>
                                )
                            }

                            else {
                                // 예약어 이외의 이벤트 처리 Custom Setting
                                return (
                                    <>
                                        <span key={Math.random()}
                                            data-toggle="tooltip" data-html="true" title={setting.tooltip}
                                            onClick={this.onCustomSettingItem.bind(this, el.i, setting)}
                                        > <Icon name={setting.icon[0]} size={setting.iconSize} color={'lightgray'} /> </span>
                                    </>
                                )

                            }

                        })}


                    </div>
                    : (
                        <div style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}>

                            {/* here remove */}
                            {/* here remove */}

                            {/* <>
                                <span
                                    data-toggle="tooltip" data-html="true" title="fix position"
                                    onClick={this.onFixItem.bind(this, el.i)}
                                > {(el.isDraggable) ? <Icon name={'RiPushpin2Fill'} size={'1em'} color={'lightgray'} /> : <Icon name={'RiPushpin2Fill'} size={'1em'} color={'lightblue'} />}
                                </span>

                                <span
                                    data-toggle="tooltip" data-placement="top" title="remove"
                                    onClick={this.onRemoveItem.bind(this, el.i)}
                                ><Icon name={'IoMdTrash'} size={'1em'} color={'lightgray'} /> </span>
                            </> */}

                        </div>
                    )

                }

            </div>
        );
    }





    onUpdateAutoHeight(id, height) { // parameter : idx

        // console.log("> onUpdateAutoHeight")

        // UPDATE 1 COMPONENT
        let convH = ((height + 25) / (0 + 25)) // itemH = height + 25 / rowHeight + 25 -> 

        // 100% Height 때문에 테스트 필요
        // convH = Math.ceil(convH) // 무조건 정수 올림

        let checkEqualHeight = false
        var updateLayout = _.cloneDeep(this.state.items)
        updateLayout["lg"].map((_ulayout, index) => {
            if (_ulayout.i == id) {
                if (_ulayout.h == convH) {
                    checkEqualHeight = true
                } else {
                    if (_ulayout.maxH < convH || _ulayout.minH > convH) {
                        checkEqualHeight = true
                    } else {
                        _ulayout.h = convH
                    }
                }
            }
        })

        if (!checkEqualHeight) {
            this.setState({
                items: updateLayout
            }, () => {
                // this.dispatch({ type: actionTypes.UpdateComponents, payload: { pageIdx: this.props.pageData._id, component: updateLayout["lg"] } })
            });
        }

    }




    onAddItem(component) {

        // console.log("> onAddItem")
        var updateLayout = _.cloneDeep(this.state.items)
        var componentCopy = _.cloneDeep(component)
        let bigestHeight = 0;

        // for (let i = 0; i < Object.values(tempLayout).length; i++) {
        //     if (tempLayout.hasOwnProperty(i) == true) {
        //         let curBottomHeight = tempLayout[i].h + tempLayout[i].y
        //         bigestHeight = (curBottomHeight > bigestHeight) ? curBottomHeight : bigestHeight
        //         updateLayout.push(tempLayout[i])

        //     }
        // }

        componentCopy.i = uuidv4().split("-").join("")
        componentCopy.y = bigestHeight
        console.log(updateLayout);
        updateLayout["lg"].push(componentCopy)
        // console.log("> updateLayout")
        // console.log(updateLayout)

        // 이부분은 가장 하단에 컴포넌트 추가 기능
        // 원래는 디비에 저장된 위치에 저장 된다 
        this.setState({
            items: updateLayout
        }, () => {
            this.dispatch({ type: actionTypes.UpdateComponents, payload: { pageIdx: this.props.pageData._id, component: updateLayout["lg"] } })
        });

    }



    // ==================================================================
    // 예약 Setting :  fix , remove
    // ==================================================================

    onFixItem(idx) {

        // console.log("> onFixItem")
        // console.log(idx)
        // console.log(this.state.items)

        var updateLayout = _.cloneDeep(this.state.items)
        updateLayout["lg"].map((_ulayout, index) => {
            if (_ulayout.i == idx) {
                _ulayout.isDraggable = !_ulayout.isDraggable
                _ulayout.isResizable = !_ulayout.isResizable
            }
        })
        // console.log(updateLayout)
        this.setState({
            items: updateLayout
        }, () => {
            this.dispatch({ type: actionTypes.UpdateComponents, payload: { pageIdx: this.props.pageData._id, component: updateLayout["lg"] } })
        });
    }


    onRemoveItem(idx) {

        // console.log("> onRemoveItem")

        var updateLayout = _.cloneDeep(this.state.items)
        updateLayout["lg"].map((_ulayout, index) => {
            if (_ulayout.i == idx) {
                _.remove(updateLayout["lg"], _ulayout)
            }
        })

        this.setState({
            items: updateLayout
        }, () => {
            this.dispatch({ type: actionTypes.UpdateComponents, payload: { pageIdx: this.props.pageData._id, component: updateLayout["lg"] } })
        });

    }





    // ==================================================================   
    // Custom Setting : component 안에 key 에 등록후 사용한다.
    // ==================================================================

    onCustomSettingItem(i, evt) {

        let evtStr = `
        {
          "component${i}": {"${evt.key}":"${Math.random()}"}
        }
        `
        let evtObj = JSON.parse(evtStr)

        this.setState({ setting: evtObj });
    }








    // ==================================================================   

    onGetAllItem() {
        // console.log("onGetAllItem");
        // console.log(this.state)
    }

    // We're using the cols coming back from this to calculate where to add new items.
    onBreakpointChange(breakpoint, cols) {
        // console.log("> onBreakpointChange : " + breakpoint + " / " + cols)
    }

    // onResizeStop / onDragStop 후 자동실행 된다 . 
    onLayoutChange = (layout, layouts) => {
        // console.log("S> onLayoutChange")
        // console.log(layouts)
    }

    onResizeStop = (layout) => {
        // console.log("S> onResizeStop layout:")
        // console.log(layout)

        var updateLayout = _.cloneDeep(this.state.items)
        if (updateLayout["lg"] != undefined) {
            updateLayout["lg"].map((_ulayout, index) => {
                layout.map((_layout, index2) => {
                    if (_ulayout.i == _layout.i) {
                        _ulayout.x = _layout.x
                        _ulayout.y = _layout.y
                        _ulayout.h = _layout.h
                        _ulayout.w = _layout.w
                    }
                })
            })
        }

        // console.log(updateLayout)
        // console.log("E> onResizeStop layout:")

        this.setState({
            items: updateLayout
        }, () => {
            this.dispatch({ type: actionTypes.UpdateComponents, payload: { pageIdx: this.props.pageData._id, component: updateLayout["lg"] } })
        });
    }



    onDragStop = (layout, selPrvLayout, selAfLayout) => {
        // console.log("S> onDragStop")

        if (selPrvLayout.x != selAfLayout.x || selPrvLayout.y != selAfLayout.y) {
            var updateLayout = _.cloneDeep(this.state.items)
            if (updateLayout["lg"] != undefined) {
                updateLayout["lg"].map((_ulayout, index) => {
                    layout.map((_layout, index2) => {
                        if (_ulayout.i == _layout.i) {
                            _ulayout.x = _layout.x
                            _ulayout.y = _layout.y
                            _ulayout.h = _layout.h
                            _ulayout.w = _layout.w
                        }
                    })
                })
            }

            // console.log("E> onDragStop")
            // console.log(updateLayout)

            this.setState({
                items: updateLayout
            }, () => {
                this.dispatch({ type: actionTypes.UpdateComponents, payload: { pageIdx: this.props.pageData._id, component: updateLayout["lg"] } })
            });
        }
    }


    render() {
        // console.log(this.state.items)

        return (
            <div style={{ zIndex: 999 }}>
                {(this.state == null) ? "Loading..." : (

                    <ResponsiveReactGridLayout
                        // breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                        // cols={{lg: 120, md: 120, sm: 120, xs: 16, xxs: 16 }}
                        {...this.props.pageData.option}
                        store={store}
                        onDragStop={this.onDragStop}
                        onResizeStop={this.onResizeStop}
                        onLayoutChange={this.onLayoutChange}
                        onBreakpointChange={this.onBreakpointChange}
                        layouts={this.state.items}
                        measureBeforeMount={true}
                    >

                        {_.map(this.state.items["lg"], (el, index) => (el.i != undefined) ? this.createElement(el, index) : <></>)}

                    </ResponsiveReactGridLayout>


                )}
            </div>
        );
    }
}


// ==========================================================================================================
// SETTING DATA STRUCTURE EXAMPLE
// ==========================================================================================================

// "settings" : [
//     {
//         "key": "expand",
//         "tooltip": "expand",
//         "iconSize": "1em",
//         "icon": [
//             "IoIosExpand"
//         ]
//     },
//     {
//         "key": "embed",
//         "tooltip": "embed",
//         "iconSize": "1em",
//         "icon": [
//             "IoMdAttach"
//         ]
//     },

//     {
//         "key": "hold",
//         "tooltip": "hold",
//         "iconSize": "1em",
//         "icon": [
//             "IoLogoSlack"
//         ]
//     },
//     {
//         "key": "fix",
//         "tooltip": "fix",
//         "iconSize": "1em",
//         "icon": [
//             "RiPushpin2Fill",
//             "RiPushpin2Fill"
//         ]
//     },
//     {
//         "key": "setting",
//         "tooltip": "setting",
//         "iconSize": "1em",
//         "icon": [
//             "IoIosSettings"
//         ]
//     },
//     {
//         "key": "remove",
//         "tooltip": "remove",
//         "iconSize": "1em",
//         "icon": [
//             "IoMdTrash"
//         ]
//     }
// ]
