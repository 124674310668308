/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';


import {Treebeard} from 'react-treebeard';
import { TreeViewerSetting } from "./TreeViewerSetting";



export function TreeViewer({ el, states, className }) {

  let id = "component" + el.i


  const testData = {
    name: 'root',
    toggled: true,
    children: [
        {
            name: 'parent',
            children: [
                { name: 'child1' },
                { name: 'child2' }
            ]
        },
        {
            name: 'loading parent',
            loading: true,
            children: []
        },
        {
            name: 'parent',
            children: [
                {
                    name: 'nested parent',
                    children: [
                        { name: 'nested child 1' },
                        { name: 'nested child 2' }
                    ]
                }
            ]
        }
    ]
};

  const [data, setData] = useState(testData);
  const [cursor, setCursor] = useState(false);




  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <TreeViewerSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================








  const onToggle = (node, toggled) => {
    if (cursor) {
        cursor.active = false;
    }
    node.active = true;
    if (node.children) {
        node.toggled = toggled;
    }
    setCursor(node);
    setData(Object.assign({}, data))
}



  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };


  return (
    <>



      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">TreeViewer</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>


        <div className="card-body d-flex flex-column px-0">
          <div className="flex-grow-1 card-spacer-x">

            <div style={{height: '30vh'}} > 
            <Treebeard data={data} onToggle={onToggle} />


            </div>

          </div>
        </div>

      </div>
      {/* end::Tiles Widget 1 */}


    </>
  );
}


