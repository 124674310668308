import React, { useState } from "react";
import { Sweetalert_class } from "../../../func-component/SweetAlert";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import { useSelector } from "react-redux";
import Utils from "../../edit/WebBuilder/Utils";
import TimeUtils from "../../edit/WebBuilder/TimeUtils";
import { chartSlice, chartSubSlice, dateSlice } from "../../../../../app/_redux/modlSlice";
import { useDispatch } from "react-redux";

/** [H] 클릭 이벤트 collection Hook */


/**
 * 사이트 url 복사 function
 * @param {string} url string type - url 경로
 */
export const use_copy_func = (url) => {

  let class_put;

  try {

    let textField = document.createElement('textarea');
    textField.innerText = url;

    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    class_put = new Sweetalert_class('success', '복사 성공!', '');
    const swal_result = class_put.crate_alert();

  } catch (err) {
    class_put = new Sweetalert_class('error', '복사 실패!', `${err}`);
    const swal_result = class_put.crate_alert();
  }
}


// =============================================
// [E] 엑셀 다운로드 custom hooks
// =============================================    
export const useExcelFunc = () => {
  const [excel_loading, set_excel_loading] = useState(false);
  const { prevMoment, nextMoment, prevDate, nextDate } = useSelector((state) => state.date);
  console.log(prevDate, nextDate);
  const { role } = useSelector((state) => state.modl.workspaces[0]);
  const { keywordGroupIdx } = useSelector((state) => state.auth.user);

  const excel_query = (param) => {
    const { el, currentWorkspace } = param;
    set_excel_loading(true);

    let payload = role === "admin" ?
      {
        queries: [
          {
            key: "dataList",
            query: `db.collection('CrawlerData').aggregate([
                  { $match: {"date":{$exists:true},"date": {$gte: "${prevMoment}", $lte: "${nextMoment}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", ${!el ? "" : `"source" : "${el.data.rowdata.source}"`}} },
                  { $sort : {"date": -1} },
                  ]).toArray()`
          }
        ]
      } : {
        queries: [
          {
            key: "dataList",
            query: `db.collection('KiciPushHistory').aggregate([
                  { $match: { "dataRegistDate": {$gte: new Date("${prevDate}"), $lte: new Date("${nextDate}") }, "keywordGroupIdx" : "${keywordGroupIdx}" , "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", ${!el ? "" : `"source" : "${el.data.rowdata.source}"`}} },
                  { $sort : { "dataRegistDate": -1} },
                  ]).toArray()`
          }
        ]
      }

    Utils.getQueryResult(payload).then((result) => {
      excel_down(result);
    });
  }


  // =====================================================
  // [E] 데이터 -> 엑셀 파일 변환
  // =====================================================
  const excel_down = (param) => {

    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `${prevMoment}-${nextMoment} KICI 데이터`;

    const ws = XLSX.utils.aoa_to_sheet([
      [excelFileName],
      [],
      [
        "날짜",
        "사이트",
        "키워드",
        "조건 키워드",
        "제목"
      ]
    ]);

    param.dataList.map((data) => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          role === "admin" ?
            [
              data.date,
              data.source,
              data.filter,
              "",
              data.title
            ] :
            [
              TimeUtils.revert_korea_time(data.dataRegistDate),
              data.source,
              data.keyword,
              data.more,
              data.title
            ]
        ],
        { origin: -1 }
      );
      ws['!cols'] = [
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 }
      ]
      return false;
    });

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);

    set_excel_loading(false);
  }

  return { excel_query, excel_loading };
}


// =============================================
// [E] 클릭 시 데이터 로드 hooks
// =============================================    
export const useGetData = (param) => {

  let class_put;
  const dispatch = useDispatch();
  const moment = require('moment');
  const { el, currentWorkspace } = param;
  const [loading, set_loading] = useState(false);
  const [list, setList] = useState(!el ? ["재난문자", "다음 IT뉴스", "DC LOL갤", "DC 주갤", "DC 인방갤"] : [el.data.rowdata.source]);
  const [colorList, setColorList] = useState(["#f44336", "#1565c0", "#00bcd4", "#388e3c", "#ffc107"]);
  const { prevMoment, nextMoment } = useSelector((state) => state.date);
  const { changeSubChartData } = chartSubSlice.actions;
  const {
    changeChartData,
    changeChartList,
    changerecentCrawling,
    changeKeywordName,
    changeKeywordCount
  } = chartSlice.actions;
  const { keywordGroupIdx } = useSelector((state) => state.auth.user);
  const { role } = useSelector((state) => state.modl.workspaces[0]);

  const search_UI = () => {

    set_loading(true);
    const prev_last_date = moment(new Date(nextMoment)).subtract(+1, 'day').format('YYYY.MM.DD');

    let payload = {
      queries: [
        prevMoment !== prev_last_date ?
          {
            key: "chartList",
            query: `db.collection('CrawlerData').aggregate([
            { $match: {"date":{$exists:true},"date": {$gte: "${prevMoment}", $lte: "${nextMoment}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", ${!el ? "" : `"source" : "${el.data.rowdata.source}"`}} },
            { $sort : {"registDate":-1} },
            { $group: {_id:{ date: {$substr : ["$date", 0,10]} , source:"$source" } , 
              count: { $sum: 1 }
            }},
            ]).toArray()`
          } : {
            key: "chartList",
            query: `db.collection('CrawlerData').aggregate([
              { $match: {"date":{$exists:true},"date": {$gte: "${prevMoment}", $lte: "${nextMoment}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", ${!el ? "" : `"source" : "${el.data.rowdata.source}"`}} },
              { $sort : {"registDate":-1} },
              { $group: {_id:{ date: {$substr : ["$date", 11,2]} , source:"$source" } , 
                count: { $sum: 1 }
              }},
              { $limit : 70 },
            ]).toArray()`
          },

        {
          key: "recentCrawling",
          query: `db.collection('CrawlerLog').aggregate([
            { $match: {"workspaceIdx" : "${currentWorkspace.replace("workspace", "")}"} },
            { $sort : {"registDate":-1} },
            { $limit : 1 },
            
           ]).toArray()`
        },

        {
          key: "dataList",
          query: `db.collection('CrawlerData').aggregate([
              { $match: { "date": {$gte: "${prevMoment}", $lte: "${nextMoment}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}" } },
              { $sort : {"date": -1} },
              { $lookup : {
                from : "KiciPushHistory",
                let : { "title" : "$title" },
                pipeline : [
                    { $match : { ${role === "admin" ? "" : `"keywordGroupIdx" : "${keywordGroupIdx}"`} } },
                    { $match : { $expr : { $eq : [ "$title", "$$title" ] } } },
                    { $project : { "more" : 1, "_id" : 0 } }
                ],
                as : "moreInfo"
              } },
              { $unwind : { "path" : "$moreInfo", preserveNullAndEmptyArrays: false } }
            ]).toArray()`
        },
        {
          key: "keywordList",
          query: `db.collection('CrawlerData').aggregate([
          { $match: {"date":{$exists:true},"date": {$gte: "${prevMoment}", $lte: "${nextMoment}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", ${!el ? "" : `"source" : "${el.data.rowdata.source}"`}} },
          { $sort : {"date": -1} }
        ]).toArray()`
        }
      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      crawling_settting(result);
      keyword_chart_rendering(result);
      chartlist_setting(result);
    })
  }


  // =====================================================
  // 크롤링 날짜 state 변경
  // =====================================================
  const crawling_settting = (param) => {
    let updated = moment(param.recentCrawling[0].registDate.toString()).format('YY-MM-DD HH:mm:ss')
    updated = updated.split("-").join(".")
    dispatch(changerecentCrawling(updated));
  }


  // =====================================================
  // 크롤링 날짜 state 변경
  // =====================================================
  const keyword_chart_rendering = (param) => {

    const { keywordList } = param;

    let word_arr = [];
    let total_count_mapping = [];
    let news_count_mapping = [];
    let news_word = [];
    let news_preg = ["재난문자", "다음 IT뉴스"];

    keywordList.map((data) => {
      const temp_keyword = data.filter.split(",").toString();

      word_arr.push(temp_keyword);

      if (news_preg.includes(data.source)) {
        news_word.push(temp_keyword);
      }
    });


    const total_result_arr = word_arr.reduce((accu, curr) => {
      accu.set(curr, (accu.get(curr) || 0) + 1);
      return accu;
    }, new Map());


    const news_result_arr = news_word.reduce((accu, curr) => {
      accu.set(curr, (accu.get(curr) || 0) + 1);
      return accu;
    }, new Map());


    Array.from(total_result_arr).map(([key, value]) => {
      let temp_data = { name: `${key}`, count: value }
      total_count_mapping.push(temp_data)
    })


    Array.from(news_result_arr).map(([key, value]) => {
      let temp_data = { name: `${key}`, count: value }
      news_count_mapping.push(temp_data)
    })


    /**
     * 키워드 집계 10순위까지 출력
     */
    const arr_by_count = total_count_mapping.sort((a, b) => (b.count - a.count));

    const chart_data_arr = arr_by_count.slice(0, 10).map((data) => data.name);

    const chart_count_arr = arr_by_count.slice(0, 10).map((data) => data.count);

    dispatch(changeKeywordName(chart_data_arr.reverse()));

    dispatch(changeKeywordCount(chart_count_arr.reverse()));
  }


  const chartlist_setting = (param) => {
    let _graphData = {}
    let _date = []

    for (let list of param.chartList) {
      //_date
      if (!_date.includes(list._id.date)) {
        _date.push(list._id.date)
      }
    }

    for (let _list of list) {
      _graphData[_list] = []
    }

    _date.sort()

    for (let _d of _date) {

      for (let _s of list) {
        let _exist = false
        for (let list of param.chartList) {
          if (_d == list._id.date && _s == list._id.source) {
            _exist = true
            _graphData[_s].push(list.count)
          }
        }

        if (!_exist) {
          _graphData[_s].push(0)
        }

      }

    }


    // ================================================================
    // 그래프 그리기
    // ================================================================

    // TODO 
    // 나중에 y_data 를 map 형식으로 돌린다. 
    if (list.every((s) => { return Object.keys(_graphData).includes(s) })) {

      let y_dataArray = []
      list.forEach((item, i) => {
        y_dataArray.push({
          type: 'line',
          name: item,
          value: _graphData[item],
          color: colorList[i]
        })
      });

      !el ?
        dispatch(changeChartData({
          chartType: 'barLine',
          title: '',
          x_label: '',
          y_label: [''],
          legendShow: true,
          x_data: _date,
          layoutChange: {
            left: '0px',
            right: '0px',
            top: '25%'
          },
          y_data: y_dataArray,
        })) :
        dispatch(changeSubChartData({
          chartType: 'barLine',
          title: '',
          x_label: '',
          y_label: [''],
          legendShow: true,
          x_data: _date,
          layoutChange: {
            left: '0px',
            right: '0px',
            top: '25%'
          },
          y_data: y_dataArray,
        }))


    } else {

      !el ?
        dispatch(changeChartData({
          chartType: 'barLine',
          title: '',
          x_label: '',
          y_label: [''],
          legendShow: true,
          x_data: [],
          layoutChange: {
            left: '0px',
            right: '0px'
          },
          y_data:
            [
              {
                type: 'bar',
                name: "",
                value: [],
                color: ''

              }
            ]
        })) :
        dispatch(changeSubChartData({
          chartType: 'barLine',
          title: '',
          x_label: '',
          y_label: [''],
          legendShow: true,
          x_data: [],
          layoutChange: {
            left: '0px',
            right: '0px'
          },
          y_data:
            [
              {
                type: 'bar',
                name: "",
                value: [],
                color: ''

              }
            ]
        }))
    }


    // ================================================================
    // 데이터 타임라인 그리기
    // ================================================================

    if (param.dataList.length == 0) {
      let _listData =
        <div className="font-weight-bolder text-dark-25 font-size-lg" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
          # 데이터가 없습니다.
        </div>

      dispatch(changeChartList(_listData));
    } else {

      const deduplication_arr = param.dataList.reduce((acc, current) => {
        const after_data = acc.find(data => (data.title === current.title) && (data.date === current.date));
        if (!after_data) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      let _listData =
        <div className="timeline timeline-6 mt-3">
          {
            deduplication_arr.map((d, index, arr) => {
              let color = colorList[0];
              let iArr = [];
              let moreArr = [];

              list.map((l, i) => {
                if (l == d.source) {
                  color = colorList[i]
                  iArr = d.filter.split(",")
                  moreArr = Object.keys(d.moreInfo).length > 0 ? d.moreInfo.more.split(",") : []
                }
              })

              let time = d.date.substr(11, 5)
              let title = d.title

              iArr.forEach(function (out_item, i) {
                let out_regex = new RegExp(out_item, "ig");
                title = title.replaceAll(out_regex, `<span style="color:${color}">${out_item}</span>`)

                moreArr.forEach(function (in_item, i) {
                  let in_regex = new RegExp(in_item, "ig");
                  title = title.replaceAll(in_regex, `<span style="color:#6A5ACD">${in_item}</span>`)
                })
              });

              return (

                <div key={Math.random()} className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                    onClick={() => { use_copy_func(d.linkUrl) }}
                  >
                    {time}
                  </div>

                  <div className="timeline-badge">
                    <i style={{ color: color }} className={`fa fa-genderless icon-xl`}></i>
                  </div>

                  <div className="timeline-content" onClick={() => window.open(`${d.linkUrl}`, "_blank")}>
                    <div className="font-weight-bolder font-size-md text-dark-60 " >
                      <span style={{ wordBreak: "break-word" }} className="p-3" dangerouslySetInnerHTML={{ __html: title }}></span>

                    </div>

                    <div className="font-size-md text-muted">

                      <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: d.content }}></span>

                      <div style={{ marginLeft: "9px" }} className={`font-size-xs `}>
                        {(d.source == undefined) ? <></> : <span>{d.source}</span>}
                        {(d.from == undefined) ? <></> : <span> {" | "} {d.from}</span>}
                        {(d.author == undefined) ? <></> : <span> {" | "} {d.author}</span>}
                      </div>
                    </div>

                  </div>

                </div>



              )
            })

          }
        </div>
      dispatch(changeChartList(_listData));
      set_loading(false);
    }
  }


  return { search_UI, loading }
}


// =============================================
// [E] input date 관련 hooks
// =============================================    
export const useCanlender = () => {

  const dispatch = useDispatch();
  const moment = require('moment');

  const [btn_mode, set_btn_mode] = useState({ week: false, month: false });

  const today = new Date();

  const { changePrevSlice } = dateSlice.actions;
  const { changeNextSlice } = dateSlice.actions;
  const { changePrevMomentSlice } = dateSlice.actions;
  const { changeNextMomentSlice } = dateSlice.actions;


  // =============================================
  // [E] 이전 날짜 변경 이벤트
  // =============================================   
  const change_prevdate = async (param) => {
    const object_reset = { ...btn_mode };

    Object.keys(object_reset).map(data => object_reset[data] = false);

    await set_btn_mode({ ...object_reset })

    const select_prev = moment(new Date(param)).format('YYYY.MM.DD');

    dispatch(changePrevSlice(param));
    dispatch(changePrevMomentSlice(select_prev));
  }


  // =============================================
  // [E] 다음 날짜 변경 이벤트
  // =============================================   
  const change_nextdate = async (param) => {

    const object_reset = { ...btn_mode };

    Object.keys(object_reset).map(data => object_reset[data] = false);

    await set_btn_mode({ ...object_reset })

    const select_next = moment(new Date(param)).subtract(-1, 'day').format('YYYY.MM.DD');

    dispatch(changeNextSlice(param));
    dispatch(changeNextMomentSlice(select_next));
  }


  // =============================================
  // [E] 주간/월간 버튼 클릭 이벤트
  // =============================================   
  const calender_change = (param) => {
    chage_btn_active(param);
    change_prev_date(param);
  }

  // =============================================
  // [E] 버튼 활성화 이벤트
  // =============================================   
  const chage_btn_active = async (param) => {
    const object_reset = { ...btn_mode };

    Object.keys(object_reset).map(data => object_reset[data] = false);

    await set_btn_mode({ ...object_reset, [param]: true })
  }


  // =============================================
  // [E] 날짜 redux 변경 이벤트
  // =============================================   
  const change_prev_date = (param) => {
    const prev_date = new Date(today);

    param === 'week' ? prev_date.setDate(today.getDate() - 7) : prev_date.setMonth(today.getMonth() - 1);

    const moment_today = moment(new Date(today)).subtract(-1, 'day').format('YYYY.MM.DD');
    const moment_prev = moment(new Date(prev_date)).format('YYYY.MM.DD');

    dispatch(changePrevSlice(prev_date));
    dispatch(changePrevMomentSlice(moment_prev));
    dispatch(changeNextSlice(today));
    dispatch(changeNextMomentSlice(moment_today));
  }

  return { change_prevdate, change_nextdate, calender_change, btn_mode }
}