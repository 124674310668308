/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';

import store from "../../../../redux/store"
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";

import { Table, Button, Form, InputGroup, Col, Row, Overlay, Tooltip, OverlayTrigger, ButtonToolbar } from "react-bootstrap";
import { BasicDataTable1Setting } from "../datatable/BasicDataTable1Setting";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250
});






export function BasicDataTable1({ el, states, className }) {
  let id = "component" + el.i

  const [state, setState] = useState([getItems(5), getItems(2, 5)]);



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    // let parent = document.getElementById("contentModal")
    // if (parent != null) {
    //   ReactDOM.render(
    //     <BannerSetting
    //       show={modalShow}
    //       onHide={() => setModalShow(false)}
    //       parentCallback={selectSetting}
    //       link={link}
    //       image={image}
    //     />,
    //     document.getElementById("contentModal"))
    // } else {
    // }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter(group => group.length));
    }
  }

  const finalSpaceCharacters = [
    {
      id: 'gary',
      name: 'Gary Goodspeed',
      thumb: '/images/gary.png'
    },
    {
      id: 'cato',
      name: 'Little Cato',
      thumb: '/images/cato.png'
    },
    {
      id: 'kvn',
      name: 'KVN',
      thumb: '/images/kvn.png'
    },
    {
      id: 'mooncake',
      name: 'Mooncake',
      thumb: '/images/mooncake.png'
    },
    {
      id: 'quinn',
      name: 'Quinn Ergon',
      thumb: '/images/quinn.png'
    }
  ]



  
  const handlePress = (e) => {
    console.log("____ handlePress:")
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    // props.callbackParent({ isDraggable: false });
}


  return (
    <>

      <BasicDataTable1Setting
        show={modalShow}
        onHide={() => setModalShow(false)}
        parentCallback={selectSetting}
      />





      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">BasicDataTable1</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}




        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">


            Table1<br />




            <div>
              <Button
                type="button"
                onClick={() => {
                  setState([...state, []]);
                }}
              >
                Add new group
              </Button>&nbsp;
              <Button
                type="button"
                onClick={() => {
                  setState([...state, getItems(1)]);
                }}
              >
                Add new item
              </Button>

              <br /><br />

              {/* <Droppable droppableId="characters">
                {(provided) => (
                  <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                    {finalSpaceCharacters.map(({ id, name, thumb }, index) => {
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div className="characters-thumb">
                                <img src={thumb} alt={`${name} Thumb`} />
                              </div>
                              <p>
                                {name}
                              </p>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable> */}


              <div style={{ display: "flex" }} onMouseDown={(e)=> handlePress(e)}>


              {/* <DragDropContext onDragEnd={onDragEnd}> */}


                {state.map((el, ind) => (
                  <Droppable key={ind} droppableId={`${(ind+1.0)*Math.random()}`}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {el.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around"
                                  }}
                                >
                                  {item.content}
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      const newState = [...state];
                                      newState[ind].splice(index, 1);
                                      setState(
                                        newState.filter(group => group.length)
                                      );
                                    }}
                                  >
                                    Component
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
                {/* </DragDropContext> */}
              </div>






            </div>



          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}




        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

    </>
  );
}


