
import React, { useEffect, useMemo, useState } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { date } from "yup";

import { ComponentLoader } from "../../pages/ComponentLoader";

export function ToComponent({location}) {


    //DB
    let host = '' //mongodb://wise:Dhkdlwm1@169.56.88.185:27017/IotData
    let id = ''
    let pw = ''
    let db_name = ''
    let db_type = 'mongodb'

    //API
    let result = ''

    const [el, setEl] = useState({});
    const [setting, setSetting] = useState({});


    //================================================================================
    //CREATE INIT TABLE
    //site /siteGroup / group / user / menu / page / component / notice   초기 파일 생성


    useEffect(() => {
        let id = location.pathname.replace("/component/","")

        console.log(location)
        console.log(id)

        const instance = axios.create({
            baseURL: axios.defaults.serverURL,
            timeout: 1000,
            headers: { 'Content-Type': 'application/json' }
        });

        // 0. Team Embed
        let data = { componentIdx: id }

        getComponent(instance, data).then(
            resp => {
                console.log('DONE getComponent ===============')
                console.log(resp);
                let _el = JSON.parse(resp)
                console.log(_el)
                console.log(_el.data.source)
                setEl(_el)


            }
        )

      }, []);

    




      
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================

    function getComponent(instance, data) {

        return new Promise((resolve, reject) => {
            
            const dataStr = JSON.stringify(data)

            const req = {
              query: `query getComponent($data: String) {
                getComponent(data: $data)
              }`,
              variables: {
                data: dataStr
              }
            }

            
            instance.post('graphql/', req)
                .then(resp => {
                    console.log(">> resp.data.data.getComponent")
                    console.log(resp.data.data.getComponent)
   
                    resolve(resp.data.data.getComponent)
                })
                .catch(error => {
                    InsertText('ERR getComponent >' + error)
                });
        });
    }





    function InsertText(text) {
        result = '<div>[' + new Date().toLocaleString() + '] ' + text + '</div>' + result
        let target = document.getElementById('result');
        target.innerHTML = result
    }


    
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================

    return (
        <>

            {/* 
            <h3>MODL Component</h3>
            {JSON.stringify(el)}
             */}

            <div className="d-flex flex-column flex-root">

                {/* <button onClick={Embed}></button> */}

                <div id='result'></div>

                {(el.hasOwnProperty('data') && el.data.hasOwnProperty('source')) ? <ComponentLoader el={el} states={setting} className="className1" /> : "NO SOURCE"}




                {/* 컴포넌트 MODAL */}
                <div id="contentModal"> </div>
            </div>

        </>
    );
}
