import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import * as requestFromServer from "../../../app/_redux/modlCrud";
import Utils from "../widgets/edit/WebBuilder/Utils";
import { Sweetalert_class } from "./SweetAlert";
import { filter_class } from "../widgets/edit/ControlFilter";
import { MdClose } from "react-icons/md";

export function TempKeywordAlertAddWork(props) {
  const {
    menu,
    item,
    data,
    parentRender,
    workSpace,
    checkEmailCallback,
    action,
    ...rest
  } = props;

  const [site_name, set_site_name] = useState("");
  const [keyword_arr, set_keyword_arr] = useState([]);
  const [choice_category, set_choice_category] = useState("");
  const [choice_keyword, set_choice_keyword] = useState("");
  const [group_name, set_group_name] = useState("");
  const [group_list, set_group_list] = useState(null);
  const [alert_text, set_alert_text] = useState("");
  const [site_id, set_site_id] = useState("");
  const [site_list, set_site_list] = useState([]);

  let function_flag = true;
  let class_put, filter_put;

  useEffect(() => {
    // if (action === "edit") {
    //   let payload = {
    //     queries: [
    //       {
    //         key: "KeywordGroup",
    //         query: `db.collection('KeywordGroup').aggregate([
    //                         { $match: { "title" : "${data.title}" }}
    //                     ]).toArray()`,
    //       },
    //     ],
    //   };

    //   Utils.getQueryResult(payload).then((result) => {
    //     set_group_list(result);
    //   });
    // }

    if (action === "edit") {
      let temp_data = { ...data };
      temp_data.value = data.site;
      set_choice_category(data.category);
      set_site_name(data.site);
      set_group_name(data.title);

      // change_site(temp_data);
    }
  }, [data]);

  useEffect(() => {
    start_render();
  }, []);

  const start_render = () => {
    let payload = {
      queries: [
        {
          key: "siteList",
          query: `db.collection('CrawlerTemplate').aggregate([
                          { $match: { "workspaceIdx" : "${workSpace.replace(
                            "workspace",
                            ""
                          )}" }}
                      ]).toArray()`,
        },
      ],
    };

    Utils.getQueryResult(payload).then((result) => {

      const { siteList } = result;

      const site_arr = siteList.map((data) => {
        return {
          id: `${data._id}`,
          title: `${data.title}`,
          mode: false,
          keyword: [],
        };
      });

      set_site_list(site_arr);
    });
  };

  const btnStyle = {
    margin: "2px",
  };

  // =====================================================
  // [S] change event
  // =====================================================
  const change_category = (e) => {
    reset_func();

    const { value } = e.target;

    if (value !== "") {
      set_choice_category(value);
    } else {
      reset_func();
    }
  };

  const group_change = (e) => {
    const { value } = e.target;

    set_group_name(value);
  };

  const blur_match = async () => {
    const match_data = await validata_group();

    match_data > 0
      ? set_alert_text(
          <p className="alert_group_name">
            존재하는 그룹명입니다, 그룹명을 변경해주세요.
          </p>
        )
      : set_alert_text("");
  };

  // =====================================================
  // [S] group name overlap
  // =====================================================
  const validata_group = () => {

    let payload = {
      queries: [
        {
          key: "group_overlap",
          query: `db.collection('KeywordGroup').aggregate([
                        { $match: { "title" : "${group_name}" } }
                    ]).toArray()`,
        },
      ],
    };

    const match_length = Utils.getQueryResult(payload).then((result) => {
      const { group_overlap } = result;
      console.log(group_overlap);
      return group_overlap.length;
    });

    return match_length;
  };

  // =====================================================
  // [E] 사이트명 변경시 이벤트
  // =====================================================
  const change_site = (param) => {
    set_site_id("");
    set_choice_keyword("");

    site_list.map((data) =>
      data.title === param ? (data.mode = true) : (data.mode = false)
    );

    if (param !== "") {
      let payload = {
        queries: [
          {
            key: "title_existence",
            query: `db.collection('CrawlerTemplate').aggregate([
                            { $match: { "title": "${param}" } },
                            { $project : { '_id' : 1, "filter" : { $arrayElemAt: ["$filter.rule", 0] } } }
                        ]).toArray()`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        console.log(result);
        const { title_existence } = result;

        const filter_arr = temp_func(title_existence[0]);

        set_site_id(title_existence[0]._id);
        set_keyword_arr(filter_arr);
        set_site_name(param);
      });
    } else {
      reset_func();
    }
  };

  // =====================================================
  // [E] 키워드 추출 함수
  // =====================================================
  const temp_func = (param) => {
    filter_put = new filter_class(param);
    const filter_result = filter_put.filter_method();

    return filter_result;
  };

  // =====================================================
  // [E] 키워드 추가
  // =====================================================
  const keyword_add = () => {
    if (choice_keyword === "") {
      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "키워드를 선택해주세요."
      );
      class_put.crate_alert();
      return false;
    }

    const duplication_keyword = site_list.filter(data => data.title === site_name);

    // 키워드 중복 확인
    if(!duplication_keyword[0].keyword.includes(choice_keyword)){
      const temp_arr = site_list.map((data) => {
        return {
          ...data,
          keyword: data.mode
            ? [...data.keyword, choice_keyword]
            : [...data.keyword],
        };
      });

      set_site_list(temp_arr);

    } else {
      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "중복된 키워드가 있습니다."
      );

      class_put.crate_alert();
      return false;
    }

    
  };

  // =====================================================
  // [E] STATE 초기화 함수
  // =====================================================
  const reset_func = async (param) => {
    if (param === "down") {
      await set_group_name("");
      await set_alert_text("");
      await set_choice_keyword("");
    } else {
      await set_choice_category("");
      await set_keyword_arr([]);
      await set_choice_keyword("");
      await set_group_name("");
      await set_alert_text("");
    }
  };

  // =====================================================
  // [E] 등록 click event (데이터 검증)
  // =====================================================
  const add_item_check = async () => {
    const match_data = await validata_group();

    function_flag = true;

    if (group_name === "") {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "그룹명을 입력해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (alert_text !== "") {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "중복된 그룹명이 있습니다."
      );
      class_put.crate_alert();

      return false;
    }

    if (function_flag) {
      add_group_item();
    }
  };

  // =====================================================
  // [E] 그룹 추가
  // =====================================================
  const add_group_item = (param) => {
    let temp_arr = [];

    site_list.map((outData) =>
      outData.keyword.map((inData) => {
        const temp_obj = {
          tplId: outData.id,
          site: outData.title,
          keyword: inData,
          more: [],
        };

        temp_arr.push(temp_obj);
      })
    );

    let insert = {
      siteIdx: "626a0b71bbcad71d2c55e422",
      workspaceIdx: workSpace.replace("workspace", ""),
      title: group_name,
      category: "...",
      threshold: 0,
      filter: temp_arr,
      updateDate: new Date(),
      registDate: new Date(),
    };

    let payload = {
      queries: [
        {
          key: "insertGroup",
          query: `db.collection('KeywordGroup').insertOne(${JSON.stringify(
            insert
          )})`,
        },
      ],
    };

    Utils.getQueryResult(payload).then((result) => {
      class_put = new Sweetalert_class(
        "success",
        "성공하였습니다!",
        "그룹을 추가하였습니다."
      );
      class_put.crate_alert();

      reset_func();
      rest.onHide();
      parentRender();
    });
  };

  // =====================================================
  // [E] 하단 키워드 삭제 이벤트
  // =====================================================
  const remove_keyword = (param) => {
    console.log("삭제");
  };

  // =====================================================
  // [E] 키워드 및 알람설정 수정시 이벤트
  // =====================================================
  const update_item_check = () => {
    if (site_list.length === 0) {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "키워드를 선택해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (function_flag) {
      update_group_item();
    }
  };

  // =====================================================
  // [E] 그룹 업데이트 함수
  // =====================================================
  const update_group_item = async (param) => {
    class_put = new Sweetalert_class(
      "warning",
      "수정하시겠습니까?",
      `수정시 키워드별 PUSH조건이 초기화됩니다,<br>정말 수정하시겠습니까?`
    );
    const swal_result = await class_put.confirm_alert();

    if (swal_result) {
      let temp_arr = [];

      site_list.map((outData) =>
        outData.keyword.map((inData) => {
          const temp_obj = {
            tplId: outData.id,
            site: outData.title,
            keyword: inData,
            more: [],
          };

          temp_arr.push(temp_obj);
        })
      );

      let update = { $set: { filter: temp_arr, registDate: new Date() } };

      let payload = {
        queries: [
          {
            key: "updateGroup",
            query: `db.collection('KeywordGroup').updateOne({ _id: new require('mongodb').ObjectID('${
              data._id
            }') },${JSON.stringify(update)})`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        if (result.updateGroup.acknowledged === true) {
          class_put = new Sweetalert_class(
            "success",
            "수정 완료!",
            "수정이 완료되었습니다."
          );
          class_put.crate_alert();
          rest.onHide();
          parentRender();
        }
      });
    }
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {action == "add" ? (
            <>키워드 및 알람설정 추가</>
          ) : (
            <>키워드 및 알람설정 수정</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {action === "add" ? (
            <>
              <Form.Group id="formUserId">
                <Form.Label>그룹명</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="그룹명을 입력해주세요."
                  value={group_name}
                  onChange={(e) => {
                    group_change(e);
                  }}
                  onBlur={() => {
                    blur_match();
                  }}
                />
                {alert_text}
              </Form.Group>
              <Form.Group>
                <Form.Label className="keyword_label">사이트</Form.Label>
                <div className="site_btn_wrap">
                  {site_list.map((data, index) => (
                    <Button
                      variant={data.mode ? "primary" : "outline-primary"}
                      onClick={() => {
                        console.log(data);
                        change_site(data.title);
                      }}
                      key={index}
                    >
                      {data.title}
                    </Button>
                  ))}
                </div>
              </Form.Group>
              <Form.Group id="formUserId">
                <Form.Label className="keyword_label">키워드</Form.Label>
                <Form.Control
                  as="select"
                  className="keyword_option"
                  onChange={(e) => {
                    set_choice_keyword(e.target.value);
                  }}
                  value={choice_keyword}
                >
                  <option value="">선택</option>
                  {keyword_arr}
                </Form.Control>
                <Button
                  className="keyword_add_btn"
                  onClick={() => {
                    keyword_add();
                  }}
                >
                  키워드 추가
                </Button>
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group id="formUserId">
                <Form.Label>그룹명</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="그룹명을 입력해주세요."
                  value={group_name}
                  onChange={(e) => {
                    group_change(e);
                  }}
                  disabled
                />
                {alert_text}
              </Form.Group>
              <Form.Group>
                <Form.Label className="keyword_label">사이트</Form.Label>
                <div className="site_btn_wrap">
                  {site_list.map((data, index) => (
                    <Button
                      variant={data.mode ? "primary" : "outline-primary"}
                      onClick={() => {
                        console.log(data);
                        change_site(data.title);
                      }}
                      key={index}
                    >
                      {data.title}
                    </Button>
                  ))}
                </div>
              </Form.Group>
              <Form.Group id="formUserId">
                <Form.Label className="keyword_label">키워드</Form.Label>
                <Form.Control
                  as="select"
                  className="keyword_option"
                  onChange={(e) => {
                    set_choice_keyword(e.target.value);
                  }}
                  value={choice_keyword}
                >
                  <option value="">선택</option>
                  {keyword_arr}
                </Form.Control>
                <Button
                  className="keyword_add_btn"
                  onClick={() => {
                    keyword_add();
                  }}
                >
                  키워드 추가
                </Button>
              </Form.Group>
            </>
          )}
        </Form>
        <div className="keyword_render_wrap">
          {site_list.length !== 0 &&
            site_list[0].mode === true &&
            site_list[0].keyword.map((data, index) => (
              <div>
                <p key={`keyword` + index}>{data}</p>
                <MdClose
                  onClick={() => {
                    remove_keyword(data);
                  }}
                />
              </div>
            ))}
          {site_list.length !== 0 &&
            site_list[1].mode === true &&
            site_list[1].keyword.map((data, index) => (
              <div>
                <p key={`keyword` + index}>{data}</p>
                <MdClose
                  onClick={() => {
                    remove_keyword(data);
                  }}
                />
              </div>
            ))}
          {site_list.length !== 0 &&
            site_list[2].mode === true &&
            site_list[2].keyword.map((data, index) => (
              <div>
                <p key={`keyword` + index}>{data}</p>
                <MdClose
                  onClick={() => {
                    remove_keyword(data);
                  }}
                />
              </div>
            ))}
          {site_list.length !== 0 &&
            site_list[3].mode === true &&
            site_list[3].keyword.map((data, index) => (
              <div>
                <p key={`keyword` + index}>{data}</p>
                <MdClose
                  onClick={() => {
                    remove_keyword(data);
                  }}
                />
              </div>
            ))}
          {site_list.length !== 0 &&
            site_list[4].mode === true &&
            site_list[4].keyword.map((data, index) => (
              <div>
                <p key={`keyword` + index}>{data}</p>
                <MdClose
                  onClick={() => {
                    remove_keyword(data);
                  }}
                />
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={btnStyle}
          onClick={() => {
            {
              action === "add" ? add_item_check() : update_item_check();
            }
          }}
        >
          {action === "add" ? "등록" : "수정"}
        </Button>
        <Button
          onClick={() => {
            rest.onHide();
            start_render();
            reset_func();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
