/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';


import { actionTypes } from "../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Table, Button, Form, InputGroup, Col, Row, Overlay, Tooltip, OverlayTrigger, ButtonToolbar } from "react-bootstrap";
import { ControlTplComponentSetting } from "./ControlTplComponentSetting";



export function ControlTplComponent({ el, states, className }) {
  let id = "component" + el.i

  const [textAreaStr, setTextAreaStr] = useState('');
  const [selectOrder, setSelectOrder] = useState(0);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const modl = useSelector((state) => state.modl, shallowEqual)
  const tplComponent = modl.tplComponent



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <ControlTplComponentSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };






  useEffect(() => {
    // console.log(">> ControlComponent Init ")
    //Load tplComponent
    dispatch({ type: actionTypes.GetTplComponent, payload: { userIdx: 'admin' } })
  }, []);


  useEffect(() => {
    // console.log(">> tplComponent")
    if(tplComponent == undefined) return

    setTextAreaStr(JSON.stringify(tplComponent[selectOrder], null, 4))
    // console.log(tplComponent)
    // console.log(workspaces)
    // console.log(currentWorkspace)


  }, [tplComponent]);

  const saveTplComponent = () => {

    // console.log("saveTplComponent : ")
    // console.log(textAreaStr)
    setTextAreaStr(textAreaStr)
    
    dispatch({ type: actionTypes.UpdateTplComponent, payload: { tplComp: JSON.parse(textAreaStr) } })
  };


  const textareaChange = (e) => {

    // console.log(e.target.value)

    setTextAreaStr(e.target.value)
    
  }



  if (tplComponent == undefined) {
    return (<></>)
  }


  return (
    <>

      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">COMPONENT TEMPLATE</div>
              <div className="font-size-sm text-muted mt-2">for 컴포넌트 템플릿 관리</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">

            <Form>
              <Form.Group id="exampleForm.ControlTextarea1">
                <Form.Label>tplComponent 초기상태 설정</Form.Label>

                <Form.Control as="textarea" onChange={textareaChange} rows={15} value={textAreaStr} />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} id="formGridEmail">
                  <Form.Control as="select"
                    onChange={e => {
                      console.log("e.target.value", e.target.value);

                      setSelectOrder(e.target.value);
                      setTextAreaStr(JSON.stringify(tplComponent[e.target.value], null, 4))
                      
                    }}
                  >
                    {tplComponent.map((val, index) =>
                      <option key={index} value={index}>{val.data.title}</option>
                    )
                    }

                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} id="formGridState">
                  <Button variant="primary" onClick={saveTplComponent.bind(this)}>저장</Button>&nbsp;
                </Form.Group>
              </Form.Row>
            </Form>


            {/* begin::Item */}

            {/* end::Item */}


          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}




    </>
  );
}

