import shortid from "shortid";

export const SIDEBAR_ELEMENT = "sidebarElement";
export const SIDEBAR_COLUMN = "sidebarColumn";
export const SIDEBAR_ROW = "sidebarRow";
export const SIDEBAR_LAYOUT = "sidebarLayout";

export const LAYOUT = "layout";
export const ROW = "row";
export const COLUMN = "column";
export const ELEMENT = "element";
export const WRAPPER = "wrapper";

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "tab",
    content: "Some tab",
    "layout": [
      {
        "type": "layout",
        "title": "tab0",
        "id": shortid.generate(),
        "children": [
          {
            "type": "row",
            "id": shortid.generate(),
            "children": [
              {
                "type": "column",
                "id": shortid.generate(),
                "children": [
                  {
                    "type": "element",
                    "id": shortid.generate(),
                    "elementType": "label",
                    "test1": "insert any element"
                  },
                  {
                    "type": "element",
                    "id": shortid.generate(),
                    "elementType": "label",
                    "test1": "insert any element"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "type": "layout",
        "title": "tab1",
        "id": shortid.generate(),
        "children": [
          {
            "type": "row",
            "id": shortid.generate(),
            "children": [
              {
                "type": "column",
                "id": shortid.generate(),
                "children": [
                  {
                    "type": "element",
                    "id": shortid.generate(),
                    "elementType": "input",
                    "test1": "insert any element"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]




  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "date",
    content: "Some date"

  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "button",
    content: "Some button"

  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "label",
    content: "Some label"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "textarea",
    content: "Some textarea"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "input",
    title: "T",
    content: "Some input"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "checkbox",
    content: "Some checkbox"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "radio",
    content: "Some radio"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "select",
    content: "Some selec"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "dropDown",
    content: "Some dropDown"
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "graph",
    content: "Some graph"

  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "image",
    content: "Some image",
    style: { width: "100%" },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ELEMENT,
    elementType: "table",
    content: "Some table",
    style: { padding: "30px", width: "300px" },
    data: { test: "data_1", test2: "data_2" } 

  },

  {
    id: shortid.generate(),
    type: SIDEBAR_COLUMN,
    elementType: "complexColumn",
    content: "Some elementSet",
    children: [
      {
        type: ELEMENT,
        id: shortid.generate(),
        elementType: "input",
        test1: "Some test1",
        style: { padding: "30px", width: "300px" },
        data: { test: "data_1", test2: "data_2" } 
      },
      {
        type: ELEMENT,
        id: shortid.generate(),
        elementType: "image",
        test1: "Some test1",
        style: { padding: "30px", width: "300px" },
        data: { test: "data_1", test2: "data_2" } 
      },
    ]
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ROW,
    elementType: "complexRow",
    children: [
      {
        id: shortid.generate(),
        type: COLUMN,
        children: [
          {
            type: ELEMENT,
            id: shortid.generate(),
            elementType: "button",
            test1: "Some test1",
            style: { padding: "30px", width: "300px" },
            data: { test: "data_1", test2: "data_2" } 
          }
        ]
      }
    ]
  },


  {
    id: shortid.generate(),
    type: SIDEBAR_LAYOUT,
    elementType: "complexLayout",
    children: [
      {
        type: ROW,
        id: shortid.generate(),
        children: [
          {
            type: COLUMN,
            id: shortid.generate(),
            children: [
              {
                type: "sidebarElement",
                id: shortid.generate(),
                test1: "Some test0",
                style: { padding: "0px", width: "0px" },
                data: { test: "data_0" }
              },
  
            ]
          }
        ]
      },
      {
        type: ROW,
        id: shortid.generate(),
        children: [
          {
            type: COLUMN,
            id: shortid.generate(),
            children: [
              {
                type: ELEMENT,
                id: shortid.generate(),
                elementType: "input",
                test1: "Some test1",
                style: { padding: "30px", width: "300px" },
                data: { test: "data_1", test2: "data_2" } 
              },
              {
                type: ELEMENT,
                id: shortid.generate(),
                elementType: "button",
                test1: "Some test1",
                style: { padding: "30px", width: "300px" },
                data: { test: "data_1", test2: "data_2" } 
              }
            ]
          }
        ]
      }
    ],
  
  }




];
