/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ChartCommon from "../edit/WebBuilder/Element/ChartCommon";
import { useSelector } from "react-redux";
import * as _ from "lodash"
import KiciDateInput from "../../func-component/KiciDateInput";
import {
  Col,
  Container,
  Row
} from "react-bootstrap";
import KiciHistoryFilter from "../../func-component/KiciHistoryFilter";
import RenderCrawlingHooks from "./customHooks/RenderCrawlingHooks";
import KiciUpdateToday from "../../func-component/KiciUpdateToday";
import data_icon from './style/img/dataicon.png';
import KiciAllHistory from "../../func-component/KiciAllHistory";
import ChartKeyword from "../edit/WebBuilder/Element/ChartKeyword";


export function KICI_CrawlingDataList({ el, className }) {

  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================
  const [values, setValues] = useState({});
  const [list, setList] = useState([el.data.rowdata.source]);

  // =====================================================
  // [S] redux
  // =====================================================
  const { recentCrawling } = useSelector((state)=>state.chart);
  const { chartData } = useSelector((state)=>state.subChart);

  
  // =====================================================
  // [S] Handle Event (handleEvent)
  // =====================================================

  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  let field1 = "7J1kudaO9"
  let field2 = "ZRd_IEk7j"

  var handleEventObj = {
    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다 
    // =====================================================
    // saveState
    saveState: function (method, id, value) {
      // console.log(">>> saveState")
      // console.log("handleEvent > method : " + method)
      // console.log("handleEvent > id : " + id)
      // console.log("handleEvent > value : " + value)
      setValues(previousState => ({ ...previousState, [id]: value }));
      return true
    },
    test: function (method, id, ...value) {
      // console.log(">>> test")
      // console.log("handleEvent > method : " + method)
      // console.log("handleEvent > id : " + id)
      // console.log("handleEvent > value : " + value)
      return true
    }
  };

  const handleEvent = (method, id, ...value) => {
    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }
  }


  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드 
  // =====================================================
  const { today_collect_date } = RenderCrawlingHooks(el);
  

  // =====================================================
  // [S] 반응형을 위한 변수 할당
  // =====================================================
  const lanking_component = {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`
  }

  return (
    <>
      <Container fluid className={"p-0"}>
        <div className="col-xs-12 col-md-12 mb-5 dashboard_title">
          <div className={`card card-custom ${className}`}>
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                {list}
              </h3>
              <div className="d-flex align-items-center flex-wrap input_box" style={{ padding : "10px 0"}}>
                <KiciDateInput el={el}/>
              </div>
            </div>
          </div>
        </div>
        <Row key={Math.random()} >
          <Col className="mb-5 col-xxl-8" xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className={`card card-custom ${className} kici_custom_card`} >
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  데이터 수집량
                </h3>
              </div>
              <div className="card-body pt-2 kici_custom_card_body chart_category">
                { chartData && <ChartCommon chartData={chartData} /> }
              </div>
            </div>
          </Col>
          <Col className="mb-5 col-xxl-3" xs={12} sm={12} md={4} lg={4} xl={4} style={lanking_component}>
            <div className="kici_side_wrap">
              <KiciUpdateToday recentCrawling={recentCrawling}/>
              <div className="crawl_data_wrap">
                <div>
                  <h3>오늘 확인한 데이터</h3>
                </div>
                <div className="crawl_data_item">
                  <span>
                    <img src={data_icon} alt="데이터 아이콘"/>
                  </span>
                  <span>{ today_collect_date && today_collect_date }</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row key={Math.random()}>
          <Col className="mb-5 col-xxl-6" xs={12} md={8} lg={6}>
            <KiciHistoryFilter className={className} el={el}/>
            <div className={`card card-custom ${className} kici_custom_card`} style={{ marginTop : "10px" }}>
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  전체내역
                </h3>
              </div>
              <div className="card-body pt-4 kici_custom_card_body">
                <KiciAllHistory el={el}/>
              </div>
            </div>
          </Col>
          <Col className="mb-5" xs={12} md={6} lg={5} style={{ height : "auto !important" }}>
            <div className={`card card-custom ${className} kici_custom_card keyword_grid_wrap`}>
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  키워드 집계
                </h3>
              </div>
              <div className="card-body pt-4 keyword_chart_wrap kici_custom_card_body">
                <ChartKeyword />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
