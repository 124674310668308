
import React from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { date } from "yup";


export function Install() {

    // //DB
    // let host = ''
    // let id = ''
    // let pw = ''
    // let db_name = ''
    // let db_type = 'mongodb'

    //API
    let result = ''

    //================================================================================
    //CREATE INIT TABLE
    //site /siteGroup / group / user / menu / page / component / notice   초기 파일 생성
    Install = () => {

        InsertText('[DONE] install start!')
        // let text = uuidv4().split("-").join("")
        // InsertText(text)

        const instance = axios.create({
            baseURL: axios.defaults.serverURL,
            timeout: 1000,
            headers: { 'Content-Type': 'application/json' }
        });

        let siteIdx = ''
        let userIdx = ''
        let workspaceIdx = ''

        let username = 'admin'
        let email = 'admin@test.com'
        let password = 'test'



        install_isExistUser(instance, email).then(
            resp => {
                console.log('DONE isExistUser ===============')
                console.log(resp);
                if (resp == "Exist") {
                    console.log('isExistUser true')
                    InsertText('[DONE] isExistUser true')
                    InsertText('[DONE] 이미 존재하는 아이디 입니다.')

                } else {

                    console.log('isExistUser false')

                    // 0. Site INSTALL
                    install_site(instance).then(
                        resp => {
                            console.log('DONE install_site ===============')
                            console.log(resp);
                            siteIdx = resp

                            // 0. Team Install
                            install_team(instance, siteIdx).then(
                                resp => {
                                    console.log('DONE install_team ===============')
                                    console.log(resp);
                                }
                            )

                            // 0. TplComponent INSTALL
                            install_component(instance, siteIdx, userIdx, workspaceIdx).then(
                                resp => {
                                    console.log('DONE Component ===============')
                                    console.log(resp);
                                }
                            )

                            install_user(instance, siteIdx, username, email, password).then(
                                resp => {
                                    console.log('DONE adminUser ===============')
                                    console.log(resp);
                                }
                            )
                        }
                    )




                }

            }
        )










    }




    //===================================================================================================
    //===================================================================================================
    //===================================================================================================
    //===================================================================================================




    function install_isExistUser(instance, email) {
        return new Promise((resolve, reject) => {
            const req = { query: "{ install_isExistUser(email: \"" + email + "\") }" };
            instance.post('graphql/', req)
                .then(resp => {
                    // InsertText('[DONE] isExistUser')
                    resolve(resp.data.data.install_isExistUser)
                })
                .catch(error => {
                    InsertText('ERR install_isExistUser >' + error)
                });
        });
    }



    function install_site(instance) {
        return new Promise((resolve, reject) => {
            const req = { query: "{ install_site }" };
            instance.post('graphql/', req)
                .then(resp => {

                    InsertText('[DONE] install_site > IDX : ' + resp.data.data.install_site)
                    resolve(resp.data.data.install_site)
                })
                .catch(error => {
                    InsertText('ERR install_site >' + error)
                });
        });
    }

    function install_team(instance, siteIdx) {
        return new Promise((resolve, reject) => {
            const req = { query: "{ install_team(siteIdx: \"" + siteIdx + "\") }" };
            instance.post('graphql/', req)
                .then(resp => {
                    InsertText('[DONE] install_team')
                    resolve(resp.data.data.install_team)
                })
                .catch(error => {
                    InsertText('ERR install_team >' + error)
                });
        });
    }

    function install_component(instance, siteIdx, userIdx, workspaceIdx) {
        return new Promise((resolve, reject) => {
            const req = { query: "{ install_component(siteIdx: \"" + siteIdx + "\", userIdx: \"" + userIdx + "\", workspaceIdx: \"" + workspaceIdx + "\") }" };
            instance.post('graphql/', req)
                .then(resp => {
                    InsertText('[DONE] install_component')
                    resolve(resp.data.data.install_component)
                })
                .catch(error => {
                    InsertText('ERR install_component >' + error)
                });
        });
    }


    function install_user(instance, siteIdx, username, email, password) {
        return new Promise((resolve, reject) => {
            const req = { query: "{ install_user(siteIdx: \"" + siteIdx + "\", username: \"" + username + "\", email: \"" + email + "\", password: \"" + password + "\") }" };
            instance.post('graphql/', req)
                .then(resp => {
                    console.log(JSON.stringify(resp.data))
                    InsertText('[DONE] install_user')
                    resolve(resp.data.data)
                })
                .catch(error => {

                    InsertText('ERR install_user >' + error)
                });
        });
    }




    function InsertText(text) {
        result = '<div>[' + new Date().toLocaleString() + '] ' + text + '</div>' + result
        let target = document.getElementById('result');
        target.innerHTML = result
    }

    return (
        <div className="d-flex flex-column flex-root">
            <button onClick={Install}>
                DB AND CONNECT INFO
            </button>
            <div id='result'></div>
        </div>
    );
}
