/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef,useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ReactDOM from 'react-dom';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { actionTypes } from "../../../../redux/actionType";
import { WeatherSetting } from "./WeatherSetting";


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import '../../../templates/type1/_assets/sass/components/utilities/_weather.css'
import axios from 'axios';

export function Weather({ el, onRef, states, className }) {
  
  
  let id = "component" + el.i

  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)

  
  // rendering 될 필요한 변수
  const [cityName, setCityName] = useState(el.data.prop.cityName);
  const [nowDate, setNowDate] = useState('');
  const [weather, setWeather] = useState('');
  const [weatherIcon, setWeatherIcon] = useState('');
  const [temp, setTemp] = useState('');
  const [tempMin, setTempMin] = useState('');
  const [tempMax, setTempMax] = useState('');
  
  //inputBox 참조하려고 useRef사용
  const inputEl = useRef('');




  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {    
    let parent = document.getElementById("contentModal")
    if (parent != null) {        
      ReactDOM.render(
        <WeatherSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))        
    } else {

    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================








  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };

  //cityName 이 변경될때마다 실행되는 useEffect 날씨를 조회함
  useEffect(() => {         
    WeatherCheck();     
  }, [cityName]);


  // if (states[id] === undefined) return (<></>)
  // if (states[id] === undefined) {
  //   getCurrentWeather();
  // } 

  // 날씨 조회 ( input 박스에 있는 value 값으로 날씨조회)
  const onClick = () => {
    
    if(!inputEl.current.value.trim()){ 
      alert('조회 할 지역을 입력하세요');
      return;
    } ;
    setCityName(inputEl.current.value);    
    // dispatch({ type: actionTypes.reloadPage, payload: 'test' })
    
  }
   

  // 처음 컴포넌트생성하고 cityName 을 저장하지않으면 (검색누르지않으면) cityName이 undefined로 되어있어
  // 현재 위치기반으로 지역을 찾음
  // 위치기반 찾는건 비동기함수라 Promise사용하여 동기로 변경
   const getInitUrl = (options) =>{
    return new Promise((resolve,reject)=>{
      navigator.geolocation.getCurrentPosition((position)=>{
        const API_KEY = "63f7e20999d893b9981ee5ec1b241c94";    
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=metric`
        resolve(url);
      }, ()=>{
        reject('error');
      });
    })
  }


  //날씨 조회 함수
  const WeatherCheck = () => {                
    //cityName 이 undefined 일경우는 최초 날씨 컴포넌트를 만든경우! (아래  axios 조회부분은 동일한 부분이므로 한개로 수정)
    if(cityName === "undefined" ){      
      // navigator.geolocation.getCurrentPosition 함수가 비동기이고 axios 가 비동기라 promise로 동기화처리
      getInitUrl().then((initUrl)=> axiosCall(initUrl));      
    }else{      
      //cityName 이 저장된경우!      
      const API_KEY = "63f7e20999d893b9981ee5ec1b241c94";    
      const url = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${API_KEY}&units=metric`;
      axiosCall(url);
    }                       
  }


  //axios 호출 하는 함수
  const axiosCall = (url) =>{           

    // axios 요청시 헤더 authorization 삭제함 .. 405 에러뜨면서 Preflight 문제 ..이건 좀더찾아봐야할듯? 전역으로 Authorization 넣어주는데..
     axios.get(url,{transformRequest :(data,headers)=>{delete headers.common['Authorization'];}})
     .then(responseData => {              
      
      //날씨 데이터 받아옴
      const data = responseData.data;
      
      // 날씨 데이터 해당 위치에 SET
      setCityName(data.name);
      setNowDate(new Date().toLocaleDateString());
      setWeather(data.weather[0].main);
      setWeatherIcon(data.weather[0].icon);
      setTemp(data.main.temp.toString().slice(0,2));
      setTempMin(data.main.temp_min.toString().slice(0,2));
      setTempMax(data.main.temp_max.toString().slice(0,2));

      // spread (전개구문) 문법을 써서 기존 data 에 cityName만 변경하는것
      // WebViewer.js 에서 보면 selectSetting 함수에서 다르게 해주었는데..윤팀장님방법으로 해도됨.
      const newObject = {
        ...el,
        data : {
                ...el.data,
                prop : {
                        ...el.data.prop,
                        cityName :  cityName
                }
        }    
      };      
      
      dispatch({ type: actionTypes.UpdateComponent, payload: { pageIdx: currentPage.slice("page".length), component: newObject } })
      

    })
    .catch((error) => {            

      setCityName(cityName);
      setNowDate('');
      setWeather('');
      setWeatherIcon('');
      setTemp('');
      setTempMin('');
      setTempMax('');

      alert('Error');
      console.log(error);
      console.log(error);
      console.log(error);
    });
   
  };

  return (
    <div  ref={onRef}  style={{ height: "100%" }}>

      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">WEATHER</div>
              <div className="font-size-sm text-muted mt-2">
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        <div className="card-body d-flex flex-column px-0">

        {/* begin::Items */}
        <div className="flex-grow-1 card-spacer-x weather-info">
          <div className="cityName">{cityName}</div>
          <div className="date">{nowDate}</div>
          <div className="weather">{weather}</div>
          <div>
            <img className="weatherIcon" alt="날씨 아이콘" src={`http://openweathermap.com/img/w/${weatherIcon}.png`}/>
            <span className="temparature">{temp}˚</span>
          </div>
          <div>
            <span className="tempMin">최저 기온 :{tempMin}˚</span>
            <span className="tempMax">/ 최고 기온 :{tempMax}˚</span>
          </div>
          
          {/* <form> */}
            <input type="text" name="city" placeholder="영어로 입력해 주세요" ref={inputEl}></input>
            <input type="submit" value="검색" onClick={onClick}></input>
          
        </div>

</div>



        {/* begin::Body */}


      </div>
      {/* end::Tiles Widget 1 */}


      </div>
  );
}
