import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../_helpers";
import { handleMoveSidebarCulumnIntoParent } from "../edit/WebBuilder/helpers";
import Utils from "../edit/WebBuilder/Utils";



export function RequestSiteModal(props) {

    const { parentId, ...rest } = props

    const [siteType, setSiteType] = useState("");
    const [siteName, setSiteName] = useState("")
    const [siteUrl, setSiteUrl] = useState("")
    const [purpose, setPurpose] = useState("")

    const siteTypeList = ["선택", "쇼핑몰", "커뮤니티", "뉴스", "기타"];

    useEffect(() => {

        if (props.show) {

        }
    })

    const handleSiteType = (e) => {
        setSiteType(e.target.value)
    }

    const handleSiteName = (e) => {
        setSiteName(e.target.value)
    }

    const handleSiteUrl = (e) => {
        setSiteUrl(e.target.value)
    }

    const handlePurpose = (e) => {
        setPurpose(e.target.value)
    }

    const requestSiteForm = () => {

        if (!siteType || !siteName || !siteUrl || !purpose) {
            alert("문항을 작성해주세요")
            return
        }

        const obj = {
            siteIdx: props.data.siteIdx,
            userIdx: props.data.userIdx,
            siteType: siteType,
            siteName: siteName,
            siteUrl: siteUrl,
            purpose: purpose,
            registDate: new Date()
        }


        let payload = {
            queries: [
                {
                    key: "insertRequestSite",
                    query: `db.collection('RequestSite').insertOne(${JSON.stringify(obj)})`
                },

            ]
        }

        Utils.getQueryResult(payload).then((result) => {
            alert("제출되었습니다")

            // 모달창 닫으면서 form 초기화
            setSiteType("")
            setSiteName("")
            setSiteUrl("")
            setPurpose("")

            rest.onHide()
        });

    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <a onClick={() => rest.onHide()}><img className="po-modal-close" src={toAbsoluteUrl("/media/pageone/button_modal_close.png")} /></a>
                <div className="po-modal-title d-flex">
                    사이트 추가 요청하기
                </div>

                <div className="po-modal-title-divider" />

                <div className="po-request-form">
                    <div className="po-request-form-title d-flex align-items-center">
                        <img src={toAbsoluteUrl("/media/pageone/icon_pageone_gray.png")} />
                        <span>추가를 원하는 사이트에 대한 정보를 적어주세요.</span>
                    </div>

                    <div className="po-request-body">
                        <label>
                            사이트 유형
                        </label>

                        <select onChange={handleSiteType} value={siteType} name="site-type">
                            {
                                siteTypeList.map((item, idx) => (
                                    idx === 0
                                        ?
                                        <option value="" key={Math.random()}>{item}</option>
                                        :
                                        <option value={item} key={Math.random()}>{item}</option>
                                ))
                            }
                        </select>

                        <label>
                            사이트 이름
                        </label>

                        <input className="request-site-name" type="text" onChange={handleSiteName} value={siteName} placeholder="쇼핑몰의 이름을 입력하세요." />

                        <label>
                            사이트 url
                        </label>

                        <input className="request-site-url" type="text" onChange={handleSiteUrl} value={siteUrl} placeholder="쇼핑몰의 주소를 입력하세요." />

                        <label>
                            데이터 수집 목적
                        </label>

                        <textarea onChange={handlePurpose} placeholder="직접입력">

                        </textarea>

                        <div className="po-purpose-text">
                            * 수집 목적을 입력해주시면, <b>원하는 데이터</b>를 수집할 수 있도록<br />
                            조치하겠습니다 :&#41;
                        </div>

                        <div className="po-request-submit">
                            <a onClick={requestSiteForm}>
                                <img src={toAbsoluteUrl("/media/pageone/button_submit_survey.png")} />
                            </a>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}