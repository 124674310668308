
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal, Form } from 'react-bootstrap';

export function WelcomeSetting(props) {
    const { data, parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Welcome Setting {parentId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Please set {data} Setting !



                <br /><br />

                {(data == "setting") ?
                    <div>
                        <Button style={btnStyle} size='lg' onClick={() => {
                            parentCallback("Setting A")
                            rest.onHide()
                        }}> Setting A</Button>


                        <Button style={btnStyle} size='lg' onClick={() => {
                            parentCallback("Setting B")
                            rest.onHide()
                        }}> Setting B</Button>
                    </div>
                    : <></>
                }



                {(data == "info") ?
                    <div>
                        INFO...
                    </div>
                    : <></>
                }


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}