import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { Brand } from "../brand/Brand";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { useHtmlClassService } from "../../_core/WiseLayout";


export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
        objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
        false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo()
    };
  }, [uiService]);




  return (
    <>
      {/* begin::Aside */}
      <div id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>

        {/* logo */}
        <Brand />


        {/* begin::Aside */}
        {/* hardcoder */}
        {/* <div style={{ padding: "9px 25px", backgroundColor: "white" }}>
          <div className="text-primary"><small>HYORIM MONITERING SYSTEM</small></div>
          <div className="text-primary"><small>캠프 워커 및 주변 지역 토양정화 용역</small></div>
        </div> */}

        {/* begin::Aside Menu */}
        <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">

          {/* hardcoder
          layoutProps.disableAsideSelfDisplay == false 이면 
          side menu전체가 사라지는데 
          로고를 나타내는 의미가 있을까?  */}

          {/* {layoutProps.disableAsideSelfDisplay && (
            <>
              <div className="header-logo">
                <Link to="">
                  <img alt="logo" src={layoutProps.headerLogo} />
                </Link>
              </div>
            </>
          )} */}

          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
