/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import { UnitTable2 } from "../edit/WebBuilder/Element/UnitTable2";

import { shallowEqual, useDispatch, useSelector } from "react-redux";



import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { actionTypes } from "../../../../redux/actionType";
import { UploadExcelSetting } from "./UploadExcelSetting";
import { Header } from "../hyorim/Header";

import * as XLSX from 'xlsx';

import Utils from "../edit/WebBuilder/Utils";

import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import axios from "axios";

import { useResizeDetector } from 'react-resize-detector';


export function UploadExcel({ el, onUpdateAutoHeight, states, className }) {

    let id = "component" + el.i

    const fullScreenHandle = useFullScreenHandle();

    const [image, setImage] = useState('');
    const [link, setLink] = useState('');

    const dispatch = useDispatch();
    const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
    const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)
    const user = useSelector((state) => state.auth.user, shallowEqual);
    // const currentPage = modl.currentPage
    // const modl = useSelector((state) => state.modl, shallowEqual)
    // console.log(modl)

    const [data, setData] = useState();
    const [layout, setLayout] = useState();
    const [uploaded, setUploaded] = useState(false);
    const [rootHeight, setRootHeight] = useState(0);



    // =====================================================
    // [S] modal - setModalShow(true)
    // =====================================================

    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        let parent = document.getElementById("contentModal")
        if (parent != null) {
            ReactDOM.render(
                <UploadExcelSetting
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    parentCallback={selectSetting}
                    link={link}
                    image={image}
                />,
                document.getElementById("contentModal"))
        } else {
        }
    });





    //==============================================================
    // [S] Event (예약어를 제외한 나머지 언급된 setting key )
    //==============================================================

    var functionObj = {
        setting: function () {
            setModalShow(true)
            return true
        }
    };

    useEffect(() => {

        if (states[id] == undefined) return
        let method = Object.keys(states[id])[0]
        if (functionObj.hasOwnProperty(method)) {
            functionObj[method]()
        } else {
            console.log("method: not exist \nInsert key in functionObj")
        }

    }, [states[id]]);






    // =====================================================
    // [S] Handle Event
    // =====================================================

    const handleEvent = (id, value) => {


    }





    // =====================================================
    // [S] Resize Event
    // =====================================================
    const onResize = useCallback((width, height) => {
        if (el.isAutoHeight == true) {

            onUpdateAutoHeight(el.i, height)

            // console.log("height: " + height)

            // let convH = ((height + 25) / (0 + 25))
            // console.log("convH: " + convH)

            // let realHeight = (Math.ceil(convH) * 25 ) - 25
            // console.log("realHeight: " + realHeight)

            // document.getElementById("test").style.height = realHeight + "px"
           
        }
    }, []);

    const { ref, width, height } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        // refreshMode: 'debounce',
        // refreshRate: 300,
        onResize
    });



    // =====================================================
    // [S] Init.
    // =====================================================

    useEffect(() => {



        // STYLE
        const _style = {
            "table": {
                overflow: "auto",
                minWidth: "900px",
                marginTop: "20px",
                marginBottom: "0"
            },
            "thead": {
                position: "sticky",
                top: "0"
            },
            "tbody": {
            },
            "tfoot": {
                position: "sticky",
                bottom: "0"
            },
            "tr": {

            },
            "th": {
                padding: "15px 22px 15px 22px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#ebebeb",
                backgroundColor: "#fafafb",
                textAlign: "center"
            },
            "td": {
                padding: "30px 22px 30px 22px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#ebebeb",
                textAlign: "center"

            },

        }


        const _settings = {
            "tableType": "dataTable",//dataTable , table

            "isAllCheck": false,
            "isPaging": true,

            "isDelete": false,
            "isAdd": false,
            "isUpdate": false,
            "isExcel": false,

            "itemCountOfPage": 5,
            "pageCountOfGroup": 10

        }


        // const _header = [
        //     {
        //         title: `idx`,
        //         style: {
        //             width: "60px"
        //         }
        //     },
        //     {
        //         title: '업로드 시간',
        //         style: {
        //             width: "200px"
        //         }
        //     },
        //     {
        //         title: '엑셀명',
        //     },
        //     {
        //         title: '버전',
        //         style: {
        //             width: "120px"
        //         }
        //     },
        //     {
        //         title: '업로드 사용자',
        //         style: {
        //             width: "200px"
        //         }
        //     },    
        // ];

        // const _body = [
        //     {
        //         title: '={autoIncrease}',
        //     },
        //     {
        //         title: '={registDate}',
        //     },
        //     {
        //         title: '={data.fileName}',
        //     },
        //     {
        //         title: '={data.version}',
        //     },
        //     {
        //         title: '={data.userName}',
        //     },
        // ];


        const _header = [
            {
                title: `idx`,
                style: {
                    width: "60px"
                }
            },
            {
                title: '업로드 시간',
                style: {
                    width: "200px"
                }
            },
            {
                title: '엑셀명',
            },
            {
                title: '버전',
                style: {
                    width: "120px"
                }
            },
            {
                title: '업로드 사용자',
                style: {
                    width: "200px"
                }
            },
            {
                title: '다운로드',
                style: {
                    width: "100px"
                }
            },
        ];

        const _body = [
            {
                title: '={autoDecrease}',
            },
            {
                title: '={registDate}',
            },
            {
                title: '={fileName}',
            },

            {
                title: '={version}',
            },
            {
                title: '={userName}',
            },
            {
                title: '={filePath}',
                type: 'link',
                link: {
                    text: '다운로드'
                }
            },
        ];


        const _footer = [

        ];


        let _layout = {}
        _layout["style"] = _style
        _layout["settings"] = _settings

        _layout["header"] = _header
        _layout["body"] = _body
        _layout["footer"] = _footer


        setLayout(_layout)


    }, []);



    // =====================================================
    // [S] 컴포넌트 초기화시 데이터 로드 
    // =====================================================
    useEffect(() => {

        if (layout == undefined) return


        // // NEW
        let payload = {
            queries: [
                {
                    key: "dataList",
                    query: `db.collection('ExcelUpload').find({}).sort({ registDate: -1 }).toArray()`
                },

            ]
        }



        Utils.getQueryResult(payload).then((result) => {
            // console.log("loaded data : ")
            // console.log(result)
            setData(result)

            console.log("loaded data ~~~~~~!!!")

        });


    }, [layout, uploaded])



    useEffect(() => {

        console.log("~~~~~~!!!")
    }, []);




    const handleFileChange = (event) => {
        // setValue(event.target.files[0]);

        if (event.target.files[0].type.includes("spreadsheet") || event.target.files[0].type.includes("haansoftxlsx")) {
            handleFileUpload(event.target.files);
        } else {
            alert("엑셀 파일만 업로드 가능합니다.");

            console.log(event.target.files[0].type)
        }

        event.target.value = ""
    };




    // // NEW
    const handleFileUpload = (files) => {

        const file = files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const excelData = e.target.result;
            let readedData = XLSX.read(excelData, { type: 'binary', cellText: false, cellDates: true });
            const wsname = readedData.SheetNames;

            let errLog = []
            let allData = []

            console.log("sheet count:" + wsname.length)

            for (let i = 1; i < wsname.length; i++) {

                // if (wsname[i] != "HR-CLR-05") continue

                const ws = readedData.Sheets[wsname[i]];
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, raw: false, dateNF: "yyyy-mm-dd" });

                let _requires = dataParse[2] //필수값 Y/N
                let _explain = dataParse[5] //설명
                let _keys = dataParse[3] //키값

                let startRow = 6
                let startCol = 3

                let requires = _requires.slice(startCol);
                let explain = _explain.slice(startCol);
                let keys = _keys.slice(startCol);
                let primaryKeys = []

                let totalRow = dataParse.length

                // console.log(">> totalRow:" + totalRow)
                // console.log(">> totalCol:" + totalCol)

                // +있는 데이터 를 변경하고 primary key 키로 넣는다
                keys.forEach((key, j) => {
                    if (key.charAt(0) == "*") {
                        key = key.substr(1);
                        keys[j] = key
                        primaryKeys.push(key)
                    }
                });

                console.log(">> sheet:" + wsname[i])
                console.log(">> key:" + keys)
                console.log(">> primaryKeys:" + primaryKeys)

                let sheetData = []
                for (let row = startRow; row < totalRow; row++) {
                    let values = dataParse[row].slice(startCol);
                    var data = {};
                    keys.forEach((key, j) => {

                        //check validation
                        // 1. 필수값 검증
                        if (requires[j] === 'Y') {
                            if (values[j] === null || values[j] === undefined || typeof values[j] == "undefined" || values[j] === "") {
                                // console.log("....")
                                // console.log(requires[j])
                                // console.log(values[j])
                                // console.log(typeof values[j])
                                errLog.push("필수값을 입력해주세요\n" + wsname[i] + " > " + explain[j] + " > " + values[j])
                            }
                        }

                        data[key] = values[j]
                    });


                    sheetData.push({ workspaceIdx: currentWorkspace.replace("workspace", ""), type: wsname[i], data: data })
                }


                //check validation
                // 2. 날짜 검증
                if (primaryKeys.length > 0) {
                    let checkPrimaryKey = []
                    sheetData.forEach((item, j) => {
                        let keySet = []

                        primaryKeys.forEach((pkey, k) => {
                            keySet.push(item.data[pkey])
                        });

                        let keySetStr = keySet.join(", ")

                        // console.log("> checkPrimaryKey")
                        // console.log(checkPrimaryKey)
                        // console.log(keySetStr)

                        if (checkPrimaryKey.includes(keySetStr)) {
                            errLog.push("데이터가 중복입니다.\n" + wsname[i] + " > " + primaryKeys.join(", ") + " > " + keySetStr)
                        } else {
                            checkPrimaryKey.push(keySetStr)
                        }
                    });
                }

                allData.push({ primaryKeys: primaryKeys, sheetData: sheetData })

            }


            //errLog validation
            if (errLog.length != 0) {
                console.log(errLog.join('\n'))
                alert(errLog.join('\n'))
                return
            }


            let dataObj = {};
            dataObj.allData = allData


            const versionParse = XLSX.utils.sheet_to_json(readedData.Sheets[wsname[0]], { header: 1, blankrows: false, raw: false });
            dataObj.uploadInfo = {
                workspaceIdx: currentWorkspace.replace("workspace", ""),
                userName: user.name,
                fileName: file.name.substring(0, file.name.lastIndexOf('.')),
                version: versionParse[1][1],
                registDate: new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '')
            }

            // console.log(">> dataObj")
            // console.log(JSON.stringify(dataObj))

            saveExcel(file, dataObj)


        };
        reader.readAsBinaryString(file);
    }



    const saveExcel = (file, dataObj) => {

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
        })

        const bucket = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_BUCKET },
            region: process.env.REACT_APP_REGION
        })


        console.log("file.name")
        console.log(file.name)


        let dataArr = []

        const putObjectPromise = []
        const originFileName = file.name.split('.').reverse()[1];
        const fileName = originFileName + "|" + uuidv4().split("-")[4] + '.' + file.name.split('.').reverse()[0];

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: process.env.REACT_APP_BUCKET,
            Key: "excel/" + fileName
        }

        let filePath = "https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/excel/" + fileName
        dataArr.push(filePath);
        dataObj.uploadInfo.filePath = filePath

        putObjectPromise.push(bucket.putObject(params).promise())

        Promise.all(putObjectPromise).then(async () => {

            console.log("> UPLOAD DATA")
            console.log(dataObj.uploadInfo.filePath)





            //dispatch({ type: actionTypes.InsertExcelData, payload: { data: dataObj } })


            var instance = axios.create();

            const dataStr = JSON.stringify({ ...dataObj })

            const req = {
                query: `mutation insertExcelData($data: String) {
                insertExcelData(data: $data)
              }`,
                variables: {
                    data: dataStr
                }
            }

            return new Promise((resolve, reject) => {
                instance.post('graphql/', req)
                    .then(resp => {
                        setTimeout(() => {
                            setUploaded(new Date())
                        }, 1500)
                    })
                    .catch(error => {
                        reject(error)
                    });
            });










        })

    }






    // 기존.
    // const handleFileUpload = (files) => {

    //     const file = files[0];
    //     const dataObj = [];
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //         const data = e.target.result;
    //         let readedData = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true });
    //         const wsname = readedData.SheetNames;


    //         for (let i = 1; i < wsname.length; i++) {
    //             // const ws = readedData.Sheets["HR-SLCL-01"];
    //             const ws = readedData.Sheets[wsname[i]];
    //             const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, raw: false, dateNF: "yyyy-mm-dd" });


    //             /** 
    //              * 엑셀에서 필수값이 Y인 열에 데이터가 있는지 체크
    //              * 테스트환경에서는 주석처리 후 작업
    //              */
    //             for (let row = 5; row < dataParse.length; row++) {
    //                 for (let col = 2; col < dataParse[2].length; col++) {
    //                     if (dataParse[2][col] === 'Y' && (dataParse[row][col] === null || dataParse[row][col] === undefined || dataParse[row][col] === "")) {
    //                         alert("필수값을 입력해주세요.");
    //                         return;
    //                     }
    //                 }
    //             }

    //             dataObj[wsname[i]] = dataParse;
    //             // dataObj["HR-SLCL-01"] = dataParse;
    //         }

    //         const versionParse = XLSX.utils.sheet_to_json(readedData.Sheets[wsname[0]], { header: 1, blankrows: false, raw: false });


    //         dataObj.uploadInfo = {
    //             userName: user.name,
    //             file: file.name.substring(0, file.name.lastIndexOf('.')),
    //             version: versionParse[1][1],
    //             registDate: new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '')
    //         }

    //         saveData(dataObj);

    //     };
    //     reader.readAsBinaryString(file);
    // }



    // const saveData = (data) => {

    //     setUploaded(new Date())

    //     dispatch({ type: actionTypes.InsertExcelData, payload: { data: data } })

    // }

    // =====================================================
    // [E] modal
    // =====================================================

    useEffect(() => {
        setLink(el.data.link)
        setImage(el.data.image)
    }, [states, el]);

    const selectSetting = (data) => {

        let image = JSON.parse(data).image
        let link = JSON.parse(data).link

        setLink(link)
        setImage(image)

        let elCopy = JSON.parse(JSON.stringify(el))

        elCopy.data.link = link
        elCopy.data.image = image

        dispatch({ type: actionTypes.UpdateComponent, payload: { pageIdx: currentPage.slice("page".length), component: elCopy } })
    };

    let modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'align': [] }, { 'color': [] }, { 'background': [] }],

        ],
    }

    const btnClick = () => {
        document.getElementById("uploadExcel").click()
    }

    const delBtnClick = () => {

        if (window.confirm("데이터를 삭제하시겠습니까?")) {

            let payload = {
                queries: [
                    {
                        key: "delData",
                        query: `db.collection('Data').deleteMany({ type: { $nin: ["MAP-AREA", "WaterDumpList1"] }})`
                    }
                ]
            }
    
            Utils.getQueryResult(payload).then((result) => {
    
                if (result.delData.ok) {
                    alert("데이터가 삭제되었습니다.")
                }
    
            })
        }

    }



    if (states == undefined) return (<></>)

    if (data == "") return <></>

    return (

        <div ref={ref}>

        {/* <div ref={ref} style={{height: "100%"}}> */}
            {/* div 대신 <></> 를 쓰면 item size 만큼 배경을 채워진다. */}

            <FullScreen handle={fullScreenHandle}>

                <div style={{ backgroundColor: "transparent", boxShadow: "none" }} className={`card card-custom ${className}`}>



                    <Header el={el}></Header>
                    <div className="card-body shadow-xs d-flex flex-column p-0" style={{ backgroundColor: "white", borderRadius: "0.45rem" }} >
                        <div className="flex-grow-1" >

                            <div style={{ overflow: "hidden", padding: "12px 20px", lineHeight: "38.38px", borderBottom: "1px solid #f2f2f2" }}>

                                <div style={{ float: "left" }}>
                                    <div style={{ fontSize: "16px", fontWeight: "500" }}>엑셀 양식 다운로드</div>
                                </div>

                                <div className="d-flex align-items-center" style={{ float: "right" }}>
                                    <div>
                                        {/* <Button variant="danger" onClick={delBtnClick} style={{marginRight: "20px"}}>초기화</Button> */}
                                        <Button onClick={btnClick}> 엑셀 업로드 </Button>
                                    </div>
                                    {/* <div>
                                        <Button onClick={onClick}> SIZE </Button>
                                    </div> */}

                                    <Form>
                                        <Form.Control id={"uploadExcel"} type="file" style={{ display: "none" }} onChange={handleFileChange} />
                                    </Form>
                                </div>
                            </div>

                            <div style={{ overflow: "auto", clear: "both", paddingBottom: "20px" }}>
                                <UnitTable2 id={id} layout={layout} data={data} onEvent={handleEvent} ></UnitTable2>
                            </div>
                        
                        </div>
                    </div>

                </div>

            </FullScreen>


        </div>
    );
}