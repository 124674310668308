
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal, Form } from 'react-bootstrap';

export function YoutubePlayerSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const [code, setCode] = useState('');

    useEffect(() => {

        setCode(props.code)

    }, [props.code]);


    const handleChangeCode = (e) => {

        console.log(e.target.value)
        setCode(e.target.value)
    }


    
    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    YoutubePlayer Setting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>



                <Form>
                    <Form.Label>Youtube Code</Form.Label>
                    {/* <Form.Control type="text" placeholder="DnVK026VbLI" /> */}
                    <Form.Control type="text" value={code} placeholder="DnVK026VbLI" onChange={handleChangeCode}  />
                </Form>

                <br/>
                <div>
                    <Button style={btnStyle} size='lg' onClick={() => {

                        let data = {}

                        data.code = code


                        console.log(data.code)

                        parentCallback(JSON.stringify(data))


                        // rest.onHide()
                    }}> SAVE </Button>
                </div>




            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}