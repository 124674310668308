/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';

import { Document, Page, pdfjs  } from 'react-pdf';

import { PdfViewerSetting } from "./PdfViewerSetting";



export function PdfViewer({ el, states, className }) {

  let id = "component" + el.i


// Create new plugin instance
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <PdfViewerSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================







  // cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js
  pdfjs.GlobalWorkerOptions.workerSrc = 
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };


  const url = "/assets/sample.pdf"
  // "http://www.africau.edu/images/default/sample.pdf"

  return (
    <>



      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">PdfViewer</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>
        {/* end::Header */}



    <div >
      <Document
      
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        >
        <Page width={400} pageNumber={pageNumber} />
      </Document>
     </div>

        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}


      
    </>
  );
}


