import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { use_copy_func } from '../widgets/kici/customHooks/useClickHooks';
import Loading from './Loading';
import { Sweetalert_class } from './SweetAlert';

const KiciAllHistory = ({el}) => {

    // =============================================
    // redux state
    // =============================================
    const { chartList } = useSelector((state)=>state.chart);
    const { filterData } = useSelector((state)=>state.chart);
    const { keyword_data } = useSelector((state)=>state.keyword);

    // =============================================
    // state
    // =============================================    
    const [temp_state, set_temp_state] = useState(null);
    const [list, setList] = useState(!el ? ["재난문자", "다음 IT뉴스", "DC LOL갤", "DC 주갤", "DC 인방갤"] : [el.data.rowdata.source]);
    const [btn_mode, set_btn_mode] = useState(true);
    const [colorList, setColorList] = useState(["#f44336", "#1565c0", "#00bcd4", "#388e3c", "#ffc107"]);
    const [loading, set_loading] = useState(false);


    useEffect(()=>{
        set_temp_state(null);

        set_loading(true);

        let flag_filtering = true;
        
        const load_data = () => {
            if(filterData && flag_filtering){
              let word_arr = [];

              let intersection = 
              keyword_data.includes('전체') 
              ? filterData : filterData.filter(data => keyword_data.includes(data.filter))
              if(btn_mode){
              
                if(intersection.length === 0){
                    let _listData = 
                    <div className="font-weight-bolder text-dark-25 font-size-lg" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                        # 데이터가 없습니다.
                    </div>

                    set_temp_state(_listData);
                    set_loading(false);
                } else {

                    const deduplication_arr = intersection.reduce((acc, current) => {
                      const after_data = acc.find(data => (data.title === current.title) && (data.date === current.date));
                      if(!after_data) {
                        return acc.concat([current]);
                      } else {
                        return acc;
                      }
                    }, []);

                    let _listData =
                        <div className="timeline timeline-6 mt-3">
                          {
                            deduplication_arr.map((d, index, arr) => {
                            
                              let color = colorList[0]
                              let iArr = []
                              let moreArr = []

                              list.map((l, i) => {
                                if (l == d.source) {
                                  color = colorList[i]
                                  iArr = d.filter.split(",")
                                  moreArr = Object.keys(d.moreInfo).length > 0? d.moreInfo.more.split(",") : []
                                }
                              })

                              let time = d.date.substr(11, 5)
                              let title = d.title

                              iArr.forEach(function (out_item, i) {
                                let out_regex = new RegExp(out_item, "ig");
                                title = title.replaceAll(out_regex, `<span style="color:${color}">${out_item}</span>`)  

                                moreArr.forEach(function (in_item, i) {
                                  let in_regex = new RegExp(in_item, "ig");
                                  title = title.replaceAll(in_regex, `<span style="color:#6A5ACD">${in_item}</span>`)
                                })
                              });
                          
                              return (
                            
                                <div key={Math.random()} className="timeline-item align-items-start">
                                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                                    onClick={()=>{ use_copy_func(d.linkUrl) }}
                                  >
                                    {time}
                                  </div>
                                  <div className="timeline-badge">
                                    <i style={{ color: color }} className={`fa fa-genderless icon-xl`}></i>
                                  </div>
                                  <div className="timeline-content" onClick={() => window.open(`${d.linkUrl}`, "_blank")}>
                                    <div className="font-weight-bolder font-size-md text-dark-60 " >
                                      <span style={{ wordBreak: "break-word" }} className="p-3" dangerouslySetInnerHTML={{ __html: title }}></span>
                                    </div>
                                    <div className="font-size-md text-muted">
                                      <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: d.content }}></span>
                                      <div style={{ marginLeft: "9px" }} className={`font-size-xs `}>
                                        {(d.source == undefined) ? <></> : <span>{d.source}</span>}
                                        {(d.from == undefined) ? <></> : <span> {" | "} {d.from}</span>}
                                        {(d.author == undefined) ? <></> : <span> {" | "} {d.author}</span>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                    set_temp_state(_listData);
                    set_loading(false);
                }
              } else {
                if(intersection.length === 0){
                  let _listData = 
                  <div className="font-weight-bolder text-dark-25 font-size-lg" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                      # 데이터가 없습니다.
                  </div>

                  set_temp_state(_listData);
                  set_loading(false);
                } else {

                  intersection.map((data) => {
                    const temp_keyword = data.filter.split(",").toString();
                        
                    word_arr.push(temp_keyword);
                  });

                  const total_result_arr = word_arr.reduce((accu, curr)=>{
                    accu[curr] = (accu[curr] || 0) + 1;
                    return accu
                  }, {});

                  const count_result = Object.keys(total_result_arr).sort((prev, next)=>{
                    return total_result_arr[next] - total_result_arr[prev]
                  })

                  const deduplication_arr = count_result.reduce((acc, current) => {
                    const after_data = acc.find(data => (data.title === current.title) && (data.date === current.date));
                    if(!after_data) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, []);

                  let _listData =
                    <div className="timeline timeline-6 mt-3">
                    { 
                      deduplication_arr.map((out_data)=>{
                        
                        const temp_arr = intersection.map((d)=>{
                          if(d.filter === out_data){
                            
                            let color = colorList[0]
                            let iArr = []
                            list.map((l, i) => {
                              if (l == d.source) {
                                color = colorList[i]
                                iArr = d.filter.split(",")
                              }
                            })
                          
                            let time = d.date.substr(11, 5)
                            let title = d.title

                            iArr.forEach(function (item, i) {
                              let regex = new RegExp(item, "ig");
                              title = title.replaceAll(regex, `<span style="color:${color}">${item}</span>`)
                            });

                            return (

                              <div key={Math.random()} className="timeline-item align-items-start">
                                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                  {time}
                                </div>
                                <div className="timeline-badge">
                                  <i style={{ color: color }} className={`fa fa-genderless icon-xl`}></i>
                                </div>
                                <div className="timeline-content" onClick={() => window.open(`${d.linkUrl}`, "_blank")}>
                                  <div className="font-weight-bolder font-size-md text-dark-60 " >
                                    <span style={{ wordBreak: "break-word" }} className="p-3" dangerouslySetInnerHTML={{ __html: title }}></span>
                                  </div>
                                  <div className="font-size-md text-muted">
                                    <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: d.content }}></span>
                                    <div style={{ marginLeft: "9px" }} className={`font-size-xs `}>
                                      {(d.source == undefined) ? <></> : <span>{d.source}</span>}
                                      {(d.from == undefined) ? <></> : <span> {" | "} {d.from}</span>}
                                      {(d.author == undefined) ? <></> : <span> {" | "} {d.author}</span>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })

                        return temp_arr
                      })
                    }
                  </div>
                
                set_temp_state(_listData);

                set_loading(false);
                }
              }
            }
        }

        load_data()

        return()=>{
            flag_filtering = false;
        }
    }, [keyword_data, chartList, btn_mode]);

    return(
        <>
          <div className="history_btn_wrap">
            <div className='flex_btn_wrap'>
              <button 
                onClick={()=>{ set_btn_mode(true) }}
                className={ btn_mode ? "active_btn" : "disabled_btn" }
              >최신순</button>
              <button 
                onClick={()=>{ set_btn_mode(false) }}
                className={ !btn_mode ? "active_btn" : "disabled_btn" }
              >수집량순</button>
            </div>
          </div>
          <div className="kici_timeline_wrap">
          { 
              !temp_state ?
                  chartList && chartList
              : temp_state
          }
          {
            loading && <div><Loading /></div>
          }
          </div>
        </>
    )
}

export default KiciAllHistory;