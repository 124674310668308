import React from 'react';
import eye_icon from '../widgets/kici/style/img/eyeicon.png';
import Loading from './Loading';

const KiciTodayData = ({today_collect_date}) => {
    return(
        <div className="side_today_data">
            <div>
                <h3>오늘 확인한 데이터</h3>
            </div>
            <div className="update_check_wrap">
                <span>
                    <img src={eye_icon} alt="눈 모양 아이콘"/>
                </span>
                <span>
                    {
                        today_collect_date ? today_collect_date : <Loading color={'#95b9ff'} size={48} />
                    }
                </span>
          </div>
        </div>
    )
}

export default KiciTodayData;