/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, forwardRef, useState, useCallback } from "react";

//import HR_WTCL_04 from "../hyorim/HR_WTCL_04"

export function FlowMap({ el, states, className }) {

  return (
    <>
      {/* <HR_WTCL_04 el={el} states={states} className={className}></HR_WTCL_04> */}
    </>
  );
}
