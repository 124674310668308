import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
import Utils from "../widgets/edit/WebBuilder/Utils";
import { Sweetalert_class } from "./SweetAlert";

export function ManageMember_Add(props) {
  const {
    parentId,
    parentCallback,
    checkEmailCallback,
    isCheckEmail,
    action,
    selectIndex,
    menu,
    memberList,
    groupAll,
    parentWorkspaceGroup,
    ...rest
  } = props;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [userIdx, setUserIdx] = useState('');
  const [phone, setPhone] = useState("");
  const [memo, setMemo] = useState("");
  const [groupIdx, setGroupIdx] = useState("");
  const [existCheckEmail, setExistCheckEmail] = useState([]);
  const [existCheckEmailText, setExistCheckEmailText] = useState("new");
  const [group_list, set_group_list] = useState([]);

  let class_put;

  useEffect(() => {
    if (rest.show == true) {
      // console.log("menu > ")
      // console.log(menu)
      // console.log("memberList > ")
      // console.log(memberList)
      // console.log("action > ")
      // console.log(action)
      // console.log("selectIndex > ")
      // console.log(selectIndex)

      console.log("isCheckEmail >");
      console.log(isCheckEmail);

      setExistCheckEmail(isCheckEmail);

      if (action == "edit") {
        console.log("edit >");

        console.log(memberList[selectIndex].userInfo[0].email);
        setEmail(memberList[selectIndex].userInfo[0].email);
        setName(memberList[selectIndex].userInfo[0].name);
        setPassword(memberList[selectIndex].userInfo[0].password);
        setPasswordConfirm(memberList[selectIndex].userInfo[0].password);
        setPhone(memberList[selectIndex].userInfo[0].phone);
        setMemo(memberList[selectIndex].userInfo[0].memo);
        
        setUserIdx(memberList[selectIndex].userInfo[0]._id);
        setGroupIdx(memberList[selectIndex].keywordGroupIdx);
      } else if (action == "add") {
        console.log("add >");

        setEmail("");
        setName("");
        setPassword("");
        setPasswordConfirm("");
        setPhone("");
        setMemo("");

        setUserIdx("");
        setGroupIdx("");

        setExistCheckEmail([]);
      }

      // console.log("allowMenuList > ")
      // console.log(allowMenuList)
    }
  }, [rest.show]);

  useEffect(() => {
    setExistCheckEmail(isCheckEmail);
  }, [isCheckEmail]);

  useEffect(() => {
    // console.log("existCheckEmail>>");
    // console.log(existCheckEmail);

    if (existCheckEmail != null) {
      if (existCheckEmail.length == 0) {
        setExistCheckEmailText("");
      } else {
        if (action == "add") {
          setExistCheckEmailText("[존재하는 아이디 입니다. 초대하시겠습니까?]");
        }
      }
    }
  }, [existCheckEmail]);

  useEffect(() => {
    let payload = {
      queries: [
        {
          key: "groupList",
          query: `db.collection('KeywordGroup').aggregate([
                                { $match: { "workspaceIdx" : "${menu[0].workspaceIdx.replace(
                                  "workspace",
                                  ""
                                )}" }}
                            ]).toArray()`,
        },
      ],
    };

    Utils.getQueryResult(payload).then((result) => {
      set_group_list(result.groupList);
    });
  }, []);

  const btnStyle = {
    margin: "2px",
  };

  const changeEmail = (e) => {
    let payload = {
      email: e.target.value,
    };

    checkEmailCallback(payload);

    setEmail(e.target.value);
  };

  const changeName = (e) => {
    setName(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const changePasswordConfirm = (e) => {
    // console.log(">> changeTitle")
    // console.log(e.target.value)
    setPasswordConfirm(e.target.value);
  };

  const changePhone = (e) => {
    // console.log(">> changeTitle")
    // console.log(e.target.value)
    setPhone(e.target.value);
  };

  const changeMemo = (e) => {
    // console.log(">> changeTitle")
    // console.log(e.target.value)
    setMemo(e.target.value);
  };

  // const getCheckState = (id) => {
  //     // console.log("^^ getCheckState")
  //     let isCheck = false

  //     for (let mList of allowMenuList) {
  //         if (mList.idx == id) {
  //             isCheck = true
  //         }
  //     }

  //     return isCheck
  // }

  const setCheckState = (e) => {
    console.log("^^ e.target.value");
    console.log(e.target.value);

    setGroupIdx(e.target.value);
  };

  let SelectorUI =
    group_list != null
      ? group_list.map((_group, index) => (
          <option key={Math.random()} value={_group._id || ""}>
            {_group.title}
          </option>
        ))
      : null;

  if (selectIndex == -1) return <></>;

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {action == "add" ? <>사용자 추가</> : <>사용자 수정</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group id="formUserId">
            <Form.Label>아이디* {existCheckEmailText}</Form.Label>
            {action == "add" ? (
              <Form.Control
                placeholder="아이디를 입력해주세요"
                onChange={changeEmail}
                value={email || ""}
              />
            ) : (
              <Form.Control
                placeholder="아이디를 입력해주세요"
                onChange={changeEmail}
                value={email || ""}
                readOnly
              />
            )}
          </Form.Group>

          {action == "edit" ||
          (action == "add" &&
            (existCheckEmail == null || existCheckEmail.length == 0)) ? (
            <>
              <Form.Group id="formUserId">
                <Form.Label>이름*</Form.Label>
                <Form.Control
                  placeholder="이름을 입력해주세요"
                  onChange={changeName}
                  value={name || ""}
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} id="formPassword">
                  <Form.Label>비밀번호*</Form.Label>
                  <Form.Control
                    placeholder="비밀번호를 입력해주세요"
                    onChange={changePassword}
                    value={password || ""}
                  />
                </Form.Group>

                <Form.Group as={Col} id="formPasswordConfirm">
                  <Form.Label>비밀번호 확인*</Form.Label>
                  <Form.Control
                    placeholder="동일한 비밀번호를 입력해주세요"
                    onChange={changePasswordConfirm}
                    value={passwordConfirm || ""}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} id="formPhone">
                  <Form.Label>전화 번호</Form.Label>
                  <Form.Control
                    placeholder="전화번호를 입력해주세요"
                    onChange={changePhone}
                    value={phone || ""}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group id="formMemo">
                <Form.Label>메모</Form.Label>
                <Form.Control
                  placeholder="memo"
                  onChange={changeMemo}
                  value={memo || ""}
                />
              </Form.Group>
            </>
          ) : (
            <></>
          )}

          <Form.Group id="formGridState">
            <Form.Label>권한 그룹 선택</Form.Label>
            <Form.Control as="select" onChange={setCheckState} value={groupIdx}>
              <option key={Math.random()} value="">
                없음
              </option>
              {SelectorUI}
            </Form.Control>
          </Form.Group>

          {action == "add" ? (
            <Button
              style={btnStyle}
              size="sm"
              onClick={async () => {
                if (groupIdx == "") {
                  alert("그룹을 선택해주세요");
                  return;
                }

                let payload = {
                  action: "add",
                  workspaceIdx: menu[0].workspaceIdx,
                  email: email,
                  name: name,
                  password: password,
                  phone: phone,
                  memo: memo,
                  groupIdx: groupIdx,
                  registDate: new Date(),
                };

                await parentCallback(payload);
                rest.onHide();
              }}
            >
              {" "}
              추가
            </Button>
          ) : (
            <Button
              style={btnStyle}
              size="sm"
              onClick={async () => {
                if (groupIdx == "") {
                  alert("그룹을 선택해주세요");
                  return;
                }

                let payload = {
                  action: "edit",
                  _id: memberList[selectIndex]._id,
                  workspaceIdx: memberList[selectIndex].workspaceIdx,
                  email: email,
                  name: name,
                  password: password,
                  phone: phone,
                  memo: memo,
                  groupIdx: groupIdx,
                  userIdx: userIdx,
                  updateDate: new Date(),
                };

                await parentCallback(payload);
                rest.onHide();
              }}
            >
              {" "}
              저장
            </Button>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={rest.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
