import React, { useState, useEffect } from "react";
import { Redirect, Link, Switch, Route, BrowserRouter as Router, } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_wise/templates/type1/layout";
import BasePage from "./BasePage";
import SelectWorkspace from "./SelectWorkspace";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/Errors/ErrorsPage";

import { Install } from "./modules/OutPage/Install";
import { Test } from "./modules/OutPage/Test";

import { Embed } from "./modules/OutPage/Embed";
import { ToComponent } from "./modules/OutPage/ToComponent";
import { ToPage } from "./modules/OutPage/ToPage";
import { ToWorkspace } from "./modules/OutPage/ToWorkspace";


import StateManager from "react-select";
import storage from "redux-persist/lib/storage";

import LoginNaver from "./modules/Auth/pages/LoginNaver";
import store from "../redux/store"


export function Routes() {

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);


  const outLocations = ['/install', '/embed', '/test', '/component', '/page', '/workspace'];
  var location = window.location.href
  var locationPath = outLocations.find(a => location.includes(a));
  var isLocation = (locationPath == undefined) ? true : false
  // console.log("__Routes")
  // console.log(isLocation)
  // console.log(currentWorkspace)
  // return (<>Routes</>)

  console.log("store")
  console.log(JSON.stringify(store,null,2))

  return (

    isLocation ?
      (
        <Switch>

          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={Logout} />
          <Route path="/naver" component={LoginNaver} />

          {!isAuthorized ? (
            <>
              <Route>
                <AuthPage />
              </Route>

              <Redirect to="/auth/login" />
            </>
          ) : (

            (currentWorkspace == undefined) ?
              <SelectWorkspace /> : <Layout><BasePage /></Layout>

          )}

        </Switch>
      )
      :
      <Router>
        <Switch>
          <Route path="/install" component={Install} />

          <Route path="/embed" component={Embed} />

          <Route path="/component" component={ToComponent} />
          <Route path="/page" component={ToPage} />
          <Route path="/workspace" component={ToWorkspace} />

          <Route path="/test" component={Test} />
        </Switch>

      </Router>

  );
}
