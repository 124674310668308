
import React, { useEffect, useMemo, Component, useState } from "react";
import ReactDOM from 'react-dom';
import { Button, Table, Pagination } from 'react-bootstrap';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { TableBuilderSetting } from "./TableBuilderSetting";

import { UnitTable2 } from "./WebBuilder/Element/UnitTable2";



// import { UnitTable } from "./WebBuilder/Element/UnitTable";

import Utils from "./WebBuilder/Utils";
import * as _ from "lodash"


export function TableBuilder({ el, states, className }) {
  let id = "component" + el.i

  const [content, setContent] = React.useState('');

  const [data, setData] = React.useState();
  const [layout, setLayout] = React.useState();






  // =============================================
  // [S] FOR Controller TEST
  // =============================================

  // inputField value
  const [values, setValues] = useState({});






  // =============================================
  // [E] FOR Controller TEST
  // =============================================



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  const [modalShow, setModalShow] = React.useState(false);


  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <SlidingPane
          className="sliding-pane-contents"
          overlayClassName="sliding-pane-overlay"
          from="right"
          isOpen={modalShow}
          onRequestClose={() => {
            setModalShow(false);
          }}
        >


          <TableBuilderSetting
            parentCallback={selectSetting}
            content={content}
            states={states}
            id={id}
          />

          {/* <SelectSettings
            parentCallback={selectSetting}
            content={content}
          /> */}

        </SlidingPane>
        ,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  // =====================================================
  // [S] Handle Event
  // =====================================================


  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  // dateRangePicker
  let field1 = "22227J1222kudaO9"


  const handleDataChange = (fieldId, data) => {
    console.log("> handleDataChange")
    setValues({ ...values, [fieldId]: data });

  };


  const handleEvent = (id, value) => {
    console.log("handleEvent > " + id + "val:" + JSON.stringify(value))

  }







  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드 
  // =====================================================

  useEffect(() => {


    let payload = {
      queries: [

        {
          key: "dataList",
          query: `db.collection('User').find({}).limit(32).toArray()`
        },

      ]
    }


    Utils.getQueryResult(payload).then((result) => {
      console.log("result >>>> ")
      console.log(result)
      setData(result)
    });

  }, [layout])







  // =====================================================
  // [S] <UnitTable></UnitTable>
  // =====================================================
  useEffect(() => {

    // STYLE
    const _style = {

      "table": {
        outline: "4px solid lightgray",
        overflow: "auto",
        // borderSpacing: "4px",
        // borderCollapse: "unset",
        // borderCollapse: "unset",
        // border: "2px solid lightgray",
      },

      "thead": {
        border: "2px solid blue",
      },

      "tbody": {
        border: "2px solid red",
      },

      "tfoot": {
        border: "2px solid green",
      },

      "th": {
        // border: "2px solid blue",
      },

      "td": {
        // border: "1px solid blue",
      },
    }

    // SETTINGS
    const _settings = {
      "tableType": "dataTable",//dataTable , table

      "isAllCheck": false,
      "isPaging": true,

      "isDelete": false,
      "isAdd": false,
      "isUpdate": false,
      "isExcel": false,

      "itemCountOfPage": 5,
      "pageCountOfGroup": 10,


      "isFixedHeader": true
    }





    const _header = [
      {
        title: `HEADER_DATA ${new Date()}`,
        children: [

          {
            title: '={[n].name}',
            children: [
              {
                title: 'TEST',
                style: {
                  textAlign: "right",
                  paddingRight: "25px"
                },
              }
            ]
          },
          {
            title: '={[0].grade}',
          },
          {
            title: 'email',
            colspan: '2',
            children: [
              {
                title: 'bottom',
                colspan: '2',
                style: {
                  textAlign: "right"
                },
              }
            ]
          },
          {
            title: 'password',
            format: ''
          },
          {
            title: 'phone',
          },
          {
            title: 'registDate',
          },
        ]
      }
    ];



    

    const _header2 = [
      {
        title: `HEADER_DATA22`,
        children: [

          {
            title: '={[n].name}',
            children: [
              {
                title: 'TEST',
                style: {
                  textAlign: "right",
                  paddingRight: "25px"
                },
              }
            ]
          },
          {
            title: '={[0].grade}',
          },
          {
            title: 'email',
            colspan: '2',
            children: [
              {
                title: 'bottom',
                colspan: '2',
                style: {
                  textAlign: "right"
                },
              }
            ]
          },
          {
            title: 'password',
            format: ''
          },
          {
            title: 'phone',
          },
          {
            title: 'registDate',
          },
        ]
      }
    ];


    const _body = [
      {
        title: '={name}',
        style: {
          backgroundColor: "white"
        },
        children: [

          {
            title: '={registDate}',
            style: {
              textAlign: "right",
              paddingRight: "25px"
            },
          },
          {
            title: '={email}',
            style: {
              verticalAlign: "middle"
            },
            format: [
              {
                type: "match",
                regex: "e-mail : {{(.+)}}",
                join: "",
                option: "gi"
              }
            ]
          },

          {
            title: 'email',
            colspan: '2',
            children: [
              {

                title: 'aaaa',
                style: {
                  border: "1px solid lightgray",
                  backgroundColor: "orange"
                },

                children: [
                  {
                    colspan: "2",
                    title: 'cccc',
                    style: {
                      border: "1px solid lightgray",
                      backgroundColor: "green"
                    },
                  }
                ]

              },
              {
                title: 'bbbb',
                rowspan: "1",
                style: {
                  border: "1px solid lightgray",
                  backgroundColor: "skyblue"
                },
              },

            ]
          },
          {
            title: 'password',
          },
          {
            title: 'phone',
          },

        ]
      },
      {
        title: '[ date ]',
        children: [
          {
            title: '={registDate}',
          }
        ]
      }
    ];

    const _footer = [
      {
        colspan: 5,
        title: '={[0].grade}',
      },
      {
        title: 'email',
        format: ''
      },
      {
        title: 'password',
        format: ''
      }
    ];


    let _layout = {}
    _layout["style"] = _style
    _layout["settings"] = _settings


    _layout["header"] = _header
    _layout["body"] = _body
    _layout["footer"] = _footer


    setLayout(_layout)

  }, [])




  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };



  const convertDateFormat = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    month = month >= 10 ? month : '0' + month;
    var day = date.getDate();
    day = day >= 10 ? day : '0' + day;
    return [year, month, day].join('-');
  }




  const clickTest = () => {
    console.log("_ clickTest _")
    console.log(values)


    return

    // ================================================================================
    // TODO : convert tag to data ({field1} -> datePicker로 현재 선택된 날짜)
    // let query = `db.collection('Data').find({'registDate':{field1}).limit(30)`
    // to
    // let query = `db.collection('Data').find({'registDate':20220102).limit(30)`
    // ================================================================================

    let query = "db.collection('Data').find({'registDate':'={field1}').limit(30)"

    let covQuery = query
    let matches = covQuery.matchAll(new RegExp("={(.+?)}", "gi"));

    for (let match of matches) {
      let convert = convertDateFormat(values[field1]) // match[1] > group ()
      console.log("match[1]:" + match[1] + " convert:" + convert)
      covQuery = _.replace(covQuery, "={" + match[1] + "}", convert)
    }

    console.log(">> covQuery")
    console.log(query)
    console.log(covQuery)

    // Excute
    // let payload = {
    //   "query": covQuery
    // }

    // Utils.getQueryResult(payload).then((result) => {
    //   console.log(result)
    //   setData(result)
    // });

  }



  return (
    <>

      <div className={`card card-custom ${className}`}>

        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">{el.data.title}</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>
        </div>

        <div className="card-body d-flex flex-column px-0">
          <div className="flex-grow-1 card-spacer-x" >
            <Button size="sm" onClick={clickTest}> query TEST 1 </Button>
            <br />

            <UnitTable2 id={id} layout={layout} data={data} onEvent={handleEvent} ></UnitTable2>
          </div>
        </div>

      </div>

    </>

  );
}


