import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import store from "../../../../redux/store";
import { getUserByToken } from "./authCrud";
import { actionTypes } from "../../../../redux/actionType";


const initialAuthState = {
  user: undefined,
  authToken: undefined,
};


//reducer
export const reducer = persistReducer(
  { storage, key: "wise-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        store.auth = authToken
        return { authToken, user: undefined };
      }
      
      case actionTypes.LoginNaver: {
        const { authToken } = action.payload;
        store.auth = authToken
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

// reducer와 합쳐서 slice 기능 을 한다 
export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  login_naver: (authToken) => ({ type: actionTypes.LoginNaver, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};


export function* saga() {

  yield takeLatest(actionTypes.LoginVisitor , function* loginVisitorSaga() {

    let user = {
      "grade": "visitor",
      "name": "visitor",
      "email": "visitor@test.com",
      "phone": "010-0000-0000",
      "isEnable": 1,
      "token": ""
    }

    // const user = JSON.parse(_user)
    console.log( "user" )
    console.log( user )
    yield put(actions.fulfillUser(user));

  });


  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.LoginNaver, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    // yield put(actions.register('done'));
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {

    const result = yield getUserByToken();
    const user = JSON.parse(result.data.data.getUserByToken)
    yield put(actions.fulfillUser(user));
  });
}
