import React, { useMemo } from "react";
import { AsideMenuList } from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/WiseLayout";

export function AsideMenu({ disableScroll }) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            asideMenuAttr: uiService.getAttributes("aside_menu"),
            ulClasses: uiService.getClasses("aside_menu_nav", true),
            asideClassesFromConfig: uiService.getClasses("aside_menu", true)
        };
    }, [uiService]);

    // console.log("_______________________ AsideMenu")

    return (
        <>
            {/* begin::Menu Container */}
            <div
                id="kt_aside_menu"
                data-menu-vertical="1"
                className={`aside-menu ${layoutProps.asideClassesFromConfig}`}
                {...layoutProps.asideMenuAttr}
            >


                {/* 굳이..menuProps 를 넘길 이유는 없을듯.. */}
                <AsideMenuList layoutProps={layoutProps} />
            </div>
            {/* end::Menu Container */}
        </>
    );
}
