/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_wise/templates/type1/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_wise/templates/type1/_assets/plugins/flaticon/flaticon.css";
import "./_wise/templates/type1/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  WiseLayoutProvider,
  WiseSplashScreenProvider,
  WiseSubheaderProvider
} from "./_wise/templates/type1/layout";
import { WiseI18nProvider } from "./_wise/i18n";




localStorage.projectName = "kici" 


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const { TYPE } = "type1"

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Wise mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/* const mock = */ //redux.mockAxios(axios);


// FOR TEST
// TODO:메뉴/페이지/컴포넌트 로드는 로그인 이후로 이전할 예정임 .
// console.log('> index.html')
// redux.setupState();





/**
 * Inject wise interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
redux.setupAxios(axios, store);


// ========================================================
// clear ALL localStorage
// ========================================================
// localStorage.clear();


// ========================================================
// select Project
// ========================================================
var url = new URL(window.location.href);
var projectName = url.searchParams.get("pn");

// if (localStorage.projectName == undefined) {
//   localStorage.projectName = "kici" // pageone , hyorim , kici
// } else {
//   if (projectName == null) {

//   } else {
//     localStorage.projectName = projectName
//   }
// }



ReactDOM.render(
  <WiseI18nProvider>
    <WiseLayoutProvider>
      <WiseSubheaderProvider>
        <WiseSplashScreenProvider>

          <App store={store} persistor={persistor} basename={PUBLIC_URL} />

        </WiseSplashScreenProvider>
      </WiseSubheaderProvider>
    </WiseLayoutProvider>
  </WiseI18nProvider>,
  document.getElementById("root")
);
