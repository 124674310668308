/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';

import * as _ from "lodash"

import { CKEditor } from "@ckeditor/ckeditor5-react";
// Modl_ckeditor5 를 빌드해서 나오는 build/* 파일을 아래 경로에 붙여 넣는다
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
// import {ClassicEditor} from './build/ckeditor'

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../../../../redux/actionType";


export function UnitTextEditor({ el, id, data, isEdit, onEvent }) {

  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)

  const [theEditor, setTheEditor] = useState();

  let originalText = ""


  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  useEffect(() => {

    if (data == undefined) return

    ClassicEditor
      .create(document.querySelector('#editor' + el.i), {
        
      })
      .then(editor => {

        editor.ui.view.toolbar.element.style.display = 'none';
        editor.plugins.get('WidgetTypeAround').forceDisabled('WidgetTypeAround');

        setTheEditor(editor)
      })
      .catch(error => {
        console.error(error);
      });

  }, [])




  useEffect(() => {

    if (isEdit == undefined) return
    if (theEditor == undefined) return

    if (isEdit == false) {
      if(window.confirm("저장하시겠습니까?")){
        
        //저장시키기
        let elCopy = JSON.parse(JSON.stringify(el))
        elCopy.data.rowdata = theEditor.getData()
  
        // TODO: page -> component -> element -> data의 rowdata에 value 저장
        dispatch({ type: actionTypes.UpdateComponentData, payload: { pageIdx: currentPage.slice("page".length), component: elCopy } })

        // 수정한 글
        onEvent("edit", id, isEdit,elCopy);
        return;

      }else{
        // 저장안됨
        // 원본글
        onEvent("edit", id, isEdit,el);
        return;

      }
    }


    theEditor.isReadOnly = !isEdit

  }, [isEdit])





  useEffect(() => {

    if (theEditor == undefined) return

    theEditor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {

      if (isReadOnly) {

        theEditor.ui.view.toolbar.element.style.display = 'none';
        theEditor.plugins.get('WidgetTypeAround').forceDisabled('WidgetTypeAround');

        originalText = theEditor.getData()


        // convert Template to data
        let tempEditorData = theEditor.getData()
        let matches = tempEditorData.matchAll(new RegExp("={(.+?)}", "gi"));

        for (let match of matches) {
          let convert = _.get(data, match[1], "undefined") // match[1] > group ()
          // console.log("match[1]:" + match[1] + " convert:" + convert)
          tempEditorData = _.replace(tempEditorData, "={" + match[1] + "}", convert)
        }

        theEditor.setData(tempEditorData)

      } else {

        theEditor.ui.view.toolbar.element.style.display = 'flex';
        theEditor.plugins.get('WidgetTypeAround').clearForceDisabled('WidgetTypeAround');

        theEditor.setData(originalText)

      }
    });

    getTextData()

    // console.log(theEditor.getData())

    // console.log(">> theEditor.on")
  }, [theEditor,el])




  // =====================================================
  // [E] modal
  // =====================================================

  const labelStyle = {
    "marginLeft": "6px",
    "display": "inline-block",
    "padding": "4px",
    "color": "#858c97",
    "border": "1px solid #c2cad4",
    "cursor": "pointer",
    "fontSize": "13px",
    "borderRadius": "4px"
  }


  const getTextData = () => {
    let query = {
      component: { $elemMatch: { i: el.i } }
    }
    const dataStr = JSON.stringify(query);

    const req = {
      query: `mutation getTextData($data: String) {
        getTextData(data: $data)
      }`,
      variables: {
        data: dataStr
      }
    }

    var instance = axios.create();

    return new Promise((resolve, reject) => {
      instance.post('graphql/', req)
        .then(resp => {

          const data = JSON.parse(resp.data.data.getTextData)

          // console.log(">>>inin  data")
          // console.log(data)


          data.component.forEach(element => {
            if (el.i === element.i) {

              Promise.resolve()
                .then(() => { theEditor.setData(element.data.rowdata) })
                .then(() => {

                  console.log(">> Init. ")

                  //Init
                  theEditor.isReadOnly = !isEdit

                })

              return
            }
          })

        })
        .catch(error => {

        });
    });
  }



  // const saveValue = () => {

  //   if (window.confirm('저장하시겠습니까??')) {

  //     let elCopy = JSON.parse(JSON.stringify(el))
  //     elCopy.data.rowdata = theEditor.getData()

  //     // TODO: page -> component -> element -> data의 rowdata에 value 저장
  //     dispatch({ type: actionTypes.UpdateComponentData, payload: { pageIdx: currentPage.slice("page".length), component: elCopy } })

  //     // parentCallback(!isEdit);  

  //     setSaveDone(true)

  //     onEvent("edit", id, !isEdit);

  //   }
  // }

  return (
    <>
      {/* <label style={labelStyle} onClick={() => setReadOnly()}>ReadOnly {isReadOnly.toString()}</label> */}
      {/* {(isEdit) ? <label style={labelStyle} onClick={() => saveValue()}>저장</label> : <></>} */}
      <div id={"editor" + el.i}></div>
    </>
  );

}




