import React from "react";

export function Header({ el }) {

  return (
    <>
      <div className="shadow-xs" style={{ backgroundColor: "white", padding:"10px 20px 10px 12px", marginBottom: "10px", boxSizing: "border-box", borderRadius: "0.45rem", borderLeft: "8px solid #027BC6" }} >
        <div style={{ margin:"0px" }}>
            <div style={{ fontSize: "16px", fontWeight: "700", color: "#1e1e1e", lineHeight: "20px" }}>{el.data.title}
            </div>
          </div>
      </div>
    </>
  );
}
