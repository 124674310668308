/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function StatusWidget2({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
}) {

  const [cpu, setCpu] = useState(0);

  useEffect(() => {
    console.log('effect')
    let timerId = setInterval(() => { setCpu((Math.floor((Math.random() * (1600 - 1400 + 1)) + 1400) / 100.0)) }, 1000);

  }, []);


  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        // style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
          </span>
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
            {cpu} %
          </div>

          <a
            href="#"
            className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
          >
            CPU USED
          </a>


          
        </div>

        
      </div>
    </>
  );
}
