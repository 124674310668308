/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, Component, useState } from "react";
import ReactDOM from 'react-dom';
import { toAbsoluteUrl } from "../../../_helpers";

import axios from "axios";


import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

var parseString = require('xml2js').parseString;

export function Tutorial1({ el, states, className }) {
  let id = "component" + el.i


  const [content, setContent] = React.useState('init 데이터 ...');




  
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {

  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================








  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  const backgroundImageUrl = toAbsoluteUrl("/media/stock-600x600/img-16.jpg");

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}
        style={{

        }}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">TEST</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>

        <div className="card-body d-flex flex-column px-0">

          <div className="flex-grow-1 card-spacer-x">

            {content}

          </div>

        </div>

      </div>



    </>

  );
}


