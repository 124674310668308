/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, Component, useState } from "react";
import ReactDOM from 'react-dom';
import { Button, Form, Col, Row, Modal, ListGroup } from 'react-bootstrap';

import axios from "axios";

import { InputBuilderCore } from "./InputBuilderCore";
import { InputBuilderSetting } from "./InputBuilderSetting";
import SlidingPane from "react-sliding-side-panel";
import 'react-sliding-side-panel/lib/index.css';

import { actionTypes } from "../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  width:"100%",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%"
});




const _dragEl = document.getElementById('draggable');



export function InputBuilder({ el, states, className, ...props }) {
  let id = "component" + el.i

  const [content, setContent] = React.useState('');

  const dispatch = useDispatch();



  // =====================================================================
  // db에서 다운로드 할 form 데이터 구조...
  // =====================================================================
  // let LayoutData = [{ "type": "input", "value": { "title": "input", "style": 1 } }, { "type": "input2", "value": { "title": "input2", "style": 1 } }, { "type": "buttonSubmit", "value": { "title": "buttonSubmit", "style": 1 } }] // layout 을 array 로 만든다 .
  let LayoutData = [{ "type": "input", "value": { "title": "input", "style": 1 } }] // layout 을 array 로 만든다 .

  const [state, setState] = useState([getItems(5), getItems(2, 5)]);



  

  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    // let parent = document.getElementById("contentModal")
    // if (parent != null) {
    //   ReactDOM.render(
    //     <BannerSetting
    //       show={modalShow}
    //       onHide={() => setModalShow(false)}
    //       parentCallback={selectSetting}
    //       link={link}
    //       image={image}
    //     />,
    //     document.getElementById("contentModal"))
    // } else {
    // }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================






  const loadContent = () => {

    let content = document.getElementById("contentModal")
    if (content != null) {

      // return ReactDOM.render(
      return ReactDOM.createPortal(

        <div style={{width:"200px"}}>
        <SlidingPane
        panelContainerClassName ="sliding-pane-container-input-builder"
        size={20}
        type={'right'}
        isOpen={modalShow}
        backdropClicked={() => setModalShow(false)}
        noBackdrop={true}
  
      >

        <InputBuilderSetting
          parentCallback={selectSetting}
          onHide={() => setModalShow(false)}
          layout={LayoutData}
          states={states}
          id={id}
        />

      </SlidingPane>

      </div>
        , content)

    }

  }

  // =====================================================
  // [E] modal
  // =====================================================




  useEffect(() => {



    console.log(">> TEST")

    // let payload = {
    //   "action": "fetch",
    //   "workspaceIdx": "workspaceIdx",
    //   "itemCountOfPage": "itemCountOfPage",
    //   "currentPage": "currentPage"
    // }
    // dispatch({ type: actionTypes.GetDataTest, payload: payload })



    // let payload = {
    //   // "_id": workspacesList[selectIndex]._id,
    //   "action": "fetch",
    //   "workspaceIdx": "workspaceIdx",
    //   "itemCountOfPage": "itemCountOfPage",
    //   "currentPage": "currentPage"
    // }
    // dispatch({ type: actionTypes.InsertDataTest, payload: payload })




    // let payloadUpdate = {
    //   "_id": "61b475bcdfe0432da4a39a04",
    //   "action": "update",
    //   "workspaceIdx": "workspaceIdx",
    //   "itemCountOfPage": "itemCountOfPage",
    //   "currentPage": "currentPage",
    //   "test":"test"
    // }
    // dispatch({ type: actionTypes.UpdateDataTest, payload: payloadUpdate })



    // let payload = {
    //   "action": "delete",
    //   "_ids": ["61b475bcdfe0432da4a39a04"]
    // }
    // dispatch({ type: actionTypes.DeleteDataTest, payload: payload })



  }, []);



  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  // let content = document.getElementById("content")


  const handlePress = (e) => {
    console.log("____ handlePress:")
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    // props.callbackParent({ isDraggable: false });
  }


  return (
    <>
      {loadContent()}


      {/* <SlidingPane
        panelContainerClassName ="sliding-pane-container-input-builder"
        type={'right'}
        size={30}
        isOpen={modalShow}
        backdropClicked={() => setModalShow(false)}
        noBackdrop={true}

  
      >

        <InputBuilderSetting
          parentCallback={selectSetting}
          onHide={() => setModalShow(false)}
          layout={LayoutData}
          states={states}
          id={id}
        />

      </SlidingPane> */}


      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">InputBuilder</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>

        <div className="card-body d-flex flex-column px-0">

          <div className="flex-grow-1 card-spacer-x">


            {/* <InputBuilderCore layout={jsonData}> 이런 형식으로 다른 파일에서 사용할수있게 만든다.*/}

            <InputBuilderCore layout={LayoutData}></InputBuilderCore>



            <div onMouseDown={(e) => handlePress(e)}>


              {/* <DragDropContext > */}


              {state.map((el, ind) => (
                <Droppable key={ind} droppableId={`${(ind + 1.0) * Math.random()}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {el.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around"
                                }}
                              >
                                {item.content}
                                <div
                                  type="button"
                                  onClick={() => {
                                    const newState = [...state];
                                    newState[ind].splice(index, 1);
                                    setState(
                                      newState.filter(group => group.length)
                                    );
                                  }}
                                >
                                  CMP
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
              {/* </DragDropContext> */}
            </div>







          </div>

        </div>

      </div>




    </>

  );
}


