import React, { useEffect, useState, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import axios from "axios";
import * as SockJS from 'sockjs-client';
import * as StompJs from '@stomp/stompjs';

export function Test1({ el, onUpdateAutoHeight, states, className }) {
    const id = "component" + el.i;

    const [token, setToken] = useState("");
    const [roomId, setRoomId] = useState("");
    const [sender, setSender] = useState("");
    const fullScreenHandle = useFullScreenHandle();

    const [isConnected, setConnected] = useState(false);
    const room = useRef({});
    const client = useRef({});
    const [chatMessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setToken("b2e015e192eb4e7d94fab0e7867fc180")
        setRoomId("628209d07dd04e3eeca8de6b-628209d07dd04e3eeca8de6a")
        setSender("user13")
        return () => disconnect();
    }, []);

    const connect = () => {
        client.current = new StompJs.Client({
            webSocketFactory: () => new SockJS("http://localhost:8080/ws-stomp"),
            connectHeaders: {
                token: token
            },
            debug: (str) => {
                console.log("debug", str);
            },
            reconnectDelay: 10_000,
            heartbeatIncoming: 4_000,
            heartbeatOutgoing: 4_000,
            onConnect: () => {
                setConnected(true)
                client.current.subscribe(`/sub/chat/room/${roomId}`, (message) => {
                    const data = JSON.parse(message.body);
                    console.log("subscribe", data);
                    receiveMessage(data);
                });

                const data = {
                    type: 'ENTER',
                    roomId: roomId,
                    sender: sender
                };

                client.current.publish({ destination: "/pub/chat/message", body: JSON.stringify(data) });
            },
            onStompError: (frame) => {
                console.error(frame);
            },
        });

        client.current.activate();
    };

    const toggleConnect = () => {
        if (isConnected) {
            disconnect();
        }
        else {
            findRoom();
            connect();
        }
    }

    const findRoom = () => {
        axios.get('/chat/room/' + roomId, { headers: { 'token': 'b2e015e192eb4e7d94fab0e7867fc180' } })
            .then(response => {
                room.current = response.data;
                console.log("findRoom", response.data);
            });
    };

    const sendMessage = () => {
        const data = {
            type: 'TALK',
            roomId: roomId,
            sender: sender,
            message: message
        };

        client.current.publish({ destination: "/pub/chat/message", body: JSON.stringify(data) });
        setMessage('');
    };

    const receiveMessage = (data) => {
        if (data.message === 'refresh') {
            window.location.reload(false);
            return;
        }
        const message = {
            type: data.type,
            sender: (data.type == 'ENTER') ? '[알림]' : data.sender,
            message: data.message,
            messageId: data.messageId
        };

        setChatMessages(chatMessages => [...chatMessages, message]);
    };

    const disconnect = () => {
        client.current.deactivate();
        room.current = {};
        setChatMessages([]);
        setConnected(false);
    };

    function MessageList(props) {
        const list = props.list;

        return (
            <ul>
                {list.map((messageInfo) =>
                    <li key={messageInfo.messageId}>{messageInfo.type == 'ENTER' ? '[알림]' : messageInfo.sender} - {messageInfo.message}</li>
                )}
            </ul>
        );
    }

    if (states == undefined) {
        return (<></>);
    }

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <FullScreen handle={fullScreenHandle}>
                <div className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`}>
                    <div className="card-body d-flex flex-column align-items-start justify-content-start">
                        <div className="p-1 flex-grow-1">
                            <h3>Room</h3>
                            <div style={{ width : "100%" }}>
                                <div className="row">
                                    <label for="token">토큰</label>
                                    <input type="text" name="token" value={token} onChange={e => setToken(e.target.value)} />
                                </div>
                                <div className="row">
                                    <label for="sender">Sender</label>
                                    <input type="text" name="sender" value={sender} onChange={e => setSender(e.target.value)} />
                                </div>
                                <div className="row">
                                    <label for="roomId">RoomId</label>
                                    <input type="text" name="roomId" style={{ width : "100%" }} value={roomId} onChange={e => setRoomId(e.target.value)} />
                                </div>
                                <div className="row">
                                    <button onClick={toggleConnect}>{isConnected ? "연결끊기" : "연결하기"}</button>
                                </div>
                                <MessageList list={chatMessages}></MessageList>
                            </div>
                        </div>
                    </div>
                </div>
            </FullScreen>
        </div>
    );
}