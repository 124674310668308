class TimeUtils {

  /**
   * TimeUtils ISO Date, 한국 날짜로 변경 메소드
   * @param {string} iso_time string type - ISO Date
   * @returns ISO Date 한국 날짜로 변경 후 반환
   */
  revert_korea_time(iso_time) {

    const offset = 1000 * 60 * 60 * 9;
    const korea_time = new Date((new Date(iso_time)).getTime() + offset);

    const convert_time = korea_time.toISOString().replace("T", " ").split('.')[0];

    return convert_time;
  }
}

export default new TimeUtils()
