/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Utils from "../edit/WebBuilder/Utils";
import * as _ from "lodash"

import {
  Button,
  Modal,
  ButtonToolbar,
  Col,
  Container,
  Row
} from "react-bootstrap";


export function KICI_ManageCrawler({ className }) {

  const moment = require('moment');

  const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);

  let [listData, setListData] = React.useState();




  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================

  const [firstRun, setFirstRun] = useState(true);
  const [values, setValues] = useState({});
  const [data, setData] = React.useState("");
  const [layout, setLayout] = React.useState();



  // =====================================================
  // [S] Handle Event (handleEvent)
  // =====================================================

  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  let field1 = "7J1kudaO9"
  let field2 = "ZRd_IEk7j"

  var handleEventObj = {

    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다 
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      // console.log(">>> saveState")
      // console.log("handleEvent > method : " + method)
      // console.log("handleEvent > id : " + id)
      // console.log("handleEvent > value : " + value)

      setValues(previousState => ({ ...previousState, [id]: value }));


      return true
    },

    test: function (method, id, ...value) {
      // console.log(">>> test")
      // console.log("handleEvent > method : " + method)
      // console.log("handleEvent > id : " + id)
      // console.log("handleEvent > value : " + value)


      return true
    }



  };


  const handleEvent = (method, id, ...value) => {

    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }

  }








  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드 
  // =====================================================
  useEffect(() => {

    if (firstRun) {

      setFirstRun(false)

      updateUI()

    }

  }, [])










  const updateUI = () => {

    let startDate = moment(new Date()).subtract(7, 'day').toDate()
    let endDate = new Date()

    let payload = {
      queries: [

        {
          key: "dataList",
          query: `db.collection('CrawlerTemplate').aggregate([
          { $match: {"workspaceIdx" : "${currentWorkspace.replace("workspace","")}"} },
          { $sort : {"registDate":1} },
        ]).toArray()`
        },

      ]
    }


    Utils.getQueryResult(payload).then((result) => {
      console.log("result >>>> ")
      console.log(result)




      if (result.dataList.length == 0) {

        let _listData =
          <div className="font-weight-bolder text-dark-25 font-size-lg" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
            # 데이터가 없습니다.
          </div>



        setListData(_listData)


      } else {


        let _listData =
          <>
            {

              result.dataList.map((d, index, arr) => {


                let color = "info"

                if (d.source == "DC인사이드") {
                  color = "primary"
                } else if (d.source == "에펨코리아") {
                  color = "success"
                } else if (d.source == "뽐뿌") {
                  color = "danger"
                }

                let time = moment(d.registDate).format('HH:mm')

                return (


                  <div key={index} className="d-flex align-items-center mb-9 bg-light-warning rounded p-5">
                    <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                      ></SVG>
                    </span>

                    <div className="d-flex flex-column flex-grow-1 mr-2">
                      <a
                        href="#"
                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                      >
                        {d.title}
                      </a>
                      <span className="text-muted font-weight-bold">{d.registDate}</span>
                    </div>

                    <span className="font-weight-bolder text-warning py-1 font-size-lg">
                      {d.rule}
                    </span>
                  </div>

                )
              })

            }
          </>

        setListData(_listData)


      }






    });
  }



  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">new</h3>

          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-ver" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>
          </div> */}



        </div>

        {/* Body */}
        <div className="card-body pt-0" style={{ overflow: "auto" }}>




          {listData}


          {/* 
        
          <div className="d-flex align-items-center mb-9 bg-light-warning rounded p-5">
            <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Group lunch celebration
              </a>
              <span className="text-muted font-weight-bold">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-warning py-1 font-size-lg">
              +28%
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-success rounded p-5 mb-9">
            <span className="svg-icon svg-icon-success mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              ></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Home navigation optimization
              </a>
              <span className="text-muted font-weight-bold">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-success py-1 font-size-lg">
              +50%
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-9">
            <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg"
                )}
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Rebrand strategy planning
              </a>
              <span className="text-muted font-weight-bold">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-danger py-1 font-size-lg">
              -27%
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Product goals strategy meet-up
              </a>
              <span className="text-muted font-weight-bold">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-info py-1 font-size-lg">
              +8%
            </span>
          </div> */}






        </div>
      </div>
    </>
  );
}
