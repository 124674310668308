
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Tabs, Tab, Dropdown, ListGroup } from 'react-bootstrap';

// import { WebBuilderCore } from "./WebBuilderCore";
import { actionTypes } from "../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DraggableArea } from 'react-draggable-tags';

import SVG from "react-inlinesvg";

import SideBarElement from "./WebBuilder/SideBarElement";
import SideBarStyle from "./WebBuilder/SideBarStyle";
import SideBarData from "./WebBuilder/SideBarData";



import "./WebBuilder/styles.css";

import { SIDEBAR_ITEMS, SIDEBAR_ELEMENT, Element, COLUMN } from "./WebBuilder/constants";





export function WebBuilderSetting(props) {

    const { mode, action, parentId, parentCallback, ...rest } = props

    const selectElement = useSelector((state) => state.modl.selectElement, shallowEqual)
    // console.log("selectElement ==== ")
    // console.log(selectElement)

    const dispatch = useDispatch();

    // =====================================================
    // [E] modal
    // =====================================================



    const handlePress = (e) => {
        console.log("____ handlePress:")
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        // props.callbackParent({ isDraggable: false });
    }

    return (
        <>

            <div style={{ height: "100%", overflow: "hidden" }} onMouseDown={(e) => handlePress(e)}>

                <div onClick={() => {
                    // parentCallback("Setting A")
                    console.log(selectElement)
                    rest.onHide()
                }}>
                    <div style={{ width: "100%", height: "40px" }} >
                        <SVG src={"/media/svg/icons/Navigation/Angle-right.svg"} />
                        Close Web Builder
                    </div>
                </div>


                <Tabs defaultActiveKey="Element" id="web-builder-setting">

                    <Tab key={Math.random()} eventKey="Element" title="Element">
                        Element
                        <div className="webBuilder">

                            <div className="sideBar">
                                {Object.values(SIDEBAR_ITEMS).map((sideBarElement, index) => (
                                    (sideBarElement.type == SIDEBAR_ELEMENT) ? <SideBarElement key={Math.random()} data={sideBarElement} /> : <></>
                                ))}
                            </div>
                        </div>
                        Element Set
                        <div className="webBuilder">

                            <div className="sideBar">
                                {Object.values(SIDEBAR_ITEMS).map((sideBarElement, index) => (
                                    (sideBarElement.type != SIDEBAR_ELEMENT) ? <SideBarElement key={Math.random()} data={sideBarElement} /> : <></>
                                ))}
                            </div>
                        </div>

                    </Tab>
                    <Tab key={Math.random()} eventKey="data" title="Data">
                        {/* <div>DB / document / query / mock-data </div> */}
                        <SideBarData data={selectElement} />
                    </Tab>
                    <Tab key={Math.random()} eventKey="style" title="Style">
                        <div># 지원 예정(미구현)</div>
                        {/* <div>size / padding / color / margin / font / align </div> */}
                        <SideBarStyle data={selectElement} />
                    </Tab>

                </Tabs>

            </div>


        </>
    );
}