import axios from "axios";

export const MODL_URL = "api/modl";

// CREATE =>  POST: add a new modl to the server
export function createModl(modl) {
  return axios.post(MODL_URL, { modl });
}

// READ
export function getAllModl() {
  return axios.get(MODL_URL);
}

export function getModlById(modlId) {
  return axios.get(`${MODL_URL}/${modlId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findModl(queryParams) {
  return axios.post(`${MODL_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the modl on the server
export function updateModl(modl) {
  return axios.put(`${MODL_URL}/${modl.id}`, { modl });
}

// UPDATE Status
export function updateStatusForModl(ids, status) {
  return axios.post(`${MODL_URL}/updateStatusForModl`, {
    ids,
    status
  });
}

//GRID PAGE TEST
export function testButton1(data) {

  var instance = axios.create();
  const req = { query: "{ getMenu }" };
  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        resolve(resp.data.data.getMenu)
      })
      .catch(error => {
        reject(error)
      });
  });
}

//GRID PAGE TEST
export function testButton2(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud testButton2 ++++")
  const req = { query: "{ getMenu }" };
  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        resolve(resp.data.data.getMenu)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//isExistUser
export function isExistUser(data) {
  var instance = axios.create();
  // console.log("++++ modlCrud isExistUser ++++")
  // console.log(data)

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query isExistUser($data: String) {
      isExistUser(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {

        // console.log(resp)
        resolve(resp.data.data.isExistUser)
      })
      .catch(error => {
        reject(error)
      });
  });

}




//getSite
export function getSite(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getSite ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getSite($data: String) {
      getSite(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getSite)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getLnkWorkspace
export function getLnkWorkspace(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getLnkWorkspace ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getLnkWorkspace($data: String) {
      getLnkWorkspace(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getLnkWorkspace)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getWorkspace
export function getWorkspace(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspace ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspace($data: String) {
      getWorkspace(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspace)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getComponent 이거 안씀
// export function getComponent(data) {

//   var instance = axios.create();

//   console.log(data)
//   const req = { query: "{ getComponent(userIdx: \"" + data+ "\") }" };
//   return new Promise((resolve, reject) => {
//     instance.post('graphql/', req)
//       .then(resp => {
//         // console.log("> server > getComponent")
//         // console.log(resp)
//         resolve(resp.data.data.getComponent)
//       })
//       .catch(error => {
//         reject(error)
//       });
//   });

// }




//getTplComponent
export function getTplComponent(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getTplComponent($data: String) {
      getTplComponent(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log("> server > getTplComponent")
        //console.log(resp)
        resolve(resp.data.data.getTplComponent)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getMenu
export function getMenu(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getMenu ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getMenu($data: String) {
      getMenu(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getMenu)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getTagGroupAll
export function getTagGroupAll(data) {
  var instance = axios.create();
  // console.log("++++ modlCrud getTagGroupAll ++++")
  // console.log(data)

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getTagGroupAll($data: String) {
      getTagGroupAll(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {

        // console.log(resp)
        resolve(resp.data.data.getTagGroupAll)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getWorkspacesAll
export function getWorkspacesAll(data) {
  var instance = axios.create();
  // console.log("++++ modlCrud getWorkspacesAll ++++")
  // console.log(data)

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspacesAll($data: String) {
      getWorkspacesAll(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {

        // console.log(resp)
        resolve(resp.data.data.getWorkspacesAll)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getGroupAll
export function getGroupAll(data) {
  var instance = axios.create();
  // console.log("++++ modlCrud getGroupAll ++++")
  // console.log(data)

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getGroupAll($data: String) {
      getGroupAll(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {

        // console.log(resp)
        resolve(resp.data.data.getGroupAll)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//getPage
export function getPage(data) {
  var instance = axios.create();
  // console.log("++++ modlCrud getPage ++++")
  // console.log(data)

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getPage($data: String) {
      getPage(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {

        // console.log(resp)
        resolve(resp.data.data.getPage)
      })
      .catch(error => {
        reject(error)
      });
  });

}

//insertMenu
export function insertMenu(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  // console.log(dataStr)

  const req = {
    query: `mutation insertMenu($data: String) {
      insertMenu(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)

        resolve(resp.data.data.insertMenu)

      })
      .catch(error => {
        reject(error)
      });
  });

}

//inviteUser
export function inviteUser(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  // console.log(dataStr)

  const req = {
    query: `mutation inviteUser($data: String) {
      inviteUser(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)

        resolve(resp.data.data.inviteUser)

      })
      .catch(error => {
        reject(error)
      });
  });


}





//updateComponentData
export function updateComponentData(data) {
  // console.log('> updateComponentData > modleCrud >>')
  // console.log(data)
  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateComponentData($data: String) {
      updateComponentData(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateComponentData)

      })
      .catch(error => {
        reject(error)
      });
  });

}



//updateComponent
export function updateComponent(data) {
  // console.log('> updateComponent > modleCrud >>')
  // console.log(data)
  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateComponent($data: String) {
      updateComponent(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateComponent)

      })
      .catch(error => {
        reject(error)
      });
  });

}

//updateComponents
export function updateComponents(data) {
  // console.log('modleCrud >>')
  // console.log(data)
  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateComponents($data: String) {
      updateComponents(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }


  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateComponents)

      })
      .catch(error => {
        reject(error)
      });
  });

}

//updateTplComponent
export function updateTplComponent(data) {

  // console.log('modleCrud > updateTplComponent >')
  // console.log(data)

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateTplComponent($data: String) {
      updateTplComponent(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }


  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)

        resolve(resp.data.data.updateTplComponent)

      })
      .catch(error => {
        reject(error)
      });
  });

}

//updateMenu
export function updateMenu(data) {

  // console.log('modleCrud > updateMenu >')
  // console.log(data)

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateMenu($data: String) {
      updateMenu(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }


  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)

        resolve(JSON.stringify(data.menu))
        // resolve(resp.data.data.updateMenu)

      })
      .catch(error => {
        reject(error)
      });
  });

}

//updatePage
export function updatePage(data) {

  // console.log('modleCrud > updatePage >')
  // console.log(data)

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updatePage($data: String) {
      updatePage(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }


  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)

        resolve(resp.data.data.updatePage)

      })
      .catch(error => {
        reject(error)
      });
  });

}


// ==================================================================
// ==================================================================
// ==================================================================


export function getDocumentCount(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getDocumentCount ++++")
  // console.log(data.workspaceIdx)
  // console.log(data.document)

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getDocumentCount($data: String) {
      getDocumentCount(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getDocumentCount)
      })
      .catch(error => {
        reject(error)
      });
  });

}



// 효림 관리자 관리(admin)
//===========================================================================================
// START
//===========================================================================================
export function getWorkspaceAdminMember(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspaceAdminMember ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspaceAdminMember($data: String) {
      getWorkspaceAdminMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspaceAdminMember)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertWorkspaceAdminMember(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertWorkspaceAdminMember($data: String) {
      insertWorkspaceAdminMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertWorkspaceAdminMember)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function updateWorkspaceAdminMember(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateWorkspaceAdminMember($data: String) {
      updateWorkspaceAdminMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateWorkspaceAdminMember)

      })
      .catch(error => {
        reject(error)
      });
  });


}

export function deleteWorkspaceAdminMember(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation deleteWorkspaceAdminMember($data: String) {
      deleteWorkspaceAdminMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteWorkspaceAdminMember)

      })
      .catch(error => {
        reject(error)
      });
  });


}




// 사용자 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
export function getWorkspaceMember(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspaceMember ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspaceMember($data: String) {
      getWorkspaceMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }


  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspaceMember)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertWorkspaceMember(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertWorkspaceMember($data: String) {
      insertWorkspaceMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertWorkspaceMember)

      })
      .catch(error => {
        reject(error)
      });
  });

}

export function updateWorkspaceMember(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateWorkspaceMember($data: String) {
      updateWorkspaceMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateWorkspaceMember)

      })
      .catch(error => {
        reject(error)
      });
  });

}

export function deleteWorkspaceMember(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation deleteWorkspaceMember($data: String) {
      deleteWorkspaceMember(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteWorkspaceMember)

      })
      .catch(error => {
        reject(error)
      });
  });

}



// 사용자 권한 그룹 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
export function getWorkspaceGroup(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspaceGroup ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspaceGroup($data: String) {
      getWorkspaceGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspaceGroup)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertWorkspaceGroup(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertWorkspaceGroup($data: String) {
      insertWorkspaceGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertWorkspaceGroup)

      })
      .catch(error => {
        reject(error)
      });
  });

}

export function updateWorkspaceGroup(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateWorkspaceGroup($data: String) {
      updateWorkspaceGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        console.log('modleCrud >> resp >>')
        console.log(resp)
        resolve(resp.data.data.updateWorkspaceGroup)

      })
      .catch(error => {
        reject(error)
      });
  });

}

export function deleteWorkspaceGroup(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)


  console.log(dataStr)

  const req = {
    query: `mutation deleteWorkspaceGroup($data: String) {
      deleteWorkspaceGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }


  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteWorkspaceGroup)

      })
      .catch(error => {
        reject(error)
      });
  });


}






// 태그 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
export function getWorkspaceTag(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspaceTag ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspaceTag($data: String) {
      getWorkspaceTag(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspaceTag)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertWorkspaceTag(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertWorkspaceTag($data: String) {
      insertWorkspaceTag(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertWorkspaceTag)

      })
      .catch(error => {
        reject(error)
      });
  });


}

export function updateWorkspaceTag(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateWorkspaceTag($data: String) {
      updateWorkspaceTag(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateWorkspaceTag)

      })
      .catch(error => {
        reject(error)
      });
  });


}

export function deleteWorkspaceTag(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation deleteWorkspaceTag($data: String) {
      deleteWorkspaceTag(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteWorkspaceTag)

      })
      .catch(error => {
        reject(error)
      });
  });


}





// 태그 그룹 관리(workspace)
//===========================================================================================
// START
//===========================================================================================
export function getWorkspaceTagGroup(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspaceTagGroup ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspaceTagGroup($data: String) {
      getWorkspaceTagGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspaceTagGroup)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertWorkspaceTagGroup(data) {


  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertWorkspaceTagGroup($data: String) {
      insertWorkspaceTagGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertWorkspaceTagGroup)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function updateWorkspaceTagGroup(data) {


  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateWorkspaceTagGroup($data: String) {
      updateWorkspaceTagGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateWorkspaceTagGroup)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function deleteWorkspaceTagGroup(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation deleteWorkspaceTagGroup($data: String) {
      deleteWorkspaceTagGroup(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteWorkspaceTagGroup)

      })
      .catch(error => {
        reject(error)
      });
  });


}




// 전체 워크스페이스 관리(admin)
//===========================================================================================
// START 
//===========================================================================================

// getWorkspaceList() 이거 있을줄이야...
export function getWorkspaceList(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getWorkspaceList ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getWorkspaceList($data: String) {
      getWorkspaceList(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getWorkspaceList)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertWorkspace(data) {


  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertWorkspace($data: String) {
      insertWorkspace(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertWorkspace)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function updateWorkspace(data) {


  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateWorkspace($data: String) {
      updateWorkspace(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateWorkspace)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function deleteWorkspace(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation deleteWorkspace($data: String) {
      deleteWorkspace(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteWorkspace)

      })
      .catch(error => {
        reject(error)
      });
  });


}

// ==================================================================














// DataTest
//===========================================================================================
// START 
//===========================================================================================

// getDataTest() 이거 있을줄이야...
export function getDataTest(data) {

  var instance = axios.create();

  // console.log("++++ modlCrud getDataTest ++++")

  const dataStr = JSON.stringify(data)

  const req = {
    query: `query getDataTest($data: String) {
      getDataTest(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log(resp)
        resolve(resp.data.data.getDataTest)
      })
      .catch(error => {
        reject(error)
      });
  });

}

export function insertDataTest(data) {


  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation insertDataTest($data: String) {
      insertDataTest(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.insertDataTest)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function updateDataTest(data) {


  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation updateDataTest($data: String) {
      updateDataTest(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.updateDataTest)

      })
      .catch(error => {
        reject(error)
      });
  });
}

export function deleteDataTest(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify(data)

  const req = {
    query: `mutation deleteDataTest($data: String) {
      deleteDataTest(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp)
        resolve(resp.data.data.deleteDataTest)

      })
      .catch(error => {
        reject(error)
      });
  });


}

export function insertExcelData(data) {

  var instance = axios.create();

  const dataStr = JSON.stringify({ ...data })

  const req = {
    query: `mutation insertExcelData($data: String) {
      insertExcelData(data: $data)
    }`,
    variables: {
      data: dataStr
    }
  }

  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('modleCrud >> resp >>')
        // console.log(resp.data)
        resolve(resp.data.data.insertExcelData)

      })
      .catch(error => {
        reject(error)
      });
  });
}

// ==================================================================

