import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form } from "react-bootstrap";
import PushTable from "./PushTable";

export function PushKeyword(props) {
  const {
    data,
    parent_send,
    workSpace,
    parentId,
    parentCallback,
    ...rest
  } = props;

  const thStyle = {
    padding: "15px 22px 15px 22px",
    border: "0 !important",
    borderColor: "#ebebeb",
    backgroundColor: "#ecf0f9",
    textAlign: "center",
  };

  const props_send = (param) => {
    parent_send(param);
  };

  if (!data) return <>데이터 없음</>;
  let table_ui = data.keywords.map((_data, index) => {
    return (
      <PushTable
        key={index}
        keyword={_data.keyword}
        length={data.keywords.length}
        rest={rest}
        props_send={props_send}
        index={index}
        _id={data._id}
        type={_data.type ? _data.type : ""}
        more={_data.more ? _data.more.toString() : ""}
      />
    );
  });

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          키워드별 PUSH 조건 추가
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          striped
          hover
          style={
            {
              /* minWidth: "1200px", marginBottom: "0" */
            }
          }
        >
          <thead>
            <tr>
              <th style={thStyle}>번호</th>
              <th style={thStyle}>키워드</th>
              <th style={thStyle}>조건</th>
              <th style={thStyle}>추가 키워드</th>
              <th style={thStyle}>수정</th>
              <th style={thStyle}>삭제</th>
            </tr>
          </thead>
          <tbody className="">{table_ui}</tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={rest.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
