/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/esm/locale"
import { Button, Form } from 'react-bootstrap';
const moment = require('moment');


export function UnitDateRangePicker({ el, id, layout, data, onEvent }) {

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;


  useEffect(() => {

    if (layout == undefined) return

    if (layout.date == undefined) {
      setDateRange([new Date(), new Date()])
      onEvent("saveState", id, [new Date(), new Date()]);
    } else {

      setDateRange(layout.date)
      onEvent("saveState", id, layout.date);
    }


  }, [layout]);


  const handleChange = date => {

    setDateRange(date);

    onEvent("saveState", id, date);

  };


  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control type="text" onClick={onClick} ref={ref} value={value} readOnly style={{width: "180px"}} />
  ))


  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      monthsShown={1}
      locale={ko}
      dateFormat="yyyy.MM.dd"
      customInput={<CustomInput />}

    />
  );

}

