import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { ROW } from "./constants";
import DropZone from "./DropZone";
import Column from "./Column";

import { actionTypes } from "../../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const style = {};
const Row = ({ el, data, elements, handleDrop, path }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const element = elements[data.id];


  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderColumn = (column, currentPath) => {
    return (
      <Column
        key={column.id}
        el={el} 
        data={column}
        elements={elements}
        handleDrop={handleDrop}
        path={currentPath}
      />
    );
  };

  
  const handleClick = (e, element, data) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    let payload = {
      "element": element,
      "data": data
    }

    dispatch({ type: actionTypes.UpdateSelectElement, payload: JSON.stringify(payload) })

  }



  const handlePress = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  return (
    <div ref={ref} style={{ ...style, opacity }} className="base draggable row"
    onClick={(e) => handleClick(e, element, data)}
    onMouseDown={(e) => handlePress(e)}
    >
      {/* {data.id} */}
      <div className="columns">
        {data.children.map((column, index) => {
          const currentPath = `${path}-${index}`;

          return (
            <React.Fragment key={Math.random()}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                  children: data.children,
                  id: data.id,
                  type: ROW
                }}
                onDrop={handleDrop}
                className="horizontalDrag"
              />
              {renderColumn(column, currentPath)}
            </React.Fragment>
          );
        })}
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
            children: data.children,
            id: data.id,
            type: ROW
          }}
          onDrop={handleDrop}
          className="horizontalDrag"
          isLast
        />
      </div>
    </div>
  );
};
export default Row;
