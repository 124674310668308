
import React, { useEffect, useMemo, Component, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";


import ReactDOM from 'react-dom';
import { Button, Table, Pagination } from 'react-bootstrap';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { CrawlingList_Add } from "./CrawlingList_Add";
import { CrawlingList_Edit } from "./CrawlingList_Edit";



import { UnitTable } from "../edit/WebBuilder/Element/UnitTable";
import { UnitDatePicker } from "../edit/WebBuilder/Element/UnitDatePicker";
import { UnitDateRangePicker } from "../edit/WebBuilder/Element/UnitDateRangePicker";

import axios from 'axios';

import Utils from "../edit/WebBuilder/Utils";
import * as _ from "lodash"

const moment = require('moment');

export function CrawlingLogList({ el, states, className }) {
  let id = "component" + el.i



  const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
  const userId = useSelector((state) => state.auth.user._id, shallowEqual)

  let _siteIdx = currentSite.split("site").join("")
  let _workspaceIdx = currentWorkspace.split("workspace").join("")
  let _userIdx = userId
  


  const [data, setData] = React.useState();
  const [layout, setLayout] = React.useState();



  // =====================================================
  // [S] modal - 모달 정의
  // =====================================================


  const [modal_add, setModal_add] = useState({ show: false });
  const [modal_edit, setModal_edit] = useState({ show: false });


  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <>


          <CrawlingList_Add
            show={modal_add.show}
            onHide={() => setModal_add({ show: false })}
            parentCallback={modal_add_callback}
          />

          <CrawlingList_Edit
            show={modal_edit.show}
            data={modal_edit}
            onHide={() => { setModal_edit({ show: false }) }}
            parentCallback={modal_edit_callback}
          />

        </>
        ,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - 모달 정의
  // =====================================================





  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================

  // All inputField value
  const [values, setValues] = useState({});



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  // [S] 컴포넌트 상단 custom setting 버튼 클릭시 functionObj 실행됨
  // [S] DB component > settings > ...
  //==============================================================

  var functionObj = {
    embed: function () {

      // src 외부 접근 가능 URL 을 적어주세요
      let copyStr =
        `<iframe width="1280" height="720"
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

      // navigator.clipboard.writeText(copyStr)

      console.log(copyStr)
      alert("check console.")

      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================








  // =====================================================
  // [S] Handle Event (handleEvent)
  // =====================================================


  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  // dateRangePicker
  let field1 = "dateRangePicker_sdfkj"

  // Select
  let field2 = "table_dskljfsfe"


  var handleEventObj = {

    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      console.log(">>> saveState")
      console.log("handleEvent > method : " + method)
      console.log("handleEvent > id : " + id)
      console.log("handleEvent > value : " + value)

      setValues({ ...values, [id]: value });

      return true
    },

    currentPage: function (method, id, ...value) {


      let payload = {
        queries: [
          {
            key: "dataList",
            query: `db.collection('CrawlerLog').aggregate([
              { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
            { $sort: { registDate: -1 } },
            
            { $skip: ${(value - 1) * layout.settings.itemCountOfPage} },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },
            }
          ]).toArray()`
          },

        ]
      }

      Utils.getQueryResult(payload).then((result) => {
        console.log(result)
        setData(result)
      });

      return true
    },

    add: function (method, id, ...value) {
      setModal_add({
        show: true
      })

      return true
    },

    edit: function (method, id, ...value) {

      setModal_edit({
        show: true,
        key: value,
        data: data
      })


      return true
    },
    delete: function (method, id, ...value) {


      if (value.length == 0) {
        alert("삭제할 항목을 선택해주세요")
      } else {

        let payload = {
          queries: [
            {
              key: "delete",
              args: value,
              query: `
              db.collection("CrawlerLog").deleteMany(query)
              `
            },
            {
              key: "dataList",
              query: `db.collection('CrawlerLog').aggregate([
                { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
                { $sort: { registDate: -1 } },
                { $skip: 0 },
                { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },
           
                }
              ]).toArray()`
            }

          ]
        }


        Utils.getQueryResult(payload).then((result) => {

          console.log("delete > ")
          console.log(result)
          setData(result)
        });


      }

      return true
    },




  };


  const handleEvent = (method, id, ...value) => {

    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }

  }











  // const handleEvent = (id, value) => {

  //   console.log("handleEvent > "+id+"val:"+JSON.stringify(value))


  //   if (id == "currentPage") {





  //     Utils.getQueryResult(payload).then((result) => {
  //       console.log(result)
  //       setData(result)
  //     });


  //   }
  //   else if (id == "add") {

  //     setModal_add({
  //       show: true
  //     })

  //   } else if (id == "edit") {

  //     // console.log(value)
  //     // console.log(data)

  //     setModal_edit({
  //       show: true,
  //       key: value,
  //       data: data
  //     })

  //   } else if (id == "delete") {

  //     if (value.length == 0) {
  //       alert("삭제할 항목을 선택해주세요")
  //     } else {


  //       let payload = {
  //         queries: [
  //           {
  //             key: "delete",
  //             args: value,
  //             query: `
  //             db.collection("CrawlerLog").deleteMany(query)
  //             `
  //           },
  //           {
  //             key: "dataList",
  //             query: `db.collection('CrawlerLog').aggregate([
  //               { $match: {} },
  //               { $sort: { update: -1 } },
  //               { $skip: 0 },
  //               { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },
  //               {
  //                 $project: {
  //                   title: 1,
  //                   author: 1,
  //                   seller: 1,
  //                   update: 1,
  //                   score: 1,
  //                   like: 1,
  //                   shop: 1,
  //                   product: 1,
  //                   tag: 1
  //                 }
  //               }
  //             ]).toArray()`
  //           }

  //         ]
  //       }


  //       Utils.getQueryResult(payload).then((result) => {

  //         console.log("delete > ")
  //         console.log(result)
  //         setData(result)
  //       });


  //     }

  //   }

  // };



  // =====================================================
  // [S] Call Back
  // =====================================================

  const modal_add_callback = (data) => {
    console.log('modal_add_callback: ' + JSON.stringify(data))

    let payload = {
      queries: [
        {
          key: "update",
          query: `
              db.collection('CrawlerLog').insertOne(${JSON.stringify(data)})`
        },
        {
          key: "dataList",
          query: `db.collection('CrawlerLog').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
            { $sort: { registDate: -1 } },
            { $skip: 0 },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },
        
          ]).toArray()`
        }

      ]
    }


    Utils.getQueryResult(payload).then((result) => {

      console.log("insert > ")
      console.log(result)
      setData(result)
    });



  }

  const modal_edit_callback = (key, data) => {
    console.log('modal_edit_callback key: ' + key)
    console.log('modal_edit_callback: ' + JSON.stringify(data))


    // let query = `{ _id: new require('mongodb').ObjectID('${key}') }`
    let update = { $set: { title: data.title, selector: data.selector, metadata: data.metadata, updateDate: new Date() } }

    let payload = {
      queries: [
        {
          key: "update",
          query: `
              db.collection('CrawlerLog').updateOne({ _id: new require('mongodb').ObjectID('${key}') },${JSON.stringify(update)})`
        },
        {
          key: "dataList",
          query: `db.collection('CrawlerLog').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
            { $sort: { registDate: -1 } },
            { $skip: 0 },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000}},
           
          ]).toArray()`
        }

      ]
    }


    Utils.getQueryResult(payload).then((result) => {

      console.log("update > ")
      console.log(result)
      setData(result)
    });
  }






  // =====================================================
  // [S] <UnitTable></UnitTable>
  // =====================================================
  useEffect(() => {

    // STYLE
    const _style = {
      "table": {
        // border: "2px solid green",
      },
      "tr": {
        // border: "5px solid red",
      },
      "th": {
        // border: "5px solid red",
      },
      "td": {
        // border: "2px solid blue",
        maxWidth: "200px"
      },

    }

    // SETTINGS
    const _setting = {
      "tableType": "dataTable",//dataTable , table
      "isPaging": true,
      "isDelete": false,
      "isAdd": false,
      "isUpdate": false,
      "isExcel": false,

      "itemCountOfPage": 10,
      "pageCountOfGroup": 10
    }

    // COLUMNS
    const _columns = [
      {
        title: 'ID',
        dataKey: '={_id}',
        style: {
          width: "220px"
        },
      },
      {
        title: 'title',
        dataKey: '={title}',
        style: {
          width: "250px",
        },
      },
      {
        title: '크롤링 주소',
        type: 'link',
        dataKey: '={selector}',
      },
      {
        title: 'date',
        dataKey: '={registDate}',
        style: {
          width: "60px",
        },

      }, 




    ];

    let layout = {}
    layout["style"] = _style
    layout["settings"] = _setting
    layout["columns"] = _columns
    setLayout(layout)

  }, [])



  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드
  // =====================================================
  useEffect(() => {


    console.log("useeffect >> ")
    if (layout == undefined) return

    let payload = {
      queries: [
        {
          key: "dataList",
          query: `db.collection('CrawlerLog').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
            { $sort: { registDate: -1 } },
            { $skip: 0 },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },
         
          ]).toArray()`
        },

      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      console.log(">>>> loaded data : ")
      console.log(result)
      setData(result)
    });

  }, [layout])






  const loadData = () => {
    console.log("_ clickTest _")
    console.log(moment(values[field1][0]).format('YYYY.MM.DD'))
    console.log(values[field1][1])

    let startDate = moment(values[field1][0]).format('YYYY.MM.DD')
    let endDate = moment(values[field1][1]).format('YYYY.MM.DD')

    let payload = {
      queries: [
        {
          key: "dataList",
          query: `db.collection('CrawlerLog').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}"} , date: {$gte: "${startDate}", $lte: "${endDate}"} } },
            { $sort: { registDate: -1 } },
            { $skip: 0 },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },

          ]).toArray()`
        },

      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      console.log("loaded data : ")
      console.log(result)
      setData(result)
    });



    // ================================================================================
    // TODO : convert tag to data ({field1} -> datePicker로 현재 선택된 날짜)
    // let query = `db.collection('Data').find({'registDate':={field1}).limit(30)`
    // to
    // let query = `db.collection('Data').find({'registDate':20220102).limit(30)`
    // ================================================================================

    // let query = "db.collection('Data').find({'registDate':'={field1}').limit(30)"

    // let covQuery = query
    // let matches = covQuery.matchAll(new RegExp("={(.+?)}", "gi"));

    // for (let match of matches) {
    //   let convert = convertDateFormat(values[field1]) // match[1] > group ()
    //   console.log("match[1]:" + match[1] + " convert:" + convert)
    //   covQuery = _.replace(covQuery, "={" + match[1] + "}", convert)
    // }

    // console.log(">> covQuery")
    // console.log(query)
    // console.log(covQuery)

    // Excute
    // let payload = {
    //   "query": covQuery
    // }

    // Utils.getQueryResult(payload).then((result) => {
    //   console.log(result)
    //   setData(result)
    // });

  }





  return (
    <>

      <div className={`card card-custom ${className}`}>

        {/* <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">{el.data.title}</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>
        </div> */}

        <div className="card-body d-flex flex-column px-0">
          <div className="flex-grow-1 card-spacer-x" style={{ overflow: "auto" }}>

{/* 
            <div style={{ height: "40px" , width:"250px" ,display: "flex" }}>
              <UnitDateRangePicker id={field1} layout={layout} data={values[field1]} onEvent={handleEvent} />
              <Button style ={{width:"100px"}} variant="outline-dark" size="sm" onClick={loadData}> 로드 </Button>
            </div> */}


            <UnitTable id={id} layout={layout} data={data} onEvent={handleEvent} ></UnitTable>
          </div>
        </div>

      </div>

    </>

  );
}


