import React from "react";
import classNames from "classnames";
import { useDrop } from "react-dnd";
import { ELEMENT, SIDEBAR_ELEMENT, SIDEBAR_ROW, SIDEBAR_COLUMN, ROW, COLUMN, SIDEBAR_LAYOUT, LAYOUT } from "./constants";

const ACCEPTS = [SIDEBAR_LAYOUT, SIDEBAR_ROW, SIDEBAR_COLUMN, SIDEBAR_ELEMENT, ELEMENT, ROW, COLUMN, LAYOUT];

const DropZone = ({ data, onDrop, isLast, className }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop(data, item);
    },
    canDrop: (item, monitor) => {

      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      const itemPath = item.path;


      // !itemPath 는 sidebar 에서 드레그해서 추가할때 이다.
      if (!itemPath) {

        // if (data.childrenCount >= 3) {
        //  return false;
        // }

        // sidebar type 에 따라 드래그 가능한 영역이 제한된다.
        // 그럼 
        if (item.type === SIDEBAR_LAYOUT) {
          if (splitDropZonePath.length > 1) {
            return false
          }
        }

        if (item.type === SIDEBAR_ROW) {
          if (splitDropZonePath.length > 1) {
            return false
          }
        }

        if (item.type === SIDEBAR_COLUMN) {
          if (splitDropZonePath.length > 2) {
            return false
          }
        }



        return true;
      }






      // console.log("data/item ============")
      // console.log(data) // 움직이고 싶은 위치{path: '0-1', childrenCount: 1}
      // console.log(item) // 이동 아이템{type: 'element', id: 'F8Qw6EW_Fo_', elementType: 'input', test1: 'insert any element', style: {…}, …}

      const splitItemPath = itemPath.split("-");

      // limit columns when dragging from one row to another row
      const dropZonePathRowIndex = splitDropZonePath[0];
      const itemPathRowIndex = splitItemPath[0];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;

      // if (
      //   diffRow &&
      //   splitDropZonePath.length === 2 &&
      //   data.childrenCount >= 3
      // ) {
      //   return false;
      // }


      // console.log("================================")
      // console.log(splitItemPath.length)
      // console.log(splitDropZonePath.length)


      // Invalid (Can't drop a parent element (row) into a child (column))
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) return false;

      // // Current item can't possible move to it's own location
      // if (itemPath === dropZonePath) return false;

      // // Current area
      // if (splitItemPath.length === splitDropZonePath.length) {
      //   const pathToItem = splitItemPath.slice(0, -1).join("-");
      //   const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

      //   const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
      //   const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

      //   if (pathToItem === pathToDropZone) {
      //     const nextDropZoneIndex = currentItemIndex + 1;
      //     if (nextDropZoneIndex === currentDropZoneIndex) return false;
      //   }
      // }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      // canDrop: monitor.canDrop()
    })
  });

  // const isActive = isOver && canDrop;
  const isActive = isOver
  return (
    <div
      className={classNames(
        "dropZone",
        { active: isActive, isLast },
        className
      )}
      ref={drop}
    />
  );
};
export default DropZone;
