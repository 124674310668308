/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';

import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";

import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { useHtmlClassService } from "../../../templates/type1/layout";


import { actionTypes } from "../../../../redux/actionType";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { BasicLinkGraphSetting } from "./BasicLinkGraphSetting";





export function BasicLinkGraph({el, states, className }) {
  let id = "component" + el.i


  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      // colorsGrayGray500: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray500"
      // ),
      // colorsGrayGray200: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray200"
      // ),
      // colorsGrayGray300: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray300"
      // ),
      // colorsThemeBaseDanger: objectPath.get(
      //   uiService.config,
      //   "js.colors.theme.base.danger"
      // ),
      // fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  // console.log('id:'+id)
  // console.log(states)


  let chartId = 'chart' + id


  
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <BasicLinkGraphSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });
  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================







  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };


  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">BasicLinkGraph</div>
              <div className="font-size-sm text-muted mt-2">for TEST</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">

not yet.






          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}


    </>
  );
}
