import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { Button, Modal, Table, Form } from "react-bootstrap";
import Utils from "../widgets/edit/WebBuilder/Utils";
import { Sweetalert_class } from "./SweetAlert";

const PushTable = ({ keyword, _id, rest, props_send, site }) => {
  const [type_state, set_type_state] = useState("and");
  const [keyword_more, set_keyword_more] = useState("");

  let class_put;

  const tdStyle = {
    padding: "10px 22px 10px 22px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    textAlign: "center",
    verticalAlign: "middle",
  };

  useEffect(()=>{
    if(site){
      let payload = {
        queries: [
          {
            key: "read_keyword_more",
            query: `db.collection('KeywordGroup').find(
              { _id : new require('mongodb').ObjectID("${_id}") },
              { filter : { $elemMatch : { site : "${site}", keyword : "${keyword}" } } }
            ).toArray()`
          }
        ]
      }

      Utils.getQueryResult(payload).then(async result => {
        // then 구현하기
        wait_func(result).then(data => {
          if(data[0].more){
            set_keyword_more(data[0].more.toString());
          } else {
            set_keyword_more("");
          }
        }).catch(err => {
          console.log(err);
        })
      })
    }
  }, [site]);

  
  const wait_func = (param) => {
    return new Promise((resolve, reject)=>{
      const temp_data = param.read_keyword_more[0].filter.filter(data => data.site === site).filter(data => data.keyword === keyword);

      if(temp_data){
        resolve(temp_data);
      }
      reject(new Error('failed'));
    });
  }

  // =============================================================================
  // [E] change Event
  // =============================================================================
  const change_type = (evt) => {
    const { value } = evt.target;

    set_type_state(value);
  };

  const change_keyword = (evt) => {
    const { value } = evt.target;

    set_keyword_more(value);
  };

  // =============================================================================
  // [E] click Event
  // =============================================================================
  const push_save_update = async (param) => {
    if (!type_state || !keyword_more) {
      class_put = new Sweetalert_class(
        "warning",
        "저장 및 수정이 불가능합니다.",
        "조건과 키워드를 선택해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    const split_array = await keyword_more.split(",");

    let payload = {
      queries: [
        {
          key: "update",
          query: `db.collection('KeywordGroup').updateOne(
            { _id: new require('mongodb').ObjectID('${param}'), filter : { $elemMatch : { site : '${site}',  keyword : '${keyword}' } } },
                  { $set: { 'filter.$.more': ${JSON.stringify(
                    split_array
                  )} }})`,
        },
      ],
    };

    Utils.getQueryResult(payload).then((result) => {
      if (result.update.acknowledged === true) {
        class_put = new Sweetalert_class(
          "success",
          "수정되었습니다.",
          "push 키워드 및 조건을 수정하였습니다."
        );
        class_put.crate_alert();
        props_send(new Date());
      }
    });
  };

  const push_delete = async (param) => {
    class_put = new Sweetalert_class("warning", "정말 삭제하시겠습니까?", "");
    const swal_result = await class_put.confirm_alert();

    if (swal_result) {
      let payload = {
        queries: [
          {
            key: "update",
            query: `db.collection('KeywordGroup').update(
                { _id: new require('mongodb').ObjectID('${param}'), filter : { $elemMatch : { site : '${site}',  keyword : '${keyword}' } } },
                        { $set: { 'filter.$.more': [] }})`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        if (result.update.acknowledged === true) {
          class_put = new Sweetalert_class(
            "success",
            "수정되었습니다.",
            "push 키워드 및 조건을 삭제하였습니다."
          );
          class_put.crate_alert();
          rest.onHide();
          props_send(new Date());
        }
      });
    }
  };

  return (
    <tr>
      <td style={tdStyle}>{keyword}</td>
      <td style={tdStyle}>
        {
          <Form.Control
            as="select"
            value={type_state}
            onChange={(e) => {
              change_type(e);
            }}
          >
            <option value="and">and</option>
          </Form.Control>
        }
      </td>
      <td style={tdStyle}>
        <Form.Control
          type="text"
          value={keyword_more}
          onChange={(e) => {
            change_keyword(e);
          }}
        />
      </td>
      <td style={tdStyle}>
        <button
          className="table_in_btn"
          onClick={() => {
            push_save_update(_id);
          }}
        >
          저장
        </button>
      </td>
      <td style={tdStyle}>
        <button
          className="table_in_del"
          onClick={() => {
            push_delete(_id);
          }}
        >
          삭제
        </button>
      </td>
    </tr>
  );
};

export default PushTable;
