import React, { Suspense, lazy, useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_wise/templates/type1/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { GridPage } from "./pages/GridPage";
import { Netar } from "./modules/Netar/Netar";

import { getInitLayoutConfig } from "../_wise/templates/type1/layout/_core/LayoutConfig";
import LayoutConfig_pageone from "./layoutConfig/LayoutConfig_pageone";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../redux/actionType";


const GoogleMaterialPage = lazy(() =>
    import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
    import("./modules/UserProfile/UserProfilePage")
);


export default function BasePage() {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user, shallowEqual);
    const currentMenu = useSelector(({ modl }) => modl.currentMenu, shallowEqual);
    const menuSelector = useSelector(({ modl }) => modl[currentMenu], shallowEqual);
    const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);
    const workspace = useSelector(({ modl }) => modl[currentWorkspace], shallowEqual);
  
    const [layoutLoaded, setLayoutLoaded] = React.useState(false);
    const [value,setValue] = useState();

    useEffect(() => {

        const ua = navigator.userAgent.toLowerCase()

        if (ua.indexOf("android") > -1) {

            let userInfo =
            {
                "userIdx": user._id,
                "siteIdx": user.siteIdx,
                "workspaceIdx": currentWorkspace.replace("workspace", ""),
                "email": user.email,
                "platform": "android"
            }

            try {

                window.android.sendUserIdToAndroid(JSON.stringify(userInfo));
            } catch (e) {

            }
        } else if (ua.indexOf("iphone") > -1) {
            let userInfo =
            {
                "userIdx": user._id,
                "siteIdx": user.siteIdx,
                "workspaceIdx": currentWorkspace.replace("workspace", ""),
                "email": user.email,
                "platform": "ios"
            }

            try {

                window.webkit.messageHandlers.sendUserIdToIOS.postMessage(JSON.stringify(userInfo))
            } catch (e) {
                
            }
            
        }


        console.log("user>>")
        console.log(user)
        // const eventFromAndroid = async (event) => {
        //   setMessageFromAndroid(event.detail.data);
        // }

        // window.addEventListener('javascriptFunction', eventFromAndroid);

        // if (window.android) {
        //   window.android.callJavaScriptFunction();
        // }

        // return () => {
        //   window.removeEventListener('javascriptFunction', eventFromAndroid);
        // };
    }, []);




    // useEffect(() => {

    //     // ================================================================
    //     // 1.projectName 기준으로 레이아웃을 정할때 ..
    //     // ================================================================
    //     // if (localStorage.projectName == "pageone") {
    //     //     _layout = LayoutConfig_pageone()
    //     // }
    //     // else {
    //     //     _layout = getInitLayoutConfig()
    //     // }

    //     // ================================================================
    //     // 2.workspace 기준으로 레이아웃을 정할때 ..
    //     // ================================================================
    //     let _layout = {}

    //     if (workspace.layout == undefined) {
    //         _layout = getInitLayoutConfig()
    //     } else {
    //         _layout = workspace.layout
    //     }


        
    //     console.log(">> basePage");
    //     console.log(_layout);





    //     const LAYOUT_CONFIG_KEY = "LayoutConfig";
    //     if (localStorage.getItem(LAYOUT_CONFIG_KEY) == JSON.stringify(_layout)) {
    //         console.log(">> PASS LAYOUT")
    //         setLayoutLoaded(true)
    //     } else {
    //         console.log(">> SET LAYOUT")
    //         localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(_layout));
    //         setLayoutLoaded(true)
           
    //         // window.location.reload(false);
    //     }

    //     console.log(">> basePage 2"); 
    //     console.log(localStorage.LayoutConfig); 
      
        
      

        
    // }, []);

    // if (layoutLoaded == false) return (<>layout loading...</>)






    var convMenu = []

    var defaultId = null

    if (menuSelector == null) return (<></>)

    if (menuSelector != null) {

        // console.log("==> menuSelector")
        // console.log(menuSelector)

        // 메뉴 진입시 초기 defaultId 설정
        menuSelector.forEach(function (menuSet, typeIndex) { // typeIndex 는 보통 workspace 0, private 1
            if (defaultId == null) {
                if (menuSet.defaultId != undefined) {
                    defaultId = menuSet.defaultId
                } else {
                    menuSet.items.forEach(function (item, index) {
                        if (index == 0) {
                            // console.log(item.id)
                            defaultId = item.id
                        }
                    })
                }
            }
        })

    }


    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* AUTO LAYOUT TEST */}

                {/* WORKSPACE */}
                {
                    (menuSelector.length > 0) ? menuSelector[0].items.map((x, i) =>
                        <ContentRoute key={uuidv4().split("-").join("")} path={'/menu/' + x.id} render={() => <GridPage id={x.pageIdx} />} />
                    ) : ""
                }

                {/* PRIVATE */}
                {
                    (menuSelector.length > 1) ? menuSelector[1].items.map((x, i) =>
                        <ContentRoute key={uuidv4().split("-").join("")} path={'/menu/' + x.id} render={() => <GridPage id={x.pageIdx} />} />
                    ) : ""
                }

                {/* {
                    menuSelector.map((menuSet, index) => {
                        menuSet.items.map((x, i) =>
                            <ContentRoute key={uuidv4().split("-").join("")} path={'/menu/' + x.id} render={() => <GridPage id={x.pageIdx} />} />
                        )
                    })
                } */}



                {/* TODO /install  */}
                <ContentRoute path="/builder" component={BuilderPage} />
                <ContentRoute path="/netar" component={Netar} />

                <Redirect to={'/menu/' + defaultId} />

            </Switch>
        </Suspense>
    );
}
