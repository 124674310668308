import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form } from "react-bootstrap";
import PushTable from "./PushTable";

export function PushKeywordWork(props) {
  const {
    data,
    parent_send,
    workSpace,
    parentId,
    parentCallback,
    ...rest
  } = props;

  const thStyle = {
    padding: "15px 22px 15px 22px",
    border: "0 !important",
    borderColor: "#ebebeb",
    backgroundColor: "#ecf0f9",
    textAlign: "center",
  };

  const [group_keyword, set_group_keyword] = useState(null);
  const [select_site, set_select_site] = useState([]);

  useEffect(() => {

    set_select_site([]);

    if (data) {
      if (data.filter.length !== 0) {
        const site_group_keyword = props.data.filter.reduce((acc, curr) => {
          const { site } = curr;
          if (acc[site]) acc[site].push(curr);
          else acc[site] = [curr];
          return acc;
        }, {});

        set_group_keyword(site_group_keyword);
      }
    }

    return () => {
      set_select_site([]);
      set_group_keyword(null);
    };
  }, [props.data]);

  const props_send = (param) => {
    parent_send(param);
  };

  const push_keyword_click = (param) => {
    set_select_site(group_keyword[param]);
  };

  if (!group_keyword) return <></>;
  let button_ui = Object.keys(group_keyword).map((data, index) => {
    return (
      <Button
        key={index}
        size="sm"
        onClick={() => {
          push_keyword_click(data);
        }}
      >
        {data}
      </Button>
    );
  });

  if (!select_site) return <>데이터 없음</>;
  let table_ui = select_site.length !== 0 ? select_site.map((_data, index) => {
    return (
      <PushTable
        key={index}
        keyword={_data.keyword}
        rest={rest}
        props_send={props_send}
        _id={data._id}
        site={_data.site}
      />
    );
  }) : <tr><td className="select_site_td" colspan="5"># 조건을 추가하실 사이트를 선택해주세요</td></tr>;

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          키워드별 PUSH 조건 추가
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="keyword_group_btn">{button_ui}</div>
        <Table
          striped
          hover
          style={
            {
              /* minWidth: "1200px", marginBottom: "0" */
            }
          }
        >
          <thead>
            <tr>
              <th style={thStyle}>키워드</th>
              <th style={thStyle}>조건</th>
              <th style={thStyle}>추가 키워드</th>
              <th style={thStyle}>수정</th>
              <th style={thStyle}>삭제</th>
            </tr>
          </thead>
          <tbody className="">{table_ui}</tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={rest.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}