/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { actionTypes } from "../../../../redux/actionType";
import { WebViewerSetting } from "../utils/WebViewerSetting";



export function WebViewer({ el, states, className }) {


  let id = "component" + el.i


  const [link, setLink] = React.useState('');
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)


  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <WebViewerSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================
  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================






  useEffect(() => {
    setLink(el.data.link)
  }, [states]);


  const selectSetting = (data) => {

    let link = JSON.parse(data).link

    setLink(link)

    let elCopy = JSON.parse(JSON.stringify(el))

    elCopy.data.link = link

    dispatch({ type: actionTypes.UpdateComponent, payload: { pageIdx: currentPage.slice("page".length), component: elCopy } })
  };


  return (
    <>



      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">WebViewer</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          <div className="flex-grow-1 card-spacer-x">

            <div style={{

              width: "100%",
              height: "100%"
            }}>

              <iframe style={{
                overflowX: "auto",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }} src={link} title="web player" frameBorder="0" ></iframe>

            </div>



          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

    </>
  );
}


