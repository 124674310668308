import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import Loading from '../../../../func-component/Loading';

const ChartKeyword = () => {

    const { keywordName, keywordCount } = useSelector((state)=> state.chart);
    
    var option2 = {
        tooltip: {
        trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {
            textStyle: {
                color: "#152e5a",
                fontFamily: "Pretendard",
                fontSize: 12,
                fontWeight: "bold"
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            
        },
        yAxis: {
            type: 'category',
            data: keywordName,
            axisLabel:{
                textStyle:{
                   color: "#152e5a",
                   fontSize: 14,
                   lineHeight: 12,
                   fontFamily: "Pretendard",
                   fontWeight: "bold"
                },
            labelMaxWidth : 50
            }
        },
        series: [
            {
                name: '크롤링 데이터 개수',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    color: "#152e5a",
                    fontSize: 14,
                    fontFamily: "Pretendard",
                    fontWeight: "bold",
                    fontColor: "#152e5a",
                    position: "right"
                },
                emphasis: {
                    focus: 'series'
                },
                data: keywordCount,
                itemStyle: {
                    color: "#95b9ff"
                }
            }
        ]
    };

    return(
        <>
        {
            !keywordName ?
            <Loading /> :
                keywordName.length === 0 ?
                <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", background: "#fafafb", borderRadius: "4px" }}>
                    <img src="https://hyorim-bucket.s3.ap-northeast-2.amazonaws.com/images/NoData.png" alt="no data" />
                </div>
                :
            <ReactECharts option={option2} />
        }
        </>
    )
}

export default ChartKeyword;