export const actionTypes = {
  Login: "[Login] Action",
  LoginNaver: "[NaverLogin] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",

  SetUser: "[Set User] Action",
  SetWorkspace: "[Set Workspace] Action",

  
  LoginVisitor: "[Set Visitor User] Action",
  LoginVisitorWorkspace:"LOGIN_VISITOR_WORKSPACE",




  IsExistUser: "IS_EXIST_USER",

  InitWorkspace: "WORKSPACE_INIT_REQUESTED", // 전체 워크스페이스 리스트를 업데이트 하고 첫번째 워크스페이스로 이동 

  GetUser: "USER_GET_REQUESTED",
  GetLnkWorkspace: "WORKSPACE_LINK_USER_GET_REQUESTED", // 전체 워크스페이스 리스트를 업데이트
  GetSite: "SITE_GET_REQUESTED", // 현재 속해있는 SITE 조회 (로그인 후 바로 fix 된다.)
  GetWorkspace: "WORKSPACE_GET_REQUESTED", // workspaceIdx 로 워크스페이스로 이동 
  GetComponent: "COMPONENT_GET_REQUESTED", //아직 사용되는 곳 없음 
  GetTplComponent: "COMPONENT_TEMPLATE_GET_REQUESTED",
  GetMenu: "MENU_GET_REQUESTED",
  GetPage: "PAGE_GET_REQUESTED",
  GetGroupAll: "GROUP_GET_REQUESTED",
  GetTagGroupAll: "TAG_GROUP_ALL_GET_REQUESTED",


  GetWorkspacesAll: "ALL_WORKSPACES_GET_REQUESTED",

  // 컴포넌트 하나의 데이터 영역만 업데이트
  UpdateComponentData: "COMPONENT_DATA_UPDATE_REQUESTED",

  // 컴포넌트 하나만 업데이트
  UpdateComponent: "COMPONENT_UPDATE_REQUESTED",

  // 컴포넌트 전체 업데이트
  UpdateComponents: "COMPONENTS_UPDATE_REQUESTED",

  // 컴포넌트 템플릿 업데이트
  UpdateTplComponent: "COMPONENT_TEMPLATE_UPDATE_REQUESTED",

  // 페이지 업데이트 (현재 ControlPage 에서만 사용)
  UpdatePage: "PAGE_UPDATE_REQUESTED",

  // 메뉴 업데이트 
  UpdateMenu: "MENU_UPDATE_REQUESTED",

  // 메뉴 추가 
  InsertMenu: "MENU_INSERT_REQUESTED",

  InviteUser: "USER_INVITE_REQUESTED",

  RemoveTplComponent: "COMPONENT_TEMPLATE_REMOVE_REQUESTED",

  ReloadPage: "PAGE_RELOAD_REQUESTED",

  Failure: "FAILURE_COMMON",

  DeleteCurrentWorkspaceInfo: "delete_CurrentWorkspace_Info",



  UpdateWebBuilder: "update_web_builder",

  GetDocumentCount: "DOCUMENT_COUNT_GET_REQUESTED", // table totalCount



  // 효림 관리자 관리(admin)
  //===========================================================================================
  // START
  //===========================================================================================
  GetWorkspaceAdminMember: "WORKSPACE_ADMIN_MEMBER_GET_REQUESTED",
  InsertWorkspaceAdminMember: "WORKSPACE_ADMIN_MEMBER_INSERT_REQUESTED",
  UpdateWorkspaceAdminMember: "WORKSPACE_ADMIN_MEMBER_UPDATE_REQUESTED",
  DeleteWorkspaceAdminMember: "WORKSPACE_ADMIN_MEMBER_DELETE_REQUESTED",


  // 사용자 관리(workspace)
  //===========================================================================================
  // START
  //===========================================================================================
  GetWorkspaceMember: "WORKSPACE_MEMBER_GET_REQUESTED",
  InsertWorkspaceMember: "WORKSPACE_MEMBER_INSERT_REQUESTED",
  UpdateWorkspaceMember: "WORKSPACE_MEMBER_UPDATE_REQUESTED",
  DeleteWorkspaceMember: "WORKSPACE_MEMBER_DELETE_REQUESTED",


  // 사용자 권한 그룹 관리(workspace)
  //===========================================================================================
  // START
  //===========================================================================================
  GetWorkspaceGroup: "WORKSPACE_MEMBER_GROUP_GET_REQUESTED",
  InsertWorkspaceGroup: "WORKSPACE_MEMBER_GROUP_INSERT_REQUESTED",
  UpdateWorkspaceGroup: "WORKSPACE_MEMBER_GROUP_UPDATE_REQUESTED",
  DeleteWorkspaceGroup: "WORKSPACE_MEMBER_GROUP_DELETE_REQUESTED",


  // 태그 관리(workspace)
  //===========================================================================================
  // START
  //===========================================================================================
  GetWorkspaceTag: "WORKSPACE_TAG_GET_REQUESTED",
  InsertWorkspaceTag: "WORKSPACE_TAG_INSERT_REQUESTED",
  UpdateWorkspaceTag: "WORKSPACE_TAG_UPDATE_REQUESTED",
  DeleteWorkspaceTag: "WORKSPACE_TAG_DELETE_REQUESTED",


  // 태그 그룹 관리(workspace)
  //===========================================================================================
  // START
  //===========================================================================================
  GetWorkspaceTagGroup: "WORKSPACE_TAG_GROUP_GET_REQUESTED",
  InsertWorkspaceTagGroup: "WORKSPACE_TAG_GROUP_INSERT_REQUESTED",
  UpdateWorkspaceTagGroup: "WORKSPACE_TAG_GROUP_UPDATE_REQUESTED",
  DeleteWorkspaceTagGroup: "WORKSPACE_TAG_GROUP_DELETE_REQUESTED",


  // 전체 워크스페이스 관리(admin)
  //===========================================================================================
  // START
  //===========================================================================================
  GetWorkspaceList: "WORKSPACE_LIST_GET_REQUESTED",
  DeleteWorkspace: "WORKSPACE_DELETE_REQUESTED",
  InsertWorkspace: "WORKSPACE_INSERT_REQUESTED",
  UpdateWorkspace: "WORKSPACE_UPDATE_REQUESTED",




  // 전체 워크스페이스 관리(admin)
  //===========================================================================================
  // START
  //===========================================================================================
  GetDataTest: "GetDataTest",
  InsertDataTest: "InsertDataTest",
  UpdateDataTest: "UpdateDataTest",
  DeleteDataTest: "DeleteDataTest",



  // 엑셀 업로드 데이터
  //===========================================================================================
  // START
  //===========================================================================================
  InsertExcelData: "InsertExcelData",



  // Web builder
  //===========================================================================================
  // START
  //===========================================================================================
  UpdateSelectElement: "UPDATE_SELECT_ELEMENT",


};