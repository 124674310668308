/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useCallback } from "react";
import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";

import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { useHtmlClassService } from "../../../templates/type1/layout";

import axios from 'axios';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import words from "./word.js";


import { BasicWordCloudSetting } from "./BasicWordCloudSetting";





export function BasicWordCloud({ el, states, className }) {
  let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      // colorsGrayGray500: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray500"
      // ),
      // colorsGrayGray200: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray200"
      // ),
      // colorsGrayGray300: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray300"
      // ),
      // colorsThemeBaseDanger: objectPath.get(
      //   uiService.config,
      //   "js.colors.theme.base.danger"
      // ),
      // fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  // console.log('id:'+id)
  // console.log(states)

  useEffect(() => {
    console.log("BasicWordCloud === ")
  }, [id]);





  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <BasicWordCloudSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });
  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    embed: function () {

      // src 외부 접근 가능 URL 을 적어주세요
      let copyStr =
        `<iframe width="1280" height="720" 
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

      console.log(copyStr)
      alert("check console.")

      return true
    }
    ,
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================



  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  const callbacks = {
    getWordColor: word => word.value > 50 ? "blue" : "red",
    onWordClick: console.log,
    onWordMouseOver: console.log,
    getWordTooltip: word => `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
  }
  const options = {
    rotations: 0,
    rotationAngles: [0, 0]

    // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    // enableTooltip: true,
    // deterministic: false,
    // fontFamily: "impact",
    // fontSizes: [5, 60],
    // fontStyle: "normal",
    // fontWeight: "normal",
    // padding: 1,
    // rotations: 3,
    // rotationAngles: [0, 90],
    // scale: "sqrt",
    // spiral: "archimedean",
    // transitionDuration: 1000
  };
  const size = [600, 400];


  return (
    <>

      <FullScreen handle={fullScreenHandle}>

        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom ${className}`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder">BasicWordCloud</div>
                <div className="font-size-sm text-muted mt-2">for TEST</div>
              </div>
            </div>

          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body d-flex flex-column px-0">

            {/* begin::Items */}
            <div className="flex-grow-1 card-spacer-x">



              {/* Chart */}
              <ReactWordcloud words={words}
                // callbacks={callbacks}
                options={options}
                // size={size}
                words={words} />







            </div>
            {/* end::Items */}
          </div>
          {/* end::Body */}
        </div>
        {/* end::Tiles Widget 1 */}
      </FullScreen>


    </>
  );
}
