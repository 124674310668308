import axios from "axios";
import store from "../../../../redux/store";
import * as redux from "../../../../redux";

export const LOGIN_URL = "/graphql";
export const REGISTER_URL = "/graphql";
export const REQUEST_PASSWORD_URL = "/graphql";
export const ME_URL = "/graphql";


export const API_END_POINT_URL = "/graphql";


export function login(email, password) {

  const instance = axios.create();
  const req = { query: "{ login(email: \"" + email + "\", password: \"" + password + "\") }" };

  return instance.post(API_END_POINT_URL, req);
}

export function login_naver(obj) {

  const instance = axios.create();
  const req = { query: "{ login_naver(token: \"" + obj.token + "\", siteIdx: \"" + obj.siteIdx + "\")}"}

  return instance.post(API_END_POINT_URL, req);
}

//토큰으로 유저 로그인 하기 .. 
export function getUserByToken() {

  redux.setupAxios(axios, store);
  
  const instance = axios.create();
  const req = { query: "{ getUserByToken }" };

  const result = instance.post(API_END_POINT_URL, req);
  
  return result;

}



export function register(email, username, password) {

  var instance = axios.create();
  
  const req = {
    query: `mutation register($email: String, $username: String, $password: String) {
      register(email: $email, username: $username, password: $password)
    }`,
    variables: {
      email: email,
      username: username,
      password: password
    }
  }
  
  return new Promise((resolve, reject) => {
    instance.post('graphql/', req)
      .then(resp => {
        // console.log('authCrud >> resp >>')
        // console.log(resp.data.data.register)

        resolve(resp.data.data.register)

      })
      .catch(error => {
        reject(error)
      });
  });












}

export function requestPassword(email) {

  const instance = axios.create();
  const req = { query: "{ login }" };

  // console.log('requestPassword')
  return instance.post(API_END_POINT_URL, req);

}









// import axios from "axios";
// export const LOGIN_URL = "api/auth/login";
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// export const ME_URL = "api/me";

//TODO 
//register , requestPassword 는 아직 못만짐 .. 


// export function login(email, password) {
//   return axios.post(LOGIN_URL, { email, password });
// }

// export function register(email, fullname, username, password) {
//   return axios.post(REGISTER_URL, { email, fullname, username, password });
// }

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   return axios.get(ME_URL);
// }
