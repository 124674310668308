
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal ,Form} from 'react-bootstrap';

export function MapViewerSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                MapViewer Setting 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

           
            TODO: NAVER / GOOGLE / KAKAO MAP 연동
            <br/><br/>
            <Form>
                    <Form.Label>lat</Form.Label>
                    <Form.Control type="text" placeholder="37.39387430684983" />
                </Form><br/>
                <Form>
                    <Form.Label>lng</Form.Label>
                    <Form.Control type="text" placeholder="126.96037975440484" />
                </Form><br/>
                <Form>
                    <Form.Label>explain</Form.Label>
                    <Form.Control type="text" placeholder="wisesolution" />
                </Form>
   
                <br/>
                <div>
                    <Button style={btnStyle} size='lg' onClick={() => {
                        parentCallback("save")
                        rest.onHide()
                    }}> SAVE </Button>
                </div>






            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}