
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, ListGroup } from 'react-bootstrap';

import { InputBuilderCore } from "./InputBuilderCore";
import { actionTypes } from "../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { DraggableArea } from 'react-draggable-tags';

import SVG from "react-inlinesvg";


// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    width:"100%",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables 
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: "100%"
});




export function InputBuilderSetting(props) {

    const { mode, action, parentId, parentCallback, ...rest } = props

    const [Layout, setLayout] = React.useState(props.layout);
    const [SettingInfo, setSettingInfo] = React.useState();
    const [Tags, setTags] = useState([]);

    const [SelectId, setSelectId] = React.useState("");

    const [example, setExample] = useState({});
    const [ValueTest, setValueTest] = React.useState("T");
    const [ValueTest2, setValueTest2] = React.useState("");


    const [state, setState] = useState([getItems(5), getItems(2, 5)]);


    useEffect(() => {
        let tags = []
        let func = new Func();

        Layout.map((el) => {
            tags.push({ id: Math.random(), type: el.type, content: func[el.type](el.value) })
        })
        setTags(tags);

    }, []);


    const returnUI = (type, index) => {
        let func = new Func();
        console.log("returnUI >> ")
        console.log(index)
        let data = func[type](index)
        return (data)

    }


    const changeValueTest = (e, index) => {
        console.log("> changeValueTest::" + index)
        console.log(e.target.value)
        setExample({ ...example, [index]: e.target.value })
        // setValueTest(e.target.value)

    }

    const changeValueTest2 = (e) => {
        console.log("> changeValueTest2")
        console.log(e.target.value)

        setValueTest2(e.target.value)

    }
    // =====================================================
    // [E] modal
    // =====================================================


















    // =====================================================
    // [S] STRUCTURE
    // =====================================================


    class Func {

        input = (data) => {
            return (
                <>
                    <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
                        <Form.Label column sm={2}>
                            {example[data]}
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control value={data} />
                        </Col>
                    </Form.Group>

                </>
            );
        }

        input2 = (data) => {
            return (
                <Form.Row key={Math.random()} >
                    <Form.Group as={Col} id="formPassword">
                        <Form.Label>{data.title}*</Form.Label>
                        <Form.Control placeholder="비밀번호를 입력해주세요" wsdata="wise" />
                    </Form.Group>

                    <Form.Group as={Col} id="formPasswordConfirm">
                        <Form.Label>비밀번호 확인*</Form.Label>
                        <Form.Control placeholder="동일한 비밀번호를 입력해주세요" />
                    </Form.Group>
                </Form.Row>
            );
        }


        buttonSubmit = (data) => {
            return (
                <Button key={Math.random()} variant="primary" onClick={((e) => submit(e))} >
                    {data.title}
                </Button>
            );
        }
    }


    class Setting {

        input = (data) => {
            return (
                <>
                    <Form.Group as={Row} >
                        <Form.Label column sm={2}>
                            db
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control value="DataTest" />
                        </Col>

                        <Form.Label column sm={2}>
                            id
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control value="email" />
                        </Col>

                        <Form.Label column sm={2}>
                            label
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control onChange={(e) => changeValueTest(e, data)} />
                        </Col>

                        <Form.Label column sm={2}>
                            value
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control onChange={changeValueTest2} />
                        </Col>


                        <Form.Label column sm={2}>
                            formcheck
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control value="email 형식" />
                        </Col>

                    </Form.Group>
                </>
            );
        }

        input2 = (data) => {
            return (
                <>
                    <Form.Group as={Row} >
                        <Form.Label column sm={2}>
                            egsdf
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control defaultValue={ValueTest || ''} />
                        </Col>
                        <Form.Label column sm={2}>
                            dsfaf
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control defaultValue={ValueTest || ''} />
                        </Col>

                        <Form.Label column sm={2}>
                            agdae
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control defaultValue={ValueTest || ''} />
                        </Col>
                    </Form.Group>
                </>
            );
        }


        buttonSubmit = (data) => {
            return (
                <>
                    <Form.Group as={Row} >
                        <Form.Label column sm={2}>
                            11111
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control defaultValue={ValueTest || ''} />
                        </Col>
                        <Form.Label column sm={2}>
                            22222
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control defaultValue={ValueTest || ''} />
                        </Col>
                    </Form.Group>
                </>
            );
        }

    }

    // =====================================================
    // [E] STRUCTURE
    // =====================================================



    const submit = (e) => {

        console.log("submit")

        // SEND DATA to DB

        // let payload = [{
        //   "document": "DataTest1",
        //   "workspaceIdx": "data1"
        // },{
        //   "document": "DataTest2",
        //   "workspaceIdx": "data2"
        // }]

        // dispatch({ type: actionTypes.InsertDataTest, payload: payload })

        // alert("submit")
    }


    const save = (e) => {

        // var closestElement = e.target.closest(".wsform");
        // var sendDataList = closestElement.querySelectorAll("[wsdata]")

        console.log(">> save")

        let saveArray = []

        // TODO : DOM 분석후 Type 별로 Content 를 만든다 . 
        for (let tag of Tags) {
            let content = {}
            if (tag.type == "input") {
                content = { "type": "input", "value": { "title": "TTTTT", "style": 1 } }
            } else if (tag.type == "input2") {
                content = { "type": "input2", "value": { "title": "input2", "style": 1 } }
            } else if (tag.type == "buttonSubmit") {
                content = { "type": "buttonSubmit", "value": { "title": "buttonSubmit" } }
            }

            saveArray.push(content)
        }

        console.log("saveArray:" + JSON.stringify(saveArray))

        setLayout(saveArray)


    }


    const handleAddTag = (type) => {

        let content = {}


        // TODO INIT STATE.
        if (type == "input") {
            content = { "type": "input", "value": { "title": "TTTTT", "style": 1 } }
        } else if (type == "input2") {
            content = { "type": "input2", "value": { "title": "input2", "style": 1 } }
        } else if (type == "buttonSubmit") {
            content = { "type": "buttonSubmit", "value": { "title": "buttonSubmit" } }
        }

        let func = new Func();
        let joined = Tags.concat({ id: Math.random(), type: type, content: func[type](content.value) })
        setTags(joined);


    }


    const handleDeleteTag = (e, tag) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        console.log("handleDeleteTag:" + tag.id)
        let _tags = Tags.filter(t => tag.id !== t.id);
        setTags(_tags)
    }


    const handleChangeTag = (tags) => {
        // console.log("handleChangeTag:" + JSON.stringify(tags))
        setTags(tags)
    }


    const handleClickTag = (tags) => {
        console.log("handleClickTag:" + JSON.stringify(tags))
        console.log("handleClickTag:" + tags.type)

        let setting = new Setting();


        setSelectId(tags.id)

        // let data = tags.id //index

        setSettingInfo(setting[tags.type](tags.id))


    }


    const handlePress = (e) => {
        console.log("____ handlePress:")
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        // props.callbackParent({ isDraggable: false });
    }



    return (
        <>

            <div style={{ height: "100%",overflow:"hidden" }} onMouseDown={(e) => handlePress(e)}>





                <div onClick={() => {
                    // parentCallback("Setting A")
                    rest.onHide()
                }}>
                    <div style={{ width: "100%", height: "40px" }} >
                    <SVG src={"/media/svg/icons/Navigation/Angle-right.svg"}/>
                        Close Input Builder
                    </div>


                </div>



                <div style={{overflowY: "scroll", height: "200px"}}>
                    {/* <DragDropContext> */}
                    {state.map((el, ind) => (
                        <Droppable  key={ind} droppableId={`${(ind + 1.0) * Math.random()}`}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    {el.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-around"
                                                        }}
                                                    >
                                                        {item.content}
                                                        <div
                                                            type="button"
                                                            onClick={() => {
                                                                const newState = [...state];
                                                                newState[ind].splice(index, 1);
                                                                setState(
                                                                    newState.filter(group => group.length)
                                                                );
                                                            }}
                                                        >
                                                            MODAL
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                    {/* </DragDropContext> */}
                </div>











{/* 
                <Button size="sm" style={{ margin: "1px" }} key={Math.random()} variant="danger" onClick={((e) => save(e))} >
                    저장
                </Button>
                <br />
                <Button size="sm" style={{ margin: "1px" }} onClick={() => handleAddTag("input")} variant="success">
                    input
                </Button>
                <Button size="sm" style={{ margin: "1px" }} onClick={() => handleAddTag("input2")} variant="success">
                    input2
                </Button>
                <Button size="sm" style={{ margin: "1px" }} onClick={() => handleAddTag("buttonSubmit")} variant="success">
                    submit
                </Button>



                <br /><br />


                <div style={{ width: "50%", float: "left" }}>
                    <DraggableArea
                        isList
                        tags={Tags}
                        render={({ tag, index }) => (
                            <>

                                <div type={tag.type} onClick={() => handleClickTag(tag)} style={{ padding: "20px 2px 2px 2px", backgroundColor: (tag.id == SelectId) ? "green" : (index % 2 == 0) ? "#eeeeee" : "#dddddd" }}>

                                    {returnUI(tag.type, tag.id)}

                                    <span style={{ position: "absolute", top: "0px", right: "0px", height: "15px", width: "15px", backgroundColor: "black" }} onClick={(e) => handleDeleteTag(e, tag)}> x </span>

                                </div>

                            </>
                        )}
                        onChange={tags => handleChangeTag(tags)}

                    />

                </div>
                <div style={{ backgroundColor: "#eeeeee", padding: "4px", width: "48%", float: "right" }} >

                    {SettingInfo}

                </div>
 */}





            </div>

        </>
    );
}