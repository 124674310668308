/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/WiseLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
const moment = require('moment');


export function CurrentDate() {
  
  const user = useSelector((state) => state.auth.user, shallowEqual);
  //console.log(user)

  // var userName = user.name

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      // offcanvas:
      //   objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <>
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
             
            >
              <>
              <div className="d-flex align-items-center">

            <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
            <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">{moment().format("YYYY.MM.DD")}</span>
        
        </div>

              </>
            </div>
          </div>
    </>
  );
}
