/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState,  forwardRef } from "react";
import * as _ from "lodash";
import {
  Container,
  Form
} from "react-bootstrap";
import access_denied from './style/img/access_denied.svg';



export function KICI_NotComponent() {

  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================

  const [firstRun, setFirstRun] = useState(true);
  const [values, setValues] = useState({});
  const [data, setData] = React.useState("");
  const [layout, setLayout] = React.useState();

  
  // =====================================================
  // [S] Handle Event (handleEvent)
  // =====================================================

  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  let field1 = "7J1kudaO9"
  let field2 = "ZRd_IEk7j"


  // =====================================================
  // [S] FOR FCM REGIST
  // =====================================================
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control type="text" onClick={onClick} ref={ref} value={value} readOnly style={{ width: "120px" }} />
  ))

  var handleEventObj = {
    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다 
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      setValues(previousState => ({ ...previousState, [id]: value }));
      return true
    },

    test: function (method, id, ...value) {
      return true
    }
  };


  const handleEvent = (method, id, ...value) => {

    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }

  }
  

  return (
    <>
      <Container fluid className={"p-0"}>
        <div className="col-12">
          <div className="not_contents_wrap">
            <img src={access_denied} alt="Access denied" />
            <p>권한이 없습니다.<br />해당 게시판을 이용하고 싶으시다면 관리자에게 문의해주세요.</p>
          </div>
        </div>
      </Container >
    </>
  );
}
