import shortid from "shortid";
import { ROW, COLUMN, ELEMENT } from "./constants";
import * as _ from "lodash"

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {

  console.log(">> reorder")

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

export const remove = (arr, index) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1)
];

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];









// ===============================================================================
// [S] reorder
// ===============================================================================

const reorderChild = (children, item, dropZone) => {

  // console.log(">> 32. reorderChild")
  // console.log(el)
  // console.log(children)
  // console.log(item)
  // console.log(dropZone)


  let _child = children

  // console.log("> 33. reorderChild ---------")
  // console.log(children)

  _child.forEach(function iter(o, index, object) {

    if (o.id == item.id) {
      // console.log("-----item o.id:" + o.id)
      // console.log(_child)

      object.splice(index, 1)

      // _child.splice(index, 1)
      // console.log("-----item children:")
      // console.log(_child)

      return false

    } else {

      if (o.children) {
        _child = o.children
        _child.forEach(iter)
      } else if (o.layout) {
        _child = o.layout
        _child.forEach(iter)
      }
    }

  });


  // console.log("> 34. reorderChild ---------")
  // console.log(children)


  let _child2 = children

  // console.log("> 36. reorderChild ---------")
  // console.log(_child2)

  _child2.forEach(function iter(o, index, object) {

    // console.log(">>> forEach : o")
    // console.log(o)


    if (o.id == dropZone.id) {

      // console.log(">>> CHECK -----dropZone children:")
      // console.log(_child2)

      // console.log("item.path: " + item.path)
      // console.log("dropZone.path: " + dropZone.path)

      let splitItemPath = item.path.split("-");
      let itemIndex = Number(splitItemPath.slice(-1)[0]);

      let splitDropZonePath = dropZone.path.split("-");
      let dropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

      // console.log("itemIndex:" + itemIndex)
      // console.log("dropZoneIndex:" + dropZoneIndex)


      // 부모가  같을때 .. 비교 할것 
      let sameParent = false
      for (let c of dropZone.children) {
        if (c.id == item.id) sameParent = true
      }

      // console.log("sameParent:" + sameParent)

      if (sameParent) {
        if (itemIndex < dropZoneIndex) {
          // 삭제(Item) 의 인덱스가 더 낮을때 (같은 뎁스 일때!!)
          // console.log("dropZoneIndex: = dropZoneIndex - 1 ")
          dropZoneIndex = dropZoneIndex - 1
        }
      }



      o.children.splice(dropZoneIndex, 0, item);
    
    
    
      // // object.splice(dropZoneIndex, 0, item);
      // if (dropZone.type == "layout") {
      //   object.splice(dropZoneIndex, 0, item);
      // } else {
      //   o.children.splice(dropZoneIndex, 0, item);
      // }


      return false

    } else {


      if (o.children) {
        _child2 = o.children
        _child2.forEach(iter)
      } else if (o.layout) {
        _child2 = o.layout
        _child2.forEach(iter)
      }
    }

  });

}




export const reorderChildren = (layout, item, dropZone) => {
  // console.log(">> 199. reorderChildren")
  // console.log(children)

  // console.log("==========================")
  // console.log("# reorderChildren ")
  // console.log("# item :")
  // console.log(item)
  // console.log("# dropZone :")
  // console.log(dropZone)
  // console.log("==========================")


  // const _layout = [...layout];
  // let _layout = _.cloneDeep(layout)

  let _layout = JSON.parse(JSON.stringify(layout))

  // console.log(">> 200. reorderChildren")
  // console.log(_children)

  reorderChild(_layout, item, dropZone)

  // console.log(">> 201. reorderChildren 2")

  return _layout;

};









// ===============================================================================
// [S] REMOVE
// ===============================================================================

const removeChild = (children, item) => {
  // console.log(">> 2. removeChild")
  // console.log(children)
  // console.log(item)

  children.forEach(function iter(o, index) {

    if (o.id == item.id) {

      // console.log("----- child index:" + index)
      // console.log(o.id)
      // console.log(item.id)
      // console.log(children)

      children.splice(index, 1)

    } else {
      if (o.children) {
        removeChild(o.children, item)
      } else if (o.layout) {
        removeChild(o.layout, item)
      }
    }

  });

}


export const removeChildFromChildren = (children, item) => {

  let _children = JSON.parse(JSON.stringify(children))

  // console.log(">> 2. removeChildFromChildren")
  // console.log(_children)
  // console.log(item)

  removeChild(_children, item)

  // console.log(">> children")
  // console.log(JSON.stringify(_children, null, 2))

  return _children;
};









// ==================================================================
// 지우거나 이동후 원래 ROW 에 자식이 없을때 EMPTY Column 을 넣어준다.
// ==================================================================

export const addColumDataToRow = layout => {

  // console.log(">> addColumDataToRow 1")
  // console.log(layout)

  const layoutCopy = [...layout];
  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: shortid.generate(),
    children: []
  };

  return layoutCopy.map(row => {
    if (!row.children.length) {
      row.children = [COLUMN_STRUCTURE];
    }
    return row;
  });

};



// ===============================================================================
// [S] addChild
// ===============================================================================

// SUCCESS
// SUCCESS
// SUCCESS
const addChild = (layout, item, dropZone) => {
  // console.log(">> 32. addChild")
  // console.log(layout)
  // console.log(item)
  // console.log(dropZone)

  let _child = layout

  // console.log("> 33. addChild ---------")
  // console.log(_child)

  _child.forEach(function iter(o, index) {

    if (o.id == dropZone.id) {

      // console.log("-----dropZone children:")
      // console.log(_child)
      // console.log("dropZone.path: " + dropZone.path)

      let splitDropZonePath = dropZone.path.split("-");
      let dropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

      // console.log(dropZoneIndex)
      // console.log(o)
      
      o.children.splice(dropZoneIndex, 0, item);

      return false

    } else {

      if (o.children) {
        _child = o.children
        _child.forEach(iter)
      } else if (o.layout) {
        _child = o.layout
        _child.forEach(iter)
      }
    }

  });

}



export const addChildToChildren = (layout, item, dropZone) => {

  // console.log("==========================")
  // console.log("# addChildToChildren ")
  // console.log("# item :")
  // console.log(item)
  // console.log("# dropZone :")
  // console.log(dropZone)
  // console.log("==========================")

  let updatedLayout = JSON.parse(JSON.stringify(layout))

  addChild(updatedLayout, item, dropZone)

  return updatedLayout;
};









// ===============================================================================
// [S] HANDLE 
// ===============================================================================


// 1 handleMoveWithinParent
export const handleMoveWithinParent = (
  layout,
  item,
  dropZone
) => {

  // console.log(">> 7. handleMoveWithinParent")
  // console.log(layout)

  return reorderChildren(layout, item, dropZone);
};





// 2 handleMoveToDifferentParent
export const handleMoveToDifferentParent = (
  layout,
  item,
  dropZone
) => {

  const splitDropZonePath = dropZone.path.split("-");

  // console.log(">> 1. handleMoveToDifferentParent")
  // console.log("splitDropZonePath.length:" + splitDropZonePath.length)


  let newLayoutStructure;

  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: shortid.generate(),
    children: [item]
  };


  const ROW_STRUCTURE = {
    type: ROW,
    id: shortid.generate()
  };



  switch (splitDropZonePath.length) {
    case 1: {
      // console.log("state:1")
      // moving column outside into new row made on the fly
      if (item.type === COLUMN) {
        // console.log("state:2")
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [item]
        };
      } else {
        // moving element outside into new row made on the fly
        // console.log("state:3")

        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [COLUMN_STRUCTURE]
        };
      }
      break;
    }
    case 2: {
      // console.log("state:4")

      // moving element outside into a row which creates column
      if (item.type === ELEMENT) {
        // console.log("state:5")

        newLayoutStructure = COLUMN_STRUCTURE;
      } else {
        // console.log("state:6")

        // moving column into existing row
        newLayoutStructure = item;
      }

      break;
    }
    default: {
      // console.log("state:7")

      newLayoutStructure = item;
    }
  }

  // console.log("- newLayoutStructure")
  // console.log(newLayoutStructure)

  let updatedLayout = layout;

  // console.log("-- 0.updatedLayout")
  // console.log(updatedLayout)


  // ==================================================================
  // 이동한 아이템 삭제
  // ==================================================================
  // console.log("-- 1.updatedLayout")
  updatedLayout = removeChildFromChildren(updatedLayout, item);
  // console.log(updatedLayout)


  // ==================================================================
  // 지우거나 이동후 원래 ROW 에 자식이 없을때 EMPTY Column 을 넣어준다.
  // ==================================================================
  // console.log("-- 2.updatedLayout")
  updatedLayout = addColumDataToRow(updatedLayout);
  // console.log(updatedLayout)


  // ==================================================================
  // 이동 목표에 지운 아이템 추가
  // ==================================================================
  // console.log("-- 3.updatedLayout")
  updatedLayout = addChildToChildren(
    updatedLayout,
    newLayoutStructure,
    dropZone

  );

  // console.log("[handleMoveToDifferentParent]")
  // console.log(updatedLayout)

  return updatedLayout;
};








// [S] ===============================================================================
// [S] HANDLE SIDEBAR
// [S] ===============================================================================

export const handleMoveSidebarElementIntoParent = (
  layout,
  item,
  dropZone

) => {

  const splitDropZonePath = dropZone.path.split("-");

  // console.log(">> 9. handleMoveSidebarElementIntoParent")

  let newLayoutStructure;
  switch (splitDropZonePath.length) {
    case 1: {
      newLayoutStructure = {
        type: ROW,
        id: shortid.generate(),
        children: [{ type: COLUMN, id: shortid.generate(), children: [item] }]
      };
      break;
    }
    case 2: {
      newLayoutStructure = {
        type: COLUMN,
        id: shortid.generate(),
        children: [item]
      };
      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }

  return addChildToChildren(layout, newLayoutStructure, dropZone);
};






export const handleMoveSidebarCulumnIntoParent = (
  layout,
  item,
  dropZone
) => {

  // console.log(">> 11. handleMoveSidebarCulumnIntoParent")

  const splitDropZonePath = dropZone.path.split("-");

  let newLayoutStructure;
  switch (splitDropZonePath.length) {
    case 1: { // 0 
      newLayoutStructure = {
        type: ROW,
        id: shortid.generate(),
        children: [item]
      };
      break;
    }
    case 2: {  // 0-1 
      newLayoutStructure = item;
      break;
    }
    default: { // 0-1-0
      // column 추가시 여기실행하면  element 영역에 column 추가 하는 게 된다.
      // newLayoutStructure = item;
    }
  }

  return addChildToChildren(layout, newLayoutStructure, dropZone);
};




export const handleMoveSidebarRowIntoParent = (
  layout,
  item,
  dropZone
) => {

  // console.log(">> 13. handleMoveSidebarRowIntoParent")

  const splitDropZonePath = dropZone.path.split("-");

  let newLayoutStructure;
  switch (splitDropZonePath.length) {
    case 1: { // 0 
      newLayoutStructure = item;
      break;
    }
    case 2: { // 0-1 
      // newLayoutStructure = item;
      break;
    }
    default: { // 0-1-0
      // Row 추가시 여기실행하면  element 영역에 row 추가 하는 게 된다.
      // newLayoutStructure = item;

    }
  }

  return addChildToChildren(layout, newLayoutStructure, dropZone);
};



export const handleMoveSidebarLayoutIntoParent = (
  layout,
  item,
  dropZone
) => {

  const splitDropZonePath = dropZone.path.split("-");

  // console.log(">> 15. handleMoveSidebarLayoutIntoParent")
  // console.log(layout)
  // console.log(item.length)

  let newLayoutStructure;

  switch (splitDropZonePath.length) {
    case 0: { // 0 
      // newLayoutStructure = item;
      break;
    }
    case 1: { // 0 

      for (let i = Object.keys(item).length - 1; i > 0; i--) {
        newLayoutStructure = item[i]
        layout = addChildToChildren(layout, newLayoutStructure, dropZone);
      }
      newLayoutStructure = item[0]

      break;
    }
    case 2: { // 0-1 
      // newLayoutStructure = item;
      break;
    }
    default: {
      // Row 추가시 여기실행하면  element 영역에 row 추가 하는 게 된다.
      // newLayoutStructure = item;
    }
  }

  return addChildToChildren(layout, newLayoutStructure, dropZone);


};





// [S] ===============================================================================
// [S] HANDLE REMOVE ITEM
// [S] ===============================================================================

export const handleRemoveItemFromLayout = (layout, item) => {

  // console.log(">> 17. handleRemoveItemFromLayout")
  // console.log(layout)
  // console.log(item)

  return removeChildFromChildren(layout, item);
};
