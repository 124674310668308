import React, { forwardRef } from "react";
import { ko } from "date-fns/esm/locale";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector, shallowEqual } from "react-redux";
import calender_img from '../widgets/kici/style/img/cal.png';
import Loading from "./Loading";
import { useExcelFunc, useGetData, useCanlender } from "../widgets/kici/customHooks/useClickHooks";
import RenderingHooks from "../widgets/kici/customHooks/RenderingHooks";

const DateInput = ({ el }) => {

    // =====================================================
    // [S] redux
    // =====================================================
    const { prevDate, nextDate } = useSelector((state)=>state.date);
    const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);


    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" onClick={onClick} ref={ref} value={value} readOnly style={{ width: "120px" }} />
    ))

    // =====================================================
    // [H] 엑셀 파일 데이터 가져오기
    // =====================================================
    const { excel_query, excel_loading } = useExcelFunc();


    // =====================================================
    // [H] 렌더링
    // =====================================================
    const { search_UI, loading } = useGetData({el, currentWorkspace});


    // =====================================================
    // [H] input date 관련 custom hooks
    // =====================================================
    const { change_prevdate, change_nextdate, calender_change, btn_mode } = useCanlender();


    return(
        <>
            <div className="input_wrap">
              <div className="flex_first_box">
                <img className="calender_icon" src={calender_img} alt="달력 아이콘" />
                <div className="calender_setting">
                  <button className={ btn_mode.week ? "active_btn" : "disabled_btn" } onClick={()=>{ calender_change('week') }}>주간</button>
                  <div className="quarter_div"></div>
                  <button className={ btn_mode.month ? "active_btn" : "disabled_btn" } onClick={()=>{ calender_change('month') }}>월간</button>
                </div>
              </div>
              <div className="flex_second_box">
                <div className="flex-grow-0 first_date">
                    <DatePicker
                        dateFormat="yyyy.MM.dd"
                        selected={prevDate}
                        locale={ko}
                        onChange={(date)=>{ change_prevdate(date) }}
                        selectsStart
                        showDateMonthYearPicker
                        customInput={<CustomInput />}
                    />
                </div>
                <div className="flex-grow-0 second_date">
                    ~
                </div>
                <div className="flex-grow-0 third_date">
                    <DatePicker
                        dateFormat="yyyy.MM.dd"
                        selected={nextDate}
                        locale={ko}
                        onChange={(date)=>{ change_nextdate(date) }}
                        selectsEnd
                        showDateMonthYearPicker
                        customInput={<CustomInput />}
                    />
                </div>
              </div>
              <div className="flex_third_box">
                <div className="mobile_loading">
                  { (loading || excel_loading) && <Loading color={"#3a427a"}/>}
                </div>
                <div className="first_btn_box">
                  <Button style={{backgroundColor : "#152e5a"}} onClick={()=>{ search_UI() }}>조회</Button>
                </div>
                <div>
                  <Button style={{backgroundColor : "#152e5a"}} onClick={()=>{ excel_query({ el, currentWorkspace }) }}>다운로드</Button>
                </div>
                <div className="desktop_loading">
                  { (loading || excel_loading) && <Loading color={"#3a427a"}/>}
                </div>
              </div>
            </div>
        </>
    )
}

export default DateInput;