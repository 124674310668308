
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

export function SelectSettings(props) {
    const { parentId, parentCallback, ...rest } = props


    return (
        <>

            SelectSettings<br />
            <Button size='lg' onClick={() => {
                parentCallback(props.content)
            }}> OK </Button>
            <br />
            {props.content}



        </>

    );
}