
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal, Form } from 'react-bootstrap';

export function WeatherSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    const handleChangeImg = (e) => {
        console.log(e.target.value)
    }

    const handleChangeUrl = (e) => {
        console.log(e.target.value)
    }


    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Weather Setting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {/* {console.log("-------+++++++")} */}



                <br />
                <div>
                    <Button style={btnStyle} size='lg' onClick={() => {

                        let data = {}

                        parentCallback(JSON.stringify(data))



                    }}> SAVE </Button>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}