
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal, Form } from 'react-bootstrap';

export function WebViewerSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const [link, setLink] = useState('');

    useEffect(() => {

        setLink(props.link)

    }, [props.link]);


    const handleChangeUrl = (e) => {

        console.log(e.target.value)
        setLink(e.target.value)
    }


    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                WebViewer 설정
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

               



                <Form>
                    <Form.Label>이동 URL</Form.Label>
                    <Form.Control type="text" value={link} placeholder="http://www.example.com" onChange={handleChangeUrl}  />
                </Form>

                <br/>
                <div>
                    <Button style={btnStyle} size='lg' onClick={() => {

                        let data = {}

                        data.link = link


                        console.log(data.link)

                        parentCallback(JSON.stringify(data))


                        // rest.onHide()
                    }}> SAVE </Button>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}