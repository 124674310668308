
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

export function BasicTreeMapSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                BasicTreeMapSetting {parentId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Please be Setting !
                <br /><br />
                <div>
                    <Button style={btnStyle} size='lg' onClick={() => {
                        parentCallback("Setting A")
                        rest.onHide()
                    }}> Setting A</Button>


                    <Button style={btnStyle} size='lg' onClick={() => {
                        parentCallback("Setting B")
                        rest.onHide()
                    }}> Setting B</Button>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}