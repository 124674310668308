/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import { Button, Table, Pagination, Form, Col, Row } from "react-bootstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ManageMember_Setting } from "./ManageMember_Setting";
import { ManageMember_Add } from "./ManageMember_Add";
import { ManageMember_Delete } from "./ManageMember_Delete";
import Utils from "../widgets/edit/WebBuilder/Utils";
import { Sweetalert_class } from "./SweetAlert";
import { useResizeDetector } from "react-resize-detector";
import { useScreenClass, Visible } from "react-grid-system";

import { actionTypes } from "../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Header } from "../widgets/hyorim/Header";

import * as requestFromServer from "../../../../src/app/_redux/modlCrud";
import { ManageGroup_Add } from "./ManageGroup_Add";

const moment = require("moment");

export function ManageMember({ el, onUpdateAutoHeight, states, className }) {
  let id = "component" + el.i;

  const fullScreenHandle = useFullScreenHandle();

  const dispatch = useDispatch();

  // =====================================================
  // [S] param
  // =====================================================

  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [delModalShow, setDelModalShow] = useState(false);
  const [groupAddModalShow, setGroupAddModalShow] = useState(false);
  const [action, setAction] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [checkedList, setCheckedList] = useState([]);
  const [checkedUserList, setCheckedUserList] = useState([]);
  const [list_id, set_list_id] = useState("");
  const [allow_menu_list, set_allow_menu_list] = useState([]);

  const modl = useSelector((state) => state.modl, shallowEqual);
  const currentWorkspace = useSelector(
    (state) => state.modl.currentWorkspace,
    shallowEqual
  );
  const currentMenu = useSelector(
    (state) => state.modl.currentMenu,
    shallowEqual
  );
  const memberList = useSelector(
    (state) => state.modl.memberList,
    shallowEqual
  );
  const groupAll = useSelector((state) => state.modl.groupAll, shallowEqual);
  const isCheckEmail = useSelector(
    (state) => state.modl.isCheckEmail,
    shallowEqual
  );

  // SETTING PAGING
  let [totalItemCount, setTotalItemCount] = useState(0); // 현재 페이지
  let [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  let itemCountOfPage = 10; // 한 페이지의 아이템수
  let pageCountOfGroup = 10; // 페이지에 보여지는 카운트 수 < 1 2 3 4 5 >

  let totalPageCount = Math.ceil(totalItemCount / itemCountOfPage); // 전체 페이지수
  let currentGroup = Math.ceil(currentPage / pageCountOfGroup); // 현재 그룹 인덱스
  let lastGroup = Math.ceil(totalPageCount / pageCountOfGroup); // 마지막 그룹 인덱스
  let pageCountOfLastGroup =
    totalPageCount % pageCountOfGroup == 0
      ? pageCountOfGroup
      : totalPageCount % pageCountOfGroup; // 마지막 그룹의 페이지 수
  let lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup; // 마지막 페이지 인덱스
  let pageCountOfCurrentGroup =
    currentGroup == lastGroup ? pageCountOfLastGroup : pageCountOfGroup; // 현제 페이지의 페이지수

  let class_put;

  const formGroupStyle = {
    overflow: "hidden",
    marginBottom: "0",
    position: "relative",
  };

  const formCheckStyle = {
    float: "left",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const btnStyle = {
    margin: "20px 4.5px",
    float: "right",
  };

  const delBtnStyle = {
    margin: "20px 0 20px 4.5px",
    float: "right",
  };

  const thStyle = {
    padding: "15px 22px 15px 22px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    backgroundColor: "#fafafb",
    textAlign: "center",
  };

  const tdStyle = {
    padding: "30px 22px 30px 22px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    textAlign: "center",
  };

  const screenClass = useScreenClass();

  // =====================================================
  // [S] Resize Event
  // =====================================================
  const onResize = useCallback((width, height) => {
    if (el.isAutoHeight == true) {
      onUpdateAutoHeight(el.i, height);
    }
  }, []);

  const { ref, width, height } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    // refreshMode: 'debounce',
    // refreshRate: 300,
    onResize,
  });

  // =====================================================
  // [E] Resize Event
  // =====================================================

  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  useEffect(() => {
    let parent = document.getElementById("contentModal");
    if (parent != null) {
      ReactDOM.render(
        <>
          <ManageMember_Setting
            show={modalShow}
            onHide={() => setModalShow(false)}
            parentCallback={settingCallback}
          />
          <ManageMember_Add
            show={addModalShow}
            action={action}
            selectIndex={selectIndex}
            menu={modl[currentMenu]}
            memberList={memberList}
            groupAll={groupAll}
            onHide={() => setAddModalShow(false)}
            isCheckEmail={isCheckEmail}
            checkEmailCallback={checkEmailCallback}
            parentCallback={addItemCallback}
            parentWorkspaceGroup={addWorkspaceGroup}
          />
          <ManageMember_Delete
            show={delModalShow}
            onHide={() => setDelModalShow(false)}
            parentCallback={delItemCallback}
          />
          <ManageGroup_Add
            show={groupAddModalShow}
            action={action}
            selectIndex={selectIndex}
            menu={modl[currentMenu]}
            onHide={() => setGroupAddModalShow(false)}
            parentCallback={action === 'add' ? addGroupItemCallback : editGroupItemCallback}
            listId={list_id}
            menuList={allow_menu_list}
          />
        </>,
        document.getElementById("contentModal")
      );
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================

  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true);
      return true;
    },
  };

  useEffect(() => {
    if (states[id] == undefined) return;
    let method = Object.keys(states[id])[0];
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]();
    } else {
      console.log("method: not exist \nInsert key in functionObj");
    }
  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  useEffect(() => {
    let payloadGroup = {
      workspaceIdx: currentWorkspace.replace("workspace", ""),
    };
    dispatch({ type: actionTypes.GetGroupAll, payload: payloadGroup });

    // let payload = {
    //   "workspaceIdx": currentWorkspace.replace("workspace", ""),
    //   "itemCountOfPage": 1,
    //   "currentPage": 1

    // }

    // dispatch({ type: actionTypes.GetWorkspaceMember, payload:payload })

    updatePaging();
  }, []);

  useEffect(() => {
    // console.log("===>> totalItemCount")
    // console.log(totalItemCount)

    totalPageCount = Math.ceil(totalItemCount / itemCountOfPage); // 전체 페이지수
    currentGroup = Math.ceil(currentPage / pageCountOfGroup); // 현재 그룹 인덱스
    lastGroup = Math.ceil(totalPageCount / pageCountOfGroup); // 마지막 그룹 인덱스
    pageCountOfLastGroup =
      totalPageCount % pageCountOfGroup == 0
        ? pageCountOfGroup
        : totalPageCount % pageCountOfGroup; // 마지막 그룹의 페이지 수
    lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup; // 마지막 페이지 인덱스

    // console.log("currentPage:"+currentPage)
    // console.log("lastPage:"+lastPage)

    if (currentPage > lastPage && lastPage != 0) {
      selectPage(lastPage);
    }
  }, [totalItemCount]);


  useEffect(() => {
    let payload = {
      action: "fetch",
      workspaceIdx: currentWorkspace.replace("workspace", ""),
      itemCountOfPage: itemCountOfPage,
      currentPage: currentPage,
    };

    dispatch({ type: actionTypes.GetWorkspaceMember, payload: payload });
  }, [currentPage]);


  // =====================================================
  // [S] modal
  // =====================================================

  const addWorkspaceGroup = (param) => {
    console.log("진입");

    const { action, groupIdx } = param;
    let insert = param;

    delete insert.action;
    delete insert.groupIdx;

    let payload =
      action === "add"
        ? {
          queries: [
            {
              key: "insertLnkUserWorksapce",
              query: `db.collection('LnkUserKeywordGroup').insertOne(${JSON.stringify(
                insert
              )})`,
            },
          ],
        }
        : {
          queries: [
            {
              key: "update",
              query: `db.collection('LnkUserKeywordGroup').updateOne({ _id: new require('mongodb').ObjectID('${groupIdx}') },${JSON.stringify(
                insert
              )})`,
            },
          ],
        };

    Utils.getQueryResult(payload).then((result) => {
      console.log(result);
      if (action === "add") {
        class_put = new Sweetalert_class(
          "success",
          "성공하였습니다!",
          "그룹을 추가하였습니다."
        );
        class_put.crate_alert();
      } else {
        class_put = new Sweetalert_class(
          "success",
          "성공하였습니다!",
          "그룹을 수정하였습니다."
        );
        class_put.crate_alert();
      }
    });
  };

  const deleteItem = () => {

    let payload = {
      action: "delete",
      workspaceIdx: currentWorkspace.replace("workspace", ""),
      _ids: checkedList,
      userIds: checkedUserList,
      itemCountOfPage: itemCountOfPage,
      currentPage: currentPage,
    };

    dispatch({ type: actionTypes.DeleteWorkspaceMember, payload: payload });
    updatePaging();

  };

  const addItem = () => {
    setAction("add");
    setSelectIndex(0);
    setAddModalShow(true);
    // console.log('addItem click: ')
  };

  const editItem = (index) => {
    setAction("edit");
    setSelectIndex(index);
    setAddModalShow(true);
  };



  /**
   * 유저 메뉴 접근 권한 추가 모달창 및 기본값 설정
   * @param {*} index 
   * @param {*} _id 
   */
  const addGroupItem = (index, _id) => {
    setAction('add');
    setSelectIndex(index);
    setGroupAddModalShow(true);
    set_list_id(_id);
  }


  /**
  * 유저 메뉴 접근 권한 업데이트 모달창 및 기본값 설정
  * @param {*} index 
  * @param {*} _id 
  */
  const updateGroupItem = (index, _id, menu) => {
    setAction('edit');
    setSelectIndex(index);
    setGroupAddModalShow(true);
    set_list_id(_id);
    set_allow_menu_list(menu);
  }


  const settingCallback = (data) => {
    // console.log('callback: ' + data)
  };

  const addItemCallback = async (payload) => {
    // console.log('addItemCallback: ' + JSON.stringify(payload))

    payload["itemCountOfPage"] = itemCountOfPage;
    payload["currentPage"] = currentPage;

    if (payload.action == "add") {
      await dispatch({
        type: actionTypes.InsertWorkspaceMember,
        payload: payload,
      });
    } else if (payload.action == "edit") {
      await dispatch({
        type: actionTypes.UpdateWorkspaceMember,
        payload: payload,
      });

      window.location.reload();
    }
  };


  /**
   * 유저 메뉴 접근 권한 추가 함수
   * @param param userIdx, workspaceIdx, menu를 구성한 Object type
   */
  const addGroupItemCallback = async (param) => {

    const { userIdx } = param;

    const payload = {
      queries: [
        {
          key: "insertGroup",
          query: `db.collection('Group').insertOne(${JSON.stringify(param)})`
        }
      ]
    };

    const { insertGroup } = await Utils.getQueryResult(payload);

    if (insertGroup.insertedId) {
      const second_payload = {
        queries: [
          {
            key: "updateLnkWorkspace",
            query: `db.collection('LnkUserWorkspace').update({ userIdx: "${userIdx}"}, { $set: { groupIdx : "${insertGroup.insertedId}" } })`
          }
        ]
      }

      const { updateLnkWorkspace } = await Utils.getQueryResult(second_payload);

      if (updateLnkWorkspace.acknowledged) {

        const result_alert = new Sweetalert_class('success', '권한을 추가했습니다.', '');

        result_alert.crate_alert();

        /**
         * menu 권한 데이터 Refetch
         */
        let refetch_payload = {
          action: "fetch",
          workspaceIdx: currentWorkspace.replace("workspace", ""),
          itemCountOfPage: itemCountOfPage,
          currentPage: currentPage,
        };

        dispatch({ type: actionTypes.GetWorkspaceMember, payload: refetch_payload });

      } else {

        const result_alert = new Sweetalert_class('success', '권한 추가를 실패하였습니다.', '관리자에게 문의해주세요.');

        result_alert.crate_alert();

      }
    }
  }


  /**
   * 유저 메뉴 접근 권한 업데이트 함수
   * @param param userIdx, workspaceIdx, menu를 구성한 Object type
   */
  const editGroupItemCallback = async (param) => {

    const { userIdx } = param;

    const payload = {
      queries: [
        {
          key: "updateGroup",
          query: `db.collection('Group').updateOne(
            { userIdx : "${userIdx}" },
            { $set : ${JSON.stringify(param)} }
          )`
        }
      ]
    }

    const { updateGroup } = await Utils.getQueryResult(payload);

    if (updateGroup.acknowledged) {

      const result_alert = new Sweetalert_class('success', '권한을 변경했습니다.', '');

      result_alert.crate_alert();


      /**
        * menu 권한 데이터 Refetch
        */
      const refetch_payload = {
        action: "fetch",
        workspaceIdx: currentWorkspace.replace("workspace", ""),
        itemCountOfPage: itemCountOfPage,
        currentPage: currentPage,
      };

      dispatch({ type: actionTypes.GetWorkspaceMember, payload: refetch_payload });

    } else {

      const result_alert = new Sweetalert_class('success', '권한 변경이 실패하였습니다.', '관리자에게 문의해주세요.');

      result_alert.crate_alert();

    }
  }


  const delItemCallback = (data) => {
    // console.log('delItemCallback: ' + data)
  };

  const checkEmailCallback = (payload) => {
    // console.log('checkEmailCallback: ' + JSON.stringify(payload))
    if (payload.email == "") {
      // setIsCheckEmail([])
    } else {
      dispatch({ type: actionTypes.IsExistUser, payload: payload });
    }
  };

  // =====================================================
  // [E] modal
  // =====================================================

  const ConvMenuName = (allowMenu) => {
    let List = [];

    try {
      for (let menu of allowMenu) {
        for (let compare of modl[currentMenu]) {
          for (let item of compare.items) {
            if (item.id == menu.idx) {
              List.push(item.title);
            }
          }
        }
      }
    } catch (e) { }
    return List.join(" / ");
  };

  const changeCheckAll = (e) => {
    // console.log("checkAll")
    let isChecked = e.target.checked;
    if (isChecked) {
      let _checkList = [];
      let _userList = [];
      for (let mGroup of memberList) {
        _checkList.push(mGroup._id);
        _userList.push(mGroup.userIdx)
      }
      setCheckedList(_checkList);
      setCheckedUserList(_userList);
    } else {
      setCheckedList([]);
      setCheckedUserList([]);
    }
  };

  const getCheckState = (id) => {
    return checkedList.includes(id);
  };

  const setCheckState = (id, e, user) => {
    let isChecked = e.target.checked;

    if (isChecked) {
      let joined = checkedList.concat(id);
      let user_joined = checkedUserList.concat(user);
      setCheckedList(joined);
      setCheckedUserList(user_joined);
    } else {
      setCheckedList(checkedList.filter((item) => item !== id));
      setCheckedUserList(checkedUserList.filter(item => item !== user));
    }
  };

  // =====================================================
  // [S] page
  // =====================================================

  let items = [];

  const updatePaging = () => {
    let payload = {
      workspaceIdx: currentWorkspace.replace("workspace", ""),
      document: "LnkUserWorkspace",
    };

    requestFromServer
      .getDocumentCount(payload)
      .then((response) => {
        // console.log("result=====")
        // console.log(response)

        setTotalItemCount(JSON.parse(response).count);

        return response;
      })
      .catch((error) => {
        error.clientMessage = "Can't delete modl";
      });

    // dispatch({ type: actionTypes.GetDocumentCount, payload: payload })

    // console.log(totalItemCount)
  };

  const selectPage = (idx) => {
    setCurrentPage(idx);
  };

  if (currentGroup != 1) {
    items.push(
      <Pagination.Prev
        key={Math.random()}
        onClick={() => {
          selectPage(currentPage - pageCountOfGroup);
        }}
      ></Pagination.Prev>
    );
  }

  for (let number = 1; number <= pageCountOfCurrentGroup; number++) {
    let idx = (currentGroup - 1) * pageCountOfGroup + number;
    items.push(
      <Pagination.Item
        key={Math.random()}
        active={idx === currentPage}
        onClick={() => {
          selectPage(idx);
        }}
      >
        {idx}
      </Pagination.Item>
    );
  }

  if (currentGroup != lastGroup) {
    items.push(
      <Pagination.Next
        key={Math.random()}
        onClick={() => {
          selectPage(
            currentPage + pageCountOfGroup > lastPage
              ? lastPage
              : currentPage + pageCountOfGroup
          );
        }}
      ></Pagination.Next>
    );
  }

  // pageCountOfLastGroup

  // =====================================================
  // [E] table content
  // =====================================================
  if (memberList == undefined) return <></>;
  // let memberUI = (<></>)
  let memberUI = memberList.map((value, index) => (
    <tr key={Math.random()}>
      <td style={tdStyle}>
        <Form.Check
          onChange={(e) => setCheckState(value._id, e, value.userIdx)}
          checked={getCheckState(value._id)}
        />
      </td>
      <td style={tdStyle}>{value.userInfo[0]?.email}</td>
      <td style={tdStyle}>{value.userInfo[0]?.password}</td>
      <td style={tdStyle}>{value.userInfo[0]?.name}</td>
      <td style={tdStyle}>{value.userInfo[0]?.memo}</td>

      {!memberList[index]?.keywordGroupInfo ? (
        <td style={tdStyle}>없음</td>
      ) : (
        <td style={tdStyle}>{memberList[index].keywordGroupInfo[0]?.title}</td>
      )}

      <td style={tdStyle}>
        {moment(value.userInfo[0]?.registDate).format("YYYY-MM-DD HH:mm:ss")}
      </td>
      <td style={tdStyle} onClick={() => { editItem(index); }}> [수정]</td>
      <td style={tdStyle} onClick={() => {
        value.groupInfo.length === 0 ?
          addGroupItem(index, value.userIdx) : updateGroupItem(index, value.userIdx, value.groupInfo[0].allowMenu)
      }}>[관리]</td>
    </tr>
  ));

  return (
    // <div style={{ paddingBottom: "20px" }}>
    <div ref={ref}>
      {/* div 대신 <></> 를 쓰면 item size 만큼 배경을 채워진다. */}

      <FullScreen handle={fullScreenHandle}>
        <div
          style={{ backgroundColor: "transparent", boxShadow: "none" }}
          className={`card card-custom ${className}`}
        >
          <Header el={el}></Header>
          <div
            className="card-body shadow-xs d-flex flex-column p-0"
            style={{
              backgroundColor: "white",
              borderRadius: "0.45rem",
              overflow: "auto",
            }}
          >
            <div className="flex-grow-1" style={{ padding: "0 20px 20px" }}>
              <Form.Group style={formGroupStyle}>
                <Form.Check
                  style={formCheckStyle}
                  onChange={changeCheckAll}
                  label="전체 선택"
                />

                <Button
                  style={delBtnStyle}
                  onClick={() => {
                    deleteItem();
                  }}
                >
                  {" "}
                  삭제
                </Button>

                <Button
                  style={btnStyle}
                  onClick={() => {
                    addItem();
                  }}
                >
                  {" "}
                  추가
                </Button>
              </Form.Group>

              <div style={{ overflow: "auto" }}>
                <Table hover style={{ minWidth: "1200px", marginBottom: "0" }}>
                  <thead>
                    <tr>
                      <th style={thStyle}>선택</th>
                      <th style={thStyle}>아이디</th>
                      <th style={thStyle}>비밀번호</th>
                      <th style={thStyle}>이름</th>
                      <th style={thStyle}>메모</th>
                      <th style={thStyle}>포함된 권한 그룹</th>
                      <th style={thStyle}>날짜</th>
                      <th style={thStyle}>수정</th>
                      <th style={thStyle}>권한</th>
                    </tr>
                  </thead>
                  <tbody>{memberUI}</tbody>
                </Table>
              </div>

              <div style={{ float: "right" }}>
                <Pagination>{items}</Pagination>
              </div>
            </div>
          </div>
        </div>
      </FullScreen>

      {/* </div> */}
    </div>
  );
}
