
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

export function ComponentWrapperSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <>TEST</>
    );
}