import React, { useState, useEffect, useRef } from "react";
import { Redirect, Router, Switch, Route, Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../_wise/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../redux/actionType";
import store from "../redux/store";
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { useScreenClass, Visible } from 'react-grid-system';
import 'react-multi-carousel/lib/styles.css';


export default function SelectWorkspace(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const workspaces = useSelector(({ modl }) => modl.workspaces, shallowEqual);
  const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);
  const currentSite = useSelector(({ modl }) => modl.currentSite, shallowEqual);
  const modl = useSelector(({ modl }) => modl, shallowEqual);
  const [cardCount, setCardCount] = useState(0);

  const screenClass = useScreenClass();

  const LAYOUT_CONFIG_KEY = "LayoutConfig";
  const SELECTED_WORKSPACE_IDX_KEY = "SelectedWorkspaceIdx";

  useEffect(() => {
    //전체 workspace 로드.
    if (user != undefined) {


      console.log(">> user")
      console.log(JSON.stringify(user, null, 2))
      
      dispatch({ type: actionTypes.GetSite, payload: { siteIdx: user.siteIdx } })
      dispatch({ type: actionTypes.GetLnkWorkspace, payload: { userIdx: user._id } })
    }
  }, [user]);


  useEffect(() => {


    // console.log("SelectWorkspace > workspaces")
    // console.log(workspaces)
    
    if (currentSite != undefined && workspaces != undefined) {

      if (modl[currentSite].setting.selectWS == false) {

        if (workspaces.length > 0) {
          changeLayout(workspaces[0])
        }

      }else{

        // "" , null , undefined > false 
        if(localStorage.getItem(SELECTED_WORKSPACE_IDX_KEY)){

          let _selectedWorkspaceIdx = JSON.parse(localStorage.getItem(SELECTED_WORKSPACE_IDX_KEY))

          selectWorkspace(_selectedWorkspaceIdx)

        }

      }


    }
  }, [currentSite, workspaces]);





  


  const changeLayout = (workspace) => {
    let currentLayout = localStorage.getItem(LAYOUT_CONFIG_KEY) // string
    let nextLayout = workspace.workspaceInfo[0]?.layout

    if (nextLayout == undefined) {
      //selectWorkspace(workspace.workspaceIdx)
      alert("workspace에 layout 값이 없습니다.")
      logout()
      
    } else {

      let nextLayoutStr = JSON.stringify(nextLayout)

      if (currentLayout == nextLayoutStr) {

        selectWorkspace(workspace.workspaceIdx)

      } else {

        localStorage.setItem(LAYOUT_CONFIG_KEY, nextLayoutStr);

        let workspaceIdxStr = JSON.stringify(workspace.workspaceIdx)
        localStorage.setItem(SELECTED_WORKSPACE_IDX_KEY, workspaceIdxStr);

        window.location.reload();

      }

    }

  }


  const selectWorkspace = (workspaceIdx) => {
    // console.log("works.id : " + workspaceIdx)
    // console.log(JSON.stringify(workspaces))
    let payload = {
      "workspaceIdx": workspaceIdx,
      "userIdx": user._id,
    }

    //선택 workspace 로드.
    dispatch({ type: actionTypes.GetWorkspace, payload: payload })
    

    // localStorage.setItem(SELECTED_WORKSPACE_IDX_KEY, JSON.stringify(null));

  }




  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  let image1 = "https://images.unsplash.com/photo-1556742044-3c52d6e88c62?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8c21hbGwlMjBzaXplfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
  let image2 = "https://cdn.shopify.com/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c_grande.gif"
  const CustomDot = ({ onClick, active, index, carouselState }) => {

    // const { currentSlide } = carouselState;
    return (
      <li style={{ marginRight: '1%' }}>
        <span style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: active ? 'rgb(54 120 255)' : 'grey',
          position: 'relative',
          display: 'inline-block'
        }}>
        </span>
      </li>
    );
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow }
    } = rest;
    return (
      <div className="carousel-button-group">
        <button aria-label="Go to previous slide"
          className={currentSlide === 0 ? "react-multiple-carousel__arrow react-multiple-carousel__arrow--left_nbutton" : "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"}
          onClick={() => previous()}></button>
        <button aria-label="Go to next slide"
          className={currentSlide === totalItems - slidesToShow ? "react-multiple-carousel__arrow react-multiple-carousel__arrow--right_nbutton" : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"}
          onClick={() => next()}></button>
      </div>
    );
  };



  //super admin 워크스페이스로 이동
  const settings = () => {
    console.log("currentWorkspace:" + JSON.stringify(currentWorkspace))
    console.log("workspaces:" + JSON.stringify(workspaces))
    console.log("user:" + JSON.stringify(user))
    if (workspaces != undefined) {
      let settingWorkspaceIdx = null
      for (let ws of workspaces) {
        if (ws.role == 'superadmin') {
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~ " + ws.workspaceIdx)
          settingWorkspaceIdx = ws.workspaceIdx
        }
      }
      console.log("settingWorkspaceIdx:" + settingWorkspaceIdx)
      if (settingWorkspaceIdx != null) {
        //workspace 로드.
        let payload = {
          "workspaceIdx": settingWorkspaceIdx,
          "userIdx": user._id,
        }
        dispatch({ type: actionTypes.GetWorkspace, payload: payload })
      } else {
        alert("권한이 없습니다.")
      }


    }
  }


  const logout = () => {
    window.location.href = '/logout';
  }


  if (workspaces == undefined) return (<></>)


  let wsList = []
  for (let ws of workspaces) {
    if (ws.role == 'superadmin') {
    } else {
      wsList.push(ws)
    }
  }
  let workspacesMax = 0;
  if (wsList.length > 3) {
    workspacesMax = 3 * ((wsList.length / 3 | 0) + 1);
  } else {
    workspacesMax = 3;
  }

  // let workspacesMax = 3 * ((wsList.length / 3 | 0) + 1)
  // let workspacesMax = 3 * ((wsList.length / 3 | 0) + 1)
  let workspaceUI = Array(workspacesMax).fill(0).map((value, index) =>
    (wsList.length > index) ? (
      (wsList[index].role == 'superadmin') ? undefined :
        <div className="row justify-content-center" key={Math.random()} style={{ margin: '30px 10px' }} >
          <Card style={{ height: '100%', padding: '30px 20px 34px', color: "#000000" }}>
            {/* <Card> */}
            <Card.Img height={160} variant="top" src={wsList[index]?.workspaceInfo[0]?.image} />
            <Card.Body className="p-0">
              <Card.Title style={{ padding: "20px 0", marginBottom: "20px", borderBottom: "1px solid #f2f2f2" }}>{wsList[index]?.workspaceInfo[0]?.title}</Card.Title>
              <Card.Text style={{ height: "100px", whiteSpace: "pre-line" }}>
                {/* ◎ {wsList[index].role}  <br /> */}
                {/* addby : {wsList[index].workspaceUserName} <br/> */}
                {wsList[index].workspaceInfo[0]?.content}
              </Card.Text>
              {/* 임시로 막음  */}
              {
                (user.email == "adminhr1@test.com" && index != 0) ?
                  <Button
                    style={{ backgroundColor: '#027bc6', marginBottom: '3%' }}
                    variant="btn btn-primary btn-md rounded-pill btn-block " onClick={() => { alert('Comming soon!') }}>현장 워크스페이스 입장</Button>
                  :
                  <Button
                    style={{ backgroundColor: '#027bc6', marginBottom: '3%' }}
                    variant="btn btn-primary btn-md rounded-pill btn-block " onClick={() => { changeLayout(wsList[index]) }}>현장 워크스페이스 입장</Button>
              }

            </Card.Body>
          </Card>
        </div>
    )
      :
      (
        <div className="d-flex justify-content-center align-items-center" key={Math.random()} >
          <div style={{ width: '100%', height: '464px', padding: '30px 20px 34px', margin: '30px 10px', backgroundColor: '#f2f2f2', borderRadius: '4px', position: "relative" }}>
            <div className="text-center" style={{ position: "absolute", top: "50%", left: '50%', transform: "translate(-50%, -50%)" }}>
              <img src={toAbsoluteUrl("/media/logos/empty.png")}></img>
              <p style={{ marginTop: "20px", color: "#b9bdc6" }}>아직 접근할 수 있는 <br /> 현장이 없습니다.</p>
            </div>
          </div>
          {/* <Card style={{    width: '30rem', height: '32rem' , padding: '15px', margin :'8%',    backgroundColor: '#f2f2f2' }}>
          <Card.Img variant="top" src={toAbsoluteUrl("/media/logos/empty.png")} />
            <img            
            src={toAbsoluteUrl("/media/logos/empty.png")}
            ></img>
          </Card> */}
        </div>
      )
  )





  if (currentSite == undefined) {
    return (<></>)
  }

  if (modl[currentSite].setting.selectWS == false) {
    return (<></>)
  }



  if(localStorage.getItem(SELECTED_WORKSPACE_IDX_KEY)){
    return (<></>)
  }


  return (

    <>
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundColor: '#ffffff',
          backgroundImage: "url('/media/bg/bg_workspace.png')",
          position: 'relative'
        }}
      >
        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 font-weight-boldest "> */}
        {/* {user.name}&nbsp;&nbsp;<Button size="sm" variant="primary" onClick={() => { logout() }}>로그아웃</Button> */}
        {/* {(user.grade == "superadmin") ? <Button size="sm" variant="primary" onClick={() => { settings() }}>settings</Button> : <></>}
          &nbsp;<Button size="sm" variant="primary" onClick={() => { logout() }}>logout</Button> */}
        {/* </div> */}
        <Container style={{ position: "relative", overflow: "hidden" }}>
          <div style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", width: "100%" }}>
            <div className="row justify-content-center"
              style={{ fontSize: '18px', fontWeight: 'bold' }}
            >
              현장선택
            </div>

            {(user.grade == "superadmin") ?
              <div className="row justify-content-center mt-3"
                style={{ fontSize: '13px', color: '#009dff' }}
              >
                <a onClick={() => { settings() }}>현장 관리자 관리  {'>'} </a>
              </div> :
              <></>
            }

            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9 col-md-6 col-sm-8 col-9">
                <Carousel
                  interval={false}
                  centerMode={false}
                  responsive={responsive}
                  showDots={true}
                  customDot={<CustomDot />}
                  customButtonGroup={<ButtonGroup />}
                  arrows={false}
                  renderButtonGroupOutside={true}
                  shouldResetAutoplay={false}
                // containerClass ={'react-multi-carousel-list max-h-44rem'} 
                // dotListClass ={'custom-dot-list-style'}                                       
                >
                  {workspaceUI}
                </Carousel>
              </div>
            </div>
          </div>
          {/* <Row>
            <Col></Col>
            <Col xs={10}>
              <Carousel 
                  centerMode={true} 
                  responsive={responsive}
                  showDots={true}
                  customDot={<CustomDot />} 
              >
                {workspaceUI}
              </Carousel>
            </Col>
            <Col></Col>
          </Row> */}


          {(cardCount == 0) ?
            <Row style={{ maxHeight: "300px" }}>
              <Col></Col>
              <Col xs={10}>
                {/* <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                  <p className="font-weight-boldest display-4">
                    # 현장이 없습니다. 관리자 에게 연락하세요.
                  </p>
                </div> */}
              </Col>
              <Col></Col>
            </Row> :
            null
          }
        </Container>
      </div>
    </>
  );
}
