
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

export function ControlUserSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                About {parentId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div >

                    - Admin<br />
                    public, workspace, share menu 접근 가능<br />
                    메뉴 이동, 초대, 권한(read/edit) 변경<br />
                    <br />
                    - Member<br />
                    public, workspace, share menu 접근 가능<br />
                    <br />
                    - Guest<br />
                    share menu 접근 가능<br />
                    <br />

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}