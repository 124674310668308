import React from "react";
import {
  SelectWorkspace,
  StatusWidget1,
  StatusWidget2,

  //chart
  BasicLine,
  BasicBar,
  BasicScatter,
  BasicHeatMap,
  BasicRadar,
  BasicTreeMap,
  BasicLinkGraph,
  BasicWordCloud,

  //natar
  CrawlingTool,
  CrawlingList,
  CrawlingDataList,
  CrawlingLogList,

  //edit
  ControlLogo,
  ControlMenu,
  ControlPage,
  ControlTplComponent,
  ControlUser,
  ControlGroup,
  TableBuilder,
  InputBuilder,
  WebBuilder,
  ComponentWrapper,

  //spreadsheet
  BasicSpredsheet,

  // datatable
  BasicDataTable1,
  BasicDataTable2,

  // utils
  Welcome,
  YoutubePlayer,
  WebViewer,
  MapViewer,
  TextEditor,
  ImageGallery,
  PdfViewer,
  SketchCanvas,
  TreeViewer,
  Banner,
  InputForm,
  Weather,
  UploadExcel,

  // map
  FlowMap,

  // api
  GenerateAPIKey,

  // test
  Test1,
  Test2,
  Test3,
  Test4,

  // tutorial
  Tutorial1,
  Tutorial2,
  Tutorial3,
  Tutorial4,
  Tutorial5,
  MixedWidget1,
  MixedWidget4,
  MixedWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget12,
  MixedWidget14,
  StatsWidget10,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
  ListsWidget9,
  ListsWidget10,
  ListsWidget11,
  ListsWidget14,
  BaseTablesWidget1,
  BaseTablesWidget2,
  BaseTablesWidget6,
  AdvanceTablesWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  AdvanceTablesWidget7,
  AdvanceTablesWidget9,
  TilesWidget1,
  TilesWidget3,
  TilesWidget10,
  TilesWidget11,
  TilesWidget12,
  TilesWidget13,

  // manage
  ManageAdminMember,
  ManageMember,
  ManageGroup,
  ManageTag,
  ManageTagGroup,
  ManageWorkspace,
  ManageKeyword,
  TempKeywordAlert,
  TempKeywordAlertWork,

  // pageone
  PO_Welcome,
  PO_MyPage,
  Contact,

  // kici
  KICI_Dashboard,
  KICI_Dashboard_Admin,
  KICI_CrawlingDataList,
  KICI_ManageCrawler,
  KICI_Assemble_Board,
  KICI_NotComponent,
} from "../../_wise/_partials/index";

//TilesWidget11
const complist = {
  SelectWorkspace: SelectWorkspace,
  StatusWidget1: StatusWidget1,
  StatusWidget2: StatusWidget2,

  // chart
  BasicLine: BasicLine,
  BasicBar: BasicBar,
  BasicScatter: BasicScatter,
  BasicHeatMap: BasicHeatMap,
  BasicRadar: BasicRadar,
  BasicTreeMap: BasicTreeMap,
  BasicLinkGraph: BasicLinkGraph,
  BasicWordCloud: BasicWordCloud,

  // natar
  CrawlingTool: CrawlingTool,
  CrawlingList: CrawlingList,
  CrawlingDataList: CrawlingDataList,
  CrawlingLogList: CrawlingLogList,

  // edit
  ControlLogo: ControlLogo,
  ControlMenu: ControlMenu,
  ControlPage: ControlPage,
  ControlTplComponent: ControlTplComponent,
  ControlUser: ControlUser,
  ControlGroup: ControlGroup,
  TableBuilder: TableBuilder,
  InputBuilder: InputBuilder,
  WebBuilder: WebBuilder,
  ComponentWrapper: ComponentWrapper,

  // spreadsheet
  BasicSpredsheet: BasicSpredsheet,

  // utils
  WebViewer: WebViewer,
  MapViewer: MapViewer,
  Welcome: Welcome,
  TextEditor: TextEditor,
  ImageGallery: ImageGallery,
  PdfViewer: PdfViewer,
  SketchCanvas: SketchCanvas,
  TreeViewer: TreeViewer,
  Banner: Banner,
  YoutubePlayer: YoutubePlayer,
  InputForm: InputForm,
  Weather: Weather,
  UploadExcel: UploadExcel,

  // tutorial
  Tutorial1: Tutorial1,
  Tutorial2: Tutorial2,
  Tutorial3: Tutorial3,
  Tutorial4: Tutorial4,
  Tutorial5: Tutorial5,

  // datatable
  BasicDataTable1: BasicDataTable1,
  BasicDataTable2: BasicDataTable2,

  MixedWidget1: MixedWidget1,
  MixedWidget4: MixedWidget4,
  MixedWidget6: MixedWidget6,
  MixedWidget10: MixedWidget10,
  MixedWidget11: MixedWidget11,
  MixedWidget12: MixedWidget12,
  MixedWidget14: MixedWidget14,
  StatsWidget10: StatsWidget10,
  StatsWidget11: StatsWidget11,
  StatsWidget12: StatsWidget12,
  ListsWidget1: ListsWidget1,
  ListsWidget3: ListsWidget3,
  ListsWidget4: ListsWidget4,
  ListsWidget8: ListsWidget8,
  ListsWidget9: ListsWidget9,
  ListsWidget10: ListsWidget10,
  ListsWidget11: ListsWidget11,
  ListsWidget14: ListsWidget14,
  BaseTablesWidget1: BaseTablesWidget1,
  BaseTablesWidget2: BaseTablesWidget2,
  BaseTablesWidget6: BaseTablesWidget6,
  AdvanceTablesWidget1: AdvanceTablesWidget1,
  AdvanceTablesWidget2: AdvanceTablesWidget2,
  AdvanceTablesWidget4: AdvanceTablesWidget4,
  AdvanceTablesWidget7: AdvanceTablesWidget7,
  AdvanceTablesWidget9: AdvanceTablesWidget9,
  TilesWidget1: TilesWidget1,
  TilesWidget3: TilesWidget3,
  TilesWidget10: TilesWidget10,
  TilesWidget11: TilesWidget11,
  TilesWidget12: TilesWidget12,
  TilesWidget13: TilesWidget13,

  // map
  FlowMap: FlowMap,

  // api
  GenerateAPIKey: GenerateAPIKey,

  // Test
  Test1: Test1,
  Test2: Test2,
  Test3: Test3,
  Test4: Test4,

  // manage
  ManageAdminMember: ManageAdminMember,
  ManageMember: ManageMember,
  ManageKeyword: ManageKeyword,
  TempKeywordAlert: TempKeywordAlert,
  ManageGroup: ManageGroup,
  ManageTag: ManageTag,
  ManageTagGroup: ManageTagGroup,
  ManageWorkspace: ManageWorkspace,

  // pageone
  PO_Welcome: PO_Welcome,
  PO_MyPage: PO_MyPage,
  Contact: Contact,

  // kici
  KICI_Dashboard: KICI_Dashboard,
  KICI_Dashboard_Admin: KICI_Dashboard_Admin,
  KICI_Assemble_Board: KICI_Assemble_Board,
  KICI_CrawlingDataList: KICI_CrawlingDataList,
  KICI_ManageCrawler: KICI_ManageCrawler,
  KICI_NotComponent: KICI_NotComponent,
  TempKeywordAlertWork: TempKeywordAlertWork,
};

export function ComponentLoader(props) {
  if (complist.hasOwnProperty(props.el.data.source)) {
    const Comp = complist[props.el.data.source];
    return <Comp {...props} />;
  } else {
    return <>COMPONENT IS NOT EXIST</>;
  }
}
