import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { ELEMENT } from "./constants";

import store from "../../../../../redux/store";
import { actionTypes } from "../../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { UILayout } from "./LayoutStructure"



// const style = {
//   border: "1px dashed black",
//   padding: "0.5rem 1rem",
//   backgroundColor: "white",
//   cursor: "move"
// };
const style = { backgroundColor: 'lightGray' };

const Element = ({ el, data, elements, path }) => {
  const ref = useRef(null);

  // console.log("=================") 
  // console.log("data =============")
  // console.log(data)
  // console.log("elements =============")
  // console.log(elements)

  const [{ isDragging }, drag] = useDrag({
    item: { ...data, type: ELEMENT, path },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);


  const dispatch = useDispatch();
  const element = elements[data.id];


  const returnUI = (element, data) => {

     if(element == undefined) return (<></>)

    // console.log("???? data")
    // console.log(data)

    let uiLayout = new UILayout();
    let ui = undefined
    if (element.elementType == "tab") {
      // console.log("__wrapper")
      ui = uiLayout[element.elementType](el, data) //hardcoder error : Cannot read properties of undefined (reading 'elementType')
    } else {
      // console.log("__not wrapper")
      ui = uiLayout[element.elementType](data) //hardcoder error : Cannot read properties of undefined (reading 'elementType')
    }
    
    return (ui)

  }


  const handleClick = (e, element, data) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    let payload = {
      "element": element,
      "data": data
    }

    dispatch({ type: actionTypes.UpdateSelectElement, payload: JSON.stringify(payload) })

  }

  const handlePress = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

  }


  return (
    <div
   
      ref={ref}
      style={{ ...style, opacity }}
      onMouseDown={(e) => handlePress(e)}
      onClick={(e) => handleClick(e, element, data)}
    >
      {returnUI(element, data)}

    </div>



  );
};
export default Element;
