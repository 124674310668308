import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import * as requestFromServer from "../../../../src/app/_redux/modlCrud";
import Utils from "../widgets/edit/WebBuilder/Utils";
import { Sweetalert_class } from "./SweetAlert";
import { filter_class } from "../widgets/edit/ControlFilter";
import { MdClose } from "react-icons/md";

export function TempKeywordAlertAdd(props) {
  const {
    menu,
    item,
    data,
    parentRender,
    workSpace,
    checkEmailCallback,
    action,
    ...rest
  } = props;

  console.log(menu);
  const [site_name, set_site_name] = useState("");
  const [keyword_arr, set_keyword_arr] = useState([]);
  const [choice_category, set_choice_category] = useState("");
  const [select_mode, set_select_mode] = useState(true);
  const [choice_keyword, set_choice_keyword] = useState("");
  const [keyword_render, set_keyword_render] = useState([]);
  const [group_name, set_group_name] = useState("");
  const [alert_text, set_alert_text] = useState("");
  const [group_list, set_group_list] = useState([]);
  const [site_id, set_site_id] = useState("");

  let function_flag = true;
  let class_put, filter_put;

  useEffect(() => {
    if (select_mode === true && !data) {
      let payload = {
        queries: [
          {
            key: "groupList",
            query: `db.collection('Group').aggregate([
                            { $match: { "workspaceIdx" : "${workSpace.replace(
                              "workspace",
                              ""
                            )}" }}
                        ]).toArray()`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        set_group_list(result.groupList);
      });
    }

    if (action === "edit" && data) {
      let temp_data = { ...data };
      console.log(data.title);
      temp_data.value = data.site;
      set_choice_category(data.category);
      set_site_name(data.site);
      set_select_mode(true);
      set_group_name(data.title);

      const keyword_view = data.keywords.map((data) => data.keyword);

      set_keyword_render(keyword_view);

      change_site(temp_data);
    }
  }, [select_mode, data]);

  const btnStyle = {
    margin: "2px",
  };

  // =====================================================
  // [S] change event
  // =====================================================
  const change_category = (e) => {
    reset_func();

    const { value } = e.target;

    if (value !== "") {
      set_choice_category(value);
    } else {
      reset_func();
    }
  };

  const group_change = (e) => {
    const { value } = e.target;

    set_group_name(value);
  };

  const blur_match = async () => {
    const match_data = await validata_group();

    match_data > 0
      ? set_alert_text(
          <p className="alert_group_name">
            존재하는 그룹명입니다, 기존에서 등록해주세요.
          </p>
        )
      : set_alert_text("");
  };

  // =====================================================
  // [S] group name overlap
  // =====================================================
  const validata_group = () => {
    const query_data = select_mode
      ? `{ "category" : "${choice_category}", "site" : "${site_name}", "title" : "${group_name}"}`
      : `{ "title" : "${group_name}" }`;

    let payload = {
      queries: [
        {
          key: "group_overlap",
          query: `db.collection('Group').aggregate([
                        { $match: ${query_data}}
                    ]).toArray()`,
        },
      ],
    };

    const match_length = Utils.getQueryResult(payload).then((result) => {
      const { group_overlap } = result;
      console.log(group_overlap);
      return group_overlap.length;
    });

    return match_length;
  };

  // =====================================================
  // [E] 사이트명 변경시 이벤트
  // =====================================================
  const change_site = (e) => {
    set_site_id("");

    const { value } = e;

    if (value !== "") {
      let payload = {
        queries: [
          {
            key: "title_existence",
            query: `db.collection('CrawlerTemplate').aggregate([
                            { $match: { "title": "${value}" } },
                            { $project : { '_id' : 1, "filter" : { $arrayElemAt: ["$filter.rule", 0] } } }
                        ]).toArray()`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        const { title_existence } = result;
        const filter_arr = temp_func(title_existence[0]);

        set_site_id(title_existence[0]._id);
        set_keyword_arr(filter_arr);
        set_site_name(value);
      });
    } else {
      reset_func();
    }
  };

  // =====================================================
  // [E] 키워드 추출 함수
  // =====================================================
  const temp_func = (param) => {
    filter_put = new filter_class(param);
    const filter_result = filter_put.filter_method();

    return filter_result;
  };

  // =====================================================
  // [E] 키워드 추가
  // =====================================================
  const keyword_add = () => {
    if (choice_keyword === "") {
      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "키워드를 선택해주세요."
      );
      class_put.crate_alert();
      return false;
    }

    const keyword_flag = keyword_render.includes(choice_keyword);

    if (keyword_flag) {
      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "이미 존재하는 키워드입니다."
      );
      class_put.crate_alert();
      return false;
    } else {
      set_keyword_render([...keyword_render, choice_keyword]);
    }
  };

  // =====================================================
  // [E] STATE 초기화 함수
  // =====================================================
  const reset_func = async (param) => {
    if (param === "down") {
      await set_group_name("");
      await set_alert_text("");
      await set_choice_keyword("");
      await set_keyword_render([]);
    } else {
      await set_choice_category("");
      await set_keyword_arr([]);
      await set_keyword_render([]);
      await set_choice_keyword("");
      await set_group_name("");
      await set_alert_text("");
    }
  };

  // =====================================================
  // [E] 추가 click event (데이터 검증)
  // =====================================================
  const add_item_check = async () => {
    const match_data = await validata_group();

    function_flag = true;

    if (choice_category === "") {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "종류를 선택해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (site_name === "") {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "사이트를 선택해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (group_name === "") {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "그룹명을 입력해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (keyword_render.length === 0) {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "키워드를 선택해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (alert_text !== "") {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "중복된 그룹명이 있습니다."
      );
      class_put.crate_alert();

      return false;
    }

    if (match_data > 0) {
      function_flag = false;

      const select_text = select_mode
        ? "해당 종류와 사이트를 갖고있는 그룹명이 이미 존재합니다."
        : "중복된 그룹명이 있습니다.";

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        select_text
      );
      class_put.crate_alert();

      return false;
    }

    if (function_flag) {
      const keyword_param = keyword_render.map((data) => ({
        keyword: `${data}`,
      }));
      add_group_item(keyword_param);
    }
  };

  // =====================================================
  // [E] 그룹 추가
  // =====================================================
  const add_group_item = (param) => {
    console.log(param);
    let has_idx = menu[0].items.filter((data) => data.title === site_name);

    let insert = {
      action: "add",
      workspaceIdx: workSpace.replace("workspace", ""),
      title: group_name,
      allowMenu: [
        {
          idx: `77a35261f3574d97873eeb469a6f4ff8_custom`,
          role: "edit",
        },
        {
          idx: `ce0cb51620a8413ab81db0f5d5_custom`,
          role: "edit",
        },
        {
          idx: `fdc45a44040c450283ea670d6c7042e4`,
          role: "edit",
        },
        {
          idx: `${has_idx[0].id}`,
          role: "edit",
        },
      ],
      itemCountOfPage: 5.0,
      currentPage: Math.ceil((item + 1) / 5),
      threshold: 0,
      keywords: param,
      category: choice_category,
      site: site_name,
      updateDate: new Date(),
      tplId: site_id,
    };

    let payload = {
      queries: [
        {
          key: "insertGroup",
          query: `db.collection('Group').insertOne(${JSON.stringify(insert)})`,
        },
      ],
    };

    Utils.getQueryResult(payload).then((result) => {
      class_put = new Sweetalert_class(
        "success",
        "성공하였습니다!",
        "그룹을 추가하였습니다."
      );
      class_put.crate_alert();

      reset_func();
      rest.onHide();
      parentRender();
    });
  };

  // =====================================================
  // [E] 하단 키워드 삭제 이벤트
  // =====================================================
  const remove_keyword = (param) => {
    const new_keyword = keyword_render.filter((data) => data !== param);

    set_keyword_render(new_keyword);
  };

  // =====================================================
  // [E] 키워드 및 알람설정 수정시 이벤트
  // =====================================================
  const update_item_check = () => {
    if (keyword_render.length === 0) {
      function_flag = false;

      class_put = new Sweetalert_class(
        "warning",
        "추가할 수 없습니다!",
        "키워드를 선택해주세요."
      );
      class_put.crate_alert();

      return false;
    }

    if (function_flag) {
      const keyword_param = keyword_render.map((data) => ({
        keyword: `${data}`,
      }));

      update_group_item(keyword_param);
    }
  };

  // =====================================================
  // [E] 그룹 업데이트 이전 CRUD 파악
  // =====================================================
  const update_split = () => {
    console.log(keyword_render);
  };

  // =====================================================
  // [E] 그룹 업데이트 함수
  // =====================================================
  const update_group_item = async (param) => {
    class_put = new Sweetalert_class(
      "warning",
      "수정하시겠습니까?",
      `수정시 키워드별 PUSH조건이 초기화됩니다,<br>정말 수정하시겠습니까?`
    );
    const swal_result = await class_put.confirm_alert();

    if (swal_result) {
      let update = {
        $set: {
          action: "edit",
          keywords: param,
          updateDate: new Date(),
        },
      };

      let payload = {
        queries: [
          {
            key: "updateGroup",
            query: `db.collection('Group').updateOne({ "title": "${group_name}"}, ${JSON.stringify(
              update
            )})`,
          },
        ],
      };

      Utils.getQueryResult(payload).then((result) => {
        if (result.updateGroup.acknowledged === true) {
          class_put = new Sweetalert_class(
            "success",
            "수정 완료!",
            "수정이 완료되었습니다."
          );
          class_put.crate_alert();
          rest.onHide();
          parentRender();
        }
      });
    }
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {action == "add" ? (
            <>키워드 및 알람설정 추가</>
          ) : (
            <>키워드 및 알람설정 수정</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group id="formUserId">
            <Form.Label>종류</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                change_category(e);
              }}
              value={choice_category}
              disabled={action == "edit" ? true : false}
            >
              <option value="">선택</option>
              <option value="커뮤니티">커뮤니티</option>
              <option value="뉴스">뉴스</option>
            </Form.Control>
          </Form.Group>
          <Form.Group id="formUserId">
            <Form.Label>사이트명</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                change_site(e.target);
              }}
              value={site_name}
              disabled={action == "edit" ? true : false}
            >
              {choice_category !== "" ? (
                choice_category === "커뮤니티" ? (
                  <>
                    <option value="">선택</option>
                    <option value="디씨인사이드 주식갤러리">
                      디씨인사이드 주식갤러리
                    </option>
                    <option value="디씨인사이드 인터넷방송 갤러리">
                      디씨인사이드 인터넷방송 갤러리
                    </option>
                    <option value="디씨인사이드 LOL 갤러리">
                      디씨인사이드 LOL 갤러리
                    </option>
                  </>
                ) : (
                  <>
                    <option value="">선택</option>
                    <option value="국민재난안전포털 재난문자">
                      국민재난안전포털 재난문자
                    </option>
                    <option value="다음 IT 뉴스">다음 IT 뉴스</option>
                  </>
                )
              ) : (
                <option value="">종류를 선택해주세요</option>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group id="formPassword">
            <Form.Label>그룹 및 키워드 선택하기</Form.Label>
            <div key="inline-radio" className="mb-3">
              <Form.Check
                inline
                label="기존"
                name="existing"
                type="radio"
                id="existing"
                checked={select_mode ? true : false}
                onChange={() => {
                  set_select_mode(true);
                }}
                disabled={action === "edit" ? true : false}
              />
              <Form.Check
                inline
                label="신규"
                name="new"
                type="radio"
                id="new"
                checked={!select_mode ? true : false}
                onChange={() => {
                  set_select_mode(false);
                }}
                disabled={action === "edit" ? true : false}
              />
            </div>
          </Form.Group>
          {select_mode ? (
            <>
              <Form.Group id="formUserId">
                <Form.Label>그룹명</Form.Label>
                <Form.Control
                  as="select"
                  value={group_name}
                  onChange={(e) => {
                    group_change(e);
                  }}
                  disabled={action === "edit" ? true : false}
                >
                  <option value="">선택</option>
                  {group_list.length !== 0 ? (
                    group_list.map((data, index) => (
                      <option key={index} value={data.title}>
                        {data.title}
                      </option>
                    ))
                  ) : (
                    <option value="">저장된 그룹이 없습니다.</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group id="formUserId">
                <Form.Label className="keyword_label">키워드</Form.Label>
                <Form.Control
                  as="select"
                  className="keyword_option"
                  onChange={(e) => {
                    set_choice_keyword(e.target.value);
                  }}
                  value={choice_keyword}
                >
                  <option value="">선택</option>
                  {keyword_arr}
                </Form.Control>
                <Button
                  className="keyword_add_btn"
                  onClick={() => {
                    keyword_add();
                  }}
                >
                  키워드 추가
                </Button>
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group id="formUserId">
                <Form.Label>그룹명</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="그룹명을 입력해주세요."
                  value={group_name}
                  onChange={(e) => {
                    group_change(e);
                  }}
                  onBlur={() => {
                    blur_match();
                  }}
                />
                {alert_text}
              </Form.Group>
              <Form.Group id="formUserId">
                <Form.Label className="keyword_label">키워드</Form.Label>
                <Form.Control
                  as="select"
                  className="keyword_option"
                  onChange={(e) => {
                    set_choice_keyword(e.target.value);
                  }}
                  value={choice_keyword}
                >
                  <option value="">선택</option>
                  {keyword_arr}
                </Form.Control>
                <Button
                  className="keyword_add_btn"
                  onClick={() => {
                    keyword_add();
                  }}
                >
                  키워드 추가
                </Button>
              </Form.Group>
            </>
          )}
        </Form>
        <div className="keyword_render_wrap">
          {keyword_render.map((data, index) => (
            <div>
              <p key={`keyword` + index}>{data}</p>
              <MdClose
                onClick={() => {
                  remove_keyword(data);
                }}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={btnStyle}
          onClick={() => {
            {
              action === "add" ? add_item_check() : update_item_check();
            }
          }}
        >
          {action === "add" ? "등록" : "수정"}
        </Button>
        <Button
          onClick={() => {
            rest.onHide();
            reset_func();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
