
import React, { useEffect, useMemo, Component, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ReactDOM from 'react-dom';
import { Button, Table, Pagination } from 'react-bootstrap';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { CrawlingList_Add } from "./CrawlingList_Add";
import { CrawlingList_Edit } from "./CrawlingList_Edit";

import { UnitTable } from "../edit/WebBuilder/Element/UnitTable";
import { UnitDatePicker } from "../edit/WebBuilder/Element/UnitDatePicker";


import axios from 'axios';

import Utils from "../edit/WebBuilder/Utils";
import * as _ from "lodash"


export function CrawlingList({ el, states, className }) {
  let id = "component" + el.i

  const [data, setData] = React.useState();
  const [layout, setLayout] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);


  // button on/off
  const [isEnableCrawling, setIsEnableCrawling] = useState(false);
  const [isStartCrawling, setIsStartCrawling] = useState(false);
  const [isDoneCrawling, setIsDoneCrawling] = useState(false);
  const [progress, setProgress] = useState(20);




  const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
  const userId = useSelector((state) => state.auth.user._id, shallowEqual)

  let _siteIdx = currentSite.split("site").join("")
  let _workspaceIdx = currentWorkspace.split("workspace").join("")
  let _userIdx = userId
  


  // =====================================================
  // [S] modal - 모달 정의
  // =====================================================
  const [modal_add, setModal_add] = useState({ show: false });
  const [modal_edit, setModal_edit] = useState({ show: false });


  let interval = null

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <>


          <CrawlingList_Add
            show={modal_add.show}
            onHide={() => setModal_add({ show: false })}
            parentCallback={modal_add_callback}
          />

          <CrawlingList_Edit
            show={modal_edit.show}
            data={modal_edit}
            onHide={() => { setModal_edit({ show: false }) }}
            parentCallback={modal_edit_callback}
          />

        </>
        ,
        document.getElementById("contentModal"))
    } else {
    }



  });

  // =====================================================
  // [E] modal - 모달 정의
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  // [S] 컴포넌트 상단 custom setting 버튼 클릭시 functionObj 실행됨
  // [S] DB component > settings > ...
  //==============================================================

  var functionObj = {
    embed: function () {

      // src 외부 접근 가능 URL 을 적어주세요
      let copyStr =
        `<iframe width="1280" height="720" 
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

      console.log(copyStr)
      alert("check console.")

      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================

  // All inputField value
  const [values, setValues] = useState({});












  // // =====================================================
  // // [S] Check Crawling
  // // =====================================================

  // let maxCrawlingCheckCount = 0
  // const checkCrawling = (_taskId) => {

  //   setTimeout(function (_taskId) {

  //     // console.log("checkCrawling:: " + maxCrawlingCheckCount)
  //     // console.log("taskId:: " + _taskId)
  //     maxCrawlingCheckCount = maxCrawlingCheckCount + 1

  //     if (maxCrawlingCheckCount < 30) {

  //       let payload = {
  //         queries: [
  //           {
  //             key: "checkCrawling",
  //             query: `db.collection('CrawlerLog').aggregate([
  //               { $match: {"taskId":"${_taskId}"} }
  //             ]).toArray()`
  //           },

  //         ]
  //       }

  //       Utils.getQueryResult(payload).then((result) => {
  //         // console.log("checkCrawling")
  //         // console.log(result)

  //         if (result.checkCrawling.length == 0) {
  //           checkCrawling(_taskId)
  //         } else {

  //           alert("크롤링이 완료되었습니다.")
  //           setIsDoneCrawling(true)
  //           setIsStartCrawling(false)
  //           loadData()
  //         }

  //       });

  //     }

  //   }, 5000, _taskId);
  // }


  // =====================================================
  // [S] Handle Event
  // =====================================================


  // table
  let field1 = "element_aslkdfjie"


  var handleEventObj = {

    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      console.log(">>> saveState")
      console.log("handleEvent > method : " + method)
      console.log("handleEvent > id : " + id)
      console.log("handleEvent > value : " + value)

      setValues({ ...values, [id]: value });

      return true
    },

    currentPage: function (method, id, ...value) {


      setCurrentPage(value)

      let payload = {
        queries: [
          {
            key: "dataList",
            query: `db.collection('CrawlerTemplate').aggregate([
              { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
            { $skip: ${(value - 1) * layout.settings.itemCountOfPage} },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} }
          ]).toArray()`
          },

        ]
      }


      Utils.getQueryResult(payload).then((result) => {
        console.log(result)
        setData(result)
      });

      return true
    },



    add: function (method, id, ...value) {
      setModal_add({
        show: true
      })

      return true
    },



    edit: function (method, id, value) {

      console.log(">> edit")
      console.log(value)
      let payloadTemplate = {
        queries: [
          {
            key: "template",
            query: `db.collection('CrawlerTemplate').aggregate([
              { $match:  { _id: new require('mongodb').ObjectID('${value}') } },
            { $skip: ${(value - 1) * layout.settings.itemCountOfPage} },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} },
          ]).toArray()`
          }
        ]
      }

      Utils.getQueryResult(payloadTemplate).then((result) => {

        console.log("result")

        console.log(result)




        setModal_edit({
          show: true,
          key: value,
          data: result.template
        })

      });


      return true
    },



    run: function (method, id, value) {

      // console.log(method)
      // console.log(id)
      // console.log(value)

      let payload = {
        "templateIdx": value
      }

      if (window.confirm("크롤링을 시작할까요?")) {


        
        const instance = axios.create({
          baseURL: 'http://localhost:4000',
          timeout: 1000,
          headers: { 'Content-Type': 'application/json' }

        });

        instance.post(`startCrawling/`, payload).then((result) => {
          console.log("> result")
          console.log(result)
          if (result.data == "success") {
            alert("크롤링이 실행되었습니다.\n잠시후에 데이터가 없데이트 됩니다.")
            // checkCrawling(_taskId)

          } else {
            alert("실행할수 없습니다. [수집완료] 상태에서 실행해주세요")
          }

        })

      } else {

      }

      return true
    },



    delete: function (method, id, value) {

      console.log(value)

      //TODO : SEND SCHEDULER
      //TODO : SEND SCHEDULER
      //TODO : SEND SCHEDULER

      // var data = JSON.stringify({"val":value})
      // var config = {
      //   method: 'post',
      //   url: 'http://localhost:8080/job',
      //   headers: { 
      //     'Content-Type': 'application/json'
      //   },
      //   data : data
      // };
      
      // axios(config)
      // .then(function (response) {
      //   console.log(JSON.stringify(response.data));
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });

      



      if (value.length == 0) {
        alert("삭제할 항목을 선택해주세요")
      } else {


        let payload = {
          queries: [
            {
              key: "delete",
              args: value,
              query: ` 
              db.collection("CrawlerTemplate").deleteMany(query)    
              `
            }
          
          ]
        }

        Utils.getQueryResult(payload).then((result) => {

          let payload2 = {
            queries: [

              {
                key: "dataList",
                query: `db.collection('CrawlerTemplate').aggregate([
                  { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
                  { $skip: 0 },
                  { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} }
                ]).toArray()`
              }
  
            ]
          }
  

          Utils.getQueryResult(payload2).then((result2) => {
            console.log("delete > ")
            console.log(result2)
            setData(result2)
          });


        });


      }


      return true
    },




  };


  const handleEvent = (method, id, ...value) => {

    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }

  }


  // =====================================================
  // [S] registSchdulerTest
  // =====================================================


  const registSchdulerTest = () => {


      
    var data = JSON.stringify({
      "userId": "60d9f3049fc15268543c7d7322",
      "site": "dcinside22",
      "template": {
        "_id": "62789ac10affe5582ca5c215",
        "siteIdx": "626a0b71bbcad71d2c55e422",
        "userIdx": "60d9f3049fc15268543c7d73",
        "workspaceIdx": "626a0b71bbcad71d2c55e426",
        "tId": "templateID_1",
        "selector": "https://search.dcinside.com/combine/q/%ED%86%B5%EC%8B%A0%20%EC%9E%A5%EC%95%A0",
        "rule": "10 5 */1 * * ?",
        "version": "0.1",
        "driver": "",
        "preBatch": "",
        "postBatch": "",
        "title": "디씨인사이드 커뮤니티 크롤링",
        "dupKey": [
          "title",
          "from"
        ],
        "items": [
          {
            "type": "element",
            "selector": "#container > div > section.center_content > div.inner > div.integrate_cont.sch_result > ul > li:nth-child(n)",
            "depth": 0,
            "properties": [
              {
                "property": "title",
                "selector": "a",
                "path": ""
              },
              {
                "property": "content",
                "selector": "p:nth-child(2)",
                "path": ""
              },
              {
                "property": "linkUrl",
                "selector": "a",
                "attr": "href"
              },
              {
                "property": "from",
                "selector": "p.link_dsc_txt.dsc_sub > a"
              },
              {
                "property": "date",
                "selector": "p.link_dsc_txt.dsc_sub > span"
              }
            ]
          }
        ],
        "isDelete": 0,
        "updateDate": "2022-05-04T16:13:54.568+0000",
        "registDate": "2022-05-04T16:13:54.568+0000",
        "metadata": {
          "tag": "",
          "source": "DC인사이드"
        }
      }
    });
    
    var config = {
      method: 'put',
      url: 'http://localhost:8080/job',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });




  }

  // =====================================================
  // [S] Call Back
  // =====================================================

  const modal_add_callback = (data) => {

    let _tempData = data

    _tempData["siteIdx"] = _siteIdx
    _tempData["userIdx"] = _userIdx
    _tempData["workspaceIdx"] = _workspaceIdx

    delete _tempData["_id"]

    console.log('modal_add_callback: ' + JSON.stringify(_tempData))

    //TODO : SEND SCHEDULER
    //TODO : SEND SCHEDULER
    //TODO : SEND SCHEDULER


    // var data = JSON.stringify(_tempData)
    // var config = {
    //   method: 'put',
    //   url: 'http://localhost:8080/job',
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    
    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });




    let payload = {
      queries: [
        {
          key: "update",
          query: `
              db.collection('CrawlerTemplate').insertOne(${JSON.stringify(_tempData)})`
        }
      ]
    }


    Utils.getQueryResult(payload).then((result) => {

      let payload2 = {
        queries: [
          {
            key: "dataList",
            query: `db.collection('CrawlerTemplate').aggregate([
              { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
              { $skip: 0 },
              { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} }
            ]).toArray()`
          }

        ]
      }


      Utils.getQueryResult(payload2).then((result2) => {

        console.log("insert > ")
        console.log(result2)
        setData(result2)
      });

    });



  }

  

  const modal_edit_callback = (key, data) => {
    console.log('modal_edit_callback key: ' + key)
    console.log('modal_edit_callback: ' + JSON.stringify(data))
   
    //TODO : SEND SCHEDULER
    //TODO : SEND SCHEDULER
    //TODO : SEND SCHEDULER

    // var data = JSON.stringify(data)
    // var config = {
    //   method: 'post',
    //   url: 'http://localhost:8080/job',
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    
    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });


    let update = { $set: { title: data.title,filter: data.filter, crawlerType: data.crawlerType, rule: data.rule, selector: data.selector, items: data.items, dataLimit: data.dataLimit, metadata: data.metadata, dupKey: data.dupKey, updateDate: new Date() } }

    let payload = {
      queries: [
        {
          key: "update",
          query: `
              db.collection('CrawlerTemplate').updateOne({ _id: new require('mongodb').ObjectID('${key}') },${JSON.stringify(update)})`
        }
      ]
    }


    Utils.getQueryResult(payload).then((result) => {

      let payload2 = {
        queries: [
          {
            key: "dataList",
            query: `db.collection('CrawlerTemplate').aggregate([
              { $match: {"workspaceIdx" : "${_workspaceIdx}"} },
              { $skip: 0 },
              { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000}}
            ]).toArray()`
          }
        ]
      }
  
  
      Utils.getQueryResult(payload2).then((result2) => {
  
        // console.log("update > ")
        // console.log(result2)
        setData(result2)
      });

    });
  }






  // =====================================================
  // [S] <UnitTable></UnitTable>
  // =====================================================
  useEffect(() => {

    // STYLE
    const _style = {
      "table": {
        // border: "2px solid green",
      },
      "tr": {
        // border: "5px solid red",
      },
      "th": {
        // border: "5px solid red",
      },
      "td": {
        // border: "2px solid blue",
        maxWidth: "200px"
      },

    }

    // SETTINGS
    const _settings = {
      "tableType": "dataTable",//dataTable , table

      "isAllCheck": false,
      "isPaging": false,

      "isDelete": true,
      "isAdd": true,
      "isUpdate": false,
      "isExcel": false,

      "itemCountOfPage": 9,
      "pageCountOfGroup": 10
    }


    // COLUMNS
    const _columns = [

      {
        title: '',
        type: 'check',
        dataKey: '={autoIncrease}',
        style: {
          width: "50px"
        },

      },
      {
        title: 'ID',
        dataKey: '={_id}',
        style: {
          width: "220px"
        },

      },
      {
        title: '제목',
        dataKey: '={title}',
        style: {
          width: "25%"
        },
      },
      {
        title: '크롤링 주소',
        type: 'link',
        dataKey: '={selector}',

      },
      // {
      //   title: '상태',
      //   type: 'displayStatus',
      //   dataKey: '={status}',
      //   style: {
      //     width: "74px"
      //   },
      // },
      {
        title: '예약시간',
        dataKey: '={rule}',
        style: {
          width: "15%"
        },
      },
      {
        title: '수정',
        type: 'edit',
        dataKey: '={_id}',
        style: {
          width: "90px"
        },
      },
      {
        title: '실행',
        type: 'run',
        dataKey: '={_id}',
        style: {
          width: "90px"
        },
      }

    ];

    let _layout = {}
    _layout["style"] = _style
    _layout["settings"] = _settings
    _layout["columns"] = _columns
    setLayout(_layout)

  }, [])





  const getData = () => {

    let payload = {
      queries: [
        {
          key: "dataList",
          query: `db.collection('CrawlerTemplate').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
          { $skip: ${(currentPage - 1) * layout.settings.itemCountOfPage} },
          { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} }

        ]).toArray()`
        },

      ]
    }


    Utils.getQueryResult(payload).then((result) => {
      console.log(result)
      setData(result)
    });

  }






  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드 
  // =====================================================
  useEffect(() => {

    if (layout == null) return
    if (layout == undefined) return

    let payload = {
      queries: [
        {
          key: "dataList",
          query: `db.collection('CrawlerTemplate').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}"} },
            { $sort: { registDate: -1 } },
            { $skip: 0 },
            { $limit: ${(layout.settings.tableType == 'table') ? layout.settings.itemCountOfPage : 1000} }

          ]).toArray()`
        },

      ]
    }


    Utils.getQueryResult(payload).then((result) => {
      console.log("loaded data : ")
      console.log(result)
      setData(result)
    });


    // clearInterval(interval);
    // interval = setInterval(getData, 20000);


  }, [layout])




  const clickTest = () => {

    // ================================================================================
    // TODO : convert tag to data ({field1} -> datePicker로 현재 선택된 날짜)
    // let query = `db.collection('Data').find({'registDate':{field1}).limit(30)`
    // to
    // let query = `db.collection('Data').find({'registDate':20220102).limit(30)`
    // ================================================================================

  }





  return (
    <>

      <div className={`card card-custom ${className}`}>

        {/* <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">{el.data.title}</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>
        </div> */}

        <div className="card-body d-flex flex-column px-0">
          <div className="flex-grow-1 card-spacer-x" style={{ overflow: "auto" }}>
            <UnitTable id={id} layout={layout} data={data} onEvent={handleEvent} ></UnitTable>
          </div>
        </div>

      </div>

    </>

  );
}


