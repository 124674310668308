/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, Component, useState } from "react";
import ReactDOM from 'react-dom';
import { Button, Form, Col, Row, Modal, ListGroup } from 'react-bootstrap';

import axios from "axios";


import { InputBuilderSetting } from "./InputBuilderSetting";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";



export function InputBuilderCore(props) {

  const { mode, action, parentId, parentCallback, ...rest } = props

  const [content, setContent] = React.useState('');
  const [ElementList, setElementList] = React.useState('');

  const editStyle = {
    backgroundColor: "#A0E7E5",
    padding: "4px"
  }
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================


  useEffect(() => {

    // let arr = [{ "type": "input", "value": "test1" }, { "type": "input2", "value": "test2" }, { "type": "buttonSubmit", "value": "test3" }] // layout 을 array 로 만든다 .
    let func = new Func();
    setElementList(props.layout.map((el) =>
      func[el.type](el.value)
    ))



    console.log(">> useEffect")


  }, []);

  // =====================================================
  // [E] modal
  // =====================================================


  class Func {


    input = (data) => {
      return (
        <>
          <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
            <Form.Label column sm={2}>
              {data.title}
            </Form.Label>
            <Col sm={10}>
              <Form.Control wsdata={JSON.stringify(layout)} defaultValue="email@example.com" />
            </Col>
          </Form.Group>

          {(mode == "edit") ?
            <>
              <Form.Group style={editStyle} as={Row} key={Math.random()} id="formPlaintextEmail">
                <Form.Label column sm={2}>
                  target
                </Form.Label>
                <Col sm={10}>
                  <Form.Control defaultValue="DataTest.email" />
                </Col>

                <Form.Label column sm={2}>
                  title
                </Form.Label>
                <Col sm={10}>
                  <Form.Control defaultValue="제목" />
                </Col>
              </Form.Group>

              <br />
            </>
            : undefined
          }
        </>

      );
    }

    input2 = (data) => {
      return (<>
        <Form.Row key={Math.random()} >
          <Form.Group as={Col} id="formPassword">
            <Form.Label>{data.title}*</Form.Label>
            <Form.Control placeholder="비밀번호를 입력해주세요" wsdata="wise" />
          </Form.Group>

          <Form.Group as={Col} id="formPasswordConfirm">
            <Form.Label>비밀번호 확인*</Form.Label>
            <Form.Control placeholder="동일한 비밀번호를 입력해주세요" />
          </Form.Group>
        </Form.Row>

        {(mode == "edit") ?
          <>
            <Form.Group style={editStyle} as={Row} key={Math.random()} id="formPlaintextEmail">
              <Form.Label column sm={2}>
                target
              </Form.Label>
              <Col sm={10}>
                <Form.Control defaultValue="DataTest.password" />
              </Col>

              <Form.Label column sm={2}>
                title1
              </Form.Label>
              <Col sm={10}>
                <Form.Control defaultValue="password" />
              </Col>

              <Form.Label column sm={2}>
                title2
              </Form.Label>
              <Col sm={10}>
                <Form.Control defaultValue="password confirm" />
              </Col>
            </Form.Group>
            <br />
          </>
          : undefined

        }

      </>
      );
    }


    // textarea = (data) => {
    //   return (
    //     <Form.Group key={Math.random()} id="exampleForm.ControlTextarea1">
    //       <Form.Label>Example textarea</Form.Label>
    //       <Form.Control as="textarea" rows="3" />
    //     </Form.Group>
    //   );
    // }

    // label = (data) => {
    //   return (
    //     <Form.Group key={Math.random()} id="formUserId">
    //       <Form.Label>{data}*</Form.Label>
    //       <Form.Control placeholder="아이디를 입력해주세요" />
    //     </Form.Group>
    //   );
    // }

    // select = (data) => {
    //   return (
    //     <Form.Group key={Math.random()} id="formGridState">
    //       <Form.Label>{data}</Form.Label>
    //       <Form.Control as="select">
    //         <option>권한 그룹 선택</option>
    //         <option>...</option>
    //       </Form.Control>
    //     </Form.Group>
    //   );
    // }

    // radio = (data) => {
    //   return (
    //     <fieldset>
    //       <Form.Group as={Row} key={Math.random()}>
    //         <Form.Label as="legend" column sm={2}>
    //           Radios
    //         </Form.Label>
    //         <Col sm={10}>
    //           <Form.Check
    //             type="radio"
    //             label="first radio"
    //             name="formHorizontalRadios"
    //             id="formHorizontalRadios1"
    //           />
    //           <Form.Check
    //             type="radio"
    //             label="second radio"
    //             name="formHorizontalRadios"
    //             id="formHorizontalRadios2"
    //           />
    //           <Form.Check
    //             type="radio"
    //             label="third radio"
    //             name="formHorizontalRadios"
    //             id="formHorizontalRadios3"
    //           />
    //         </Col>
    //       </Form.Group>
    //     </fieldset>
    //   );
    // }


    buttonSubmit = (data) => {
      return (
        <>
          {(mode == "edit") ? undefined
            :
            <Button key={Math.random()} variant="primary" onClick={((e) => submit(e))} >
              {data.title}
            </Button>
          }
        </>
      );
    }

  }



  const submit = (e) => {

    var closestElement = e.target.closest(".wsform");

    var sendDataList = closestElement.querySelectorAll("[wsdata]")

    // console.log(">> sendDataList")

    // console.log(sendDataList)

    for (let el of sendDataList) {

      // console.log(el)
      // console.log(el.attributes)
      console.log(el.value)

    }

    //Info to Json Array

    // SEND DATA to DB

    // let payload = [{
    //   "document": "DataTest1",
    //   "workspaceIdx": "data1"
    // },{
    //   "document": "DataTest2",
    //   "workspaceIdx": "data2"
    // }]

    // dispatch({ type: actionTypes.InsertDataTest, payload: payload })

    // alert("submit")
  }


  const save = (e) => {

    // var closestElement = e.target.closest(".wsform");
    // var sendDataList = closestElement.querySelectorAll("[wsdata]")

    console.log(">> save")



  }

  // let arr = ["dd","sss"] // layout 을 array 로 만든다 .
  // let x = new X();
  // let ElementList = arr.map((value) => x['label']("value"));


  let layout = `
  {
    "type": "VerticalLayout",
    "elements": [
      {
        "type": "HorizontalLayout",
        "elements": [
          {
            "type": "Control",
            "scope": "#/properties/name",
            "size": {
              "md": 3
            }
          },
          {
            "type": "Control",
            "scope": "#/properties/personalData/properties/age",
            "size": {
              "md": 3
            }
          }
        ]
      },
      {
        "type": "Label",
        "text": "Additional Information"
      }
    ]
  }
  
  `




  return (
    <>
      {mode}
      <br />
      <Form className="wsform">
        {ElementList}

        {(mode == "edit") ?
          <Button key={Math.random()} variant="primary" onClick={((e) => save(e))} >
            저장
          </Button>
          : undefined
        }

      </Form>

      {/* <Form className="wsform">
          {ElementList}
          <br /><br />
          <Button variant="primary" onClick={((e) => submit(e))} >
            추가
          </Button>
        </Form> */}
    </>

  );
}


