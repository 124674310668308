
import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Modal, Form } from 'react-bootstrap';

export function UploadExcelSetting(props) {
    const { parentId, parentCallback, ...rest } = props

    // console.log(props)

    const [image, setImage] = useState('');
    const [link, setLink] = useState('');


    useEffect(() => {
        setImage(props.image)
        setLink(props.link)

    }, [props.image, props.link]);


    const btnStyle = {
        margin: "2px",
    }


    const handleChangeImg = (e) => {

        console.log(e.target.value)

        setImage(e.target.value)
    }

    const handleChangeUrl = (e) => {

        console.log(e.target.value)
        setLink(e.target.value)
    }



    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    UploadExcel Setting
                </Modal.Title>

            </Modal.Header>
            <Modal.Body>

                {/* {console.log("-------+++++++")} */}


                <Form>
                    <Form.Label>Image Url</Form.Label>
                    <Form.Control type="text" value={image} placeholder="http://www.example.com/image01.png" onChange={handleChangeImg} />
                </Form><br />
                <Form>
                    <Form.Label>Link Url</Form.Label>
                    <Form.Control type="text" value={link} placeholder="http://www.example.com" onChange={handleChangeUrl} />
                </Form>

                <br />
                <div>
                    <Button style={btnStyle} size='lg' onClick={() => {

                        let data = {}
                        data.image = image
                        data.link = link

                        console.log(data)
                        console.log(data.link)

                        parentCallback(JSON.stringify(data))



                    }}> SAVE </Button>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}