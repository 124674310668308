/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { shallowEqual, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/WiseLayout";
import { toAbsoluteUrl } from "../../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../../_partials/dropdowns";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { actionTypes } from '../../../../../../../redux/actionType';

export function UserProfileDropdown() {

  const user = useSelector((state) => state.auth.user, shallowEqual);
  var userName = user?.name
  var userEmail = user?.email
  var userGrade = user?.grade

  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const menuStyle = {
    width: "260px",
    marginTop: "6px"
  }


  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg"}
        >                  
        {/* <div style={{padding:"2px 10px", borderRadius:"4px", backgroundColor:"#027bc6"}}>H</div> */}
          <img src={toAbsoluteUrl("/media/users/UserHyorim-b.png")} alt="" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        style={menuStyle}
      >
        <>
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  {/* <div style={{padding:"2px 10px", borderRadius:"4px", backgroundColor:"white"}}>H</div> */}

                  <img src={toAbsoluteUrl("/media/users/UserHyorim.svg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {userName}
                </div>
                <span className="label label-light-success label-lg font-weight-bold label-inline">
                  {userGrade}
                </span>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}
          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap bgi-size-cover bgi-no-repeat rounded-top"
              style={{ padding: "20px 15px", backgroundImage: "linear-gradient(71deg, #027bc6 -8%, #02c7b0 106%)" }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="text-success font-weight-bold font-size-h4">
                  {/* <div style={{padding:"2px 10px", borderRadius:"4px", backgroundColor:"white"}}>H</div> */}
                  
                  <img src={toAbsoluteUrl("/media/users/UserHyorim.svg")} alt="" />
                </span>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3">
                {userEmail}
              </div>
            </div>
          )}

        </>

        <div style={{ color: "#aaaaaa" }}>

          {


            // TODO : 이부분은 삭제될예정입니다. 
            // 레이아웃이 아닌 내용으로 
            // localStorage.projectName 에서 layoutConfig 로 이전 예정 

            (localStorage.projectName === "hyorim") ?
              (<>

                <div className='cursor-pointer cursor-pointer-setting'
                  style={{ marginTop: "10px", padding: "10px 9px", lineHeight: "24px" }}
                  onClick={() => {
                    dispatch({ type: actionTypes.DeleteCurrentWorkspaceInfo, payload: { userIdx: user._id } })
                  }}
                >
                  <span className="font-weight-normal font-size-base d-md-inline">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Field.svg")} className="mr-1" />
                    현장선택
                  </span>
                </div>

              </>
              ) :
              (localStorage.projectName === "kici") ?
                (<>

                </>
                ) :
                (localStorage.projectName === "pageone") ?
                  (<>

                  </>
                  ) :
                  <></>
          }







          <div style={{ marginBottom: "10px", padding: "10px 9px", lineHeight: "24px" }}>
            <Link
              to="/logout"
              className="cursor-pointer cursor-pointer-setting font-weight-normal font-size-base mr-3"
              style={{ display: "block", color: "#aaaaaa" }}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Logout.svg")} className="mr-1" style={{ verticalAlign: "-7px" }} />
              로그아웃
            </Link>
          </div>
        </div>
      </Dropdown.Menu>

    </Dropdown>
  );
}
