/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';



export function ControlLogo({ el, states, className }) {
  let id = "TEST"//"component" + el.i


  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {

    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================



  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };



  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">CONTROL Logo</div>
              <div className="font-size-sm text-muted mt-2">for TEST</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">

Workspace Logo <br/>
Workspace 로고 변경<br/>


<br/><br/>
          #적당한 형식 선택 .. 미정
{/* 


            {
              selector["workspaces"].map((ws, index) =>
                <div key={index} className="d-flex align-items-center justify-content-between mb-10">
                  <div className="d-flex align-items-center mr-2">
                    <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                      <div className="symbol-label">
                        <span className="svg-icon">
                          <SVG
                            className=" h-50"
                            src={toAbsoluteUrl("/media/svg/misc/006-plurk.svg")}
                          ></SVG>
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        href="#"
                        className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
                      >
                        
                        워크스페이스 {(selector["modl"]["currentWorkspace"] == ("workspace" + ws.workspaceIdx)) ? "(현재)" : ""}
                      </a>
                      <div className="font-size-sm text-muted font-weight-bold mt-1">
                        {ws.workspaceIdx}
                      </div>
                    </div>
                  </div>
                  {(selector["modl"]["currentWorkspace"] == ("workspace" + ws.workspaceIdx)) ? "" : <Button onClick={changeWorkspace.bind(this, index)}>이동</Button>}
                </div>
              )
            }


 */}


            {/* begin::Item */}

            {/* end::Item */}


          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}


    </>
  );
}

