/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toAbsoluteUrl } from "../../../_helpers";
import { GenerateAPIKeySetting } from "./GenerateAPIKeySetting";

import { Button, Form, Group, Label } from 'react-bootstrap';

import Utils from "../edit/WebBuilder/Utils";

import axios from 'axios';

export function GenerateAPIKey({ el, states, className }) {



  let id = "component" + el.i

  const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
  const userId = useSelector((state) => state.auth.user._id, shallowEqual)

  let _siteIdx = currentSite.split("site").join("")
  let _workspaceIdx = currentWorkspace.split("workspace").join("")
  let _userIdx = userId

  const fullScreenHandle = useFullScreenHandle();

  const [data, setData] = React.useState("");

  const [apiKey, setApiKey] = React.useState("");
  const [server, setServer] = React.useState("");




  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드
  // =====================================================
  useEffect(() => {

    console.log("useeffect >> ")

    let payload = {
      queries: [
        {
          key: "dataList",
          query: `db.collection('ApiKey').aggregate([
            { $match: { "workspaceIdx" : "${_workspaceIdx}" , "userIdx" : "${_userIdx}"} }
         
          ]).toArray()`
        },

      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      console.log(">>>> loaded data : ")
      console.log(result)

      if (result.dataList.length != 0) {
        setApiKey(result.dataList[0].apiKey)
        setServer(result.dataList[0].server)
      }

      setData(result.dataList)

    });

  }, [])






  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    // let parent = document.getElementById("contentModal")
    // if (parent != null) {
    //   ReactDOM.render(
    //     <GenerateAPIKeySetting
    //       data={data}
    //       show={modalShow}
    //       onHide={() => setModalShow(false)}
    //       parentCallback={selectSetting}
    //     />,
    //     document.getElementById("contentModal"))
    // } else {
    // }
  });


  //==============================================================
  // Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    },
    expand: function () {
      fullScreenHandle.enter()

      return true
    },
    embed: function () {

      // src 외부 접근 가능 URL 을 적어주세요
      let copyStr =
        `<iframe width="1280" height="720" 
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

      console.log(copyStr)
      alert("check console.")

      return true
    },
    test: function (a, b) {
      return a + b
    }
  };


  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);






  // =====================================================
  // [S] Call Back
  // =====================================================

  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  const changeServer = (e) => {
    setServer(e.target.value)
  }




  const generateKey = () => {

    console.log(el)

    let payload = { type: "apiKey", siteIdx: _siteIdx, userIdx: _userIdx, workspaceIdx: _workspaceIdx, server: server }

    const instance = axios.create({
      baseURL: axios.defaults.serverURL,
      timeout: 1000,
      headers: { 'Content-Type': 'application/json' }
    });

    const dataStr = JSON.stringify(payload)
    // console.log(dataStr)

    const req = {
      query: `mutation generateAPIKey($data: String) {
        generateAPIKey(data: $data)
    }`,
      variables: {
        data: dataStr
      }
    }

    instance.post('graphql/', req)
      .then(resp => {
        setApiKey(resp.data.data.generateAPIKey)
      })
      .catch(error => {

      });




  }




  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        {/* begin::Tiles Widget 1 */}
        <div key={el.i} className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`} style={{ width: "100%", height: "100%" }}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder text-muted">GenerateAPIKey</div>
                <div className="font-size-sm text-muted mt-2"></div>
              </div>
            </div>
          </div>
          {/* end::Header */}

          <div style={{ margin: "30px" }}>

            <Form>

              <Form.Group className="mb-3">
                <Button variant="primary" size='lg' onClick={() => { generateKey() }}>key 생성+저장</Button>
              </Form.Group>

              <Form.Group className="mb-3" style={{ marginTop: "40px" }}>
                <Form.Label># API key</Form.Label>
              </Form.Group>

              <Form.Group className="mb-3" style={{ backgroundColor: "skyblue" }}>
                {(apiKey == "") ? <Form.Label>새로운 키를 생성해주세요.</Form.Label> : <Form.Label>{apiKey}</Form.Label>}

              </Form.Group>





              <Form.Group id="formMemo" className="mb-3" style={{ marginTop: "40px" }}>
                <Form.Label># server addr (체크안하지만 나중에 쓸예정입니다.)</Form.Label>

                <Form.Control as="textarea" rows={6} placeholder="서버주소 입력해주세요" onChange={changeServer} value={server || ''} />
              </Form.Group>


            </Form>

          </div>

          {/* end::Body */}
        </div>
        {/* end::Tiles Widget 1 */}
      </FullScreen>
    </>
  );
}


