
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';

export function ManageTagGroup_Add(props) {
    const { parentId, parentCallback, action, selectIndex, menu, tagGroupList, ...rest } = props


    const [title, setTitle] = useState("");
    const [comment, setComment] = useState("");


    useEffect(() => {

        if (rest.show == true) {

            console.log("menu > ")
            console.log(menu)

            console.log("tagGroupList > ")
            console.log(tagGroupList)

            console.log("action > ")
            console.log(action)
            console.log(selectIndex)


            if (action == "edit") {
                console.log(tagGroupList[selectIndex].title)
                setTitle(tagGroupList[selectIndex].title)
                setComment(tagGroupList[selectIndex].comment)

            } else if (action == "add") {
                setTitle("")
                setComment("")
            }


            // console.log("allowMenuList > ")
            // console.log(allowMenuList)
        }

    }, [rest.show]);

    const btnStyle = {
        margin: "2px",
    }




    const changeTitle = (e) => {
        // console.log(">> changeTitle")
        // console.log(e.target.value)
        setTitle(e.target.value)
    }


    const changeComment = (e) => {
        // console.log(">> changeTitle")
        // console.log(e.target.value)
        setComment(e.target.value)
    }



    if (selectIndex == -1) return <></>



    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(action == "add") ? <>태그그룹 관리 추가</> : <>태그그룹 관리 수정</>}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>태그그룹 명*</Form.Label>

                        {(action == "add") ?
                            <Form.Control placeholder="태그그룹을 입력해주세요" onChange={changeTitle} /> :
                            <Form.Control placeholder="태그그룹을 입력해주세요" onChange={changeTitle} value={title} />}


                    </Form.Group>

                    <Form.Group id="formUserId">
                        <Form.Label>비고</Form.Label>
                        {(action == "add") ?
                            <Form.Control placeholder="태그그룹을 입력해주세요" onChange={changeComment} /> :
                            <Form.Control placeholder="태그그룹을 입력해주세요" onChange={changeComment} value={comment} />}
                    </Form.Group>

                    {(action == "add") ?
                        <Button style={btnStyle} size='sm' onClick={() => {
                            let payload = {
                                "action": "add",
                                "workspaceIdx": menu[0].workspaceIdx,
                                "title": title,
                                "comment": comment

                            }
                            parentCallback(payload)
                            rest.onHide()
                        }}> 추가</Button>
                        :
                        <Button style={btnStyle} size='sm' onClick={() => {
                            let payload = {
                                "action": "edit",
                                "_id": tagGroupList[selectIndex]._id,
                                "workspaceIdx": tagGroupList[selectIndex].workspaceIdx,
                                "title": title,
                                "comment": comment

                            }

                            parentCallback(payload)
                            rest.onHide()
                        }}> 저장</Button>
                    }


                </Form>



            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}