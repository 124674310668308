/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';

import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { useHtmlClassService } from "../../../templates/type1/layout";


import { actionTypes } from "../../../../redux/actionType";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

import { BasicHeatMapSetting } from "./BasicHeatMapSetting";

import axios from 'axios';


export function BasicHeatMap({el, states, className }) {
  let id = "component" + el.i


  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      // colorsGrayGray500: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray500"
      // ),
      // colorsGrayGray200: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray200"
      // ),
      // colorsGrayGray300: objectPath.get(
      //   uiService.config,
      //   "js.colors.gray.gray300"
      // ),
      // colorsThemeBaseDanger: objectPath.get(
      //   uiService.config,
      //   "js.colors.theme.base.danger"
      // ),
      // fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  let chartId = 'chart' + id

  
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <BasicHeatMapSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });
  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    embed: function () {

      // src 외부 접근 가능 URL 을 적어주세요
      let copyStr =
        `<iframe width="1280" height="720" 
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

      console.log(copyStr)
      alert("check console.")

      return true
    }
    ,
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };





  useEffect(() => {
    const element = document.getElementById(chartId);
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);



  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">BasicHeatMap</div>
              <div className="font-size-sm text-muted mt-2">for TEST</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">



            {/* Chart */}
            <div
              id={chartId}

              style={{ height: "100%" }}
            ></div>







          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

      
    </>
  );
}

function generateData(){

  let arr = []
  for(let i = 0; i< 18 ; i++){
    arr.push( Math.floor(Math.random()*100) )
  }

  return arr 
}


function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [{
      name: 'Metric1',
      data: generateData()
    },
    {
      name: 'Metric2',
      data: generateData()
    },
    {
      name: 'Metric3',
      data: generateData()
    },
    {
      name: 'Metric4',
      data: generateData()
    },
    {
      name: 'Metric5',
      data: generateData()
    },
    {
      name: 'Metric6',
      data: generateData()
    },
    {
      name: 'Metric7',
      data: generateData()
    },
    {
      name: 'Metric8',
      data: generateData()
    },
    {
      name: 'Metric9',
      data: generateData()
    }
    ],


    chart: {
      height: "100%",
      type: 'heatmap',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#008FFB"],
    // title: {
    //   text: 'HeatMap Chart (Single color)'
    // }




  };
  return options;
}
