
import React from "react";

import { Button, Form, Col, Row, Tabs, Tab, Dropdown, Select, ListGroup } from 'react-bootstrap';
import Builder from "./Builder";
import shortid from "shortid";
import { LAYOUT, ROW, COLUMN, ELEMENT, SIDEBAR_LAYOUT, SIDEBAR_ROW, SIDEBAR_COLUMN, SIDEBAR_ELEMENT } from "./constants";


import { UnitTable } from "./Element/UnitTable";
import { UnitGraph } from "./Element/UnitGraph";
import { UnitDatePicker } from "./Element/UnitDatePicker";


// =====================================================
// [S] STRUCTURE
// =====================================================
export class UILayout {

  tab = (el, data) => {
    // console.log("> tab")
    // console.log(data)

    return (

      <Tabs defaultActiveKey="tab0" id="web-builder-setting">

        {data.layout?.map((row, index) => {
          return (
            <Tab key={Math.random()} eventKey={"tab"+index} title={row.title}>
              <Builder el={el} initLayout={[row]} />
            </Tab>
          );
        })}

      </Tabs>

    );
  }

  date = (data) => {
    return (

      <UnitDatePicker >
        {data.id}
      </UnitDatePicker>

    );
  }
  
  label = (data) => {
    return (

      <Form.Label >
        {data.id}
      </Form.Label>

    );
  }

  input = (data) => {
    return (

      <Form.Row key={Math.random()}  >
        <Col sm={3}>
          <Form.Label>{data.title}</Form.Label>
        </Col>
        <Col sm={9}>
          <Form.Control placeholder={data.id} />
        </Col>
      </Form.Row>


    );
  }


  textarea = (data) => {
    return (

      <Form.Control
        as="textarea"
        placeholder={data.id}

      />


    );
  }

  checkbox = (data) => {
    return (

      <div key={Math.random()}>
        <Form.Check
          inline
          label="1"
          name="group1"
          type="checkbox"
          id={`inline-checkbox-1`}
        />
        <Form.Check
          inline
          label="2"
          name="group1"
          type="checkbox"
          id={`inline-checkbox-2`}
        />
        <Form.Check
          inline
          disabled
          label="3 (disabled)"
          type="checkbox"
          id={`inline-checkbox-3`}
        />
      </div>


    );
  }

  radio = (data) => {
    return (

      <div key={Math.random()}>
        <Form.Check
          inline
          label="1"
          name="group1"
          type="radio"
          id={`inline-radio-1`}
        />
        <Form.Check
          inline
          label="2"
          name="group1"
          type="radio"
          id={`inline-radio-2`}
        />
        <Form.Check
          inline
          disabled
          label="3 (disabled)"
          type="radio"
          id={`inline-radio-3`}
        />
      </div>


    );
  }

  select = (data) => {
    return (

      <Form.Control as="select">
        <option value="DICTUM">Dictamen</option>
        <option value="CONSTANCY">Constancia</option>
        <option value="COMPLEMENT">Complemento</option>
      </Form.Control>

    );
  }

  image = (data) => {
    return (
      <>
        <img style={data?.style} src="https://mblogthumb-phinf.pstatic.net/MjAyMTA2MTRfMjQ2/MDAxNjIzNjQ3OTc5MTQ5.mNXdRy2VeQwLpWCzcdXpHLXGuRTlNYzuJ5gEfxf1csYg.BKW8NwwrKy2IJTSC-qtjZOkdUKT_ullNnMHqXQAYBA0g.PNG.page1corp/%EC%99%80%EC%9D%B4%EC%A6%88%EC%86%94%EB%A3%A8%EC%85%98_%EB%A1%9C%EA%B3%A0%EB%A7%88%ED%81%AC(RGB).png?type=w800"></img>

      </>
    );
  }

  graph = (data) => {
    return (
      <>
        <UnitGraph></UnitGraph>
      </>
    );
  }
  dropDown = (data) => {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Dropdown Button
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }


  input2 = (data) => {
    return (
      <Form.Row key={Math.random()} >
        <Form.Group as={Col} id="formPassword">
          <Form.Label>{data.title}*</Form.Label>
          <Form.Control placeholder="비밀번호를 입력해주세요" wsdata="wise" />
        </Form.Group>

        <Form.Group as={Col} id="formPasswordConfirm">
          <Form.Label>비밀번호 확인*</Form.Label>
          <Form.Control placeholder="동일한 비밀번호를 입력해주세요" />
        </Form.Group>
      </Form.Row>
    );
  }

  table = (data) => {
    return (

      <>
        UnitTable
      </>
     
    );
  }

  button = (data) => {
    return (
      <Button key={Math.random()} variant={data.style?.variant}>
        button
      </Button>
      // <Button key={Math.random()} variant="primary" onClick={((e) => submit(e))} >
      //   {data.title}
      // </Button>
    );
  }
}





// =====================================================
// [S] DataLayout
// =====================================================
export class DataLayout {


  
  row = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
        row
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="row DataLayout" />
        </Col>
      </Form.Group>
    );
  }


  
  column = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
        column
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="column DataLayout" />
        </Col>
        
      </Form.Group>
    );
  }


  tab = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>

      </Form.Group>
    );
  }

  date = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>

      </Form.Group>
    );
  }


  label = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          placeholder
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          key
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="DB에 저장할때 key 값" />
        </Col>
      </Form.Group>
    );
  }


  input = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          placeholder
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          key
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="DB에 저장할때 key 값" />
        </Col>
      </Form.Group>
    );
  }



  textarea = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          placeholder
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          key
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="DB에 저장할때 key 값" />
        </Col>
      </Form.Group>
    );
  }

  checkbox = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          placeholder
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          key
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="DB에 저장할때 key 값" />
        </Col>
      </Form.Group>
    );
  }

  radio = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          placeholder
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          key
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="DB에 저장할때 key 값" />
        </Col>
      </Form.Group>
    );
  }

  select = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          placeholder
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          key
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="DB에 저장할때 key 값" />
        </Col>
      </Form.Group>
    );
  }

  image = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          이미지 URL
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="https://www." />
        </Col>

        <Button key={Math.random()} variant="primary" >
          불러오기
        </Button>
      </Form.Group>
    );
  }

  graph = (data) => {
    return (
      <>미지원</>
    );
  }
  dropDown = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={12}>
          DropDown JSON
        </Form.Label>

      </Form.Group>
    );
  }


  input2 = (data) => {
    return (
      <>미지원</>
    );
  }

  table = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          노출 태그 리스트
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="id,password,password" />
        </Col>
        <Form.Label column sm={4}>
          페이징 유무
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="true" />
        </Col>
        <Form.Label column sm={4}>
          체크박스 유무
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="true" />
        </Col>
        <Form.Label column sm={4}>
          수정셀 유무
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="true" />
        </Col>
        <Form.Label column sm={4}>
          추가삭제 유무
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="true" />
        </Col>
        <Form.Label column sm={4}>
          한페이지 노출 갯수
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="10" />
        </Col>
      </Form.Group>
    );
  }

  button = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          title
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="title" />
        </Col>
        <Form.Label column sm={4}>
          color
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
        <Form.Label column sm={4}>
          size
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="md" />
        </Col>
        <Form.Label column sm={4}>
          action
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="click시 호출되는 함수" />
        </Col>
      </Form.Group>
    );
  }
}


// =====================================================
// [S] StyleLayout
// =====================================================
export class StyleLayout {

  row = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
        row
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="row StyleLayout" />
        </Col>
      </Form.Group>
    );
  }


  
  column = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
        column
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="column StyleLayout" />
        </Col>
        
      </Form.Group>
    );
  }


  tab = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }

  date = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }


  label = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }


  input = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }


  textarea = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }


  checkbox = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }

  radio = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }

  select = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
      </Form.Group>
    );
  }

  image = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }
  graph = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }
  dropDown = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }


  input2 = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }

  table = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }

  button = (data) => {
    return (
      <Form.Group as={Row} key={Math.random()} id="formPlaintextEmail">
        <Form.Label column sm={4}>
          padding
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 1 1 0" />
        </Col>
        <Form.Label column sm={4}>
          margin
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="0 0 0 1" />
        </Col>
        <Form.Label column sm={4}>
          width
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          height
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="30px" />
        </Col>
        <Form.Label column sm={4}>
          bgColor
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="input" placeholder="#000000" />
        </Col>
      </Form.Group>
    );
  }
}

