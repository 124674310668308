/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { actionTypes } from "../../../../redux/actionType";
import { BannerSetting } from "./BannerSetting";


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { property } from "lodash";
import { useResizeDetector } from 'react-resize-detector';

export function Banner({ el, onUpdateAutoHeight, states, className }) {

  const backgroundImageUrl = toAbsoluteUrl("/media/stock-600x600/image-16.jpg");

  let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  const [image, setImage] = useState('');
  const [link, setLink] = useState('');
  const [value, setValue] = useState('BANNER');

  const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)
  const dispatch = useDispatch();



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {


    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <BannerSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
          link={link}
          image={image}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  // =====================================================
  // [S] Resize Event
  // =====================================================
  const onResize = useCallback((width, height) => {
    // console.log("height update:" + width + "/" + height)
    // console.log(el.isAutoHeight)

    if (el.isAutoHeight == true) {
      onUpdateAutoHeight(el.i, height)
    }
  }, []);

  const { ref, width, height } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    // refreshMode: 'debounce',
    // refreshRate: 300,
    onResize
  });

  // =====================================================


  useEffect(() => {

    console.log(">> useEffect")


  }, []);



  useEffect(() => {
    setLink(el.data.link)
    setImage(el.data.image)
  }, [states, el]);

  const selectSetting = (data) => {




    let image = JSON.parse(data).image
    let link = JSON.parse(data).link

    setLink(link)
    setImage(image)

    let elCopy = JSON.parse(JSON.stringify(el))

    elCopy.data.link = link
    elCopy.data.image = image

    dispatch({ type: actionTypes.UpdateComponent, payload: { pageIdx: currentPage.slice("page".length), component: elCopy } })
  };


  let modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ 'align': [] }, { 'color': [] }, { 'background': [] }],

    ],
  }



  if (states == undefined) return (<></>)

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <FullScreen handle={fullScreenHandle}>
        <div
          className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`}
          style={{
            backgroundImage: `url("${image}")`
          }}
        >
          <div className="card-body d-flex flex-column align-items-start justify-content-start">
            <div className="p-1 flex-grow-1">
              <h3 className="text-white font-weight-bolder line-height-lg mb-5">
                Create Reports
                <br />
                With App
              </h3>
            </div>

            <a
              href={link}
              className="btn btn-link btn-link-warning font-weight-bold"
              target={(link == '#') ? "" : "_blank"}
            >
              GO LINK
              <span className="svg-icon-lg svg-icon-warning">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-right.svg"
                  )}
                />
              </span>
            </a>
          </div>

        </div>

      </FullScreen>
    </div>
  );
}
