import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { GoArrowDown } from "react-icons/go";
import { CgSmileNone } from "react-icons/cg";
import * as _ from "lodash";
import {
  Col,
  Container,
  Row,
  Form
} from "react-bootstrap";
import './style/kici.scss';
import './style/mobile.scss';
import Loading from "../../func-component/Loading";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import Utils from "../edit/WebBuilder/Utils";


/**
 * 시위 및 집회 컴포넌트
 * @param className 부모 컴포넌트 props
 * @returns 해당 컴포넌트 (KICI_Assemble_Board)
 */

export function KICI_Assemble_Board({ className }) {


  /**
   * currentWorkspace - 현재 사용하는 workspace redux
   * recentCrawling - Calender 선택 날짜를 담은 redux
   * 
   * today_value - Calender 선택한 날짜를 담은 상태 값
   * assembly_dom - api로 받아온 데이터를 html로 변환해 담은 상태 값
   * assembly_state - 해당 날짜에 시위 및 집회 데이터가 있는지 체크하기 위한 api로 받아온 상태 값
   */
  const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);
  const { recentCrawling } = useSelector((state) => state.chart);

  const [today_value, set_today_value] = useState(new Date());
  const [assembly_dom, set_assembly_dom] = useState(null);
  const [assembly_state, set_assembly_state] = useState(null);
  const [police_arr, set_police_arr] = useState(['경기남부경찰청', '인천경찰청', '서울경찰청']);
  const [assembly_loading, set_assembly_loading] = useState(false);


  // =====================================================
  // [S] 반응형을 위한 변수 할당
  // =====================================================
  const lanking_component = {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`
  };


  // =====================================================
  // [E] 시위 및 집회 데이터 가져오기
  // =====================================================
  const search_assembly = () => {

    set_assembly_loading(true);

    const assemble_date_moment = moment(new Date(today_value)).format('YYYY.MM.DD');


    /**
     * 시위 및 집회 데이터를 가져오기 위한 쿼리문
     * 
     * 순서대로
     * 
     * 인천경찰청
     * 
     * 서울경찰청
     * 
     * 경기남부경찰청
     * 
     * 해당 날짜에 시위 및 집회 데이터가 있는지 체크를 위한 쿼리문
     */
    let payload = {
      queries: [
        {
          key: "incheon",
          query: `db.collection('CrawlerSchedulData').aggregate([
          { $match: { "assemblyDate": "${assemble_date_moment.replace(/\.0/g, '.')}", "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", "Source" : "인천경찰청" } },
          { $limit : 1 }
          ]).toArray()`
        },
        {
          key: "seoul",
          query: `db.collection('CrawlerSchedulData').aggregate([
          { $match: { "assemblyDate": "${assemble_date_moment.replace(/\./g, "")}", "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", "Source" : "서울경찰청" } },
          { $limit : 1 }
          ]).toArray()`
        },
        {
          key: "gyungi_south",
          query: `db.collection('CrawlerSchedulData').aggregate([
          { $match: { "assemblyDate": "${assemble_date_moment}", "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}", "Source" : "경기남부경찰청" } },
          { $limit : 1 }
          ]).toArray()`
        },
        {
          key: "assembly_data",
          query: `db.collection('CrawlerSchedulData').aggregate([
          { $match: { "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}" } },
          { $project : { "assemblyDate" : 1 } }
          ]).toArray()`
        }
      ]
    }

    Utils.getQueryResult(payload).then((result) => {

      const { incheon, seoul, gyungi_south, assembly_data } = result;

      set_assembly_state(assembly_data);

      if ((incheon.length === 0) && (seoul.length === 0) && (gyungi_south.length === 0)) {

        /**
         * 인천 & 서울 & 경기남부 경찰청 모두 시위 데이터가 없을 경우
         */
        const assembly_dom = police_arr.map((data, index) => {
          return (
            <>
              <div className="assembly_item">
                <h3>{data}</h3>
                <div className="none_wrap">
                  <span>시위 및 집회가 없습니다.</span>
                  <CgSmileNone />
                </div>
              </div>
              {(index + 1) !== police_arr.length && <hr />}
            </>
          )
        });

        set_assembly_dom(assembly_dom);

        set_assembly_loading(false);

      } else {

        /**
         * 인천 & 서울 & 경기남부경찰청 중 시위 데이터가 하나라도 있을 경우
         */
        const result_array = [
          ...incheon,
          ...seoul,
          ...gyungi_south
        ];

        const assembly_dom = result_array.map((data, index) => {

          /**
           * data.files 없다면 시위 및 집회 데이터가 없다는 html 생성합니다.
           */
          if (!data.files) {
            return (
              <>
                <div className="assembly_item">
                  <h3>{data.Source}</h3>
                  <div className="none_wrap">
                    <span>선택한 날짜에 시위 데이터가 없습니다.</span>
                    <CgSmileNone />
                  </div>
                </div>
                {(index + 1) !== result_array.length && <hr />}
              </>
            )
          } else {

            /**
             * 시위 데이터가 존재하므로 다운로드 html을 생성합니다.
             */
            return (
              <>
                <div className="assembly_item">
                  <h3>{data.Source}</h3>
                  <a href={`${data.files}`} target="_blank" className="down_wrap">
                    <span>다운로드하기</span>
                    <GoArrowDown />
                  </a>
                </div>
                {(index + 1) !== result_array.length && <hr />}
              </>
            )
          }
        })

        set_assembly_dom(assembly_dom)

        set_assembly_loading(false);
      }
    });
  }


  /**
   * 해당 컴포넌트 (KICI_Assemble_Board) 
   * 
   * 마운트 및 today_value 상태 값 변경시
   * 
   * search_assembly() 함수 실행 Effect
   */
  useEffect(() => {
    search_assembly();
  }, [today_value]);

  return (
    <>
      <Container fluid className={"p-0"}>
        <div className="col-xs-12 col-md-12 mb-5 dashboard_title">
          <div className={`card card-custom ${className}`}>
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                시위 및 집회
              </h3>
            </div>
          </div>
        </div>
        <Row key={Math.random()} >
          <Col className="mb-5 col-xxl-8" xs={12} sm={12} md={8} lg={7} xl={8}>
            <div className={`card card-custom ${className} kici_custom_card`} >
              <div className="card-header border-0 kici_custom_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  날짜 선택하기
                </h3>
              </div>
              <div className="card-body pt-2 kici_custom_card_body assemble_admin">
                {assembly_state && <Calendar onChange={set_today_value}
                  formatDay={(locale, date) => moment(date).format("DD")}
                  value={today_value}
                  tileContent={({ date }) => {
                    /**
                     * 해당 날짜에 시위 및 데이터 존재시 dot html 생성
                     */
                    let html = [];
                    const tile_date = moment(date).format("YYYY.MM.DD");

                    if (assembly_state.find((data) => (data.assemblyDate === tile_date) || (data.assemblyDate === tile_date.replace(/\.0/g, '.')) || (data.assemblyDate === tile_date.replace(/\./g, "")))) {
                      html.push(<div className="dot"></div>);
                    }

                    return (
                      <>
                        <div className="flex justify-center items-center absoluteDiv">
                          {html}
                        </div>
                      </>
                    );
                  }}
                />
                }
              </div>
            </div>
          </Col>
          <Col className="mb-5 col-xxl-3" xs={12} sm={12} md={4} lg={5} xl={4} style={lanking_component}>
            <div className="kici_assembly_wrap">
              <div className="side_update_date">
                <div>
                  <h3>선택한 날짜</h3>
                </div>
                <div className="check_data_wrap">
                  <span className='left_date'>{recentCrawling ? moment(new Date(today_value)).format('YYYY.MM.DD') : <Loading color={'white'} size={48} />}</span>
                </div>
              </div>
              <div className="assembly_wrap">
                {!assembly_loading ? assembly_dom : <div className="assemble_loading"><Loading color={"#483d8b"} size={60} /></div>}
              </div>
            </div>
          </Col>
        </Row>
      </Container >
    </>
  );
}
