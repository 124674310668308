/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';


import { MapViewerSetting } from "./MapViewerSetting";
import GoogleMapReact from 'google-map-react';


export function MapViewer({ el, states, className }) {

  let id = "component" + el.i

  


  
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <MapViewerSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================







  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };



  const AnyReactComponent = ({ text }) => <div style={{ width: '200px' }}>{text}</div>;

  let defaultProps = {
    center: {
      lat: 37.29989549284594,
      lng: 128.2501529402187
    },
    zoom: 8
  };
 

  const Marker = props => {
    return <div><img src="/media/custom/marker.png"></img></div>
  }


  return (
    <>



      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">MapViewer</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}



        <div style={{
          width: "100%",
          height: "100%"
        }}>


          <div style={{

            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}>

            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyAI02y3ze8JrW9LhKlfvXHR4SHBMSVm1cA" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}

            >
              <AnyReactComponent
                lat={37.29989549284594}
                lng={128.2501529402187}
                text="Wise"
              />


<Marker lat={37.398331466432424} lng={126.96321666958669} />
            </GoogleMapReact>

          </div>


        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}


    </>
  );
}


