import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { Button, Form, InputGroup, Col, Row, Overlay, Tooltip, OverlayTrigger, ButtonToolbar } from "react-bootstrap";

//TODO 
//1.REMOTE 에서 preload.js 를 받을수있는 부분 구현 해야함 ( https://developpaper.com/electronic-webview-load-remote-preload-method/ )
//2.LOCAL or REOMTE 접속 경로에 따라 자동으로 webview > src 와 script > src 경로 설정하게 하기
export const CrawlingTool = ({ el, states, className }, props) => {

  let id = "component" + el.i

  const fs = require('fs');
  var path = require('path');
  var appDir = path.dirname(require.main.filename);
  // console.log(appDir)
  // console.log(process.env.PRELOAD_PATH)

  const [webview, setWebview] = useState(null);
  const [url, setUrl] = useState('https://news.daum.net/');
  let inputText = ""

  // =====================================================
  // [S] Netar webview setting
  // =====================================================
  useEffect(() => {
    setWebview(document.querySelector('#netarWebview'))
  }, []);


  useEffect(() => {

    if (webview != null) {




      webview.addEventListener('dom-ready', () => {
        console.log('>> dom-ready')


        //[S] Send data REACT to ELECTRON..
        //webview.send('ipc-main', 'pong')
        //[E] Send data REACT to ELECTRON..

        // CSS
        webview.insertCSS(`

        .ws_select_target{
          background-color: #A9DFBF !important;
          outline: 2px solid #A9DFBF !important;
          outline-offset: -2px !important;
          // box-shadow: 7px 0px 0px 0px #52BE80 inset !important;
        }

        .ws_select_target_sibling{
          background-color: #F9E79F !important;
          outline: 2px solid #F9E79F !important;
          outline-offset: -2px !important;
          // box-shadow: 10px 0px 0px 0px #F4D03F inset !important;
        }

        
        #mytabs {
          width: 600px;
          height: 300px;
        }

        .tinytabs .tabs {
          margin-left: 0px;
          display: flex;
          white-space: nowrap;
          overflow-x: auto;
          
        }

        .tinytabs .tabs::-webkit-scrollbar {
          height: 7px;
        }
        .tinytabs .tabs::-webkit-scrollbar-thumb {
          background-color: #2f3542;
        }
        .tinytabs .tabs::-webkit-scrollbar-track {
          background-color: grey;
        }




        .tinytabs .tabs .tab .close {
          padding-left: 5px;
        }
        .tinytabs .tabs .tab {
          margin: 0 3px 0 0;
          background: #e1e1e1;
          display: block;
          padding: 6px 15px;
          text-decoration: none;
          color: #666;
          font-weight: bold;
          border-radius: 3px 3px 0 0;

        }


        .tinytabs .section {
          background: #f1f1f1;
          overflow: auto;
          padding: 15px;
          clear: both;
          color: #f00;
          border-radius: 3px;
          height:300px;
        }

        .tinytabs .section::-webkit-scrollbar {
          width: 7px;
        }
        .tinytabs .section::-webkit-scrollbar-thumb {
          background-color: #2f3542;
        }
        .tinytabs .section::-webkit-scrollbar-track {
          background-color: grey;
        }




        .tinytabs .tab.sel {
          background: #f1f1f1;
          color: #333;
          text-shadow: none;
        }



        `)
        // webview.insertCSS(fs.readFileSync('style.css', 'utf8'));
        // webview.insertCSS(`html,body{ background-color: #FF0000 !important;}`)


        // JAVASCRIPT
        webview.executeJavaScript(`var a = 'foo'; Promise.resolve(a);`)
          .then((a) => { console.log(a) })
          .catch((error) => console.log(error));
      })


      //[S] Receive data from webview
      webview.addEventListener('ipc-message', (e) => {
        if (e.channel === 'data') {
          console.log('>> ipc-message')
          console.log(e.args[0])
        } else if (e.channel == 'printLog') {
          console.log(e.args[0])
        }
      })

    }
    //[E] Receive data from webview

  }, [webview]);
  // =====================================================
  // [E] webview setting
  // =====================================================




  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  const goUrl = () => {
    if (url.length < 2) {
      console.log('too short!!')
      return
    }

    webview.loadURL(url)
    // dispatch({ type: actionTypes.InsertMenu, payload: { menu: modl[currentMenu], menuName: url, menuRole: addRole } })
  }

  const handleChangeAddName = (e) => {

    console.log(e.target.value)
    setUrl(e.target.value)
  }

  function addDiv_iFrame() {
    webview.send('ipc-from-host', 'pong')
  }


  function addDiv_Host() {
    const div = document.createElement('div');
    const top = Math.floor(Math.random() * 1024);
    const left = Math.floor(Math.random() * 1024);

    div.className = 'insertedHost';
    div.innerHTML = `
    <div style="position: fixed; z-index: 99999; top: ${top}px; left: ${left}px; width:50px;height:50px;background-color:green;"></div>`;

    document.querySelector('body').appendChild(div);
  }



  return (<>

    <Form.Row>
      <Form.Group as={Col} id="formGridEmail">
        <Form.Label>1.메뉴를 추가합니다.</Form.Label><br />
        <input id="targetUrl" type="text" placeholder="Enter name" value={url} onChange={handleChangeAddName} style={{ height: "37px", width: "100%" }} />
      </Form.Group>

      <Form.Group as={Col} id="formGridState">
        <Form.Label>&nbsp;</Form.Label>
        <Form.Group>
          <Button type="button" onClick={goUrl} variant="primary">이동하기</Button>&nbsp;
          <Button type="button" onClick={addDiv_Host} variant="primary">HOST</Button>&nbsp;
          <Button type="button" onClick={addDiv_iFrame} variant="primary">iFrame</Button>&nbsp;
        </Form.Group>
      </Form.Group>
    </Form.Row>
    <webview id="netarWebview" src={url} preload={`file://D:/Projects/Netar_electron/public/preloadNetar.js`} />

    <Helmet>

     


      {/* 1. 로컬에 빼도 된다 */}
      {/* <script src="webviewNetar.js" type="text/javascript" /> */}
      {/* 2. 서버에 빼도 된다 */}
      {/* <script src="http://169.56.88.179/build/webviewNetar.js" type="text/javascript" /> */}
    </Helmet>


  </>);
};
