import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";

export function KeywordTagWork(props) {
  const {
    keyword,
    parentId,
    parentCallback,
    checkEmailCallback,
    isCheckEmail,
    action,
    selectIndex,
    menu,
    memberList,
    groupAll,
    siteList,
    ...rest
  } = props;

  const [email, setEmail] = useState("");
  const [site_name, set_site_name] = useState("");
  const [name, setName] = useState("");
  const [site_url, set_site_url] = useState("");
  const [site_keyword, set_site_keyword] = useState([]);
  const [choice_category, set_choice_category] = useState("");
  const [group_keyword, set_group_keyword] = useState(null);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [phone, setPhone] = useState("");
  const [memo, setMemo] = useState("");
  const [existCheckEmail, setExistCheckEmail] = useState([]);
  const [existCheckEmailText, setExistCheckEmailText] = useState("new");

  useEffect(() => {
    if (rest.show == true) {
      // console.log("menu > ")
      // console.log(menu)
      // console.log("memberList > ")
      // console.log(memberList)
      // console.log("action > ")
      // console.log(action)
      // console.log("selectIndex > ")
      // console.log(selectIndex)

      console.log("isCheckEmail >");
      console.log(isCheckEmail);

      setExistCheckEmail(isCheckEmail);

      if (action == "edit") {
        set_site_url(siteList[selectIndex].selector);
        set_site_name(siteList[selectIndex].title);
        set_site_keyword(siteList[selectIndex].filter[0].rule);
      } else if (action == "add") {
        set_site_url("");
        set_site_name("");
        set_site_keyword([]);

        setExistCheckEmail([]);
      }

      // console.log("allowMenuList > ")
      // console.log(allowMenuList)
    }
  }, [rest.show]);

  useEffect(() => {
    setExistCheckEmail(isCheckEmail);
  }, [isCheckEmail]);

  useEffect(() => {
    console.log("existCheckEmail>>");
    console.log(existCheckEmail);

    if (existCheckEmail != null) {
      if (existCheckEmail.length == 0) {
        setExistCheckEmailText("");
      } else {
        if (action == "add") {
          setExistCheckEmailText("[존재하는 아이디 입니다. 초대하시겠습니까?]");
        }
      }
    }
  }, [existCheckEmail]);

  useEffect(() => {
    if (keyword.length !== 0) {
      const site_group_keyword = keyword.reduce((acc, curr) => {
        const { site } = curr;
        if (acc[site]) acc[site].push(curr);
        else acc[site] = [curr];
        return acc;
      }, {});
      console.log(site_group_keyword["다음 IT 뉴스"]);
      set_group_keyword(site_group_keyword);
    }
  }, [props.keyword]);

  const btnStyle = {
    margin: "2px",
  };

  const change_site_name = (e) => {
    const { value } = e.target;
    // let payload = {
    //     "email": e.target.value
    // }
    //checkEmailCallback(payload)
    //setEmail(e.target.value)
    set_site_name(value);
  };

  const change_site_url = (e) => {
    const { value } = e.target;

    setName(value);
  };

  // =====================================================
  // [S] category state change
  // =====================================================
  const change_category = (e) => {
    const { value } = e.target;

    set_choice_category(value);
  };

  // const getCheckState = (id) => {
  //     // console.log("^^ getCheckState")
  //     let isCheck = false

  //     for (let mList of allowMenuList) {
  //         if (mList.idx == id) {
  //             isCheck = true
  //         }
  //     }

  //     return isCheck
  // }

  let SelectorUI =
    groupAll != null
      ? groupAll.map((_group, index) => (
          <option key={Math.random()} value={_group._id || ""}>
            {_group.title}
          </option>
        ))
      : null;

  if (selectIndex == -1) return <></>;

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="keyword_tag_header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          사이트 별 키워드 확인하기
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {group_keyword &&
          Object.keys(group_keyword).map((outData, index) => (
            <>
              <h4 className="tag_keyword_title" key={index}>
                {outData}
              </h4>
              <div className="tag_keyword_wrap">
                {group_keyword[outData].map((inData, index) => (
                  <p key={index}>{inData.keyword}</p>
                ))}
              </div>
            </>
          ))}
      </Modal.Body>
      <Modal.Footer className="keyword_tag_footer">
        <Button onClick={rest.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
