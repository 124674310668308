/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, Component, useState } from "react";
import ReactDOM from 'react-dom';

import axios from "axios";


import { SelectSettings } from "./SelectSettings";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";


const csv = require('csvtojson')
var parseString = require('xml2js').parseString;

export function Tutorial4({ el, states, className }) {

  let id = "component" + el.i

  const [content, setContent] = React.useState('');
  const csv = require('csvtojson')


  
  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <SlidingPane
          className="sliding-pane-contents"
          overlayClassName="sliding-pane-overlay"
          from="left"

          isOpen={modalShow}
          onRequestClose={() => {
            setModalShow(false);
          }}
        >
          <SelectSettings
            parentCallback={selectSetting}
            content={content}
          />

        </SlidingPane>,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================







  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  // 서울시 코로나19 확진자 현황
  // http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/Corona19Status/1/5/


  // 서울특별시 코로나19 백신 예방접종 현황
  // http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/tvCorona19VaccinestatNew/1/5/


  // 서울시 문화행사정보 장소명 검색
  // http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/SearchConcertPlaceService/1/50


  // 서울시 고용지표 통계
  // http://data.seoul.go.kr/dataList/59/S/2/datasetView.do


  // const url = 'http://openapi.seoul.go.kr:8088/7141655550686172383450666f6361/xml/SearchConcertPlaceService/1/50'



  useEffect(() => {
    const url = "http://localhost:3000/assets/report.csv"


    axios({
      method: 'get',
      url: url,
      responseType: 'stream'
    })
      .then(function (response) {
        csv().fromString(response.data)
          .then((result) => {
            // console.log(result) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
            setContent(JSON.stringify(result))
          })

      });



  }, []);


  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">Tutorial4</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>

        <div className="card-body d-flex flex-column px-0">

          <div className="flex-grow-1 card-spacer-x">

            Tutorial4<br /><br />

            draw [ graph / table / other ] <br />
            by selectSetting() resp <br />


            {/* {content} */}

          </div>

        </div>

      </div>



    </>

  );
}


