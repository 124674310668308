/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from 'react-dom';
import { Button, Table, Pagination, Form, Col, Row } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ManageKeyword_Setting } from "./ManageKeyword_Setting";
import { ManageKeyword_Add } from "./ManageKeyword_Add";
import { ManageKeyword_Delete } from "./ManageKeyword_Delete";
import { ManageStopWord_Add } from "./ManageStopWord_Add";

import { useResizeDetector } from 'react-resize-detector';
import { useScreenClass, Visible } from 'react-grid-system';

import { actionTypes } from "../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Header } from "../widgets/hyorim/Header"
import Utils from "../widgets/edit/WebBuilder/Utils";

import * as requestFromServer from "../../../../src/app/_redux/modlCrud";
import axios from "axios";

const moment = require('moment');

export function ManageKeyword({ el, onUpdateAutoHeight, states, className }) {



  let id = "component" + el.i

  const fullScreenHandle = useFullScreenHandle();

  const dispatch = useDispatch();

  let filter_put;

  // =====================================================
  // [S] param
  // =====================================================

  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [delModalShow, setDelModalShow] = useState(false);
  const [stop_word_modal, set_stop_word_modal] = useState(false);
  const [action, setAction] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [checkedList, setCheckedList] = useState([]);
  const [site_list, set_site_list] = useState([]);
  const [changed, set_changed] = useState(null);
  const [keyword_arr, set_keyword_arr] = useState([]);
  const [pn_state, set_pn_state] = useState(null);


  const modl = useSelector((state) => state.modl, shallowEqual)
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
  const currentMenu = useSelector((state) => state.modl.currentMenu, shallowEqual)
  const memberList = useSelector((state) => state.modl.memberList, shallowEqual)
  const groupAll = useSelector((state) => state.modl.groupAll, shallowEqual)
  const isCheckEmail = useSelector((state) => state.modl.isCheckEmail, shallowEqual)


  // SETTING PAGING
  let [totalItemCount, setTotalItemCount] = useState(0); // 현재 페이지
  let [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  let itemCountOfPage = 10 // 한 페이지의 아이템수
  let pageCountOfGroup = 10 // 페이지에 보여지는 카운트 수 < 1 2 3 4 5 > 

  let totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
  let currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
  let lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
  let pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
  let lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스
  let pageCountOfCurrentGroup = (currentGroup == lastGroup) ? pageCountOfLastGroup : pageCountOfGroup // 현제 페이지의 페이지수


  const formGroupStyle = {
    overflow: "hidden",
    marginBottom: "0",
    position: "relative",
    height: "78.375px"
  }

  const formCheckStyle = {
    float: "left",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  }

  const btnStyle = {
    margin: "20px 4.5px",
    float: "right"

  }

  const delBtnStyle = {
    margin: "20px 0 20px 4.5px",
    float: "right"
  }

  // const btnStyle = {
  //   margin: "8px 2px",
  //   float: "right"
  // }




  const thStyle = {
    padding: "15px 22px 15px 22px",
    //borderWidth: "1px",
    border: "0 !important",
    // borderStyle: "solid",
    borderColor: "#ebebeb",
    // backgroundColor: "#fafafb",
    backgroundColor: "#ecf0f9",
    textAlign: "center"
  }

  const kicithStyle = {
    padding: "15px 22px 15px 22px",
    // borderWidth: "1px",
    border: "0 !important",
    // borderStyle: "solid",
    borderColor: "#ebebeb",
    backgroundColor: "#cbd2e1",
    textAlign: "center"
  }

  const tdStyle = {
    padding: "30px 22px 30px 22px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ebebeb",
    textAlign: "center"
  }


  const screenClass = useScreenClass()

  // =====================================================
  // [S] Resize Event
  // =====================================================
  const onResize = useCallback((width, height) => {
    if (el.isAutoHeight == true) {
      onUpdateAutoHeight(el.i, height)
    }
  }, []);

  const { ref, width, height } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    // refreshMode: 'debounce',
    // refreshRate: 300,
    onResize
  });

  // =====================================================
  // [E] Resize Event
  // =====================================================



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================


  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <>
          <ManageKeyword_Setting
            show={modalShow}
            onHide={() => setModalShow(false)}
            parentCallback={settingCallback}
          />
          <ManageKeyword_Add
            show={addModalShow}
            action={action}
            selectIndex={selectIndex}
            menu={modl[currentMenu]}
            memberList={memberList}
            siteList={site_list}
            groupAll={groupAll}
            onHide={() => setAddModalShow(false)}
            isCheckEmail={isCheckEmail}
            checkEmailCallback={checkEmailCallback}
            parentCallback={addListCallback}
          />
          <ManageStopWord_Add
            show={stop_word_modal}
            action={action}
            selectIndex={selectIndex}
            menu={modl[currentMenu]}
            memberList={memberList}
            siteList={site_list}
            groupAll={groupAll}
            onHide={() => set_stop_word_modal(false)}
            isCheckEmail={isCheckEmail}
            checkEmailCallback={checkEmailCallback}
            parentCallback={addStopListCallback} />
        </>
        ,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================








  useEffect(() => {

    let payloadGroup = {
      "workspaceIdx": currentWorkspace.replace("workspace", "")
    }
    dispatch({ type: actionTypes.GetGroupAll, payload: payloadGroup })



    // let payload = {
    //   "workspaceIdx": currentWorkspace.replace("workspace", ""),
    //   "itemCountOfPage": 1,
    //   "currentPage": 1

    // }

    // dispatch({ type: actionTypes.GetWorkspaceMember, payload:payload })





    updatePaging()

  }, []);


  //==============================================================
  // [E] 컴포넌트 로딩시 사이트 데이터 가져오기
  //==============================================================

  useEffect(() => {
    const local_project = window.localStorage.getItem("projectName");

    set_pn_state(local_project);
  }, []);

  useEffect(() => {
    let payload = {
      queries: [
        {
          key: "keyword_list",
          query: `db.collection('CrawlerTemplate').find({ workspaceIdx : "626a0b71bbcad71d2c55e426"}).toArray()`
        },

      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      console.log(result);
      const { keyword_list } = { ...result };

      let i = 0;
      let j = 0;

      while (i < keyword_list.length) {
        if (keyword_list[i].filter) {
          let filter_data = keyword_list[i].filter[0].rule.replaceAll("⚡", "").split("data");
          filter_data = filter_data.map(data => data.trim());
          filter_data = filter_data[1].replaceAll('"', '');
          filter_data = filter_data.replaceAll(':', '');
          filter_data = filter_data.replaceAll('}', '');
          filter_data = filter_data.replaceAll(']', '');
          let filter_arr = filter_data.split(',');

          if (keyword_list[i].filter.length === 2) {
            let filter_data2 = keyword_list[i].filter[1].rule.replaceAll("⚡", "").split("data");
            filter_data2 = filter_data2.map(data => data.trim());
            filter_data2 = filter_data2[1].replaceAll('"', '');
            filter_data2 = filter_data2.replaceAll(':', '');
            filter_data2 = filter_data2.replaceAll('}', '');
            filter_data2 = filter_data2.replaceAll(']', '');
            let filter_arr2 = filter_data2.split(',');

            result.keyword_list[i].filter[1].rule = filter_arr2
          }

          result.keyword_list[i].filter[0].rule = filter_arr
        } else {

          /**
           * template에 filter 및 keyword가 없는 데이터 처리
           */

          keyword_list.splice(i, 1);
          i--;
        }
        i++;
      }

      set_site_list(result.keyword_list);
    });
  }, [changed]);




  useEffect(() => {
    // console.log("===>> totalItemCount")
    // console.log(totalItemCount)

    totalPageCount = Math.ceil(totalItemCount / itemCountOfPage) // 전체 페이지수
    currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
    lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
    pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
    lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스

    // console.log("currentPage:"+currentPage)
    // console.log("lastPage:"+lastPage)

    if (currentPage > lastPage && lastPage != 0) {
      selectPage(lastPage)
    }

  }, [totalItemCount]);


  useEffect(() => {

    let payload = {
      "action": "fetch",
      "workspaceIdx": currentWorkspace.replace("workspace", ""),
      "itemCountOfPage": itemCountOfPage,
      "currentPage": currentPage
    }

    dispatch({ type: actionTypes.GetWorkspaceMember, payload: payload })


  }, [currentPage]);




  // useEffect(() => {

  //   console.log("--- >>>> memberList change:")
  //   console.log(memberList)

  // }, [memberList]);



  // =====================================================
  // [S] modal
  // =====================================================





  const deleteItem = () => {
    // console.log("deleteItem >> ")
    // console.log(checkedList)

    let payload = {
      "action": "delete",
      "workspaceIdx": currentWorkspace.replace("workspace", ""),
      "_ids": checkedList,
      "itemCountOfPage": itemCountOfPage,
      "currentPage": currentPage
    }

    dispatch({ type: actionTypes.DeleteWorkspaceMember, payload: payload })
    updatePaging()
    // setDelModalShow(true)

  };


  const addItem = () => {
    setAction("add")
    setSelectIndex(0)
    setAddModalShow(true)
    // console.log('addItem click: ')

  };


  const editItem = (index) => {
    setAction("edit")
    setSelectIndex(index)
    setAddModalShow(true)
    // console.log('editItem click: ' + index)
  };

  const stop_word_item = (index) => {
    setAction("edit");
    setSelectIndex(index);
    set_stop_word_modal(true);
  }


  const settingCallback = (data) => {
    // console.log('callback: ' + data)

  };

  // template 키워드 변경 함수
  const addListCallback = (param) => {

    const { keyword, _id } = param;
    const filter_arr = keyword.filter(data => data !== "");

    let update = {
      $set: { "topic": "crawler-kici-node", "filter.0": { "key": "⚡", "rule": "[⚡includes:{\"source\":\"[⚡prop:title⚡]\",\"data\":\"" + filter_arr + "\"}⚡]" } }
    }

    // 실제 데이터 쿼리 용도
    let payload = {
      queries: [
        {
          key: "update",
          query: `db.collection('CrawlerTemplate').updateOne({ _id: new require('mongodb').ObjectID('${param._id}') },${JSON.stringify(update)})`
        }
      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      manager_api(_id, update.$set["filter.0"], "keyword");

      set_changed(new Date());
    });
  };


  // 키워드 조회 함수
  const manager_api = (param, mode, quarter) => {
    const url = `http://175.45.193.65:32572/crawlerManager/job/${param}`;
    axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8"
      }
    })
      .then((res) => {
        const { data } = res.data;
        update_filter_api(data, mode, quarter);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  // 키워드 변경 함수
  const update_filter_api = (param, word, quarter) => {

    quarter === "keyword" ? param.filter[0] = word : param.filter[1] = word;

    const url = `http://175.45.193.65:32572/crawlerManager/job`;

    axios.post(url, param)
      .then((res) => {
        alert('키워드 변경이 완료되었습니다.');
      })
      .catch((err) => {
        console.log('통신에러');
      })
  }


  /** template 불용어 변경 함수 */
  const addStopListCallback = (param) => {

    console.log(param._id);

    const { keyword, _id } = param;

    let update = {
      $set: { "filter.1": { "key": "⚡", "rule": "[⚡excludes:{\"source\":\"[⚡prop:title⚡]\",\"data\":\"" + keyword + "\"}⚡]" } }
    }

    // 실제 데이터 쿼리 용도
    let payload = {
      queries: [
        {
          key: "update",
          query: `db.collection('CrawlerTemplate').updateOne({ _id: new require('mongodb').ObjectID('${param._id}') },${JSON.stringify(update)})`
        }
      ]
    }

    Utils.getQueryResult(payload).then((result) => {
      manager_api(_id, update.$set["filter.1"], "stop_keyword");

      set_changed(new Date());
    });
  }

  const checkEmailCallback = (payload) => {
    //console.log('checkEmailCallback: ' + JSON.stringify(payload))
    if (payload.email == "") {
      // setIsCheckEmail([])
    } else {
      dispatch({ type: actionTypes.IsExistUser, payload: payload })

    }
  };






  // =====================================================
  // [E] modal
  // =====================================================



  const ConvMenuName = (allowMenu) => {

    let List = []

    try {
      for (let menu of allowMenu) {
        for (let compare of modl[currentMenu]) {
          for (let item of compare.items) {
            if (item.id == menu.idx) {
              List.push(item.title)
            }
          }
        }
      }

    } catch (e) {

    }
    return List.join(" / ")
  }


  const changeCheckAll = (e) => {


    console.log("checkAll")
    let isChecked = e.target.checked
    if (isChecked) {
      let _checkList = []
      for (let mGroup of memberList) {
        _checkList.push(mGroup._id)
      }
      setCheckedList(_checkList)
    } else {
      setCheckedList([])
    }
  }


  const getCheckState = (id) => {
    return checkedList.includes(id)
  }


  const setCheckState = (id, e) => {

    let isChecked = e.target.checked

    if (isChecked) {
      let joined = checkedList.concat(id)
      setCheckedList(joined)
    } else {
      setCheckedList(checkedList.filter(item => item !== id))
    }

  }



  // =====================================================
  // [S] page
  // =====================================================



  let items = [];

  const updatePaging = () => {

    let payload = {
      "workspaceIdx": currentWorkspace.replace("workspace", ""),
      "document": "LnkUserWorkspace"
    }


    requestFromServer
      .getDocumentCount(payload)
      .then(response => {

        // console.log("result=====")
        // console.log(response)

        setTotalItemCount(JSON.parse(response).count)


        return response
      })
      .catch(error => {
        error.clientMessage = "Can't delete modl";

      });


    // dispatch({ type: actionTypes.GetDocumentCount, payload: payload })

    // console.log(totalItemCount)

  };



  const selectPage = (idx) => {

    setCurrentPage(idx)

  };




  if (currentGroup != 1) {
    items.push(
      <Pagination.Prev key={Math.random()} onClick={() => { selectPage(currentPage - pageCountOfGroup) }}></Pagination.Prev>
    );
  }

  for (let number = 1; number <= pageCountOfCurrentGroup; number++) {

    let idx = (currentGroup - 1) * pageCountOfGroup + number
    items.push(
      <Pagination.Item key={Math.random()} active={idx === currentPage} onClick={() => { selectPage(idx) }}>
        {idx}
      </Pagination.Item>,
    );
  }

  if (currentGroup != lastGroup) {
    items.push(
      <Pagination.Next key={Math.random()} onClick={() => { selectPage((currentPage + pageCountOfGroup > lastPage) ? lastPage : currentPage + pageCountOfGroup) }}></Pagination.Next>
    );
  }



  // pageCountOfLastGroup

  // =====================================================
  // [E] table content
  // =====================================================






  if (site_list == undefined) return (<>데이터 없음</>)
  // let memberUI = (<></>)

  let site_ui = site_list.map((value, index) =>
    <tr key={Math.random()}>
      <td style={tdStyle}><Form.Check onChange={(e) => setCheckState(value._id, e)} checked={getCheckState(value._id)} /></td>
      <td style={tdStyle}>{site_list.length - index}</td>
      <td style={tdStyle}>
        <a href={value.selector}>{value.title}</a>
      </td>
      <td style={tdStyle}>{value.filter[0].rule.toString()}</td>
      <td style={tdStyle}>{value.filter.length === 2 ? value.filter[1].rule.toString() : ""}</td>


      {/* {(memberList[index]?.groupInfo.length == 0) ?
        <td style={tdStyle}>없음</td> :
        <td style={tdStyle}>{memberList[index].groupInfo[0]?.title}</td>
      }

      <td style={tdStyle}>{moment(value.userInfo[0]?.registDate).format('YYYY-MM-DD HH:mm:ss')}</td> */}
      <td style={tdStyle} onClick={() => {
        editItem(index)
      }}>[관리]</td>
      <td style={tdStyle} onClick={() => {
        stop_word_item(index)
      }}>[관리]</td>
    </tr>

  )






  return (





    // <div style={{ paddingBottom: "20px" }}>
    <div ref={ref}>
      {/* div 대신 <></> 를 쓰면 item size 만큼 배경을 채워진다. */}

      <FullScreen handle={fullScreenHandle}>

        <div style={{ backgroundColor: "transparent", boxShadow: "none" }} className={`card card-custom ${className}`}>


          <Header el={el}></Header>
          <div className="card-body shadow-xs d-flex flex-column p-0" style={{ backgroundColor: "white", borderRadius: "0.45rem", overflow: "auto" }} >
            <div className="flex-grow-1" style={{ padding: "0 20px 20px" }} >







              <Form.Group style={formGroupStyle} >
                <Form.Check style={formCheckStyle} onChange={changeCheckAll} label="전체 선택" />

                {/* <Button style={delBtnStyle} onClick={() => {
                  deleteItem()
                }}> 삭제</Button>

                <Button style={btnStyle} onClick={() => {
                  addItem()
                }}> 추가</Button> */}

              </Form.Group>


              <div style={{ overflow: "auto" }}>
                <Table hover style={{ minWidth: "1200px", marginBottom: "0" }} >
                  <thead>
                    <tr>
                      <th style={thStyle}>선택</th>
                      <th style={thStyle}>번호</th>
                      <th style={thStyle}>사이트명</th>
                      <th style={thStyle}>키워드</th>
                      <th style={thStyle}>불용어</th>
                      <th style={thStyle}>키워드 설정</th>
                      <th style={thStyle}>불용어 설정</th>
                    </tr>
                  </thead>
                  <tbody>
                    {site_ui}
                  </tbody>
                </Table>
              </div>



              <div style={{ float: "right" }}><Pagination>{items}</Pagination></div>


            </div>
          </div>

        </div>

      </FullScreen>


      {/* </div> */}
    </div>













  );
}


