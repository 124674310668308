/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom';


import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { useHtmlClassService } from "../../../templates/type1/layout";


import { actionTypes } from "../../../../redux/actionType";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Form, Col, ButtonToolbar, Modal } from 'react-bootstrap';
import { ControlPageSetting } from "./ControlPageSetting";





export function ControlPage({ el, states, className }) {
  let id = "component" + el.i


  const [textAreaStr, setTextAreaStr] = useState('');
  const dispatch = useDispatch();
  const modl = useSelector((state) => state.modl, shallowEqual)
  const currentPage = modl.currentPage

  useEffect(() => {

    setTextAreaStr(JSON.stringify( modl[currentPage],null,4))

  }, [modl[currentPage]]);




  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <ControlPageSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================





  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };





  const textareaChange = (e) => {

    // console.log(e.target.value)

    setTextAreaStr(e.target.value)

  }



  const savePage = () => {

    // console.log('savePage')

    dispatch({ type: actionTypes.UpdatePage, payload: { page: JSON.parse(textAreaStr) } })
  }



  
  if (document.getElementById("m" + id) == null) {
    let div = document.createElement('div');
    div.id = "m" + id;
    let parent = document.getElementById("contentModal")
    if (parent != null){
      parent.appendChild(div)
    }else{
      return (<></>)
    } 
  }



  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">CONTROL PAGE</div>
              <div className="font-size-sm text-muted mt-2">for 페이지 상태관리</div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x">

            <Form>

              <Form.Group id="exampleForm.ControlTextarea1">
                <Form.Label>현재 페이지 설정값</Form.Label>
                <Form.Control as="textarea" onChange={textareaChange} rows={15}  value={textAreaStr}  />
              </Form.Group>

              <Form.Group>
                <Button onClick={savePage} variant="primary">SAVE & PAGE LOAD</Button>
              </Form.Group>

            </Form>



          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

    </>
  );
}

