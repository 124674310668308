import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Utils from "../../edit/WebBuilder/Utils";
import { chartSlice, dateSlice } from '../../../../../app/_redux/modlSlice';
import * as _ from "lodash";
import { use_copy_func } from "./useClickHooks";
import { Sweetalert_class } from "../../../func-component/SweetAlert";

const RenderingHooks = () => {

    const dispatch = useDispatch();
    const moment = require('moment');
    let class_put;

    // =====================================================
    // [S] redux
    // =====================================================
    const currentWorkspace = useSelector(({ modl }) => modl.currentWorkspace, shallowEqual);


    // =====================================================
    // [S] state
    // =====================================================
    const [firstRun, setFirstRun] = useState(true);
    const [list, setList] = useState(["재난문자", "다음 IT뉴스", "DC LOL갤", "DC 주갤", "DC 인방갤", "DC 국내야구갤"]); // "네이버 IT뉴스"
    const [colorList, setColorList] = useState(["#f44336", "#c2b010", "#00bcd4", "#388e3c", "#ffc107", "#5470C6"]); //"#1565c0",
    const [week_date, set_week_date] = useState(moment(new Date()).subtract(9, 'day').format('YYYY.MM.DD'));
    const [last_date, set_last_date] = useState(moment(new Date()).subtract(-1, 'day').format('YYYY.MM.DD'));
    const [today_date, set_today_date] = useState(moment(new Date()).format('YYYY.MM.DD'));
    const [today_next, set_today_next] = useState(moment(new Date()).subtract(-1, 'day').format('YYYY.MM.DD'));
    const [today_collect_date, set_today_collect_date] = useState(0);
    const [today_lanking, set_today_lanking] = useState([]);


    // =====================================================
    // [S] redux action
    // =====================================================
    const { changePrevSlice } = dateSlice.actions;
    const { changeNextSlice } = dateSlice.actions;
    const { changePrevMomentSlice } = dateSlice.actions;
    const { changeNextMomentSlice } = dateSlice.actions;
    const { 
        changeChartData, 
        changeChartList, 
        changerecentCrawling, 
        changeKeywordName, 
        changeKeywordCount 
    } = chartSlice.actions;
    const { keywordGroupIdx } = useSelector((state) => state.auth.user);
    const { role } = useSelector((state) => state.modl.workspaces[0]);


    useEffect(()=>{

        if(firstRun){

            setFirstRun(false)

            reset_date()

            updateUI()

            var refresh_interval = setInterval(() => { reset_date(); updateUI();  }, 10 * 60 * 1000)
        }

        return()=>{
            // =====================================================
            // component unmount시 redux 리셋
            // =====================================================
            dispatch(changerecentCrawling(null));
            dispatch(changeKeywordName(null));
            dispatch(changeKeywordCount(null));
            dispatch(changeChartData({}));
            dispatch(changeChartList(null));

            clearInterval(refresh_interval);
            setFirstRun(false);
        }
    }, []);


    // =====================================================
    // 렌더링시 날짜 초기화
    // =====================================================
    const reset_date = () => {
        const now = new Date();
        const now2 = new Date();

        dispatch(changePrevSlice(new Date(now.setDate(now.getDate() - 9))));
        dispatch(changePrevMomentSlice(week_date));
        dispatch(changeNextSlice(new Date(now2.setDate(now2.getDate()))));
        dispatch(changeNextMomentSlice(last_date));
    }


    // =====================================================
    // 렌더링 호출
    // =====================================================
    const updateUI = async() => {

        let payload = {
            queries: [
              {
                key: "chartList",
                query: `db.collection('CrawlerData').aggregate([
                { $match: {"date":{$exists:true},"date": {$gte: "${week_date}", $lte: "${last_date}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}"} },
                { $sort : {"registDate":-1} },
                { $group: {_id:{ date: {$substr : ["$date", 0,10]} , source:"$source" } , 
                  count: { $sum: 1 }
                }},
                ]).toArray()`
              },
      
      
              {
                key: "recentCrawling",
                query: `db.collection('CrawlerLog').aggregate([
                { $match: {"workspaceIdx" : "${currentWorkspace.replace("workspace", "")}"} },
                { $sort : {"registDate":-1} },
                { $limit : 1 },
      
              ]).toArray()`
              },
      
              {
                key: "dataList",
                query: `db.collection('CrawlerData').aggregate([
                    { $match: {"date":{$exists:true},"date": {$gte: "${today_date}", $lte: "${today_next}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}" } },
                    { $lookup : {
                      from : "KiciPushHistory",
                      let : { "title" : "$title" },
                      pipeline : [
                          { $match : { ${ role === "admin" ? "" : `"keywordGroupIdx" : "${keywordGroupIdx}"`} } },
                          { $match : { $expr : { $eq : [ "$title", "$$title" ] } } },
                          { $project : { "more" : 1, "_id" : 0 } }
                      ],
                      as : "moreInfo"
                    } },
                    { $sort : {"date": -1} },
                    { $unwind : { "path" : "$moreInfo", preserveNullAndEmptyArrays: false } }
                  ]).toArray()`
              },
      
              {
                key: "keywordList",
                query: `db.collection('CrawlerData').aggregate([
                { $match: {"date":{$exists:true},"date": {$gte: "${week_date}", $lte: "${last_date}" }, "workspaceIdx" : "${currentWorkspace.replace("workspace", "")}"} },
                { $sort : {"date": -1} },
                { $project : { '_id' : 0, 'filter' : 1 } }
              ]).toArray()`
              }
            ]
        }

        const run_query = await Utils.getQueryResult(payload);

        console.log("[s]run_query > ")
        console.log(run_query)
        console.log(today_date)
        console.log(today_next)
        console.log(currentWorkspace.replace("workspace", ""))
        console.log(role)
        console.log(keywordGroupIdx)
        console.log("[e]run_query > ")
        
        if(Object.keys(run_query).length > 0){
            chartlist_setting(run_query);
            crawling_settting(run_query.recentCrawling[0]); 
            today_collect_func(run_query.chartList); 
            keyword_chart_rendering(run_query.keywordList); 
        } else {
            class_put = new Sweetalert_class();
            class_put.designated_alert();
        }
    } 


    // =====================================================
    // 크롤링 날짜 state 변경
    // =====================================================
    const crawling_settting = (param) => {
        let updated = moment(param.registDate.toString()).format('YY-MM-DD HH:mm:ss')
        updated = updated.split("-").join(".")
        dispatch(changerecentCrawling(updated));
    }



    // =====================================================
    // 오늘 수집된 데이터 함수
    // =====================================================
    const today_collect_func = (param) => {

        let today_collect = 0;
        let i = 0;

        /** 오늘 수집된 데이터 건수 */
        const filter_date = param.filter((data)=>{
            return data._id.date === today_date
        })

        while(i<filter_date.length){
            today_collect += Number(filter_date[i].count);
            i++;
        }

        set_today_collect_date(today_collect);
    }


    // =====================================================
    // 키워드 집계 함수
    // =====================================================
    const keyword_chart_rendering = (param) => {
        
        /** 키워드 차트 내림차순 */
        let word_arr = [];
        let total_count_mapping = [];
        let news_count_mapping = [];
        let news_word = [];
        let news_preg = ["재난문자", "다음 IT뉴스"];
        

        param.map((data) => {
            const temp_keyword = data.filter.split(",").toString();
                
            word_arr.push(temp_keyword);
            
            if(news_preg.includes(data.source)){
                news_word.push(temp_keyword);
            }
        });


        const total_result_arr = word_arr.reduce((accu, curr)=>{
            accu.set(curr, (accu.get(curr)||0) + 1);
            return accu;
        }, new Map());


        const news_result_arr = news_word.reduce((accu, curr)=>{
            accu.set(curr, (accu.get(curr)||0) + 1);
            return accu;
        }, new Map());


        Array.from(total_result_arr).map(([key,value]) => {
            let temp_data = { name : `${key}`, count : value }
            total_count_mapping.push(temp_data)
        })

        
        Array.from(news_result_arr).map(([key,value]) => {
            let temp_data = { name : `${key}`, count : value }
            news_count_mapping.push(temp_data)
        })


        /**
         * 키워드 집계 10순위까지 출력
         */
        const arr_by_count = total_count_mapping.sort((a,b)=> (b.count - a.count));

        const chart_data_arr = arr_by_count.slice(0, 10).map((data)=> data.name);

        const chart_count_arr = arr_by_count.slice(0, 10).map((data)=> data.count);

        dispatch(changeKeywordName(chart_data_arr.reverse()));

        dispatch(changeKeywordCount(chart_count_arr.reverse()));
    }


    // =====================================================
    // 차트 리스트 state 변경
    // =====================================================
    const chartlist_setting = (param) => {

        let _graphData = {}
        let _date = []

        for (let list of param.chartList) {
            if (!_date.includes(list._id.date)) {
              _date.push(list._id.date)
            }
        }

        for (let _list of list) {
            _graphData[_list] = []
        }
        
        _date.sort()

        for (let _d of _date) {

            for (let _s of list) {
                let _exist = false;

                for (let list of param.chartList) {
                    if (_d == list._id.date && _s == list._id.source) {
                        _exist = true
                        _graphData[_s].push(list.count)
                    }
                }
    
                if (!_exist) {
                    _graphData[_s].push(0)
                }
            }
        }


        // ================================================================
        // 그래프 그리기
        // ================================================================
        if (list.every((s) => { return Object.keys(_graphData).includes(s) })) {
            let y_dataArray = []
            list.forEach((item, i) => {
                y_dataArray.push({
                    type: 'line',
                    name: item,
                    value: _graphData[item],
                    color: colorList[i]
                })
            });

            dispatch(changeChartData({
                chartType: 'barLine',
                title: '',
                x_label: '',
                y_label: [''],
                legendShow: true,
                x_data: _date,
                layoutChange: {
                  left: '0px',
                  right: '0px',
                  top: '25%'
                },
                y_data: y_dataArray,
            }));
        } else {
            dispatch(changeChartData({
                chartType: 'barLine',
                title: '',
                x_label: '',
                y_label: [''],
                legendShow: true,
                x_data: [],
                layoutChange: {
                  left: '0px',
                  right: '0px'
                },
                y_data: [
                    {
                      type: 'bar',
                      name: "",
                      value: [],
                      color: ''
      
                    }
                ]
            }));
        }


        // ================================================================
        // 데이터 타임라인 그리기
        // ================================================================
        if (param.dataList.length == 0) {
            let _listData = 
            <div className="font-weight-bolder text-dark-25 font-size-lg" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                # 데이터가 없습니다...
            </div>

            dispatch(changeChartList(_listData));
        } else {

            const deduplication_arr = param.dataList.reduce((acc, current) => {
                const after_data = acc.find(data => (data.title === current.title) && (data.date === current.date));
                if(!after_data) {
                    return acc.concat([current]);
                } else {
                  return acc;
                }
            }, []);
            
            let _listData = 
                <div className="timeline timeline-6 mt-3">
                    {
                        deduplication_arr.map((data) => {
                            let color = colorList[0];
                            let iArr = [];
                            let moreArr = [];

                            list.map((l, i)=> {
                                if(l === data.source){
                                    color = colorList[i]
                                    iArr = data.filter.split(",")
                                    moreArr = Object.keys(data.moreInfo).length > 0? data.moreInfo.more.split(",") : []
                                }
                            });

                            let time = data.date.substr(11,5);
                            let title = data.title;

                            iArr.forEach(( out_item )=>{
                                let out_regex = new RegExp(out_item, "ig");
                                title = title.replaceAll(out_regex, `<span style="color:${color}">${out_item}</span>`);
                                
                                moreArr.forEach(function (in_item, i) {
                                    let in_regex = new RegExp(in_item, "ig");
                                    title = title.replaceAll(in_regex, `<span style="color:#6A5ACD">${in_item}</span>`)
                                })
                            });

                            return (
                                <div key={Math.random()} className="timeline-item align-items-start">
                                    <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                                        onClick={()=>{ use_copy_func(data.linkUrl) }}
                                    >
                                        {time}
                                    </div>
                                    <div className="timeline-badge">
                                        <i style={{ color: color }} className={`fa fa-genderless icon-xl`}></i>
                                    </div>
                                    <div className="timeline-content" onClick={() => window.open(`${data.linkUrl}`, "_blank")}>
                                        <div className="font-weight-bolder font-size-md text-dark-60 " >
                                            <span style={{ wordBreak: "break-word" }} className="p-3" dangerouslySetInnerHTML={{ __html: title }}></span>
                                        </div>
                                        <div className="font-size-md text-muted">
                                            <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: data.content }}></span>
                                            <div style={{ marginLeft: "9px" }} className={`font-size-xs `}>
                                                {(data.source == undefined) ? <></> : <span>{data.source}</span>}
                                                {(data.from == undefined) ? <></> : <span> {" | "} {data.from}</span>}
                                                {(data.author == undefined) ? <></> : <span> {" | "} {data.author}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            dispatch(changeChartList(_listData))
        }
    }

    return { today_collect_date, today_lanking }
}

export default RenderingHooks;