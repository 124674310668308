/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import '../../../../../../_partials/widgets/kici/style/kici.scss';

import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../../_helpers";
import axios from "axios";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { actionTypes } from "../../../../../../../redux/actionType";

// import store from "../../../../../redux/store";

import { Flipper, Flipped } from 'react-flip-toolkit';

import { Button } from 'react-bootstrap';
import { useScreenClass } from "react-grid-system";

export function BuildMenu() {

}

export function AsideMenuList({ layoutProps }) {


  const [menuPrivate, setMenuPrivate] = useState([]);
  const [menuWorkspace, setMenuWorkspace] = useState([]);
  const [menuPublic, setMenuPublic] = useState([]);

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const currentMenu = useSelector((state) => state.modl.currentMenu, shallowEqual);
  const menuSelector = useSelector((state) => state.modl[currentMenu], shallowEqual);



  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual);
  const _workspaces = useSelector((state) => state.modl.workspaces, shallowEqual);
  const [workspaces, setWorkspaces] = useState(_workspaces || []);



  

  const screenClass = useScreenClass()


  // console.log(">>> menuSelector")

  // console.log(menuSelector)


  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {

    // console.log(":: CONVERT DEPTH LIST TO NESTED MENU")

    setMenuPrivate([])
    setMenuWorkspace([])

    for (let i in menuSelector) {
      if (menuSelector[i].type == 'private') {
        //setMenuPrivate(menuSelector[i].menu)

        let convertedArr = []
        menuSelector[i].items.map((item, index) => {

          let itemCopy = JSON.parse(JSON.stringify(item))

          // console.log(itemCopy.title)
          // console.log(itemCopy.depth)
          if (itemCopy.visible) {
            if (itemCopy.depth == 0) {
              convertedArr.push(itemCopy)
            } else if (itemCopy.depth == 1) {

              let lastElement1 = convertedArr[convertedArr.length - 1]
              if (lastElement1.menu == undefined) {
                lastElement1.menu = []
              }
              lastElement1.menu.push(itemCopy)

            } else if (itemCopy.depth == 2) {

              let lastElement1 = convertedArr[convertedArr.length - 1]
              let lastElement2 = lastElement1.menu[lastElement1.menu.length - 1]
              if (lastElement2.menu == undefined) {
                lastElement2.menu = []
              }
              lastElement2.menu.push(itemCopy)

            }
          }

        })

        setMenuPrivate(convertedArr)

      } else if (menuSelector[i].type == 'workspace') {
        // setMenuWorkspace(menuSelector[i].menu)

        let convertedArr = []
        menuSelector[i].items.map((item, index) => {

          let itemCopy = JSON.parse(JSON.stringify(item))


          if (itemCopy.visible) {

            const checkMobile = ['xs'].includes(screenClass) && !(itemCopy.hasOwnProperty("showInMobile") ? itemCopy.showInMobile : true)

            if (itemCopy.depth == 0) {
              if (!checkMobile) {

                convertedArr.push(itemCopy)
              }
            } else if (itemCopy.depth == 1) {

              if (!checkMobile) {

                let lastElement1 = convertedArr[convertedArr.length - 1]
                if (lastElement1.menu == undefined) {
                  lastElement1.menu = []
                }
                lastElement1.menu.push(itemCopy)
              }

            } else if (itemCopy.depth == 2) {

              if (!checkMobile) {

                let lastElement1 = convertedArr[convertedArr.length - 1]
                let lastElement2 = lastElement1.menu[lastElement1.menu.length - 1]
                if (lastElement2.menu == undefined) {
                  lastElement2.menu = []
                }
                lastElement2.menu.push(itemCopy)
              }

            }

          }


        })

        setMenuWorkspace(convertedArr)


      }
    }
  }, [menuSelector]);






  if (currentMenu == null || menuSelector == null || currentWorkspace == null || workspaces == null) return (<></>)

  let currentRole = 'guest'
  workspaces.map(ws => {
    if ('workspace' + ws.workspaceIdx == currentWorkspace) {
      currentRole = ws.role
    } else {
      return (<>없음</>)
    }
  })


  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };


  let menuSetStyle = {
    fontSize: "0.9rem",
    color: "#1E1E1E" //mediumpurple
  }




  const back = () => {

    console.log("back")


    dispatch({ type: actionTypes.DeleteCurrentWorkspaceInfo, payload: { userIdx: user._id } })


  }

  // 원래의 레이 아웃 메뉴 를 로딩 하는 부분 .. 
  // DB MENU 를 사용 하지만 ITEMS 를 로딩 할려면
  // Converting 작업을 진행해야 한다.  

  return (
    <>


      {/* {console.log('> AsideMenuList')} */}

      {/* START : AUTO LAYOUT */}
      {/* START : AUTO LAYOUT */}

      {/* begin::AUTO Menu Nav */}


      <ul id="items" className={`menu-nav ${layoutProps.ulClasses} kici_menu`}>




        {/* START : AUTO LAYOUT depth:1*/}
        {/* START : AUTO LAYOUT depth:1*/}
        {/* {(menuWorkspace.length == 0) ? "" : (<li className="menu-section " key={Math.random()}><h4 style={menuSetStyle} className="menu-text"> | WORKSPACE </h4> <i className="menu-icon flaticon-more-v2"></i></li>)} */}
        {

          (menuWorkspace.length == 0) ? "" : (

            menuWorkspace.map((value1, index1) => {

              return (value1.type == 'devider') ? (
                <li style={{ display: "block", margin: "10px 26px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index1}>
                </li>)
                : (value1.type == 'section') ? (
                  <li className="menu-section" key={index1}>
                    <h4 className="menu-text">{value1.title}</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>)
                  : (<li
                    key={value1.id}
                    className={`menu-item menu-item-submenu  ${getMenuItemActive(
                      (value1.path !== undefined) ? value1.path : '/menu/' + value1.id,
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle={(value1.menu !== undefined) ? "hover" : ""}
                  >

                    <NavLink className={(value1.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                      to={(value1.path !== undefined) ? value1.path : '/menu/' + value1.id}>

                      {(value1.icon !== undefined && value1.icon !== "") ? (
                        <span className="svg-icon">
                          <img src={toAbsoluteUrl(value1.icon)} alt="아이콘"/>
                        </span>) : (<></>)}

                      <span className="menu-text">{value1.title}</span>
                      {(value1.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                    </NavLink>

                    {/* START : AUTO LAYOUT depth:2*/}
                    {/* START : AUTO LAYOUT depth:2*/}

                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li className="menu-item  menu-item-parent" aria-haspopup="true">
                          <span className="menu-link">
                            <span className="menu-text">{value1.title}</span>
                          </span>
                        </li>

                        {
                          (value1.menu !== undefined) && value1.menu.map((value2, index2) => {

                            return (value2.type == 'devider') ? (
                              <li style={{ display: "block", margin: "10px 40px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index2}>
                              </li>)
                              : (value2.type == 'section') ? (
                                <li className="menu-section " key={index2}>
                                  <h4 className="menu-text">{value2.title}</h4>
                                  <i className="menu-icon flaticon-more-v2"></i>
                                </li>)
                                : (<li
                                  key={value2.id}
                                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    (value2.path !== undefined) ? value2.path : '/menu/' + value2.id,
                                    true
                                  )}`}
                                  aria-haspopup="true"
                                  data-menu-toggle={(value2.menu !== undefined) ? "hover" : ""}
                                >
                                  <NavLink
                                    className={(value2.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                                    to={(value2.path !== undefined) ? value2.path : '/menu/' + value2.id}
                                  >

                                    {(value2.icon !== undefined && value2.icon !== "") ? (
                                      <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                                        <img src={toAbsoluteUrl(value2.icon)} alt="아이콘" />
                                      </span>) : (<></>)}


                                    <span className="menu-text">{value2.title}</span>
                                    {(value2.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                                  </NavLink>

                                  {/* START : AUTO LAYOUT depth:3*/}
                                  {/* START : AUTO LAYOUT depth:3*/}

                                  <div className="menu-submenu ">
                                    <i className="menu-arrow" />
                                    <ul className="menu-subnav">
                                      <li className="menu-item menu-item-parent" aria-haspopup="true">
                                        <span className="menu-link">
                                          <span className="menu-text">{value2.title}</span>
                                        </span>
                                      </li>
                                      {
                                        (value2.menu !== undefined) && value2.menu.map((value3, index3) => {

                                          return (value3.type == 'devider') ? (
                                            <li style={{ display: "block", margin: "10px 54px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index3}>
                                            </li>)
                                            : (value3.type == 'section') ? (
                                              <li className="menu-section " key={index3}>
                                                <h4 className="menu-text">{value3.title}</h4>
                                                <i className="menu-icon flaticon-more-v2"></i>
                                              </li>)
                                              : (<li
                                                key={value3.id}
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                  (value3.path !== undefined) ? value3.path : '/menu/' + value3.id,
                                                  true
                                                )}`}
                                                aria-haspopup="true"
                                                data-menu-toggle={(value3.menu !== undefined) ? "hover" : ""}
                                              >
                                                <NavLink
                                                  className={(value3.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                                                  to={(value3.path !== undefined) ? value3.path : '/menu/' + value3.id}
                                                >


                                                  {(value3.icon !== undefined && value3.icon !== "") ? (
                                                    <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                                                      <SVG src={toAbsoluteUrl(value3.icon)} />
                                                    </span>) : (<></>)}

                                                  <span className="menu-text">{value3.title}</span>
                                                  {(value3.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                                                </NavLink>


                                              </li>)

                                        })
                                      }

                                    </ul>
                                  </div>

                                  {/* END: AUTO LAYOUT depth:3*/}
                                  {/* END: AUTO LAYOUT depth:3*/}

                                </li>)
                          })
                        }

                      </ul>
                    </div>

                    {/* END: AUTO LAYOUT depth:2*/}
                    {/* END: AUTO LAYOUT depth:2*/}

                  </li>)
            })
          )
        }
        {/* END: AUTO LAYOUT depth:1*/}
        {/* END: AUTO LAYOUT depth:1*/}














        {/* START : AUTO LAYOUT depth:1*/}
        {/* START : AUTO LAYOUT depth:1*/}
        {/* {(menuPrivate.length == 0) ? "" : (<li className="menu-section " key={Math.random()}><h4 style={menuSetStyle} className="menu-text"> | PRIVATE </h4> <i className="menu-icon flaticon-more-v2"></i></li>)} */}
        {

          (menuPrivate.length == 0) ? "" : (

            menuPrivate.map((value1, index1) => {


              return (value1.type == 'devider') ? (
                <li style={{ display: "block", margin: "10px 26px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index1}>
                </li>)
                : (value1.type == 'section') ? (
                  <li className="menu-section" key={index1}>
                    <h4 className="menu-text">{value1.title}</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>)
                  : (<li
                    key={value1.id}
                    className={`menu-item menu-item-submenu  ${getMenuItemActive(
                      (value1.path !== undefined) ? value1.path : '/menu/' + value1.id,
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle={(value1.menu !== undefined) ? "hover" : ""}
                  >

                    <NavLink className={(value1.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                      to={(value1.path !== undefined) ? value1.path : '/menu/' + value1.id}>

                      {(value1.icon !== undefined && value1.icon !== "") ? (
                        <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                          <SVG src={toAbsoluteUrl(value1.icon)} />
                        </span>) : (<></>)}

                      <span className="menu-text">{value1.title}</span>
                      {(value1.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                    </NavLink>

                    {/* START : AUTO LAYOUT depth:2*/}
                    {/* START : AUTO LAYOUT depth:2*/}

                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li className="menu-item  menu-item-parent" aria-haspopup="true">
                          <span className="menu-link">
                            <span className="menu-text">{value1.title}</span>
                          </span>
                        </li>

                        {
                          (value1.menu !== undefined) && value1.menu.map((value2, index2) => {

                            return (value2.type == 'devider') ? (
                              <li style={{ display: "block", margin: "10px 40px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index2}>
                              </li>)
                              : (value2.type == 'section') ? (
                                <li className="menu-section " key={index2}>
                                  <h4 className="menu-text">{value2.title}</h4>
                                  <i className="menu-icon flaticon-more-v2"></i>
                                </li>)
                                : (<li
                                  key={value2.id}
                                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    (value2.path !== undefined) ? value2.path : '/menu/' + value2.id,
                                    true
                                  )}`}
                                  aria-haspopup="true"
                                  data-menu-toggle={(value2.menu !== undefined) ? "hover" : ""}
                                >
                                  <NavLink
                                    className={(value2.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                                    to={(value2.path !== undefined) ? value2.path : '/menu/' + value2.id}
                                  >

                                    {(value2.icon !== undefined && value2.icon !== "") ? (
                                      <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                                        <SVG src={toAbsoluteUrl(value2.icon)} />
                                      </span>) : (<></>)}


                                    <span className="menu-text">{value2.title}</span>
                                    {(value2.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                                  </NavLink>

                                  {/* START : AUTO LAYOUT depth:3*/}
                                  {/* START : AUTO LAYOUT depth:3*/}

                                  <div className="menu-submenu ">
                                    <i className="menu-arrow" />
                                    <ul className="menu-subnav">
                                      <li className="menu-item menu-item-parent" aria-haspopup="true">
                                        <span className="menu-link">
                                          <span className="menu-text">{value2.title}</span>
                                        </span>
                                      </li>
                                      {
                                        (value2.menu !== undefined) && value2.menu.map((value3, index3) => {

                                          return (value3.type == 'devider') ? (
                                            <li style={{ display: "block", margin: "10px 54px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index3}>
                                            </li>)
                                            : (value3.type == 'section') ? (
                                              <li className="menu-section " key={index3}>
                                                <h4 className="menu-text">{value3.title}</h4>
                                                <i className="menu-icon flaticon-more-v2"></i>
                                              </li>)
                                              : (<li
                                                key={value3.id}
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                  (value3.path !== undefined) ? value3.path : '/menu/' + value3.id,
                                                  true
                                                )}`}
                                                aria-haspopup="true"
                                                data-menu-toggle={(value3.menu !== undefined) ? "hover" : ""}
                                              >
                                                <NavLink
                                                  className={(value3.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                                                  to={(value3.path !== undefined) ? value3.path : '/menu/' + value3.id}
                                                >


                                                  {(value3.icon !== undefined && value3.icon !== "") ? (
                                                    <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                                                      <SVG src={toAbsoluteUrl(value3.icon)} />
                                                    </span>) : (<></>)}

                                                  <span className="menu-text">{value3.title}</span>
                                                  {(value3.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                                                </NavLink>


                                              </li>)

                                        })
                                      }

                                    </ul>
                                  </div>

                                  {/* END: AUTO LAYOUT depth:3*/}
                                  {/* END: AUTO LAYOUT depth:3*/}

                                </li>)
                          })
                        }

                      </ul>
                    </div>

                    {/* END: AUTO LAYOUT depth:2*/}
                    {/* END: AUTO LAYOUT depth:2*/}

                  </li>)
            })
          )
        }
        {/* END: AUTO LAYOUT depth:1*/}
        {/* END: AUTO LAYOUT depth:1*/}














        {/* START : AUTO LAYOUT depth:1*/}
        {/* START : AUTO LAYOUT depth:1*/}
        {(menuPublic.length == 0) ? "" : (<li className="menu-section " key={Math.random()}><h4 style={menuSetStyle} className="menu-text"> | PUBLIC </h4> <i className="menu-icon flaticon-more-v2"></i></li>)}
        {

          (menuPublic.length == 0) ? "" : (

            menuPublic.map((value1, index1) => {

              return (value1.type == 'devider') ? (
                <li style={{ display: "block", margin: "10px 26px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index1}>
                </li>)
                : (value1.type == 'section') ? (
                  <li className="menu-section " key={index1}>
                    <h4 className="menu-text">{value1.title}</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>)
                  : (<li
                    key={value1.id}
                    className={`menu-item menu-item-submenu  ${getMenuItemActive(
                      (value1.path !== undefined) ? value1.path : '/menu/' + value1.id,
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle={(value1.menu !== undefined) ? "hover" : ""}
                  >

                    <NavLink className={(value1.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                      to={(value1.path !== undefined) ? value1.path : '/menu/' + value1.id}>

                      {(value1.icon !== undefined && value1.icon !== "") ? (
                        <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                          <SVG src={toAbsoluteUrl(value1.icon)} />
                        </span>) : (<></>)}

                      <span className="menu-text">{value1.title}</span>
                      {(value1.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                    </NavLink>

                    {/* START : AUTO LAYOUT depth:2*/}
                    {/* START : AUTO LAYOUT depth:2*/}

                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li className="menu-item  menu-item-parent" aria-haspopup="true">
                          <span className="menu-link">
                            <span className="menu-text">{value1.title}</span>
                          </span>
                        </li>

                        {
                          (value1.menu !== undefined) && value1.menu.map((value2, index2) => {

                            return (value2.type == 'devider') ? (
                              <li style={{ display: "block", margin: "10px 40px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index2}>
                              </li>)
                              : (value2.type == 'section') ? (
                                <li className="menu-section " key={index2}>
                                  <h4 className="menu-text">{value2.title}</h4>
                                  <i className="menu-icon flaticon-more-v2"></i>
                                </li>)
                                : (<li
                                  key={value2.id}
                                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    (value2.path !== undefined) ? value2.path : '/menu/' + value2.id,
                                    true
                                  )}`}
                                  aria-haspopup="true"
                                  data-menu-toggle={(value2.menu !== undefined) ? "hover" : ""}
                                >
                                  <NavLink
                                    className={(value2.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                                    to={(value2.path !== undefined) ? value2.path : '/menu/' + value2.id}
                                  >

                                    {(value2.icon !== undefined && value2.icon !== "") ? (
                                      <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                                        <SVG src={toAbsoluteUrl(value2.icon)} />
                                      </span>) : (<></>)}


                                    <span className="menu-text">{value2.title}</span>
                                    {(value2.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                                  </NavLink>

                                  {/* START : AUTO LAYOUT depth:3*/}
                                  {/* START : AUTO LAYOUT depth:3*/}

                                  <div className="menu-submenu ">
                                    <i className="menu-arrow" />
                                    <ul className="menu-subnav">
                                      <li className="menu-item menu-item-parent" aria-haspopup="true">
                                        <span className="menu-link">
                                          <span className="menu-text">{value2.title}</span>
                                        </span>
                                      </li>
                                      {
                                        (value2.menu !== undefined) && value2.menu.map((value3, index3) => {

                                          return (value3.type == 'devider') ? (
                                            <li style={{ display: "block", margin: "10px 54px", borderBottom: "1px solid #ebebeb", width: "200px" }} key={index3}>
                                            </li>)
                                            : (value3.type == 'section') ? (
                                              <li className="menu-section " key={index3}>
                                                <h4 className="menu-text">{value3.title}</h4>
                                                <i className="menu-icon flaticon-more-v2"></i>
                                              </li>)
                                              : (<li
                                                key={value3.id}
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                  (value3.path !== undefined) ? value3.path : '/menu/' + value3.id,
                                                  true
                                                )}`}
                                                aria-haspopup="true"
                                                data-menu-toggle={(value3.menu !== undefined) ? "hover" : ""}
                                              >
                                                <NavLink
                                                  className={(value3.menu !== undefined) ? 'menu-link menu-toggle' : 'menu-link'}
                                                  to={(value3.path !== undefined) ? value3.path : '/menu/' + value3.id}
                                                >


                                                  {(value3.icon !== undefined && value3.icon !== "") ? (
                                                    <span className="svg-icon menu-icon" style={{ flex: "0 0 0px" }}>
                                                      <SVG src={toAbsoluteUrl(value3.icon)} />
                                                    </span>) : (<></>)}

                                                  <span className="menu-text">{value3.title}</span>
                                                  {(value3.menu !== undefined) ? <i className="menu-arrow" /> : <></>}
                                                </NavLink>


                                              </li>)

                                        })
                                      }

                                    </ul>
                                  </div>

                                  {/* END: AUTO LAYOUT depth:3*/}
                                  {/* END: AUTO LAYOUT depth:3*/}

                                </li>)
                          })
                        }

                      </ul>
                    </div>

                    {/* END: AUTO LAYOUT depth:2*/}
                    {/* END: AUTO LAYOUT depth:2*/}

                  </li>)
            })
          )
        }
        {/* END: AUTO LAYOUT depth:1*/}
        {/* END: AUTO LAYOUT depth:1*/}





        {/* 
        <li
          className={`menu-item ${getMenuItemActive("/netar")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/e-commerce/customers">
            <span className="svg-icon menu-icon" style={{flex: "0 0 0px"}}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Example</span>
          </NavLink>
        </li> */}




        {/* {(false) ? "" : (<h3 style={menuSetStyle}> | ALWAYS </h3>)} */}

        {/* <li className="menu-section " >
          <h4 className="menu-text">TOOLS</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon" style={{flex: "0 0 0px"}}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Layout Builder</span>
          </NavLink>
        </li> */}

        {/* 
        <li
          className={`menu-item ${getMenuItemActive("/netar")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/e-commerce/customers">
            <span className="svg-icon menu-icon" style={{flex: "0 0 0px"}}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Netar Editor</span>
          </NavLink>
        </li>
        */}

      </ul>

      {/* {
        // (user.grade == 'superadmin') ?
        (true) ?
          <div style={{ position: "sticky", bottom: "10px", padding: "20px", width: "100%" }}>
            <Button style={{ background: "white", width: "100%" }} variant="outline-light" onClick={() => { back() }}>◀ 현장 선택 </Button>
          </div> : <></>
      } */}


      {/* end::Menu Nav */}
    </>
  );
}
