
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal,Dropdown,DropdownButton,Row } from 'react-bootstrap';

export function ManageGroup_Delete(props) {
    const { parentId, parentCallback, ...rest } = props

    const btnStyle = {
        margin: "2px",
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                사용자 권한그룹 추가
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>권한 그룹명 *</Form.Label>
                        <Form.Control placeholder="권한 그룹명을 입력해주세요" />
                    </Form.Group>



                    <Form.Group as={Row} id="formPlaintextEmail">
                        <Form.Label column sm="6">
                            PAGE 1
                        </Form.Label>
                        <Col sm="6">
                            <DropdownButton id="dropdown-item-button" title="Dropdown button" size="sm">
                                <Dropdown.Item as="button">Action</Dropdown.Item>
                                <Dropdown.Item as="button">Another action</Dropdown.Item>
                                <Dropdown.Item as="button">Something else</Dropdown.Item>
                            </DropdownButton>
                        </Col>

                        <Form.Label column sm="6">
                            PAGE 2
                        </Form.Label>
                        <Col sm="6">
                            <DropdownButton id="dropdown-item-button" title="Dropdown button" size="sm">
                                <Dropdown.Item as="button">Action</Dropdown.Item>
                                <Dropdown.Item as="button">Another action</Dropdown.Item>
                                <Dropdown.Item as="button">Something else</Dropdown.Item>
                            </DropdownButton>
                        </Col>

                        <Form.Label column sm="6">
                            PAGE 3444444
                        </Form.Label>
                        <Col sm="6">
                            <DropdownButton id="dropdown-item-button" title="Dropdown button" size="sm">
                                <Dropdown.Item as="button">Action</Dropdown.Item>
                                <Dropdown.Item as="button">Another action</Dropdown.Item>
                                <Dropdown.Item as="button">Something else</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        추가
                    </Button>



                </Form>










            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}