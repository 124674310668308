import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./constants";
import DropZone from "./DropZone";
import Element from "./Element";

import { Button, Form, Col, Row, Tabs, Tab, Dropdown, ListGroup } from 'react-bootstrap';

import { actionTypes } from "../../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const style = { };
const Column = ({ el, data, elements, handleDrop, path }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const element = elements[data.id];

  
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderElement = (element, currentPath) => {

    // console.log(">>data> element")
    // console.log(data)

    return (
      <Element
        key={element.id}
        el={el} 
        data={element}
        elements={elements}
        path={currentPath}

      />
    );
  };



  const handleClick = (e, element, data) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    let payload = {
      "element": element,
      "data": data
    }

    dispatch({ type: actionTypes.UpdateSelectElement, payload: JSON.stringify(payload) })

  }


  const handlePress = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }




  return (
    <Col
      md={12}
      ref={ref}
      style={{ ...style, opacity }}
      className="base draggable column"
      onClick={(e) => handleClick(e, element, data)}
      onMouseDown={(e) => handlePress(e)}
    >

      {/* {data.id} */}
      {data.children.map((element, index) => {
        const currentPath = `${path}-${index}`;

        return (
         
          <React.Fragment key={Math.random()}>
             
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
                children: data.children,
                id: data.id,
                type: COLUMN
              }}
              onDrop={handleDrop}
            />
            {renderElement(element, currentPath)}
            
          </React.Fragment>
        );
      })}
      <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length,
          children: data.children,
          id: data.id,
          type: COLUMN
        }}
        onDrop={handleDrop}
        isLast
      />
    </Col>
  );
};
export default Column;
