/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toAbsoluteUrl } from "../../../_helpers";

/* PO_welcome.js 에만 적용되는 css: _pageone.scss */
import "../../../templates/type1/_assets/sass/components/_pageone.scss";

import { Pagination } from "react-bootstrap";

import { useResizeDetector } from 'react-resize-detector';

import * as XLSX from 'xlsx';
import Utils from "../edit/WebBuilder/Utils";

const moment = require('moment');

// Example
// https://www.ssg.com/item/itemView.ssg?itemId=1000197958414&siteNo=6001&salestrNo=2034&tlidSrchWd=cj%20%EB%8B%AD%EA%B0%80%EC%8A%B4%EC%82%B4&srchPgNo=1&src_area=ssglist
// https://front.homeplus.co.kr/item?itemNo=138368793&storeType=HYPER
// https://www.lotteon.com/p/product/LM8809029039181?areaCode=RCM2002_P03&sitmNo=LM8809029039181_001&dp_infw_cd=PDD

export function PO_MyPage({ el, onUpdateAutoHeight, states, className }) {

    const { v4: uuidv4 } = require('uuid');

    let id = "component" + el.i

    const fullScreenHandle = useFullScreenHandle();


    const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
    const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
    const userId = useSelector((state) => state.auth.user._id, shallowEqual)



    // =====================================================
    // [S] Resize Event
    // =====================================================
    const onResize = useCallback((width, height) => {
        if (el.isAutoHeight == true) {
            onUpdateAutoHeight(el.i, height)
        }
    }, []);

    const { ref, width, height } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        // refreshMode: 'debounce',
        // refreshRate: 300,
        onResize
    });

    // =====================================================
    // [E] Resize Event
    // =====================================================





    // =====================================================
    // [S] modal - setModalShow(true)
    // =====================================================

    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        let parent = document.getElementById("contentModal")
        if (parent != null) {
        } else {
        }
    });

    // =====================================================
    // [S] modal - setModalShow(true)
    // =====================================================

    const [logList, setLogList] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [sort, setSort] = useState("date")
    const [order, setOrder] = useState(-1)

    const [currentPage, setCurrentPage] = useState(1) // 현재 page
    const logPerPage = 10 // 한 페이지의 item 수
    const pageCountOfGroup = 10
    let totalPageCount = Math.ceil(totalCount / logPerPage) // 전체 page 수
    let currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
    let lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
    let pageCountOfLastGroup = (totalPageCount % pageCountOfGroup === 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
    let lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스
    let pageCountOfCurrentGroup = (currentGroup == lastGroup) ? pageCountOfLastGroup : pageCountOfGroup // 현제 페이지의 페이지수



    useEffect(() => {

        loadData()

    }, [])

    useEffect(() => {

        loadData()
    }, [currentPage, sort, order])

    useEffect(() => {
        totalPageCount = Math.ceil(totalCount / logPerPage) // 전체 페이지수
        currentGroup = Math.ceil(currentPage / pageCountOfGroup) // 현재 그룹 인덱스
        lastGroup = Math.ceil(totalPageCount / pageCountOfGroup) // 마지막 그룹 인덱스
        pageCountOfLastGroup = (totalPageCount % pageCountOfGroup == 0) ? pageCountOfGroup : totalPageCount % pageCountOfGroup // 마지막 그룹의 페이지 수
        lastPage = (lastGroup - 1) * pageCountOfGroup + pageCountOfLastGroup // 마지막 페이지 인덱스

        if (currentPage > lastPage && lastPage != 0) {
            setCurrentPage(lastPage)
        }
    }, [totalCount])


    const loadData = () => {

        let _workspaceIdx = currentWorkspace.split("workspace").join("")
        let _userIdx = userId

        let sortQuery = ""

        if (sort === "date") {
            sortQuery = `registDate : ${order}, title : 1`
        } else if (sort === "site") {
            sortQuery = `title : ${-1 * order}, registDate : -1`
        }

        let payload = {
            queries: [
                {
                    key: "totalCount",
                    query: `db.collection('CrawlerLog').find({"workspaceIdx":"${_workspaceIdx}", "userIdx":"${_userIdx}"}).count()`
                },
                {
                    key: "logList",
                    query: `db.collection('CrawlerLog').aggregate([
                        { $match: {"workspaceIdx":"${_workspaceIdx}", "userIdx":"${_userIdx}"} },

                        { $sort: { ${sortQuery} } },
                        { $skip: ${(currentPage - 1) * logPerPage}},
                        { $limit: ${logPerPage} },
                        {
                        $project: {
                            taskId: 1,
                            title: 1,
                            selector: 1,
                            registDate: 1,
                        }
                        }
                    ]).toArray()`
                }

            ]
        }

        Utils.getQueryResult(payload).then((result) => {

            setLogList(result.logList)
            setTotalCount(result.totalCount)
        });
    }

    const loadPaging = () => {


        const pageArr = []

        for (let number = 1; number <= pageCountOfCurrentGroup; number++) {

            let idx = (currentGroup - 1) * pageCountOfGroup + number
            pageArr.push(
                <Pagination.Item key={Math.random()} onClick={() => setCurrentPage(idx)} active={idx === currentPage}>
                    {idx}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.First onClick={() => setCurrentPage(1)} />
                <Pagination.Prev onClick={() => setCurrentPage((currentGroup !== 1) ? currentPage - pageCountOfGroup : 1)} />
                {pageArr}
                <Pagination.Next onClick={() => setCurrentPage((currentPage + pageCountOfGroup > lastPage) ? lastPage : currentPage + pageCountOfGroup)} />
                <Pagination.Last onClick={() => setCurrentPage(lastPage)} />
            </Pagination>
        )

    }

    const downloadExcel = (_taskId) => {
        let payload = {
            queries: [
                {
                    key: "dataList",
                    query: `db.collection('CrawlerData').aggregate([
                        { $match: {"taskId":"${_taskId}"} }
                    ]).toArray()`
                },

            ]
        }

        Utils.getQueryResult(payload).then((result) => {

            let excelData = result.dataList
            let resultData = excelData.map((item) => {
                delete item["_id"]
                delete item["url"]
                delete item["tplId"]
                delete item["taskId"]
                delete item["siteIdx"]
                delete item["userIdx"]
                delete item["workspaceIdx"]
                delete item["filter"]
                delete item["registDate"]
                return item
            })

            if (resultData.length == 0) {
                alert("데이터가 없습니다.")
            } else {
                const ws = XLSX.utils.json_to_sheet(resultData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                XLSX.writeFile(wb, "wisesolution.xlsx");

            }

        })
    }


    // =====================================================
    // [S] Call Back
    // =====================================================


    return (
        <div ref={ref}>
            <FullScreen handle={fullScreenHandle}>
                <div style={{ minWidth: "1080px", height: "100%" }}>

                    {/* Sub Header */}
                    <div className="subheader-background" />

                    <div className="subheader d-flex align-items-center">
                        {/* <img src={toAbsoluteUrl("/media/pageone/icon_pageone.png")} className="mypage-subheader-menu" /> */}

                        <div className="d-flex align-items-center">
                            <img src={toAbsoluteUrl("/media/pageone/icon_page.png")} className="subheader-icon-page" />
                            <span>데이터 수집 이력</span>
                        </div>
                    </div>
                    {/* Sub Header */}

                    {/* Body */}
                    <div className="mypage-body">

                        {/* Total */}
                        <div className="mypage-total d-flex align-items-center">
                            <span className="mypage-today">today</span>

                            <span className="mypage-remain-count">99</span>
                            <span>/100</span>
                        </div>
                        {/* Total */}

                        {/* Table */}
                        <div className="mypage-table">
                            <table>
                                <thead>
                                    <tr>
                                        <td width="14%">no.</td>
                                        <td width="18%">
                                            <span>site name</span>
                                            <a onClick={() => {
                                                if (sort !== "site") {
                                                    setSort("site")
                                                    setOrder(-1)
                                                } else {
                                                    setOrder(-1 * order)
                                                }
                                                setCurrentPage(1)
                                            }}>
                                                <img className="mypage-icon-sort" src={toAbsoluteUrl("/media/pageone/icon_sort.png")} />
                                            </a>
                                        </td>
                                        <td>URL</td>
                                        <td width="4%"></td>
                                        <td width="15%" align="center">file</td>
                                        <td width="23%" align="end">
                                            <span>date</span>
                                            <a onClick={() => {
                                                if (sort !== "date") {
                                                    setSort("date")
                                                    setOrder(-1)
                                                } else {
                                                    setOrder(-1 * order)
                                                }
                                                setCurrentPage(1)
                                            }}>
                                                <img className="mypage-icon-sort" src={toAbsoluteUrl("/media/pageone/icon_sort.png")} />
                                            </a>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        logList.length === 0
                                            ?
                                            <tr>
                                                <td colSpan={6}>데이터가 없습니다</td>
                                            </tr>
                                            :
                                            logList.map((item, idx) => (

                                                <tr key={Math.random()}>
                                                    <td>{totalCount - ((currentPage - 1) * logPerPage + idx)}</td>
                                                    <td><span>{item.title}</span></td>
                                                    <td>{item.selector}</td>
                                                    <td align="center">
                                                        <a href={item.selector} target="_blank" style={{ width: "22px", height: "22px" }}>
                                                            <img src={toAbsoluteUrl("/media/pageone/icon_link_gray.png")} />
                                                        </a>
                                                    </td>
                                                    <td align="center">
                                                        <a onClick={() => downloadExcel(item.taskId)}><span>download</span></a>
                                                    </td>
                                                    <td align="end">{moment(item.registDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        {/* Table */}

                        {/* Paging */}
                        <div className="mypage-paging d-flex justify-content-center align-items-center">

                            {
                                totalCount === 0
                                    ?
                                    <Pagination>
                                        <Pagination.Item>1</Pagination.Item>
                                    </Pagination>
                                    :

                                    loadPaging()
                            }
                        </div>
                        {/* Paging */}

                    </div>
                    {/* Body */}
                </div>
            </FullScreen>
        </div>
    );
}


