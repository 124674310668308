/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';

import CanvasDraw from "react-canvas-draw";
import { SketchCanvasSetting } from "./SketchCanvasSetting";

export function SketchCanvas({ el, states, className }) {

  let id = "component" + el.i



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <SketchCanvasSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  
  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================










  const selectSetting = (data) => {
    console.log('callback: '+ data)
  };



  const styles = {
    border: "0.0625rem solid #9c9c9c",
    borderRadius: "0.25rem",
  };





  return (
    <>



      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">SketchCanvas</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>
        {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body d-flex flex-column px-0">

            {/* begin::Items */}
            <div className="flex-grow-1 card-spacer-x">

            <CanvasDraw/>

            </div>

          </div>


          
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

      
    </>
  );
}


