import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/WiseLayout";
import objectPath from "object-path";
import { useScreenClass } from "react-grid-system";
import { toAbsoluteUrl } from "../../../../../_helpers";

export function Footer() {
  const thisYear = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const screenClass = useScreenClass()

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  const style = {
    borderTop: "1px solid #c6c6c6",
    margin: localStorage.projectName === "pageone" ? "0" : ['xs'].includes(screenClass) ? "0 15px" : "0 29.5px",
    padding: "30px 0",
    fontSize: "12px",
    color: "#666",
    backgroundColor: localStorage.projectName === "pageone" ? "#393c4a" : ""
  };

  return (
    // <div
    //   // style={{zIndex:"1000000",	position: "fixed", bottom: "0", left: "0", right: "0"}}
    //   className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
    //   id="kt_footer"
    // >
    //   <div
    //     className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
    //   >
    //     <div className="text-dark order-2 order-md-1">
    //       <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
    //       <a
    //         href="https://www.wisesolution.co.kr"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="text-dark-75 text-hover-primary"
    //       >
    //         wisesolution!
    //       </a>
    //     </div>
    //     <div className="nav nav-dark order-1 order-md-2">
    //       <a
    //         href="https://www.wisesolution.co.kr"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="nav-link pr-3 pl-0"
    //       >
    //         About
    //       </a>
    //       <a
    //         href="https://www.wisesolution.co.kr"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="nav-link px-3"
    //       >
    //         Team
    //       </a>
    //       <a
    //         href="https://www.wisesolution.co.kr"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="nav-link pl-3 pr-0"
    //       >
    //         Contact
    //       </a>
    //     </div>
    //   </div>
    // </div>

    <div
      className={`footer ${layoutProps.footerClasses}`}
      style={style}
      id="kt_footer"
    >
      <div className="text-center">


        {
          (localStorage.projectName == "hyorim") ?
            (<>
              <span>COPY RIGHT {thisYear.toString()}</span> &copy;{" "}
              <span>HYORIM INDUSTRIES INC. ALL RIGHT RESERVED</span>
            </>
          ) :
          (localStorage.projectName == "kici") ?
            (<>
              <span>COPY RIGHT {thisYear.toString()}</span> &copy;{" "}
              <span>wisesolution. ALL RIGHT RESERVED</span>

            </>
          ) :
          (localStorage.projectName == "pageone") ?
            (<>
              {/* <span>COPY RIGHT {thisYear.toString()}</span> &copy;{" "}
              <span>wisesolution. ALL RIGHT RESERVED</span> */}

              <div style={{ width: "1080px", margin: "0 auto", textAlign: "left", fontSize: "15px", color: "#6b7684" }}>
                <div style={{ marginBottom: "27px" }}>
                  <strong className="d-block" style={{ fontWeight: "bold", marginBottom: "12px" }}>고객센터</strong>
                  <p style={{ fontWeight: "500" }}>
                    전화: 02-402-2022 <br />
                    이메일: biz@wisesolution.co.kr
                  </p>
                </div>
                
                <div style={{ marginBottom: "37px" }}>
                  <strong className="d-block" style={{ fontWeight: "bold", marginBottom: "12px" }}>(주)와이즈솔루션</strong>
                  <p style={{ fontWeight: "500" }}>
                    사업자 등록번호: 416-86-02906 | 대표: 윤재덕 장성식 <br />
                    호스팅 서비스: 주식회사 와이즈솔루션 <br />
                    (14055) 경기도 안양시 동안구 시민대로 327번길 11-41 (관양동) 611호 <br />
                    Copyright 2022 &copy; wisesolution. All right reserved.
                  </p>
                </div>
                
                <div style={{ fontWeight: "500" }}>
                  <a href="https://www.wisesolution.co.kr/main/home3/privacy.html" target="_blank" style={{ color: "#6b7684" }}><u>개인정보 처리방침</u></a>
                </div>
                
                {/* <div>
                  <a><img style={{ width: "32px" }} src={toAbsoluteUrl("/media/logos/youtube.png")} /></a>
                </div> */}
              </div>
            </>
            ) :
            <></>
        }




        {/* <span>COPY RIGHT {thisYear.toString()}</span> &copy;{" "}
        <span>{objectPath.get(uiService.config, "footer.self.message")}</span> */}
      </div>
    </div>
  );
}
