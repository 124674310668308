/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';

import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { useHtmlClassService } from "../../../templates/type1/layout";


import { actionTypes } from "../../../../redux/actionType";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

import { SelectWorkspaceSetting } from "./SelectWorkspaceSetting";


export function SelectWorkspace({ el, states, className }) {
  let id = "component" + el.i


  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const modl = useSelector((state) => state.modl, shallowEqual);
  const workspaces = (modl == undefined || modl.workspaces == undefined) ? [] : modl.workspaces
  const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)


  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================
  
  const [modalShow, setModalShow] = React.useState(false);
  
  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <SelectWorkspaceSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });

  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================



  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    setting: function () {
      setModalShow(true)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);

  //==============================================================
  // [E] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================








  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  useEffect(() => {
    // 링크된 워크스페이스 로드
    // dispatch({ type: actionTypes.GetLnkWorkspace, payload: { userIdx: user._id } })
  }, []);


  // selector 에 selector["modl"] 넘어오는데 이름을 워크스테이션 이름을 로드할려면 ... 
  // 먼저 workspace 데이터를 selector["modl+workspaceIdx"] 에 입력해야 된다 .. 
  // 이건 다음에 구조 바꾸는 걸로 . 
  const changeWorkspace = (index) => {


    let payload = {
      "workspaceIdx": workspaces[index]["workspaceIdx"],
      "userIdx": user._id,
    }


    dispatch({ type: actionTypes.GetWorkspace, payload: payload })
  };
  

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">CHANGE WORKSPACE</div>
              <div className="font-size-sm text-muted mt-2"></div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">

          {/* begin::Items */}
          <div className="flex-grow-1 card-spacer-x"  style={{ overflow: "auto", height: "100%" }}>


            <div>
              {

                workspaces.map((ws, index) =>

                  <div key={index} className="d-flex align-items-center justify-content-between mb-10">
                    <div className="d-flex align-items-center mr-2">
                      <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                        <div className="symbol-label">
                          <span className="svg-icon">
                            <SVG
                              className=" h-50"
                              src={toAbsoluteUrl("/media/svg/misc/006-plurk.svg")}
                            ></SVG>
                          </span>
                        </div>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
                        >

                          {ws.workspaceInfo[0].title} {(currentWorkspace == ("workspace" + ws.workspaceIdx)) ? "[ current ]" : ""}
                        </a>
                        <div className="font-size-sm text-muted font-weight-bold mt-1">
                          {ws.role} | {ws.workspaceUserEmail}
                        </div>
                      </div>
                    </div>
                    {(currentWorkspace == ("workspace" + ws.workspaceIdx)) ? "" : <Button  size="sm" variant="outline-danger" onClick={changeWorkspace.bind(this, index)}>이동</Button>}

                  </div>



                )

              }

            </div>
          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}

    </>
  );


}

