/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import ReactDOM from 'react-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toAbsoluteUrl } from "../../../_helpers";

/* PO_welcome.js 에만 적용되는 css: _pageone.scss */
import "../../../templates/type1/_assets/sass/components/_pageone.scss";

import axios from 'axios';

import { useResizeDetector } from 'react-resize-detector';

import ImageGallery from 'react-image-gallery';
import Slider from "react-slick";
import Utils from "../edit/WebBuilder/Utils";
import ChannelService from "./ChannelService";
import { DownloadFormModal } from "./DownloadFormModal";
import { LoginModal } from "./LoginModal";
import { RequestSiteModal } from "./RequestSiteModal";
import { TargetModal } from "./TargetModal";

const moment = require('moment');

// Example
// https://www.ssg.com/item/itemView.ssg?itemId=1000197958414&siteNo=6001&salestrNo=2034&tlidSrchWd=cj%20%EB%8B%AD%EA%B0%80%EC%8A%B4%EC%82%B4&srchPgNo=1&src_area=ssglist
// https://front.homeplus.co.kr/item?itemNo=138368793&storeType=HYPER
// https://www.lotteon.com/p/product/LM8809029039181?areaCode=RCM2002_P03&sitmNo=LM8809029039181_001&dp_infw_cd=PDD

export function PO_Welcome({ el, onUpdateAutoHeight, states, className }) {

    const { v4: uuidv4 } = require('uuid');

    let id = "component" + el.i

    const fullScreenHandle = useFullScreenHandle();


    const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
    const currentWorkspace = useSelector((state) => state.modl.currentWorkspace, shallowEqual)
    const userId = useSelector((state) => state.auth.user._id, shallowEqual)

    const [taskId, setTaskId] = useState("");
    const [selector, setSelector] = useState("");

    // get source Data from db
    const [data, setData] = React.useState();
    const [selectData, setSelectData] = useState();
    const [isLogin, setIsLogin] = useState(false)

    const [isEnableCrawling, setIsEnableCrawling] = useState(false);
    const [isStartCrawling, setIsStartCrawling] = useState(false);
    const [isDoneCrawling, setIsDoneCrawling] = useState(false);
    const [crawling, setCrawling] = useState(false)


    ChannelService.boot({
        "pluginKey": "48111bf1-dd7c-4679-9138-07de6c6027fb", //please fill with your plugin key
    });


    // =====================================================
    // [S] Resize Event
    // =====================================================
    const onResize = useCallback((width, height) => {
        if (el.isAutoHeight == true) {
            onUpdateAutoHeight(el.i, height)
        }
    }, []);

    const { ref, width, height } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        // refreshMode: 'debounce',
        // refreshRate: 300,
        onResize
    });

    // =====================================================
    // [E] Resize Event
    // =====================================================


    // =====================================================
    // [S] modal - setModalShow(true)
    // =====================================================

    const [downloadFormModalShow, setDownloadFormModalShow] = useState(false)
    const [targetModalShow, setTargetModalShow] = useState(false)
    const [requestSiteModalShow, setRequestSiteModalShow] = useState(false)
    const [loginModalShow, setLoginModalShow] = useState(false)

    useEffect(() => {
        let parent = document.getElementById("contentModal")
        if (parent != null) {
            ReactDOM.render(
                <>
                    <TargetModal
                        show={targetModalShow}
                        onHide={() => setTargetModalShow(false)}
                    />
                    <DownloadFormModal
                        show={downloadFormModalShow}
                        data={{
                            siteIdx: currentSite.split("site").join(""),
                            userIdx: userId,
                            taskId: taskId
                        }}
                        parentCallback={downloadFormModalClosed}
                        onHide={() => setDownloadFormModalShow(false)}
                    />
                    <RequestSiteModal
                        show={requestSiteModalShow}
                        data={{
                            siteIdx: currentSite.split("site").join(""),
                            userIdx: userId
                        }}
                        onHide={() => setRequestSiteModalShow(false)}
                    />
                    <LoginModal
                        show={loginModalShow}
                        onHide={() => setLoginModalShow(false)}
                    />
                </>
                ,
                document.getElementById("contentModal")
            )
        } else {
        }
    });



    // =====================================================
    // [S] DATA
    // =====================================================

    useEffect(() => {
        let _workspaceIdx = currentWorkspace.split("workspace").join("")

        if (_workspaceIdx !== "629db67cca53e246ac6c159d") {
            setIsLogin(true)
        }
        loadData()

    }, []);

    useEffect(() => {
        if (crawling) {
            let interval
            // 상태 변경 ....
            setIsStartCrawling(true)
            setIsDoneCrawling(false)

            let _siteIdx = currentSite.split("site").join("")
            let _workspaceIdx = currentWorkspace.split("workspace").join("")
            let _userIdx = userId
            let title = selectData.source

            let payload = {
                "siteIdx": _siteIdx,
                "userIdx": _userIdx,
                "workspaceIdx": _workspaceIdx,
                "title": title,
                "comment": "",
                "selector": selector,
                "rule": "0 0 0 0 0 ?",
                "updateDate": new Date()
            }

            let _taskId = uuidv4().replace(/-/g, "") // Unique Key
            setTaskId(_taskId)

            payload["taskId"] = _taskId;
            payload["sourceId"] = selectData._id;


            const instance = axios.create({
                // baseURL: 'http://192.168.29.128:4000',
                baseURL: 'http://localhost:4000',
                timeout: 1000,
                headers: { 'Content-Type': 'application/json' }

            });

            instance.post(`startCrawlingSource/`, payload).then((result) => {
                if (result.data == "success") {
                    interval = setInterval(checkCrawling, 2000, _taskId)

                } else {
                    alert("실행할수 없습니다. [수집완료] 상태에서 실행해주세요")
                }



            })
            return () => clearInterval(interval)

        }

    }, [crawling])



    const loadData = () => {

        let payload = {
            queries: [
                {
                    key: "dataList",
                    query: `db.collection('CrawlerSource').aggregate([
                                { $match: {} },
                                { $sort: { order : 1 } },
                                {
                                $project: {
                                    _id: 1,
                                    title: 1,
                                    source: 1,
                                    check: 1
                                }
                                }
                            ]).toArray()`
                }

            ]
        }


        Utils.getQueryResult(payload).then((result) => {

            setData(result)



            if (result.dataList.length != 0) {
                if (selectData == undefined) {
                    setSelectData(result.dataList[0])
                }
            }




        });

    }



    //==============================================================
    // Event (예약어를 제외한 나머지 언급된 setting key )
    //==============================================================

    var functionObj = {
        setting: function () {
            // setModalShow(true)
            return true
        },
        expand: function () {
            fullScreenHandle.enter()

            return true
        },
        embed: function () {
            // src 외부 접근 가능 URL 을 적어주세요
            let copyStr =
                `<iframe width="1280" height="720" 
          src="${axios.defaults.clientURL}/embed/${el.i}" title="MODL VIEWER"
          frameborder="0" allowfullscreen>
          </iframe>`

            console.log(copyStr)
            alert("check console.")

            return true
        },
        test: function (a, b) {
            return a + b
        }
    };


    useEffect(() => {

        if (states[id] == undefined) return
        let method = Object.keys(states[id])[0]
        if (functionObj.hasOwnProperty(method)) {
            functionObj[method]()
        } else {
            console.log("method: not exist \nInsert key in functionObj")
        }

    }, [states[id]]);



    // =====================================================
    // [S] Function
    // =====================================================


    function checkValidateSelector(_selector) {

        // 1.url check
        let _setIsEnableCrawling = []
        let regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        var url = new RegExp(regex, 'i');
        if (url.test(_selector)) {
            _setIsEnableCrawling.push(true)
        } else {
            _setIsEnableCrawling.push(false)
        }


        // 2.include check
        let trueCount = 0
        data.dataList.map((d, index, arr) => {
            if (_selector.includes(d.check)) {
                trueCount = trueCount + 1
                setSelectData(d)
            }
        })

        if (trueCount >= 1) {
            _setIsEnableCrawling.push(true)
        } else {
            _setIsEnableCrawling.push(false)
        }


        // 0.result
        let result = _setIsEnableCrawling.every((item) => item == true)

        return result

    }

    const changeSelector = (e) => {
        setSelector(e.target.value)
        let _selector = e.target.value

        setIsEnableCrawling(checkValidateSelector(_selector))

    }

    const downloadFormModalClosed = (isDownload) => {

        if (isDownload) {
            setSelector("")
            setIsStartCrawling(false)
            setIsEnableCrawling(false)
            setIsDoneCrawling(false)
        }
    }



    // =====================================================
    // [S] Check Crawling
    // =====================================================

    let maxCrawlingCheckCount = 0
    const checkCrawling = (_taskId) => {

        // setTimeout(function (_taskId) {

        maxCrawlingCheckCount = maxCrawlingCheckCount + 1

        if (maxCrawlingCheckCount < 100) {

            let payload = {
                queries: [
                    {
                        key: "checkCrawling",
                        query: `db.collection('CrawlerLog').aggregate([
                { $match: {"taskId":"${_taskId}"} }
              ]).toArray()`
                    },

                ]
            }

            Utils.getQueryResult(payload).then((result) => {
                console.log("크롤링 중입니다...")

                if (result.checkCrawling.length == 0) {
                    // checkCrawling(_taskId)
                } else {

                    setCrawling(false)
                    setIsDoneCrawling(true)
                    setIsStartCrawling(false)

                    alert("크롤링이 완료되었습니다.")

                    loadData()
                }

            });

        }

        // }, 2000, _taskId);
    }

    const stopCrawling = () => {
        alert("크롤링이 중지되었습니다.")

        setIsDoneCrawling(false)
        setIsStartCrawling(false)
    }

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        draggable: false,
        vertical: true
    }


    // =====================================================
    // [S] Call Back
    // =====================================================


    // if (data == undefined) return (<></>)
    // if (selectData == undefined) return (<></>)

    return (
        <div ref={ref}>
            <FullScreen handle={fullScreenHandle}>
                {/* begin::Tiles Widget 1 */}
                <div style={{ minWidth: "1080px", height: "100%" }}>

                    {/* Top Banner */}
                    <div className="po-top-banner">
                        <ImageGallery
                            items={
                                [
                                    {
                                        original: "https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/top_banner_01.png"
                                    },
                                    {
                                        original: "https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/top_banner_02.png"
                                    }
                                ]
                            }
                            showBullets={true}
                            showPlayButton={false}
                            showThumbnails={false}
                            showFullscreenButton={false}
                        />
                    </div>
                    {/* Top Banner */}

                    {/* Insert Url */}
                    <div className="po-insert-url">
                        <strong>상품페이지 URL 입력</strong>
                        <span>페이지 이탈 시 크롤링이 중단됩니다.</span>

                        <div className="po-url-input">
                            <div className="po-input-circle" />
                            <input
                                className="d-block"
                                type="text"
                                placeholder="https:// 지원하는 사이트에서 수집하고 싶은 상품페이지 URL입력"
                                onClick={() => {
                                    if (!isLogin) {
                                        setLoginModalShow(true)
                                    }
                                }}
                                onChange={changeSelector}
                                value={selector || ''}
                                readOnly={!isLogin} />
                        </div>

                        {
                            !isStartCrawling && !isDoneCrawling
                                ?
                                <>
                                </>
                                :

                                (isStartCrawling && !isDoneCrawling)
                                    ?
                                    <>
                                        <div className="po-loading-crawling d-flex align-items-center">
                                            <img className="po-loading-crawling-bg" src={toAbsoluteUrl("/media/pageone/bg_loading_crawling.png")} />
                                            <img className="po-loading-crawling-icon" src={toAbsoluteUrl("/media/pageone/anim_loading_crawling.gif")} />
                                        </div>

                                        <div className="po-loading-crawling-text d-flex justify-content-center">
                                            크롤링 진행중 ⋯
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="po-loading-crawling d-flex align-items-center">
                                            <img className="po-loading-crawling-bg" src={toAbsoluteUrl("/media/pageone/bg_loading_crawling.png")} />
                                            <img className="po-loading-crawling-bg po-sparkles" src={toAbsoluteUrl("/media/pageone/bg_done_crawling.png")} />
                                            <img className="po-loading-crawling-icon" src={toAbsoluteUrl("/media/pageone/icon_done_crawling.png")} />
                                        </div>

                                        <div className="po-done-crawling-text d-flex justify-content-center">
                                            크롤링 완료 !
                                        </div>
                                    </>
                        }

                        <div className="po-url-button d-flex justify-content-center" style={{ marginTop: isDoneCrawling && !isStartCrawling ? "25px" : "" }}>
                            {
                                !isStartCrawling && !isDoneCrawling
                                    ?
                                    <button onClick={() => {

                                        if (!isLogin) {
                                            setLoginModalShow(true)
                                            return
                                        }

                                        if (!isEnableCrawling) {
                                            alert("지원하지 않는 url 입니다.")
                                            return
                                        } else if (window.confirm("크롤링을 시작하시겠습니까?")) {
                                            setCrawling(true)
                                        }


                                    }}>데이터 수집 시작<i className="fas fa-chevron-right" style={{ marginLeft: "15px", marginRight: "0" }} />
                                    </button>
                                    :
                                    (isStartCrawling && !isDoneCrawling)
                                        ?
                                        <button className="url-reset-button" onClick={() => {
                                            setCrawling(false)
                                            stopCrawling()
                                        }}>
                                            <i className="fa fa-redo" />크롤링 URL 리셋하기
                                        </button>
                                        :
                                        <button onClick={() => setDownloadFormModalShow(true)}>
                                            <i className="fas fa-download" />엑셀 파일 다운로드
                                        </button>
                            }

                        </div>
                    </div>
                    {/* Insert Url */}

                    {/* Middle Banner */}
                    <div className="po-middle-banner d-flex align-items-center">

                        <img src={toAbsoluteUrl("/media/pageone/bg_middle_banner.png")} style={{ width: "100%", position: "absolute" }} />

                        <div className="po-banner-box d-flex align-items-center">
                            <img src={toAbsoluteUrl("/media/pageone/bg_middle_banner_content.png")} />

                            <div className="scroll-area">
                                <Slider {...settings}>
                                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/logo_coupang.png" />
                                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/logo_ssg.png" />
                                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/logo_lotte.png" />
                                    <img src="https://wisesolution.s3.ap-northeast-2.amazonaws.com/pageone/logo_homeplus.png" />
                                </Slider>
                            </div>
                            <a onClick={() => setTargetModalShow(true)}>
                                <img src={toAbsoluteUrl("/media/pageone/button_check_site.png")} />
                            </a>
                        </div>
                    </div>
                    {/* Middle Banner */}

                    {/* Q&A */}
                    <div className="po-message">
                        <div className="po-message-question d-flex align-items-end">
                            <img src={toAbsoluteUrl("/media/pageone/bg_message_question.png")} style={{ height: "155px" }} />
                        </div>

                        <div className="po-message-answer d-flex justify-content-end">
                            <img src={toAbsoluteUrl("/media/pageone/bg_message_answer.png")} style={{ height: "160px" }} />
                        </div>
                    </div>
                    {/* Q&A */}

                    {/* Request Rank */}
                    <div className="po-request-rank">

                        <div className="rank-title d-flex align-items-center">
                            <img src={toAbsoluteUrl("/media/pageone/icon_pageone.png")} />
                            <span>유저 신규 요청 사이트 순위</span>
                        </div>

                        <img className="rank-background" src={toAbsoluteUrl("/media/pageone/bg_ranking_back.png")} />
                        {/* 이미지 여백(그림자) 부분 top: 15px left, right: 18px, bottom: 21px */}
                        <img className="best-background" src={toAbsoluteUrl("/media/pageone/bg_best_request.png")} />

                        <div className="rank-body">


                            {/* Best Request */}
                            {/* best-site margin 값은 background 여백 값을 더해 계산함 */}
                            <div className="best-site">

                                <div className="best-site-body d-flex align-items-center">
                                    <img src={toAbsoluteUrl("/media/pageone/best_request.png")} />
                                    <div className="best-site-inform flex-grow-1">
                                        <div className="best-site-name">네이버 쇼핑 - XX몰</div>
                                        <div className="best-divider" />
                                        <div className="best-site-url d-flex align-items-center">
                                            <span>https://www.naver.com/askdjhfkjdhfsfqwfwqf</span>
                                            <a className="best-site-link" href="https://www.naver.com" target="_blank">
                                                <img src={toAbsoluteUrl("/media/pageone/icon_link.png")} />
                                            </a>
                                        </div>
                                        <div className="best-divider" />
                                    </div>
                                </div>

                            </div>
                            {/* Best Request */}

                            {/* Request Rank Table */}
                            <div className="rank-table">
                                <div className="rank-table-header d-flex">
                                    <span>요청순위</span>
                                    <span>사이트명</span>
                                    <span>URL</span>
                                </div>

                                <div className="rank-divider" />

                                <div className="rank-table-body">
                                    <div className="rank-tr d-flex align-items-center">
                                        <span className="td-rank top3">1</span>
                                        <span className="td-divider" />
                                        <span className="td-site top3">네이버 쇼핑 - XX몰asdsfafsafqfqffqfqfa</span>
                                        <span className="td-divider" />
                                        <span className="td-url top3">https://www.naver.com/3ejhekjgfqlk</span>
                                        <a href="https://www.naver.com" target="_blank">
                                            <img className="td-link" alt="link" src={toAbsoluteUrl("/media/pageone/icon_link.png")} />
                                        </a>
                                    </div>
                                    <div className="tr-divider" />

                                    <div className="rank-tr d-flex align-items-center">
                                        <span className="td-rank top3">2</span>
                                        <span className="td-divider" />
                                        <span className="td-site top3">네이버 쇼핑 - XX몰asdsfafsafqfqffqfqfa</span>
                                        <span className="td-divider" />
                                        <span className="td-url top3">https://www.naver.com/3ejhekjgfqlk</span>
                                        <a href="https://www.naver.com" target="_blank">
                                            <img className="td-link" alt="link" src={toAbsoluteUrl("/media/pageone/icon_link.png")} />
                                        </a>
                                    </div>
                                    <div className="tr-divider" />

                                    <div className="rank-tr d-flex align-items-center">
                                        <span className="td-rank top3">3</span>
                                        <span className="td-divider" />
                                        <span className="td-site top3">네이버 쇼핑 - XX몰asdsfafsafqfqffqfqfa</span>
                                        <span className="td-divider" />
                                        <span className="td-url top3">https://www.naver.com/3ejhekjgfqlk</span>
                                        <a href="https://www.naver.com" target="_blank">
                                            <img className="td-link" alt="link" src={toAbsoluteUrl("/media/pageone/icon_link.png")} />
                                        </a>
                                    </div>
                                    <div className="tr-divider" />

                                    <div className="rank-tr d-flex align-items-center">
                                        <span className="td-rank">4</span>
                                        <span className="td-divider" />
                                        <span className="td-site">네이버 쇼핑 - XX몰asdsfafsafqfqffqfqfa</span>
                                        <span className="td-divider" />
                                        <span className="td-url">https://www.naver.com/3ejhekjgfqlk</span>
                                        <a href="https://www.naver.com" target="_blank">
                                            <img className="td-link" alt="link" src={toAbsoluteUrl("/media/pageone/icon_link_gray.png")} />
                                        </a>
                                    </div>
                                    <div className="tr-divider" />

                                    <div className="rank-tr d-flex align-items-center">
                                        <span className="td-rank">5</span>
                                        <span className="td-divider" />
                                        <span className="td-site">네이버 쇼핑 - XX몰asdsfafsafqfqffqfqfa</span>
                                        <span className="td-divider" />
                                        <span className="td-url">https://www.naver.com/3ejhekjgfqlk</span>
                                        <a href="https://www.naver.com" target="_blank">
                                            <img className="td-link" alt="link" src={toAbsoluteUrl("/media/pageone/icon_link_gray.png")} />
                                        </a>
                                    </div>
                                </div>

                                <div className="rank-divider" />
                            </div>
                            {/* Request Rank Table */}

                        </div>
                    </div>
                    {/* Request Rank */}

                    {/* Request Button */}
                    <div className="po-request">
                        <button
                            className="po-request-btn"
                            onClick={() => {
                                if (!isLogin) {
                                    setLoginModalShow(true)
                                } else {
                                    setRequestSiteModalShow(true)
                                }
                            }}
                        >
                            사이트 요청 하기<i className="fas fa-chevron-right" style={{ marginLeft: "12px", color: "#fff" }} />
                        </button>
                    </div>
                    {/* Request Button */}

                    {/* Look Around Wisesolution */}
                    <div className="po-lookaround">
                        <a className="po-lookaround-btn" href="https://www.wisesolution.co.kr" target="_blank"><img src={toAbsoluteUrl("/media/pageone/button_wise.png")} /></a>
                    </div>

                    {/* Look Around Wisesolution */}


                </div>

                {/* end::Tiles Widget 1 */}
            </FullScreen>
        </div>
    );
}


