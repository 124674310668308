/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../../_helpers";
import { CurrentDate } from "../../extras/CurrentDate";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/WiseLayout";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const uiService = useHtmlClassService();
    
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }


    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >


        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>
                {/* <CurrentDate /> */}

                <div style={{ paddingLeft: localStorage.projectName === "pageone" ? "22px" : "16.5px", color: "#000" }} >

                    {
                        (localStorage.projectName == "hyorim") ?
                            (<>
                                <span style={{ fontWeight: "bold" }}>토양·지하수 모니터링 SYSTEM | </span>
                                <span>캠프 워커 및 주변 지역 토양·지하수정화 용역</span>
                            </>
                            ) :
                            (localStorage.projectName == "kici") ?
                                (<>
                                    <span style={{ fontWeight: "bold" }}>키워드 관제 시스템 </span>
                                </>
                                ) :
                                (localStorage.projectName == "pageone") ?
                                    (<>
                                        {/* <span style={{ fontWeight: "bold" }}>PAGEONE | </span>
                                        <span>다이나믹 크롤링 매니져</span> */}

                                        <div  style={{ color: "#b9bdc6", fontWeight: "400", fontSize: "12px" }}>
                                            <button className="m-auto text-center" style={{ height: "25px", borderRadius: "12px", backgroundColor: "#bdcbe2", fontSize: "10px", fontWeight: "300", color: "#fff", border: "none", padding: "0 10px" }}>버전 업데이트 공지</button>
                                            <span style={{ marginLeft: "7px", fontSize: "8.5px", fontWeight: "300", color: "#6b7684" }}>현재 버전 v.1.0</span>
                                        </div>
                                    </>
                                    ) :
                                    <></>
                    }

                    {/* {objectPath.get(uiService.config, "header.menu.self.message")} */}
                </div>
            </li>



            {/*begin::1 Level*/}

            {/*end::1 Level*/}


            {(true) ? (
                <>

                    {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                        <NavLink className="menu-link" to="/dashboard">
                            <span className="menu-text">Dashboard</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li> */}


                    {/*Classic submenu*/}
                    {/*begin::1 Level*/}

                </>
            ) : ""
            }



            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
