
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from 'react-bootstrap';
import { Sweetalert_class } from "./SweetAlert";
import { MdClose } from "react-icons/md";

export function ManageStopWord_Add(props) {
    const { parentId, parentCallback, checkEmailCallback,isCheckEmail, action, selectIndex, menu, memberList,groupAll, siteList, ...rest } = props

    let class_put;

    const [email, setEmail] = useState("");
    const [site_name, set_site_name] = useState("");
    const [name, setName] = useState("");
    const [site_url, set_site_url] = useState("");
    const [site_keyword, set_site_keyword] = useState([]);
    const [site_stopword, set_site_stopword] = useState([]);
    const [choice_keyword, set_choice_keyword] = useState("");
    const [keyword_arr, set_keyword_arr] = useState([]);
    const [keyword_render, set_keyword_render] = useState([]);

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [phone, setPhone] = useState("");
    const [memo, setMemo] = useState("");
    const [existCheckEmail, setExistCheckEmail] = useState([]);
    const [existCheckEmailText, setExistCheckEmailText] = useState("new");

  
    useEffect(() => {
        if (rest.show == true) {
            if (action == "edit") {
                console.log(siteList[selectIndex].filter.length);
                set_site_url(siteList[selectIndex].selector);
                set_site_name(siteList[selectIndex].title);
                
                siteList[selectIndex].filter.length === 2 ? 
                set_keyword_render(siteList[selectIndex].filter[1].rule) : set_keyword_render([]);
            } else if (action == "add") {
                set_site_url("");
                set_site_name("");
                set_keyword_render([]);
                setExistCheckEmail([]);
            }
        }

    }, [rest.show]);


    
    useEffect(() => {
        setExistCheckEmail(isCheckEmail)
    }, [isCheckEmail]);




    useEffect(() => {
        // console.log("existCheckEmail>>")
        // console.log(existCheckEmail)

        if (existCheckEmail != null) {
            if (existCheckEmail.length == 0) {
                setExistCheckEmailText("")

            } else {
                if(action == "add") {setExistCheckEmailText("[존재하는 아이디 입니다. 초대하시겠습니까?]")}

            }
        }
    }, [existCheckEmail]);




    const btnStyle = {
        margin: "2px",
    }


    
    const change_site_name = (e) => {

        const { value } = e.target;
        // let payload = {
        //     "email": e.target.value
        // }
        //checkEmailCallback(payload)
        //setEmail(e.target.value)
        set_site_name(value);
    }

    const change_site_url = (e) => {
        
        const { value } = e.target;
        
        setName(value);
    }

    const change_keyword = (e, param) => {

        const { value } = e.target;
        
        const modify_arr = site_stopword.map((data, index)=> index === param ? (
            data = value
        ) : data )

        set_site_stopword(modify_arr);
    }



    const keyword_add = () => {

        if(choice_keyword === ""){

            class_put = new Sweetalert_class('warning','추가할 수 없습니다!','키워드를 선택해주세요.');
            class_put.crate_alert();
            return false;

        }

        const keyword_flag = keyword_render.includes(choice_keyword);

        if(keyword_flag){

            class_put = new Sweetalert_class('warning','추가할 수 없습니다!','이미 존재하는 키워드입니다.');
            class_put.crate_alert();
            return false;

        } else {
            set_keyword_render([...keyword_render, choice_keyword]);
        }
    }

    // =====================================================
    // [E] 하단 키워드 삭제 이벤트
    // =====================================================
    const remove_keyword = (param) => {
        const new_keyword = keyword_render.filter(data => data !== param);

        set_keyword_render(new_keyword);
    }



    // const getCheckState = (id) => {
    //     // console.log("^^ getCheckState")
    //     let isCheck = false

    //     for (let mList of allowMenuList) {
    //         if (mList.idx == id) {
    //             isCheck = true
    //         }
    //     }

    //     return isCheck
    // }

    let SelectorUI = (groupAll != null)? groupAll.map((_group, index) =>
        <option key={Math.random()} value={_group._id || ''}>{_group.title}</option>
    ):null

    if (selectIndex == -1) return <></>


    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {(action == "add") ? <>불용어 관리</> : <>불용어 관리</>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




                <Form>

                    <Form.Group id="formUserId">
                        <Form.Label>사이트명* {existCheckEmailText}</Form.Label>
                        {
                            (action == "add") ? <Form.Control placeholder="사이트명을 입력해주세요" onChange={change_site_name} value={site_name || ''} />
                                : <Form.Control placeholder="사이트명을 입력해주세요" onChange={change_site_name} value={site_name || ''} readOnly />
                        }
                        
                    </Form.Group>

                    {(action == "edit" || (action == "add" &&(existCheckEmail == null || existCheckEmail.length == 0))) ? (
                    <>
                        <Form.Group id="formUserId">
                            <Form.Label>URL*</Form.Label>
                            <Form.Control placeholder="URL를 입력해주세요"  onChange={change_site_url} value={site_url || ''} readOnly />
                        </Form.Group>
                        {/* <Form.Row>
                        {
                            site_stopword.map((data, index) => (
                                <Form.Group as={Col} id="formPassword" md={6}>
                                    <Form.Label>불용어{Number(index) + 1}*</Form.Label>
                                    <Form.Control placeholder="첫번째 키워드를 입력해주세요"  onChange={(e)=>{ change_keyword(e, index) }} value={!data? "" : data} />
                                </Form.Group>
                            ))
                        }
                        </Form.Row> */}
                        <Form.Group>
                            <Form.Label className="keyword_label">불용어</Form.Label>
                            <Form.Control as="input" className="keyword_option"
                                onChange={(e)=>{ set_choice_keyword(e.target.value) }}
                                value={choice_keyword}
                            ></Form.Control>
                            <Button className="keyword_add_btn" onClick={()=>{ keyword_add() }}>키워드 추가</Button>
                        </Form.Group>
                    </>   
                    )
                        : <></>
                    }



                </Form>
                <div className="keyword_render_wrap">
                    {
                        keyword_render.map((data, index)=>(
                            <div>
                                <p key={`keyword`+index}>{data}</p>
                                <MdClose onClick={()=>{ remove_keyword(data) }}/>
                            </div>
                        ))
                    }
                </div>




            </Modal.Body>
            <Modal.Footer>
            {(action === "edit") &&
                <Button style={btnStyle} onClick={() => {
                    let payload = {
                        "action": "edit",
                        "title": site_name,
                        "url": site_url,
                        "keyword": keyword_render,                                
                        "_id": siteList[selectIndex]._id
                    }
                
                
                    parentCallback(payload)
                    rest.onHide()
                }}> 저장</Button>
            }
                <Button onClick={rest.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}