import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chartSlice, keywordSlice } from '../../../../../app/_redux/modlSlice';

const KiciKeyword = ({el, data, index}) => {

    const dispatch = useDispatch();

    const { filterData } = useSelector((state)=>state.chart)
    const { keyword_data } = useSelector((state)=>state.keyword);

    const { changeKeywordData } = keywordSlice.actions;

    const [list, setList] = useState(!el ? ["재난문자", "다음 IT뉴스", "DC LOL갤", "DC 주갤", "DC 인방갤"] : [el.data.rowdata.source]);
    const [colorList, setColorList] = useState(["#f44336", "#1565c0", "#00bcd4", "#388e3c", "#ffc107"]);

    const { changeChartList } = chartSlice.actions;

    var after_arr = [];

    // =====================================================
    // [S] 키워드 체크
    // =====================================================
    const select_keyword = (param) => {
        const keyword_absence = keyword_data.includes(param);

        if(keyword_absence){
            after_arr = keyword_data.filter((data) => data !== param);
            dispatch(changeKeywordData(after_arr));

            // temp_func();

        } else {
            dispatch(changeKeywordData([...keyword_data, param]));

            // temp_func();
        }
    }

    // const temp_func = () => {
    //     let intersection = filterData.filter(data => keyword_data.includes(data.filter));
    //             console.log(intersection);
    //             if(intersection.length === 0){
    //                 return false
    //             } else {
    //                 let _listData =
    //                     <div className="timeline timeline-6 mt-3">
    //                       {
    //                         intersection.map((d, index, arr) => {
                            
    //                           let color = colorList[0]
    //                           let iArr = []
    //                           list.map((l, i) => {
    //                             if (l == d.source) {
    //                               color = colorList[i]
    //                               iArr = d.filter.split(",")
    //                             }
    //                           })

    //                           let time = d.date.substr(11, 5)
    //                           let title = d.title
                          
    //                           iArr.forEach(function (item, i) {
    //                             let regex = new RegExp(item, "ig");
    //                             title = title.replaceAll(regex, `<span style="color:${color}">${item}</span>`)
                            
    //                           });
                          
    //                           return (
                            
    //                             <div key={Math.random()} className="timeline-item align-items-start">
    //                               <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
    //                                 {time}
    //                               </div>
    //                               <div className="timeline-badge">
    //                                 <i style={{ color: color }} className={`fa fa-genderless icon-xl`}></i>
    //                               </div>
    //                               <div className="timeline-content" onClick={() => window.open(`${d.linkUrl}`, "_blank")}>
    //                                 <div className="font-weight-bolder font-size-md text-dark-60 " >
    //                                   <span style={{ wordBreak: "break-word" }} className="p-3" dangerouslySetInnerHTML={{ __html: title }}></span>
    //                                 </div>
    //                                 <div className="font-size-md text-muted">
    //                                   <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: d.content }}></span>
    //                                   <div style={{ marginLeft: "9px" }} className={`font-size-xs `}>
    //                                     {(d.source == undefined) ? <></> : <span>{d.source}</span>}
    //                                     {(d.from == undefined) ? <></> : <span> {" | "} {d.from}</span>}
    //                                     {(d.author == undefined) ? <></> : <span> {" | "} {d.author}</span>}
    //                                   </div>
    //                                 </div>
    //                               </div>
    //                             </div>
    //                           )
    //                         })
    //                       }
    //                     </div>
    //                 dispatch(changeChartList(_listData))
    //             }
    // }

    // useEffect(()=>{
    //     console.log('진입')
    //     let flag_filtering = true;
        
    //     const load_data = () => {
    //         if(filterData && flag_filtering){

    //             let intersection = filterData.filter(data => keyword_data.includes(data.filter));
    //             console.log(intersection);
    //             if(intersection.length === 0){
    //                 return false
    //             } else {
    //                 let _listData =
    //                     <div className="timeline timeline-6 mt-3">
    //                       {
    //                         intersection.map((d, index, arr) => {
                            
    //                           let color = colorList[0]
    //                           let iArr = []
    //                           list.map((l, i) => {
    //                             if (l == d.source) {
    //                               color = colorList[i]
    //                               iArr = d.filter.split(",")
    //                             }
    //                           })

    //                           let time = d.date.substr(11, 5)
    //                           let title = d.title
                          
    //                           iArr.forEach(function (item, i) {
    //                             let regex = new RegExp(item, "ig");
    //                             title = title.replaceAll(regex, `<span style="color:${color}">${item}</span>`)
                            
    //                           });
                          
    //                           return (
                            
    //                             <div key={Math.random()} className="timeline-item align-items-start">
    //                               <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
    //                                 {time}
    //                               </div>
    //                               <div className="timeline-badge">
    //                                 <i style={{ color: color }} className={`fa fa-genderless icon-xl`}></i>
    //                               </div>
    //                               <div className="timeline-content" onClick={() => window.open(`${d.linkUrl}`, "_blank")}>
    //                                 <div className="font-weight-bolder font-size-md text-dark-60 " >
    //                                   <span style={{ wordBreak: "break-word" }} className="p-3" dangerouslySetInnerHTML={{ __html: title }}></span>
    //                                 </div>
    //                                 <div className="font-size-md text-muted">
    //                                   <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: d.content }}></span>
    //                                   <div style={{ marginLeft: "9px" }} className={`font-size-xs `}>
    //                                     {(d.source == undefined) ? <></> : <span>{d.source}</span>}
    //                                     {(d.from == undefined) ? <></> : <span> {" | "} {d.from}</span>}
    //                                     {(d.author == undefined) ? <></> : <span> {" | "} {d.author}</span>}
    //                                   </div>
    //                                 </div>
    //                               </div>
    //                             </div>
    //                           )
    //                         })
    //                       }
    //                     </div>
    //                 dispatch(changeChartList(_listData))
    //             }
    //         }
    //     }

    //     load_data()

    //     return()=>{
    //         flag_filtering = false;
    //     }
    // }, [keyword_data])

    return(
        <>
            <p key={index}
                className={ keyword_data.includes(data) ? `active_filter` : '' }
                onClick={()=>{ select_keyword(data) }}
            >{data && data}</p>
        </>
    )
}

export default KiciKeyword;