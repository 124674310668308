import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../_helpers";
import Utils from "../edit/WebBuilder/Utils";

import * as XLSX from 'xlsx';
import moment from "moment";


export function DownloadFormModal(props) {

    const { parentId, parentCallback, ...rest } = props
    const [visitPath, setVisitPath] = useState("");
    const [branch, setBranch] = useState("");

    const [purpose, setPurpose] = useState("");

    const visitPathList = ["선택", "포털 사이트 검색", "지인 추천", "블로그", "와이즈솔루션 홈페이지 방문", "기타"];
    const branchList = ["선택", "IT", "마케팅", "소,도매업", "제조업", "자영업", "학생", "기타"];

    useEffect(() => {

        if (props.show) {

        }
    })

    const handleVisitPath = (e) => {
        setVisitPath(e.target.value)
    }

    const handleBranch = (e) => {
        setBranch(e.target.value)
    }

    const handlePurpose = (e) => {
        setPurpose(e.target.value)
    }

    const submitForm = () => {

        if (!visitPath || !branch || !purpose) {
            alert("문항을 작성해주세요")
            return
        }

        const obj = {
            siteIdx: props.data.siteIdx,
            userIdx: props.data.userIdx,
            visitPath: visitPath,
            branch: branch,
            purpose: purpose,
            registDate: new Date()
        }


        let payload = {
            queries: [
                {
                    key: "insertForm",
                    query: `db.collection('VisitPath').insertOne(${JSON.stringify(obj)})`
                },

            ]
        }

        Utils.getQueryResult(payload).then((result) => {
            // form 초기화
            setVisitPath("")
            setBranch("")
            setPurpose("")

            downloadExcel()
        });

    }

    const downloadExcel = () => {
        let payload = {
            queries: [
                {
                    key: "dataList",
                    query: `db.collection('CrawlerData').aggregate([
                        { $match: {"taskId":"${props.data.taskId}"} }
                    ]).toArray()`
                },
                {
                    key: "logList",
                    query: `db.collection('CrawlerLog').aggregate([
                        { $match: {"taskId":"${props.data.taskId}"}},
                        { $project: {title: 1}}
                    ]).toArray()`
                }

            ]
        }

        Utils.getQueryResult(payload).then((result) => {

            let excelData = result.dataList
            let resultData = excelData.map((item) => {
                delete item["_id"]
                delete item["url"]
                delete item["tplId"]
                delete item["taskId"]
                delete item["siteIdx"]
                delete item["userIdx"]
                delete item["workspaceIdx"]
                delete item["filter"]
                delete item["registDate"]
                return item
            })

            if (resultData.length == 0) {
                alert("데이터가 없습니다.")
            } else {
                alert("제출되었습니다.")

                const ws = XLSX.utils.json_to_sheet(resultData);
                const wb = XLSX.utils.book_new();
                const fileName = `${moment(new Date()).format("YYMMDD")}_${result.logList[0].title}_상세페이지_${Math.floor(Math.random() * 10000)}`

                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                XLSX.writeFile(wb, `${fileName}.xlsx`);

                parentCallback(true)

            }

            rest.onHide()
        })
    }

    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <a onClick={() => rest.onHide()}>
                    <img className="po-modal-close" src={toAbsoluteUrl("/media/pageone/button_modal_close.png")} />
                </a>
                <div className="po-modal-title d-flex">
                    user servey
                </div>

                <div className="po-modal-title-divider" />

                <div className="po-download-form">
                    <div className="po-visit-path-title d-flex align-items-center">
                        <img src={toAbsoluteUrl("/media/pageone/icon_pageone_gray.png")} />
                        <span>Pageone은 어떻게 방문하게 되었나요?</span>
                    </div>

                    <div className="po-visit-path">
                        <label>
                            방문경로
                        </label>

                        <select onChange={handleVisitPath} value={visitPath} name="visit-path">
                            {
                                visitPathList.map((item, idx) => (
                                    idx === 0
                                        ?
                                        <option value="" key={Math.random()}>{item}</option>
                                        :
                                        <option value={item} key={Math.random()}>{item}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="po-purpose-title d-flex align-itmes-center">
                        <img src={toAbsoluteUrl("/media/pageone/icon_pageone_gray.png")} />
                        <span>수집한 데이터는 어떤 목적으로 사용할 예정인가요?</span>
                    </div>

                    <div className="po-purpose">
                        <label>
                            분야
                        </label>

                        <select onChange={handleBranch} value={branch} name="branch">
                            {
                                branchList.map((item, idx) => (
                                    idx === 0
                                        ?
                                        <option value="" key={Math.random()}>{item}</option>
                                        :
                                        <option value={item} key={Math.random()}>{item}</option>
                                ))
                            }
                        </select>

                        <label>
                            사용목적
                        </label>

                        <textarea onChange={handlePurpose} placeholder="직접입력">

                        </textarea>
                    </div>

                    <div className="po-survey-submit">
                        <a onClick={submitForm}>
                            <img src={toAbsoluteUrl("/media/pageone/button_submit_survey.png")} />
                        </a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}