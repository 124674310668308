import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { login_naver } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

function LoginNaver(props) {

    const currentSite = useSelector((state) => state.modl.currentSite, shallowEqual)
    const location = useLocation()

    const getNaverToken = () => {
        if (!location.hash) return
        const token = location.hash.split('=')[1].split('&')[0]
        const siteIdx = currentSite.split("site").join("")

        const obj = {
            "token": token,
            "siteIdx": siteIdx
        }

        // console.log(props)
        // return
        props.logout()
        setTimeout(() => {
            // connect(null, auth.actions)(Logout)
            login_naver(obj)
                .then(resp => {
                    console.log("loginNaver ======== resp")
                    console.log(resp)

                    if (resp.status === 200 && resp.statusText === "OK") {
                        console.log(resp.data.data.login_naver)
                        // alert('로그인 성공')
                        props.login_naver(resp.data.data.login_naver);
                        document.getElementById("loginLink").click()
                    } else {
                        alert("로그인 실패");
                    }

                })
                .catch(() => {

                });
        });

    }

    useEffect(() => {
        getNaverToken()
    }, [])

    return (
        <>
            <Link to="/auth/login" id="loginLink" style={{ display: "none" }} />
        </>
    )
}

export default injectIntl(connect(null, auth.actions)(LoginNaver));