/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';

import { FullScreen, useFullScreenHandle } from "react-full-screen";



import { CKEditor } from "@ckeditor/ckeditor5-react";
// Modl_ckeditor5 를 빌드해서 나오는 build/* 파일을 아래 경로에 붙여 넣는다
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';



import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../../redux/actionType";

import { UnitTextEditor } from "../edit/WebBuilder/Element/UnitTextEditor";
import { TextEditorSetting } from "./TextEditorSetting";
import { elementType } from "prop-types";


import Utils from "../edit/WebBuilder/Utils";


export function TextEditor({ el, states, className }) {

  let id = "component" + el.i

  // const dispatch = useDispatch();
  // const currentPage = useSelector((state) => state.modl.currentPage, shallowEqual)
  // const [theEditor, setTheEditor] = useState();
  // const [isReadOnly, setIsReadOnly] = React.useState(true);
  // const [value, setValue] = useState('');

  const fullScreenHandle = useFullScreenHandle();



  // =====================================================
  // [S] modal - setModalShow(true)
  // =====================================================

  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    let parent = document.getElementById("contentModal")
    if (parent != null) {
      ReactDOM.render(
        <TextEditorSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          parentCallback={selectSetting}
        />,
        document.getElementById("contentModal"))
    } else {
    }
  });


  const selectSetting = (data) => {
    console.log('callback: ' + data)
  };


  // =====================================================
  // [E] modal - setModalShow(true)
  // =====================================================








  //==============================================================
  // [S] Event (예약어를 제외한 나머지 언급된 setting key )
  //==============================================================

  var functionObj = {
    edit: function () {
      setIsEdit(!isEdit)
      return true
    }
  };

  useEffect(() => {

    if (states[id] == undefined) return
    let method = Object.keys(states[id])[0]
    if (functionObj.hasOwnProperty(method)) {
      functionObj[method]()
    } else {
      console.log("method: not exist \nInsert key in functionObj")
    }

  }, [states[id]]);






  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================

  const [values, setValues] = useState({});
  const [data, setData] = React.useState("");
  const [layout, setLayout] = React.useState();
  const [isEdit, setIsEdit] = useState(false);


  // =====================================================
  // [S] Handle Event (handleEvent)
  // ====================================================

  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  let field1 = "7J1kudaO9"
  let field2 = "ZRd_IEk7j"

  var handleEventObj = {

    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다 
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      console.log("handleEvent > method : " + method)
      console.log("handleEvent > id : " + id)
      console.log("handleEvent > value : " + value)

      setValues(previousState => ({ ...previousState, [id]: value }));


      return true
    },

    test: function (method, id, ...value) {
      console.log("handleEvent > method : " + method)
      console.log("handleEvent > id : " + id)
      console.log("handleEvent > value : " + value)


      return true
    },
    edit: function (method, id, value) {
      console.log("handleEvent > method : " + method)
      console.log("handleEvent > id : " + id)
      console.log("handleEvent > value : " + value)

      setIsEdit(value)

      return true
    }

  };


  const handleEvent = (method, id, ...value) => {

    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }

  }









  return (
    <>
      <FullScreen handle={fullScreenHandle}>

        <div className={`card card-custom ${className}`}>

          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder">TextEditor
                </div>
                <div className="font-size-sm text-muted mt-2"></div>

              </div>
            </div>
          </div>

          <div className="card-body d-flex flex-column px-0">
            <div className="flex-grow-1 card-spacer-x" style={{ overflow: "auto" }}>
              <UnitTextEditor el={el} id={field1} data={data} isEdit={isEdit} onEvent={handleEvent}></UnitTextEditor>
            </div>

          </div>

        </div>

      </FullScreen>
    </>
  );

}




