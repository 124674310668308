/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef } from "react";
import ChartCommon from "../edit/WebBuilder/Element/ChartCommon";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import {
  Col,
  Container,
  Row,
  Form
} from "react-bootstrap";
import KiciDateInput from "../../func-component/KiciDateInput";
import RenderingHooks from "./customHooks/RenderingHooks";


export function KICI_Dashboard({ className, el }) {

  const { chartData, chartList, recentCrawling } = useSelector((state) => state.chart);

  // =============================================
  // [S] FOR Controller(inputField) TEST
  // =============================================
  const [values, setValues] = useState({});


  // =====================================================
  // [S] Handle Event (handleEvent)
  // =====================================================

  // Layout 에서 id 를 가져와서 키값에 넣는다 ...

  let field1 = "7J1kudaO9"
  let field2 = "ZRd_IEk7j"


  // =====================================================
  // [S] FOR FCM REGIST
  // =====================================================
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control type="text" onClick={onClick} ref={ref} value={value} readOnly style={{ width: "120px" }} />
  ))

  var handleEventObj = {

    // =====================================================
    // [S] 설명 :
    // saveState : {id:value} 로 state에 저장한다 
    // =====================================================

    // saveState
    saveState: function (method, id, value) {
      setValues(previousState => ({ ...previousState, [id]: value }));
      return true
    },

    test: function (method, id, ...value) {
      return true
    }
  };


  const handleEvent = (method, id, ...value) => {
    if (handleEventObj.hasOwnProperty(method)) {
      handleEventObj[method](method, id, ...value)
    } else {
      console.log("method: not exist \nInsert key in handleEventObj")
    }
  }
  

  console.log(JSON.stringify(chartList, null, 2))

  // =====================================================
  // [S] 컴포넌트 초기화시 데이터 로드 
  // =====================================================
  const rendering_result = RenderingHooks();


  return (
    <>

      <Container fluid className={"p-0"}>
        <Row key={Math.random()} >
          <Col className="mb-5" xs={12} md={12}>
            <div className={`card card-custom ${className}`} >
              <div className="card-header border-0">
                <h3 className="card-title font-weight-bolder text-dark">
                  데이터 수집량
                  <div style={{ marginLeft: "5px" }}>
                    <span className="text-muted mt-2 font-weight-bold font-size-sm">
                      {recentCrawling && recentCrawling} updated.
                    </span>
                  </div>
                </h3>
                <div className="d-flex align-items-center flex-wrap input_box" style={{ padding: "10px 0" }}>
                  <KiciDateInput />
                </div>
              </div>
              <div className="card-body pt-2">
                {chartData && <ChartCommon chartData={chartData} type={el.data.source} />}
              </div>
            </div>
          </Col>
        </Row>
        <Row key={Math.random()}  >
          <Col className="mb-5" xs={12} md={12}>
            <div className={`card card-custom  ${className}`}>
              <div className="card-header border-0 assemble_header">
                <h3 className="card-title font-weight-bolder text-dark">
                  전체내역
                </h3>
              </div>
              <div className="card-body pt-4">
                {chartList && chartList}
              </div>
            </div>
          </Col>
        </Row>
      </Container >
    </>
  );
}
