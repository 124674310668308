import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loading = ({color, size}) => {
    return(
        <div className='loading_wrap'>
            <ClipLoader 
                color={color}
                size={size}
            />
        </div>
    )
}

export default Loading;