/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link, Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_wise/templates/type1/layout";
import "../../../../_wise/templates/type1/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../../_wise/_helpers";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Registration from "./Registration";


import { useScreenClass } from 'react-grid-system';

export function AuthPage() {

  // console.log("> index"); 
  // console.log(localStorage.LayoutConfig); 
  // console.log(store)

  const screenClass = useScreenClass()

  const backgroudStyle = ['xs'].includes(screenClass) ? {} : {
    backgroundImage: `url(${toAbsoluteUrl("/media/logos/login_image2.png")})`,
    backgroundRepeat: 'no-repeat',
    borderLeft: '1px solid #ebebed',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };


 // ----------------------------------------------------------------------------------------------------
    // kici
    // ----------------------------------------------------------------------------------------------------
    return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
          >

            {/*begin::Content*/}
            <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
              {/*begin::Content header*/}

              {/* 회원 등록 (register) */}

              {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}




              {/*end::Content header*/}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute
                    path="/auth/registration"
                    component={Registration}
                  />
                  <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  {/* <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" /> */}
                </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2020 Wise
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div> */}
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
    );


}
